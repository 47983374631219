import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Panel } from '../Panel'
import { ProfileDetailForm, ProfileDetailFormRef } from './ProfileDetailForm'
import { useDispatch, useSelector } from '../../store'
import { showCreateDeviceAccessPanel } from 'store/slices/deviceAccess'
import { showHttpError } from 'utils/helpers'
import { ADD_PROFILES } from 'lib/mutations/profile'
import { useMutation } from '@apollo/client'

export const AddProfilePanel = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef<ProfileDetailFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { creatingDeviceAccess } = useSelector((state) => state.deviceAccesses)
  const [isValid, setIsValid] = useState(false)
  const [addProfile, { data, loading, error }] = useMutation(ADD_PROFILES)

  const onSubmit = async () => {
    const formData = await formRef.current.getFormData()
    if (formData) {
      addProfile({
        variables: { dto: formData },
      })
    }
    if (formData) {
      if (loading) return 'Submitting...'
      if (error)
        return showHttpError(enqueueSnackbar, error, 'Creating profile failed')

      enqueueSnackbar('Created profile successfully', {
        variant: 'success',
      })
      onClose()
    }
  }

  const onClose = () => {
    dispatch(showCreateDeviceAccessPanel(false))
  }

  return (
    <Panel
      className="w-157"
      open={creatingDeviceAccess}
      title={t('addProfilePanel.title') as string}
      submitButtonText={t('addProfilePanel.submit') as string}
      isValid={isValid}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
    >
      {creatingDeviceAccess && (
        <ProfileDetailForm formRef={formRef} onValidate={setIsValid} />
      )}
    </Panel>
  )
}
