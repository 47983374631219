import { FC, MutableRefObject, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InputAdornment, MenuItem } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { Panel } from '../Panel'
import { RoleUsersTable } from './RoleUsersTable'
import {
  FormProvider,
  RHFAutocomplete,
  RHFSelect,
  RHFTextarea,
  RHFTextField,
} from 'common-lib/components/RHFControls'
import { User } from 'common-api/clients/system/typescript'
import { Role } from 'gql/graphql'
import { useSelector } from 'store'

export interface RoleDetailFormData {
  name: string
  color: string
  description?: string
  users: string[]
}

export interface RoleDetailFormRef {
  getFormData(): Promise<RoleDetailFormData | undefined>
}

export interface RoleDetailFormProps {
  className?: string
  role?: Role
  formRef: MutableRefObject<RoleDetailFormRef>
  onValidate(isValid: boolean): void
}

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .trim()
      .required('This field is required')
      .matches(
        /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
        'This field accepts only lowercase alphanumeric characters with "-" between two words'
      ),
    color: yup.string().trim().notRequired(),
    description: yup.string().notRequired(),
    users: yup.array().notRequired(),
  })
  .required()

const colorOptions = [
  { value: 'green', color: '#56BC2B' },
  { value: 'blue', color: '#29B6F6' },
  { value: 'orange', color: '#FFAB31' },
  { value: 'red', color: '#EA4040' },
]

export const RoleDetailForm: FC<RoleDetailFormProps> = ({
  className,
  role,
  formRef,
  onValidate,
}) => {
  const users = useSelector((state) => state.users.users.byName)
  const { t } = useTranslation()
  let roleUserIds: string[] = []
  if (role) {
    roleUserIds = Object.values(users)
      .filter((user) => user.spec.roles.includes(role!.name))
      .map((user) => user.metadata.name)
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: role?.name || '',
      color: role?.color || 'green',
      description: role?.description || '',
      users: roleUserIds,
    },
  })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = () => {
        return new Promise((resolve) => {
          methods.handleSubmit(
            (data) => {
              resolve(data)
            },
            (err) => {
              console.log(err)
              resolve(undefined)
            }
          )()
        })
      }
    }
  }, [formRef])

  useEffect(() => {
    if (role) {
      methods.trigger()
    }
  }, [role])

  const userOptions = useMemo(() => {
    return Object.values(users).map((user: User) => ({
      id: user.metadata.name,
      name: `${user.spec.firstName} ${user.spec.lastName}`,
    }))
  }, [users])

  const getUserName = (id: string) => {
    return userOptions.find((option) => option.id === id)?.name || id
  }

  const formData = methods.watch()
  const color = formData.color
  const userIds = formData.users
  const colorOption = colorOptions.find((option) => option.value === color)

  useEffect(() => {
    onValidate(methods.formState.isValid)
  }, [formData, onValidate])

  const onDeselectUser = (userId: string) => {
    methods.setValue(
      'users',
      userIds.filter((id) => id !== userId)
    )
  }

  return (
    <FormProvider
      className={className}
      methods={methods}
      data-testid="role-detail-form"
    >
      <Panel.Section className="grid grid-cols-12 gap-x-6 gap-y-4">
        <RHFTextField
          className="col-span-8"
          name="name"
          label={`${t('roleAdd.roleName')}*`}
          disabled={!!role}
        />
        <RHFSelect
          className="col-span-4"
          name="color"
          label={t('roleAdd.color')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div
                  className="h-6 w-6 rounded"
                  style={{ background: colorOption?.color }}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            '.MuiSelect-select': {
              opacity: 0,
            },
          }}
        >
          {colorOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <div
                className="h-6 w-6 rounded"
                style={{ background: option.color }}
              />
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFTextarea
          className="col-span-12"
          name="description"
          label={t('common.descriptionOptional')}
          minRows={4}
        />
      </Panel.Section>

      <Panel.Section title={t('common.users')} containerClass="mt-6">
        <RHFAutocomplete
          name="users"
          label={t('roleAdd.addUser') as string}
          placeholder={t('roleAdd.addUserPlaceholder') as string}
          multiple
          checkbox
          options={userOptions.map((item) => item.id)}
          getOptionLabel={getUserName}
          renderTags={() => null}
        />

        <RoleUsersTable
          className="mt-6"
          roleName={role?.name}
          userIds={userIds}
          size="small"
          deletable
          onDeleteUser={onDeselectUser}
        />
      </Panel.Section>
    </FormProvider>
  )
}
