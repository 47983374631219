import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {
  Device,
  DeviceList,
  DeviceSpec,
  Recorder,
} from 'common-api/clients/recorder/typescript'
import { List, NamedMap } from './common'
import * as devicesService from 'services/openapi/device'
import { todoChangeMeNamespace } from 'services/openapi/types'
import { WatchEvent, WatchEventType } from 'services/openapi/watch'
import { parseAxiosError } from 'utils/helpers'

export interface CreatingDeviceInfo {
  recorder: Recorder
}

export interface EditingDeviceInfo {
  deviceName: string
  channelName?: string
  streamName?: string
}

export interface DevicesState {
  devices: List<Device>
  creatingDevice?: CreatingDeviceInfo
  editingDevice?: EditingDeviceInfo
  deletingDevice?: string
}

const initialState: DevicesState = {
  devices: { byName: {}, loading: false },
}

export const fetchDevices = createAsyncThunk<
  DeviceList,
  void,
  { state: RootState }
>(
  'devices/fetchDevices',
  async () => {
    // TODO: Filter out objects with deletionTimestamp set
    return devicesService
      .listDevices(todoChangeMeNamespace)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (_, { getState }) => {
      const { devices } = getState()
      // don't fetch again, if we're already fetching
      return !devices.devices.loading
    },
  }
)

export const createDevice = createAsyncThunk<
  Device,
  DeviceSpec,
  { state: RootState }
>('devices/createDevice', async (payload, api) => {
  const { creatingDevice } = api.getState().devices
  return devicesService
    .createDevice(
      todoChangeMeNamespace,
      creatingDevice!.recorder.metadata.name,
      payload
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

export interface PatchDeviceRequest {
  name: string
  before: DeviceSpec
  after: DeviceSpec
}

export const updateDevice = createAsyncThunk<Device, PatchDeviceRequest>(
  'devices/updateDevice',
  async (payload) => {
    return devicesService
      .patchDevice(
        payload.name,
        todoChangeMeNamespace,
        payload.before,
        payload.after
      )
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

export const deleteDevice = createAsyncThunk<string, string>(
  'devices/deleteDevice',
  async (id) => {
    return devicesService
      .deleteDevice(todoChangeMeNamespace, id)
      .then(() => id)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

const devices = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setCreatingDevice: (
      state,
      action: PayloadAction<CreatingDeviceInfo | undefined>
    ) => {
      state.creatingDevice = action.payload
    },
    setEditingDevice: (
      state,
      action: PayloadAction<EditingDeviceInfo | undefined>
    ) => {
      // TODO: Break this out into another reducer?
      state.editingDevice = action.payload
    },
    setDeletingDevice: (state, action: PayloadAction<string | undefined>) => {
      state.deletingDevice = action.payload
    },
    watchDeviceChange: (state, action: PayloadAction<WatchEvent<Device>>) => {
      const device = action.payload.object
      switch (action.payload.type) {
        case WatchEventType.ADDED:
          state.devices.byName[device.metadata.name] = device
          break
        case WatchEventType.MODIFIED:
          if (device.metadata.deletionTimestamp) {
            delete state.devices.byName[device.metadata.name]
          } else {
            state.devices.byName[device.metadata.name] = device
          }
          break
        case WatchEventType.DELETED:
          delete state.devices.byName[device.metadata.name]
          break
        default:
          console.log('unknown watch event', action.payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDevice.fulfilled, (state, action) => {
        const device = action.payload
        if (device.metadata.deletionTimestamp !== undefined) {
          return
        }
        state.devices.byName[device.metadata.name] = device
        state.devices.resourceVersion = action.payload.metadata.resourceVersion
      })
      .addCase(fetchDevices.pending, (state) => {
        state.devices.loading = true
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        const devices = {} as NamedMap<Device>
        action.payload.items.forEach((device) => {
          // skip users being deleted
          if (device.metadata.deletionTimestamp !== undefined) {
            return
          }
          devices[device.metadata.name] = device
        })
        state.devices.byName = devices
        state.devices.loading = false
        state.devices.resourceVersion = action.payload.metadata.resourceVersion
      })
      .addCase(updateDevice.fulfilled, (state, action) => {
        state.devices.byName[action.payload.metadata.name] = action.payload
        state.devices.resourceVersion = action.payload.metadata.resourceVersion
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        delete state.devices.byName[action.payload]
        state.creatingDevice = undefined
        state.editingDevice = undefined
        state.deletingDevice = undefined
      })
  },
})

export const {
  setCreatingDevice,
  setEditingDevice,
  setDeletingDevice,
  watchDeviceChange,
} = devices.actions

export default devices.reducer
