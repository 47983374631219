import { useEffect } from 'react'
import {
  VideoChannel,
  VideoStream,
} from 'common-api/clients/recorder/typescript'

import {
  RECORDER_API_PATH,
  todoChangeMeNamespace,
} from 'services/openapi/types'
import { watch } from 'services/openapi/watch'
import { useDispatch, useSelector } from '../../store'
import {
  fetchVideoChannels,
  watchVideoChannelChange,
} from 'store/slices/videoChannels'
import {
  fetchVideoStreams,
  watchVideoStreamChange,
} from 'store/slices/videoStreams'

export interface VideoChannelsLoaderProps {
  children: JSX.Element
}

export const VideoChannelsLoader = ({ children }: VideoChannelsLoaderProps) => {
  const dispatch = useDispatch()

  const channelsRV = useSelector(
    (state) => state.videoChannels.videoChannels.resourceVersion
  )
  const streamsRV = useSelector(
    (state) => state.videoStreams.videoStreams.resourceVersion
  )

  useEffect(() => {
    dispatch(fetchVideoChannels())
    dispatch(fetchVideoStreams())
  }, [])

  useEffect(() => {
    if (!channelsRV) {
      return
    }

    const { abort } = watch<VideoChannel>(
      RECORDER_API_PATH,
      'channels',
      channelsRV,
      (event) => dispatch(watchVideoChannelChange(event)),
      todoChangeMeNamespace
    )

    return abort
  }, [channelsRV])

  useEffect(() => {
    if (!streamsRV) {
      return
    }

    const { abort } = watch<VideoStream>(
      RECORDER_API_PATH,
      'streams',
      streamsRV,
      (event) => dispatch(watchVideoStreamChange(event)),
      todoChangeMeNamespace
    )

    return abort
  }, [streamsRV])

  return children
}
