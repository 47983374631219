import React, { FC, ReactNode, useMemo } from 'react'
import classNames from 'classnames'
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material'
import { Info } from '@mui/icons-material'

export type TooltipSeverity =
  | 'primary'
  | 'success'
  | 'info'
  | 'warning'
  | 'error'

export interface TooltipProps extends MuiTooltipProps {
  severity?: TooltipSeverity
  icon?: ReactNode | null
  disabled?: boolean
}

const defaultIcons: Record<string, ReactNode> = {
  info: <Info fontSize="small" />,
  warning: <Info fontSize="small" />,
  error: <Info fontSize="small" />,
}

export const Tooltip: FC<TooltipProps> = ({
  title,
  severity,
  icon,
  disabled,
  children,
  ...props
}) => {
  const content = useMemo(() => {
    let tooltipIcon = icon
    if (tooltipIcon === undefined && severity) {
      tooltipIcon = defaultIcons[severity]
    }

    return (
      <div className="flex items-start">
        {tooltipIcon && <div className="mt-1 mr-3">{tooltipIcon}</div>}
        <div>{title}</div>
      </div>
    )
  }, [title, icon, severity])

  if (!title || disabled) {
    return children
  }

  return (
    <MuiTooltip
      title={content}
      {...props}
      classes={{
        tooltip: classNames(
          severity === undefined && 'bg-secondary-900 text-white',
          severity === 'info' && 'bg-info-100 text-neutral-800',
          severity === 'warning' && 'bg-warning-200 text-neutral-800'
        ),
        arrow: classNames(
          severity === undefined && 'text-secondary-900',
          severity === 'info' && 'text-info-100',
          severity === 'warning' && 'text-warning-200'
        ),
      }}
    >
      {children}
    </MuiTooltip>
  )
}
