import { useFormContext, Controller } from 'react-hook-form'
import { Autocomplete, AutocompleteProps } from '../FormControls'

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string
}

export const RHFAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  helperText,
  ...other
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) =>
            setValue(name, newValue, { shouldValidate: true })
          }
          {...other}
          error={!!error}
          helperText={error ? error?.message : helperText}
        />
      )}
    />
  )
}
