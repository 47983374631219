import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Sidebar } from 'components/Sidebar'
import { PERMISSIONS } from 'common-lib/constants/permissions'
import { UsersPage } from './Users'
import { RolesPage } from './Roles'
import { ProfilesPage } from './Profiles'
import { RecordersPage } from './Recorders'
import { selectAccount } from 'store/selectors'
import { hasPermission } from 'utils/helpers'

export const MainPage = () => {
  const account = useSelector(selectAccount)

  return (
    <div className="flex h-full bg-neutral-800 text-white">
      <Sidebar />
      <div className="w-0 flex-1">
        <Routes>
          {hasPermission(account, PERMISSIONS.USER.READ) && (
            <Route path="/user-management/users/*" element={<UsersPage />} />
          )}
          {hasPermission(account, PERMISSIONS.ROLE.READ) && (
            <Route path="/user-management/roles/*" element={<RolesPage />} />
          )}
          {hasPermission(account, PERMISSIONS.RECORDER.READ) && (
            <Route path="/recorders/*" element={<RecordersPage />} />
          )}
          {hasPermission(account, PERMISSIONS.DEVICE_ACCESS.READ) && (
            <Route path="/profiles/*" element={<ProfilesPage />} />
          )}
          <Route
            path="*"
            element={
              <div className="flex-center h-full w-full text-xl">
                404 | Page Not Found
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  )
}
