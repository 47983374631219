import { AxiosError, CanceledError } from 'axios'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

type EnqueueSnackBar = (
  message: SnackbarMessage,
  options?: OptionsObject
) => SnackbarKey

export const showHttpError = (
  enqueueSnackbar: EnqueueSnackBar,
  err: Error,
  defaultMessage: SnackbarMessage
) => {
  if (err instanceof CanceledError) {
    return
  }

  enqueueSnackbar(err?.message || defaultMessage, { variant: 'warning' })
}

export const parseAxiosError = (err: AxiosError<any>) => {
  return new Error(err.response?.data?.message || err.message)
}
