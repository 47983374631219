import axios from 'axios'
import { AUTH_SERVER_URI, SSO_AUTH_URL } from 'common-lib/constants/endpoints'
import { COOKIE_ITEM, CookieService } from './cookies'

const logout = () => {
  CookieService.remove(COOKIE_ITEM.ACCESS_TOKEN)
  CookieService.remove(COOKIE_ITEM.REFRESH_TOKEN)
  window.location.href = `${SSO_AUTH_URL}/login?redirectTo=${window.location.href}`
}

const getCurrentUser = async (access_token: string) => {
  try {
    const response = await axios.get(AUTH_SERVER_URI + '/users/me', {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

const AuthService = {
  logout,
  getCurrentUser,
}

export default AuthService
