import { ForwardedRef, forwardRef, ReactNode, useCallback } from 'react'
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  Checkbox,
  Chip,
  MenuItem,
  TextField,
} from '@mui/material'
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  label?: string
  checkbox?: boolean
  error?: boolean
  helperText?: ReactNode
}

const FAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  {
    label,
    placeholder,
    checkbox,
    limitTags,
    getOptionLabel,
    error,
    helperText,
    onChange,
    ...other
  }: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: ForwardedRef<any>
) => {
  const onChangeValue = useCallback(
    (value: any, reason?: AutocompleteChangeReason) => {
      if (onChange) {
        onChange(new Event('change') as any, value, reason || 'selectOption')
      }
    },
    [onChange]
  )

  const renderTags = useCallback(
    (values: T[]) => {
      if (limitTags === undefined) {
        return undefined
      }
      if (!values.length) {
        return null
      }
      if (!limitTags) {
        return (
          <div className="mx-0.5">
            <Chip
              className="mx-0.5"
              size="small"
              label={`${values.length} item${values.length > 1 ? 's' : ''}`}
              onDelete={() => onChangeValue([], 'clear')}
            />
          </div>
        )
      }
      return (
        <div className="mx-0.5 flex flex-wrap items-center gap-0.5">
          {values.slice(0, limitTags).map((value, i) => (
            <Chip
              key={i}
              size="small"
              label={value as string}
              onDelete={() =>
                onChangeValue(
                  values.filter((_, index) => index !== i),
                  'removeOption'
                )
              }
              sx={{ maxWidth: 160 }}
            />
          ))}
          {values.length > limitTags && (
            <span className="ml-1">+{values.length - limitTags}</span>
          )}
        </div>
      )
    },
    [limitTags, onChangeValue]
  )

  return (
    <MuiAutocomplete
      disableCloseOnSelect={other.multiple}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          className="Mui-select"
          label={label}
          error={error}
          placeholder={placeholder}
          helperText={helperText}
          {...params}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderOption={(props, option, state) => {
        return (
          <MenuItem {...props}>
            <>
              {checkbox && (
                <Checkbox
                  disableRipple
                  checked={state.selected}
                  sx={{ p: 0, mr: 2 }}
                />
              )}

              {getOptionLabel ? getOptionLabel(option) : option}
            </>
          </MenuItem>
        )
      }}
      renderTags={renderTags}
      onChange={onChange}
      {...other}
      ref={ref}
    />
  )
}

export const Autocomplete = forwardRef<
  unknown,
  AutocompleteProps<any, any, any, any>
>(FAutocomplete)
