import { useMemo } from 'react'
import { flatten, get, uniq } from 'lodash'
import { Autocomplete } from '../FormControls'
import { TableColumn } from './types'
import { isEmptyFilter } from './utils'

export interface TableHeaderProps {
  column: TableColumn
  rows: any[]
  value?: string | string[]
  fullWidth?: boolean
  multiple?: boolean
  onChange(value: string | string[]): void
  'data-testid'?: string
}

export const SelectFilter = ({
  column,
  rows,
  value,
  fullWidth,
  multiple,
  onChange,
  ...otherProps
}: TableHeaderProps) => {
  const field = column.field as string

  const options = useMemo(() => {
    let items = rows.map((row) => {
      if (column.getFilterValue) {
        return column.getFilterValue(row)
      }
      return get(row, field as string)
    })
    if (column.dataForm === 'array') {
      items = flatten(items)
    }
    items = uniq(items)
      .filter((item) => !isEmptyFilter(item))
      .sort()
    return items
  }, [column, rows])

  return (
    <Autocomplete
      placeholder="Search"
      value={!isEmptyFilter(value) ? value : multiple ? [] : null}
      options={options}
      getOptionLabel={(item) => item.toString()}
      size="small"
      fullWidth={fullWidth}
      multiple={multiple}
      checkbox
      limitTags={1}
      onChange={(_, value) => onChange(value)}
      {...otherProps}
    />
  )
}
