import React, { FC, ReactNode } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

export interface IConfirmDialogProps {
  open?: boolean
  title: ReactNode
  content: ReactNode
  onClose(result: boolean): void
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  open = false,
  title,
  content,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle className="px-10 pt-10 pb-8 text-22p">{title}</DialogTitle>
      <DialogContent className="px-10 pb-2.5">
        <div>{content}</div>
      </DialogContent>
      <DialogActions className="p-10">
        <Button
          className="w-33"
          color="secondary"
          variant="outlined"
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button
          className="ml-auto w-33"
          color="error"
          onClick={() => onClose(true)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
