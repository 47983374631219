import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {
  VideoChannel,
  VideoChannelList,
  VideoChannelSpec,
} from 'common-api/clients/recorder/typescript'
import { List, NamedMap } from './common'
import * as videoChannelsService from 'services/openapi/videoChannel'
import { todoChangeMeNamespace } from 'services/openapi/types'
import { WatchEvent, WatchEventType } from 'services/openapi/watch'
import { parseAxiosError } from '../../utils/helpers'

export interface VideoChannelsState {
  videoChannels: List<VideoChannel>
  creatingVideoChannel?: boolean
  editingVideoChannel?: VideoChannel
  deletingVideoChannel?: string
}

const initialState: VideoChannelsState = {
  videoChannels: { byName: {}, loading: false },
}

export const fetchVideoChannels = createAsyncThunk<
  VideoChannelList,
  void,
  {
    state: RootState
  }
>(
  'videoChannels/fetchVideoChannels',
  async () => {
    // TODO: Filter out objects with deletionTimestamp set
    return videoChannelsService
      .listVideoChannels(todoChangeMeNamespace)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (notused, { getState }) => {
      const { videoChannels } = getState()
      // don't fetch again, if we're already fetching
      return !videoChannels.videoChannels.loading
    },
  }
)

export const createVideoChannel = createAsyncThunk<
  VideoChannel,
  VideoChannelSpec
>('videoChannels/createVideoChannel', async (payload) => {
  return videoChannelsService
    .createVideoChannel(todoChangeMeNamespace, payload)
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

export interface PatchVideoChannelRequest {
  name: string
  before: VideoChannelSpec
  after: VideoChannelSpec
}

export const updateVideoChannel = createAsyncThunk<
  VideoChannel,
  PatchVideoChannelRequest
>('videoChannels/updateVideoChannel', async (payload) => {
  return videoChannelsService
    .patchVideoChannel(
      payload.name,
      todoChangeMeNamespace,
      payload.before,
      payload.after
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

export const deleteVideoChannel = createAsyncThunk<string, string>(
  'videoChannels/deleteVideoChannel',
  async (id) => {
    return videoChannelsService
      .deleteVideoChannel(todoChangeMeNamespace, id)
      .then(() => id)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

const videoChannels = createSlice({
  name: 'videoChannels',
  initialState,
  reducers: {
    showCreateVideoChannelPanel: (state, action: PayloadAction<boolean>) => {
      state.creatingVideoChannel = action.payload
    },
    setEditingVideoChannel: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      // TODO: Break this out into another reducer?
      if (action.payload === undefined) {
        state.editingVideoChannel = undefined
        return
      }
      state.editingVideoChannel = state.videoChannels.byName[action.payload]
    },
    setDeletingVideoChannel: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.deletingVideoChannel = action.payload
    },
    watchVideoChannelChange: (
      state,
      action: PayloadAction<WatchEvent<VideoChannel>>
    ) => {
      const videoChannel = action.payload.object
      switch (action.payload.type) {
        case WatchEventType.ADDED:
          state.videoChannels.byName[videoChannel.metadata.name] = videoChannel
          break
        case WatchEventType.MODIFIED:
          if (videoChannel.metadata.deletionTimestamp) {
            delete state.videoChannels.byName[videoChannel.metadata.name]
          } else {
            state.videoChannels.byName[videoChannel.metadata.name] =
              videoChannel
          }
          break
        case WatchEventType.DELETED:
          delete state.videoChannels.byName[videoChannel.metadata.name]
          break
        default:
          console.log('unknown watch event', action.payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVideoChannel.fulfilled, (state, action) => {
        const videoChannel = action.payload
        if (videoChannel.metadata.deletionTimestamp !== undefined) {
          return
        }
        state.videoChannels.byName[videoChannel.metadata.name] = videoChannel
        state.videoChannels.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(fetchVideoChannels.pending, (state) => {
        state.videoChannels.loading = true
      })
      .addCase(fetchVideoChannels.fulfilled, (state, action) => {
        const videoChannels = {} as NamedMap<VideoChannel>
        action.payload.items.forEach((videoChannel) => {
          // skip users being deleted
          if (videoChannel.metadata.deletionTimestamp !== undefined) {
            return
          }
          videoChannels[videoChannel.metadata.name] = videoChannel
        })
        state.videoChannels.byName = videoChannels
        state.videoChannels.loading = false
        state.videoChannels.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(updateVideoChannel.fulfilled, (state, action) => {
        state.videoChannels.byName[action.payload.metadata.name] =
          action.payload
        state.videoChannels.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(deleteVideoChannel.fulfilled, (state, action) => {
        delete state.videoChannels.byName[action.payload]
        state.creatingVideoChannel = false
        state.editingVideoChannel = undefined
        state.deletingVideoChannel = undefined
      })
  },
})

export const {
  showCreateVideoChannelPanel,
  setEditingVideoChannel,
  setDeletingVideoChannel,
  watchVideoChannelChange,
} = videoChannels.actions

export default videoChannels.reducer
