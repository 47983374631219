import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { DeviceAccess } from 'common-api/clients/system/typescript'
import { RoleChip } from 'common-lib/components'
import { DeviceAccessTree } from 'components/DeviceAccessTree'
import { useDispatch, useSelector } from '../../store'
import {
  setDeletingDeviceAccess,
  setEditingDeviceAccess,
} from 'store/slices/deviceAccess'
import { Profile } from 'gql/graphql'

export interface ProfileDetailProps {
  className?: string
  profile: Profile
}

export const ProfileDetail: FC<ProfileDetailProps> = ({
  className,
  profile,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const roles = profile.roles || []

  const nodes = profile?.nodes || ''

  const onEditProfile = (profile: Profile) => {
    dispatch(setEditingDeviceAccess(profile.id))
  }

  const onDeleteProfile = (name: string) => {
    dispatch(setDeletingDeviceAccess(name))
  }

  return (
    <div className={className} data-testid="profile-detail">
      <div className="flex justify-between">
        <h2 data-testid="profile-name">{profile.name}</h2>
        <Button
          color="primary"
          className="ml-auto w-34"
          onClick={() => onEditProfile(profile)}
          data-testid="edit-profile-button"
        >
          Edit Profile
        </Button>
      </div>

      <p className="typo-body2 mt-6 min-h-10 max-w-155">
        {profile.description}
      </p>

      <div className="mt-4" data-testid="roles-section">
        <h4>Role(s)</h4>
        <div className="mt-2 flex flex-wrap gap-x-4 gap-y-2">
          {roles.map(
            (role) => role !== null && <RoleChip key={role?.name} role={role} />
          )}
        </div>
      </div>

      <div className="mt-6" data-testid="tree-section">
        <h4>Profile Tree</h4>
        {/* <DeviceAccessTree
          className="mt-5 max-w-93 bg-secondary-800 py-2"
          profileName={profile.name}
          data={nodes}
          readonly
        /> */}
        <p>Waiting for correct datatype defined.</p>
      </div>

      <div className="mt-6">
        <h3>Delete Profile</h3>
        <p className="typo-body2 mt-1 text-neutral-300">
          Copy - placeholder text
        </p>
        <Button
          className="mt-4 w-43"
          color="error"
          onClick={() => onDeleteProfile(profile.id)}
          data-testid="delete-profile-button"
        >
          {t('profiles.deleteProfile')}
        </Button>
      </div>
    </div>
  )
}
