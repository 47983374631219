import React, { FC } from 'react'
import { Chip, ChipProps } from '@mui/material'
import { Role } from '../../../gql/graphql'

export interface RoleChipProps extends Omit<ChipProps, 'role'> {
  role: Role
}

type ChipColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

const roleColors: { [color: string]: ChipColor } = {
  gray: 'default',
  green: 'primary',
  blue: 'info',
  orange: 'warning',
  red: 'error',
}

export const RoleChip: FC<RoleChipProps> = ({ role, ...props }) => {
  const color = role.color ? roleColors[role.color] : undefined
  return <Chip color={color || 'default'} label={role.name} {...props} />
}
