import { AllProfiles } from 'components/Profiles/AllProfiles'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { ConfirmDialog } from 'common-lib/components'
import { ProfileView } from 'components/Profiles/ProfileView'
import { AddProfilePanel } from 'components/Profiles/AddProfilePanel'
import { EditProfilePanel } from 'components/Profiles/EditProfilePanel'
import { useDispatch, useSelector } from '../store'
import {
  deleteDeviceAccess,
  setDeletingDeviceAccess,
} from 'store/slices/deviceAccess'
import { showHttpError } from 'utils/helpers'

export const ProfilesPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { deletingDeviceAccess } = useSelector((state) => state.deviceAccesses)

  const onCloseDeleteProfileConfirmDialog = (result: boolean) => {
    if (deletingDeviceAccess && result) {
      dispatch(deleteDeviceAccess(deletingDeviceAccess)).then((e: any) => {
        if (e.error) {
          showHttpError(enqueueSnackbar, e.error, 'Deleting profile failed')
        } else {
          enqueueSnackbar('Deleted profile successfully', {
            variant: 'success',
          })

          if (
            location.pathname.startsWith(`/profiles/${deletingDeviceAccess}`)
          ) {
            navigate('/profiles', { replace: true })
          }
        }

        dispatch(setDeletingDeviceAccess(undefined))
      })
    } else {
      dispatch(setDeletingDeviceAccess(undefined))
    }
  }

  return (
    <>
      <div className="flex h-full w-full flex-col justify-between overflow-y-scroll px-15 py-10">
        <Routes>
          <Route path="/" element={<AllProfiles />} />
          <Route path="/:profileName/*" element={<ProfileView />} />
        </Routes>
      </div>

      <AddProfilePanel />
      <EditProfilePanel />

      <ConfirmDialog
        open={!!deletingDeviceAccess}
        title="Delete Profile"
        content="This action will result in deleting the profile permanently. Are you sure you want to delete this profile?"
        onClose={onCloseDeleteProfileConfirmDialog}
      />
    </>
  )
}
