import { FC, useRef } from 'react'
import { Button } from '@mui/material'
import PermissionPanel, { PermissionFormRef } from '../Roles/PermissionPanel'
import { RoleChip } from '../../common-lib/components'
import { User } from '../../common-api/clients/system/typescript'
import { useDispatch, useSelector } from '../../store'
import {
  langCodeToLanguage,
  displayLanguages,
  setEditingUser,
  setDeletingUser,
} from 'store/slices/users'
import { useTranslation } from 'react-i18next'
interface UserDetailProps {
  className?: string
  user: User
}

export const UserDetail: FC<UserDetailProps> = ({ className, user }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const roles = useSelector((state) => state.roles.roles)
  const userRoles = roles.filter((item) =>
    (user.spec.roles || []).includes(item.name)
  )
  const permissionRole = roles.find(
    (role) => role.name === `user-${user.metadata.name}`
  )
  const permissionFormRef = useRef<PermissionFormRef>({
    getFormData: async () => undefined,
  })

  const onEdit = () => {
    dispatch(setEditingUser(user.metadata.name))
  }
  const onDelete = async () => {
    dispatch(setDeletingUser(user))
  }

  return (
    <div className={className} data-testid="user-detail">
      <div className="flex justify-between">
        <h2 data-testid="user-name">
          {user.spec.firstName} {user.spec.lastName}
        </h2>
        <Button
          color="primary"
          className="ml-auto px-8"
          onClick={onEdit}
          data-testid="edit-user-button"
        >
          {t('userEditView.edit')}
        </Button>
      </div>

      <div className="mt-1" data-testid="user-detail-section">
        <h3 className="mb-4">{t('userCreate.userDetails')}</h3>

        <div className="flex flex-wrap gap-x-24 gap-y-6">
          <div>
            <h4>{t('userCreate.firstName')}</h4>
            <span className="typo-body2 mt-2">{user.spec.firstName}</span>
          </div>
          <div>
            <h4>{t('userCreate.lastName')}</h4>
            <span className="typo-body2 mt-2">{user.spec.lastName}</span>
          </div>
          <div>
            <h4>{t('common.email')}</h4>
            <span className="typo-body2 mt-2">{user.spec.email}</span>
          </div>
          <div>
            <h4>{t('userCreate.language')}</h4>
            <span className="typo-body2 mt-2">
              {displayLanguages[langCodeToLanguage(user.spec.languageCode)]}
            </span>
          </div>
        </div>

        <div className="mt-6">
          <h4>{t('common.description')}</h4>
          <p className="typo-body2 mt-2 max-w-155">{user.spec.description}</p>
        </div>
      </div>

      <div className="mt-10" data-testid="roles-section">
        <h3>{t('userCreate.roles')}</h3>
        <p className="typo-body2 mt-1 max-w-155 text-neutral-400">
          {t('common.description')}
        </p>
        <div className="mt-3 flex flex-wrap gap-x-4 gap-y-2">
          {userRoles.map((role) => (
            <RoleChip key={role.name} role={role} />
          ))}
        </div>
      </div>

      <div className="mt-10" data-testid="permissions-section">
        <h3 className="mb-6">{t('userEditView.permissions')}</h3>
        <PermissionPanel
          formRef={permissionFormRef}
          role={permissionRole}
          createEditPanel={false}
        />
      </div>

      <div className="mt-10">
        <h3>{t('userEditView.password')}</h3>
        <p className="typo-body2 mt-1 max-w-114 text-neutral-300">
          {t('userEditView.passwordTip')}
        </p>
        <Button
          className="mt-4 w-43"
          color="primary"
          variant="outlined"
          data-testid="reset-password-button"
        >
          {t('userEditView.resetPassword')}
        </Button>
      </div>

      <div className="mt-10">
        <h3>{t('userEditView.deleteUser')}</h3>
        <p className="typo-body2 mt-1 max-w-114 text-neutral-300">
          {t('userEditView.placeholder')}
        </p>
        <Button
          className="mt-4 w-43"
          color="error"
          onClick={onDelete}
          data-testid="delete-user-button"
        >
          {t('userEditView.deleteUser')}
        </Button>
      </div>
    </div>
  )
}
