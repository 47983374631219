import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {
  DeviceAccess,
  DeviceAccessList,
  DeviceAccessSpec,
} from 'common-api/clients/system/typescript'
import { List, NamedMap } from './common'
import * as deviceAccessesService from 'services/openapi/deviceAccess'
import { todoChangeMeNamespace } from 'services/openapi/types'
import { WatchEvent, WatchEventType } from 'services/openapi/watch'
import { parseAxiosError } from '../../utils/helpers'

export interface DeviceAccessesState {
  deviceAccesses: List<DeviceAccess>
  creatingDeviceAccess?: boolean
  editingDeviceAccess?: string
  deletingDeviceAccess?: string
}

const initialState: DeviceAccessesState = {
  deviceAccesses: { byName: {}, loading: false },
}

export const fetchDeviceAccesses = createAsyncThunk<
  DeviceAccessList,
  void,
  {
    state: RootState
  }
>(
  'deviceAccesses/fetchDeviceAccesses',
  async () => {
    // TODO: Filter out objects with deletionTimestamp set
    return deviceAccessesService
      .listDeviceAccesses(todoChangeMeNamespace)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (notused, { getState }) => {
      const { deviceAccesses } = getState()
      // don't fetch again, if we're already fetching
      return !deviceAccesses.deviceAccesses.loading
    },
  }
)

export const createDeviceAccess = createAsyncThunk<
  DeviceAccess,
  DeviceAccessSpec
>('deviceAccesses/createDeviceAccess', async (payload) => {
  return deviceAccessesService
    .createDeviceAccess(todoChangeMeNamespace, payload)
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

export interface PatchDeviceAccessRequest {
  name: string
  before: DeviceAccessSpec
  after: DeviceAccessSpec
}

export const updateDeviceAccess = createAsyncThunk<
  DeviceAccess,
  PatchDeviceAccessRequest
>('deviceAccesses/updateDeviceAccess', async (payload) => {
  return deviceAccessesService
    .patchDeviceAccess(
      payload.name,
      todoChangeMeNamespace,
      payload.before,
      payload.after
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

export const deleteDeviceAccess = createAsyncThunk<string, string>(
  'deviceAccesses/deleteDeviceAccess',
  async (id) => {
    return deviceAccessesService
      .deleteDeviceAccess(todoChangeMeNamespace, id)
      .then(() => id)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

const deviceAccesses = createSlice({
  name: 'deviceAccesses',
  initialState,
  reducers: {
    showCreateDeviceAccessPanel: (state, action: PayloadAction<boolean>) => {
      state.creatingDeviceAccess = action.payload
    },
    setEditingDeviceAccess: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      // TODO: Break this out into another reducer?
      if (action.payload === undefined) {
        state.editingDeviceAccess = undefined
        return
      }
      state.editingDeviceAccess = action.payload
    },
    setDeletingDeviceAccess: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.deletingDeviceAccess = action.payload
    },
    watchDeviceAccessChange: (
      state,
      action: PayloadAction<WatchEvent<DeviceAccess>>
    ) => {
      const deviceAccess = action.payload.object
      switch (action.payload.type) {
        case WatchEventType.ADDED:
          state.deviceAccesses.byName[deviceAccess.metadata.name] = deviceAccess
          break
        case WatchEventType.MODIFIED:
          if (deviceAccess.metadata.deletionTimestamp) {
            delete state.deviceAccesses.byName[deviceAccess.metadata.name]
          } else {
            state.deviceAccesses.byName[deviceAccess.metadata.name] =
              deviceAccess
          }
          break
        case WatchEventType.DELETED:
          delete state.deviceAccesses.byName[deviceAccess.metadata.name]
          break
        default:
          console.log('unknown watch event', action.payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDeviceAccess.fulfilled, (state, action) => {
        const deviceAccess = action.payload
        if (deviceAccess.metadata.deletionTimestamp !== undefined) {
          return
        }
        state.deviceAccesses.byName[deviceAccess.metadata.name] = deviceAccess
        state.deviceAccesses.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(fetchDeviceAccesses.pending, (state) => {
        state.deviceAccesses.loading = true
      })
      .addCase(fetchDeviceAccesses.fulfilled, (state, action) => {
        const deviceAccesses = {} as NamedMap<DeviceAccess>
        action.payload.items.forEach((deviceAccess) => {
          // skip users being deleted
          if (deviceAccess.metadata.deletionTimestamp !== undefined) {
            return
          }
          deviceAccesses[deviceAccess.metadata.name] = deviceAccess
        })
        state.deviceAccesses.byName = deviceAccesses
        state.deviceAccesses.loading = false
        state.deviceAccesses.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(updateDeviceAccess.fulfilled, (state, action) => {
        state.deviceAccesses.byName[action.payload.metadata.name] =
          action.payload
        state.deviceAccesses.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(deleteDeviceAccess.fulfilled, (state, action) => {
        delete state.deviceAccesses.byName[action.payload]
        state.creatingDeviceAccess = false
        state.editingDeviceAccess = undefined
        state.deletingDeviceAccess = undefined
      })
  },
})

export const {
  showCreateDeviceAccessPanel,
  setEditingDeviceAccess,
  setDeletingDeviceAccess,
  watchDeviceAccessChange,
} = deviceAccesses.actions

export default deviceAccesses.reducer
