import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Card, CardContent, IconButton, TextField } from '@mui/material'
import { Search, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Breadcrumb, Pagination } from 'common-lib/components'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import { Profile, Role } from 'gql/graphql'
import {
  showCreateDeviceAccessPanel,
  setDeletingDeviceAccess,
  setEditingDeviceAccess,
} from 'store/slices/deviceAccess'
import noProfilesIllustration from 'assets/img/illustrations/no-profiles.svg'
import noSearchedProfilesIllustration from 'assets/img/illustrations/no-searched-profiles.svg'
import { ScrollableRoles } from './ScrollableRoles'
import { useMutation, useQuery } from '@apollo/client'
import { LIST_PROFILES } from 'lib/queries/profile'
import { DELETE_PROFILE } from 'lib/mutations/profile'

const cardsPerPage = 9

export interface ProfileData {
  id: string
  name: string
  description: string
  roles: Role[]
}

export const AllProfiles = () => {
  const { loading, error, data } = useQuery(LIST_PROFILES)
  const [
    deleteProfile,
    { loading: deleteLoad, error: deleteError, data: deleteData },
  ] = useMutation(DELETE_PROFILE)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const breadcrumbs = [{ text: t('profiles.breadcrumb') }]

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const deviceAccesses: Profile[] = data?.listProfiles || []
  const roles = useTypedSelector((state) => state.roles.roles)

  const profiles = useMemo<ProfileData[]>(() => {
    return deviceAccesses.map((item) => ({
      id: item.id,
      name: item.name || 'No name',
      description: item.description || 'No description',
      roles: (item.roles || []) as Role[],
    }))
  }, [deviceAccesses, roles])

  const filteredProfiles = useMemo(() => {
    if (!search) {
      return profiles
    }
    const keyword = search.trim().toLowerCase()
    return profiles.filter(
      (profile) =>
        profile.name?.toLowerCase().includes(keyword) ||
        profile.description?.toLowerCase().includes(keyword) ||
        profile.roles?.filter((role) =>
          role.name.toLowerCase().includes(keyword)
        ).length
    )
  }, [search, profiles])

  const visibleCards = useMemo(() => {
    return filteredProfiles.slice(
      (page - 1) * cardsPerPage,
      page * cardsPerPage
    )
  }, [filteredProfiles, page, cardsPerPage])

  useEffect(() => {
    if (!visibleCards.length && page > 1) {
      const newPage = Math.ceil(filteredProfiles.length / cardsPerPage)
      setPage(Math.max(1, newPage))
    }
  }, [filteredProfiles, visibleCards, page, cardsPerPage])

  const onCreateProfile = () => {
    dispatch(showCreateDeviceAccessPanel(true))
  }

  const onEditProfile = (profile: ProfileData) => {
    dispatch(setEditingDeviceAccess(profile.id))
  }

  const onDeleteProfile = (profile: ProfileData) => {
    deleteProfile({ variables: { deleteProfileId: profile.id } })
  }

  const onSearchChange = (value: string) => {
    setSearch(value)
    setPage(1)
  }

  return (
    <>
      <Breadcrumb items={breadcrumbs} />

      <div className="mt-4 mb-6 flex items-start justify-between">
        <div>
          <h3>{t('profiles.all')}</h3>
          <p className="typo-body2 mt-2 text-secondary-400">
            {t('profiles.description')}
          </p>
          <TextField
            className="mt-6 h-10 w-[343px]"
            name="search"
            placeholder="Search"
            InputProps={{ endAdornment: <Search className="mr-3" /> }}
            onChange={(e) => onSearchChange(e.target.value)}
            data-testid="search-filter"
          />
        </div>

        <Button
          className="ml-auto w-37"
          onClick={onCreateProfile}
          data-testid="add-profile-button"
        >
          {t('profiles.create')}
        </Button>
      </div>

      {!profiles.length ? (
        <div
          className="flex flex-1 flex-col items-center py-30"
          data-testid="no-profiles"
        >
          <img src={noProfilesIllustration} alt="" />
          <p className="mt-7">{t('profiles.noProfiles')}</p>
          <Button
            className="mt-4 px-5"
            color="primary"
            variant="outlined"
            onClick={onCreateProfile}
            data-testid="add-recorder-button"
          >
            {t('profiles.createNew')}
          </Button>
        </div>
      ) : !filteredProfiles.length ? (
        <div
          className="flex flex-1 flex-col items-center py-30"
          data-testid="no-search-results"
        >
          <img src={noSearchedProfilesIllustration} alt="" />
          <p className="mt-7">{t('profiles.noSearch')}</p>
        </div>
      ) : (
        <>
          <div className="-mx-4 flex-1 overflow-y-auto px-4">
            <div className="grid grid-cols-onecard gap-4 pb-8 1lg:grid-cols-twocards 1xl:grid-cols-threecards">
              {visibleCards.map((profile) => (
                <Link
                  key={profile.id}
                  className="block"
                  to={`/profiles/${profile.id}`}
                >
                  <Card>
                    <CardContent className="flex flex-col p-6">
                      <div className="flex items-center">
                        <h4 className="mr-4">{profile.name}</h4>
                        <IconButton
                          className="ml-auto"
                          color="secondary"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault()
                            onEditProfile(profile)
                          }}
                          data-testid="edit-profile"
                        >
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault()
                            onDeleteProfile(profile)
                          }}
                          data-testid="delete-profile"
                        >
                          <DeleteOutline />
                        </IconButton>
                      </div>
                      <div className="line-clamp-2 typo-body2 mt-4 min-h-10">
                        {profile.description.length === 0 ? (
                          <p className="text-neutral-300">
                            No Description provided
                          </p>
                        ) : (
                          <p>{profile.description}</p>
                        )}
                      </div>
                      <div className="typo-button mt-8">Roles</div>
                      <div className="mt-2 flex gap-2 overflow-x-auto">
                        <ScrollableRoles roles={profile.roles} />
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              ))}
            </div>
          </div>
          {filteredProfiles.length > cardsPerPage && (
            <Pagination
              className="mt-6"
              count={filteredProfiles.length}
              page={page}
              rowsPerPage={cardsPerPage}
              showFirstLastButton
              onPageChange={setPage}
            />
          )}
        </>
      )}
    </>
  )
}
