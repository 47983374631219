import { FC, MutableRefObject, useEffect } from 'react'
import classNames from 'classnames'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
} from '@mui/material'
import { PermissionGroup } from './PermissionGroup'
import PermissionLine, { PermissionInfo } from './PermissionLine'
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFAutocomplete,
} from 'common-lib/components/RHFControls'
import {
  RoleSpecApiPermissionsKindEnum,
  RoleSpecApiPermissionsVerbsEnum,
  RoleSpecApplicationsEnum,
  RoleSpecUiPermissionsEnum,
} from 'common-api/clients/system/typescript'
import {
  spotterAdminPermissions,
  spotterWebPermissions,
} from 'common-lib/constants/permissions'
import { Role } from 'gql/graphql'
// import {
//   roleHasApiPermission,
//   roleHasApplication,
//   roleHasUiPermission,
// } from 'utils/helpers'

export interface PermissionFormData {
  spotter_admin_access: boolean
  user_management: string
  role_view: boolean
  role_add: boolean
  role_delete: boolean
  role_edit: boolean
  device_view: boolean
  device_add: boolean
  device_delete: boolean
  device_edit: boolean
  recorder_view: boolean
  recorder_add: boolean
  recorder_delete: boolean
  recorder_edit: boolean

  spotter_web_access: boolean
  view_livestream: boolean
  view_playback: boolean
  export_video: boolean
  control_ptz: boolean
  create_ptz: boolean
  view_alarms: boolean
  view_alarm_details: boolean
}

export interface PermissionFormRef {
  getFormData(): Promise<PermissionFormData | undefined>
}

export interface PermissionFormProps {
  className?: string
  role?: Role
  createEditPanel?: boolean
  disabled?: boolean
  formRef: MutableRefObject<PermissionFormRef>
}

const schema = yup
  .object()
  .shape({
    spotter_admin_access: yup.boolean().required(),
    user_management: yup.string().required(),
    role_view: yup.boolean().required(),
    role_add: yup.boolean().required(),
    role_delete: yup.boolean().required(),
    role_edit: yup.boolean().required(),
    device_view: yup.boolean().required(),
    device_add: yup.boolean().required(),
    device_delete: yup.boolean().required(),
    device_edit: yup.boolean().required(),
    recorder_view: yup.boolean().required(),
    recorder_add: yup.boolean().required(),
    recorder_delete: yup.boolean().required(),
    recorder_edit: yup.boolean().required(),

    spotter_web_access: yup.boolean().required(),
    view_livestream: yup.boolean().required(),
    view_playback: yup.boolean().required(),
    export_video: yup.boolean().required(),
    control_ptz: yup.boolean().required(),
    create_ptz: yup.boolean().required(),
    view_alarms: yup.boolean().required(),
    view_alarm_details: yup.boolean().required(),
  })
  .required()

export const PermissionPanel: FC<PermissionFormProps> = ({
  className,
  role,
  formRef,
  createEditPanel,
  disabled,
}) => {
  // TODO: complete
  return null
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     spotter_admin_access: roleHasApplication(
  //       role,
  //       RoleSpecApplicationsEnum.Admin
  //     ),
  //     user_management: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.User
  //     )
  //       ? 'View Only'
  //       : 'No Access',
  //     role_view: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Role,
  //       RoleSpecApiPermissionsVerbsEnum.Read
  //     ),
  //     role_add: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Role,
  //       RoleSpecApiPermissionsVerbsEnum.Create
  //     ),
  //     role_delete: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Role,
  //       RoleSpecApiPermissionsVerbsEnum.Delete
  //     ),
  //     role_edit: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Role,
  //       RoleSpecApiPermissionsVerbsEnum.Update
  //     ),
  //     device_view: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.DeviceAccess,
  //       RoleSpecApiPermissionsVerbsEnum.Read
  //     ),
  //     device_add: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.DeviceAccess,
  //       RoleSpecApiPermissionsVerbsEnum.Create
  //     ),
  //     device_delete: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.DeviceAccess,
  //       RoleSpecApiPermissionsVerbsEnum.Delete
  //     ),
  //     device_edit: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.DeviceAccess,
  //       RoleSpecApiPermissionsVerbsEnum.Update
  //     ),
  //     recorder_view: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Recorder,
  //       RoleSpecApiPermissionsVerbsEnum.Read
  //     ),
  //     recorder_add: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Recorder,
  //       RoleSpecApiPermissionsVerbsEnum.Create
  //     ),
  //     recorder_delete: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Recorder,
  //       RoleSpecApiPermissionsVerbsEnum.Delete
  //     ),
  //     recorder_edit: roleHasApiPermission(
  //       role,
  //       RoleSpecApiPermissionsKindEnum.Recorder,
  //       RoleSpecApiPermissionsVerbsEnum.Update
  //     ),
  //
  //     spotter_web_access: roleHasApplication(
  //       role,
  //       RoleSpecApplicationsEnum.Web
  //     ),
  //     view_livestream: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.ViewLivestream
  //     ),
  //     view_playback: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.ViewPlayback
  //     ),
  //     export_video: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.ExportVideo
  //     ),
  //     control_ptz: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.ControlPtz
  //     ),
  //     create_ptz: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.CreatePtz
  //     ),
  //     view_alarms: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.ViewAlarms
  //     ),
  //     view_alarm_details: roleHasUiPermission(
  //       role,
  //       RoleSpecUiPermissionsEnum.ViewAlarmDetails
  //     ),
  //   },
  // })
  //
  // const spotterAdminAccess = methods.watch('spotter_admin_access')
  // useEffect(() => {
  //   if (!spotterAdminAccess) {
  //     methods.setValue('user_management', 'No Access')
  //     methods.setValue('device_view', false)
  //     methods.setValue('recorder_view', false)
  //   }
  // }, [spotterAdminAccess])
  //
  // const userManagement = methods.watch('user_management')
  // useEffect(() => {
  //   if (userManagement === 'No Access') {
  //     methods.setValue('role_view', false)
  //   }
  // }, [userManagement])
  //
  // const roleView = methods.watch('role_view')
  // useEffect(() => {
  //   if (!roleView) {
  //     methods.setValue('role_add', false)
  //     methods.setValue('role_edit', false)
  //     methods.setValue('role_delete', false)
  //   }
  // }, [roleView])
  //
  // const deviceView = methods.watch('device_view')
  // useEffect(() => {
  //   if (!deviceView) {
  //     methods.setValue('device_add', false)
  //     methods.setValue('device_edit', false)
  //     methods.setValue('device_delete', false)
  //   }
  // }, [deviceView])
  //
  // const recorderView = methods.watch('recorder_view')
  // useEffect(() => {
  //   if (!recorderView) {
  //     methods.setValue('recorder_add', false)
  //     methods.setValue('recorder_edit', false)
  //     methods.setValue('recorder_delete', false)
  //   }
  // }, [recorderView])
  //
  // const spotterWebAccess = methods.watch('spotter_web_access')
  // useEffect(() => {
  //   if (!spotterWebAccess) {
  //     methods.setValue('view_livestream', false)
  //     methods.setValue('view_playback', false)
  //     methods.setValue('export_video', false)
  //     methods.setValue('control_ptz', false)
  //     methods.setValue('create_ptz', false)
  //     methods.setValue('view_alarms', false)
  //     methods.setValue('view_alarm_details', false)
  //   }
  // }, [spotterWebAccess])
  //
  // useEffect(() => {
  //   if (formRef.current) {
  //     formRef.current.getFormData = () => {
  //       return new Promise((resolve) => {
  //         methods.handleSubmit(
  //           (data) => {
  //             resolve(data)
  //           },
  //           (err) => {
  //             console.log(err)
  //             resolve(undefined)
  //           }
  //         )()
  //       })
  //     }
  //   }
  // }, [formRef])
  //
  // const getPermissionState = (permission: PermissionInfo) => {
  //   if (disabled || !createEditPanel) {
  //     return { disabled: true }
  //   }
  //   if (permission.depend === 'user_management') {
  //     return { disabled: methods.getValues(permission.depend) === 'No Access' }
  //   }
  //   return { disabled: !methods.getValues(permission.depend) }
  // }
  // const { t } = useTranslation()
  //
  // const accessPermissions = {
  //   'No Access': t('permissionForm.noAccess'),
  //   'View Only': t('permissionForm.viewOnly'),
  //   Edit: t('permissionForm.edit'),
  //   Admin: t('permissionForm.admin'),
  // }
  // return (
  //   <FormProvider
  //     className={className}
  //     methods={methods}
  //     data-testid="permission-form"
  //   >
  //     <div
  //       className={classNames(
  //         'grid gap-x-6 gap-y-4',
  //         createEditPanel
  //           ? 'pl-6 pr-9'
  //           : 'max-w-132 lg:max-w-262 lg:grid-cols-2'
  //       )}
  //     >
  //       <div>
  //         <Accordion
  //           defaultExpanded={roleHasApplication(
  //             role,
  //             RoleSpecApplicationsEnum.Admin
  //           )}
  //         >
  //           <AccordionSummary>
  //             <h4>{t('permissionForm.spotterAdmin')}</h4>
  //             <RHFSwitch
  //               className="ml-auto"
  //               name="spotter_admin_access"
  //               onClick={(e) => e.stopPropagation()}
  //               disabled={disabled || !createEditPanel}
  //             />
  //           </AccordionSummary>
  //           <AccordionDetails className="pl-12">
  //             <h4>{t('permissionForm.userManagement')}</h4>
  //             <RHFSelect
  //               className="mt-3"
  //               name="user_management"
  //               label={t('permissionForm.userManagement')}
  //               disabled={disabled || !createEditPanel}
  //             >
  //               {Object.keys(accessPermissions).map((key) => (
  //                 <MenuItem key={key} value={key}>
  //                   {accessPermissions[key as keyof typeof accessPermissions]}
  //                 </MenuItem>
  //               ))}
  //             </RHFSelect>
  //             {spotterAdminPermissions.map((permissionGroup, i) => (
  //               <PermissionGroup
  //                 key={i}
  //                 className={i === 0 ? 'mt-5.5' : 'mt-6.5'}
  //                 permissionGroup={permissionGroup}
  //                 getPermissionState={getPermissionState}
  //               />
  //             ))}
  //           </AccordionDetails>
  //         </Accordion>
  //       </div>
  //
  //       <div>
  //         <Accordion
  //           defaultExpanded={roleHasApplication(
  //             role,
  //             RoleSpecApplicationsEnum.Web
  //           )}
  //         >
  //           <AccordionSummary>
  //             <h4>{t('permissionForm.spotterWeb')}</h4>
  //             <RHFSwitch
  //               className="ml-auto"
  //               name="spotter_web_access"
  //               onClick={(e) => e.stopPropagation()}
  //               disabled={disabled || !createEditPanel}
  //             />
  //           </AccordionSummary>
  //           <AccordionDetails>
  //             {spotterWebPermissions.map((permission, i) => (
  //               <PermissionLine
  //                 key={i}
  //                 className={classNames('typo-body1 pl-6', i > 0 && 'mt-5')}
  //                 permission={permission}
  //                 getPermissionState={getPermissionState}
  //               />
  //             ))}
  //           </AccordionDetails>
  //         </Accordion>
  //       </div>
  //     </div>
  //
  //     {createEditPanel && (
  //       <div className="mt-8 px-10">
  //         <h4>{t('permissionForm.deviceAccess')}</h4>
  //         <p className="typo-body2 mt-1 text-neutral-300">
  //           {t('permissionForm.deviceDescription')}
  //         </p>
  //         <RHFAutocomplete
  //           className="mt-4"
  //           name="role"
  //           label={t('permissionForm.deviceAccess') as string}
  //           placeholder={t('permissionForm.deviceAccessSelect') as string}
  //           disabled={disabled}
  //           options={[]}
  //         />
  //       </div>
  //     )}
  //   </FormProvider>
  // )
}

export default PermissionPanel
