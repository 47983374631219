import React, { FC } from 'react'
import classNames from 'classnames'
import { IconButton, TextField } from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from '@mui/icons-material'

export interface PaginationProps {
  className?: string
  count: number
  page: number
  rowsPerPage: number
  showFirstLastButton?: boolean
  onPageChange(page: number): void
}

export const Pagination: FC<PaginationProps> = ({
  className,
  count,
  page,
  rowsPerPage,
  showFirstLastButton,
  onPageChange,
}: PaginationProps) => {
  const totalPages = Math.max(1, Math.ceil(count / rowsPerPage))
  const pageNumber = Math.max(1, Math.min(page, totalPages))

  const onPageInput = (page: number) => {
    onPageChange(Math.max(1, Math.min(page, totalPages)))
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-end text-white',
        className
      )}
    >
      {showFirstLastButton && (
        <IconButton
          className="mr-2"
          disabled={pageNumber === 1}
          onClick={() => onPageInput(1)}
        >
          <FirstPage />
        </IconButton>
      )}
      <IconButton
        className="mr-4"
        disabled={pageNumber === 1}
        onClick={() => onPageInput(pageNumber - 1)}
      >
        <ChevronLeft />
      </IconButton>

      <TextField
        className="w-18"
        type="number"
        size="small"
        value={page}
        onChange={(e) => onPageInput(Number(e.target.value))}
      />
      <span className="typo-body1 ml-2">of {totalPages}</span>

      <IconButton
        className="ml-4"
        disabled={pageNumber === totalPages}
        onClick={() => onPageInput(pageNumber + 1)}
      >
        <ChevronRight />
      </IconButton>
      {showFirstLastButton && (
        <IconButton
          className="ml-2"
          disabled={pageNumber === totalPages}
          onClick={() => onPageInput(totalPages)}
        >
          <LastPage />
        </IconButton>
      )}
    </div>
  )
}
