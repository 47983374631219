import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Panel } from '../Panel'
import { UserForm, UserFormRef } from './UserForm'
import { useDispatch, useSelector } from '../../store'
import { createUser, showCreateUserPanel } from 'store/slices/users'
import { showHttpError } from '../../utils/helpers'

export const UserCreatePanel = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef<UserFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { creatingUser } = useSelector((state) => state.users)
  const [isValid, setIsValid] = useState(false)

  const onSubmit = async () => {
    const data = await formRef.current.getFormData()
    if (data) {
      const { permissions, oldRolePermission, ...spec } = data
      dispatch(createUser(spec)).then((e: any) => {
        if (e.error) {
          showHttpError(enqueueSnackbar, e.error, 'Creating user failed')
        } else {
          enqueueSnackbar('Created user successfully', { variant: 'success' })
          onClose()
        }
      })
    }
  }

  const onClose = () => {
    dispatch(showCreateUserPanel(false))
  }

  return (
    <Panel
      open={creatingUser}
      title={t('userCreate.title') as string}
      submitButtonText={t('common.addUser') as string}
      isValid={isValid}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
    >
      {creatingUser && <UserForm formRef={formRef} onValidate={setIsValid} />}
    </Panel>
  )
}
