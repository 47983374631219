import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as openapi from 'services/openapi/user'
import { RootState } from '../index'
import {
  User,
  UserRequestSpec,
  UserSpec,
  UserList,
  UserRequest,
} from 'common-api/clients/system/typescript'
import { List, NamedMap } from './common'
import { WatchEvent, WatchEventType } from 'services/openapi/watch'
import { todoChangeMeNamespace } from 'services/openapi/types'
import { parseAxiosError } from '../../utils/helpers'

export interface UsersState {
  users: List<User>

  // TODO: Make this an exclusive enum of some sort?
  creatingUser?: boolean
  editingUser?: User
  // TODO: Should this be deletingUserID?
  deletingUser?: User
}

const initialState: UsersState = {
  users: { byName: {}, loading: false },
}

export enum Language {
  English = 'en',
  Finnish = 'fi',
  Swedish = 'se',
  Brasilian = 'pt-BR',
}

export const displayLanguages: { readonly [key in Language]: string } = {
  [Language.English]: 'English',
  [Language.Finnish]: 'Suomi',
  [Language.Swedish]: 'Svenska',
  [Language.Brasilian]: 'Brasil',
}

// langCodeToLanguage takes in a language code such as "en" and return stricty typed Language enum.
// If langCode is not known to the system (for example, "hello"), this fuction logs the error and put default to "English"
export const langCodeToLanguage = (langCode: string) => {
  const langs = Object.values(Language).filter((l) => l === langCode)
  if (langs.length !== 1) {
    // TODO: add structured logging
    console.log(
      'Expected one matching language code',
      'langCode',
      langCode,
      'langs',
      langs
    )
  }
  if (langs.length === 0) {
    return Language.English // default
  }
  return langs[0]
}

// Fetch users from Keycloak: TODO: start fetching from Kubernetes instead
// Ref https://redux-toolkit.js.org/usage/usage-with-typescript#manually-defining-thunkapi-types
// TODO(lucas): make a generic type for these parameters
export const fetchUsers = createAsyncThunk<
  UserList,
  void,
  {
    state: RootState
  }
>(
  'users/fetchUsers',
  async () => {
    // TODO: Filter out objects with deletionTimestamp set
    return openapi
      .listUsers(todoChangeMeNamespace)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (notused, { getState }) => {
      const { users } = getState()
      // don't fetch again, if we're already fetching
      return !users.users.loading
    },
  }
)

// Add users to Keycloak
export const createUser = createAsyncThunk<UserRequest, UserRequestSpec>(
  'users/createUser',
  async (payload) => {
    if (!payload.languageCode) {
      payload.languageCode = 'en'
    }
    return openapi
      .createUser(todoChangeMeNamespace, payload)
      .then((res) => res.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

// Update user
export const updateUser = createAsyncThunk<
  User,
  UserSpec,
  {
    state: RootState
  }
>('users/updateUser', async (newUserSpec, thunkAPI) => {
  const { editingUser } = thunkAPI.getState().users
  if (editingUser === undefined) {
    return Promise.reject('cannot edit non-existing user!')
  }

  return await openapi
    .patchUser(
      editingUser.metadata.name,
      editingUser.metadata.namespace,
      editingUser.spec,
      newUserSpec
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

// delete user
export const deleteUser = createAsyncThunk<string, string>(
  'users/deleteUser',
  async (guid) => {
    // TODO: Maybe use foreground cascading deletion? Background is default. https://kubernetes.io/docs/concepts/architecture/garbage-collection/#cascading-deletion
    // Anyways, it seems like we need the controller-manager to run
    return openapi
      .deleteUser(guid)
      .then(() => guid)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

// Fetch groups from Keycloak
// Ref https://redux-toolkit.js.org/usage/usage-with-typescript#manually-defining-thunkapi-types

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    showCreateUserPanel: (state, action: PayloadAction<boolean>) => {
      state.creatingUser = action.payload
    },
    setEditingUser: (state, action: PayloadAction<string | undefined>) => {
      // TODO: Break this out into another reducer?
      if (!action.payload) {
        state.editingUser = undefined
        return
      }
      state.editingUser = state.users.byName[action.payload]
    },
    setDeletingUser: (state, action: PayloadAction<User | undefined>) => {
      state.deletingUser = action.payload
    },
    watchUserChange: (state, action: PayloadAction<WatchEvent<User>>) => {
      const user = action.payload.object
      switch (action.payload.type) {
        case WatchEventType.ADDED:
          state.users.byName[user.metadata.name] = user
          break
        case WatchEventType.MODIFIED:
          if (user.metadata.deletionTimestamp) {
            delete state.users.byName[user.metadata.name]
          } else {
            state.users.byName[user.metadata.name] = user
          }
          break
        case WatchEventType.DELETED:
          delete state.users.byName[user.metadata.name]
          break
        default:
          console.log('unknown watch event', action.payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.users.loading = true
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const users = {} as NamedMap<User>
        action.payload.items.forEach((user) => {
          // skip users being deleted
          if (user.metadata.deletionTimestamp !== undefined) {
            return
          }
          users[user.metadata.name] = user
        })
        state.users.byName = users
        state.users.loading = false
        state.users.resourceVersion = action.payload.metadata.resourceVersion
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.resourceVersion = action.payload.metadata.resourceVersion
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.users.byName[action.payload.metadata.name] = action.payload
        state.users.resourceVersion = action.payload.metadata.resourceVersion
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        delete state.users.byName[action.payload]

        state.creatingUser = false
        state.editingUser = undefined
        state.deletingUser = undefined
      })
  },
})

export const {
  showCreateUserPanel,
  setEditingUser,
  setDeletingUser,
  watchUserChange,
} = users.actions

export default users.reducer
