import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      emails
      isMFAEnabled
      roles {
        id
        name
        description
        color
        permissions {
          id
        }
      }
      organizations {
        id
        name
        version
        permissions
      }
      permissions
    }
  }
`
