import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { RoleManager } from 'components/Roles/RoleManager'
import { RoleCreatePanel } from '../components/Roles/RoleCreatePanel'
import { RoleEditPanel } from '../components/Roles/RoleEditPanel'
import { RoleView } from 'components/Roles/RoleView'
import { UserEditPanel } from '../components/User/UserEditPanel'
import { ConfirmDialog } from '../common-lib/components'
import { useDispatch, useSelector } from '../store'
import { setDeletingRole } from '../store/slices/roles'
import { showHttpError } from '../utils/helpers'

export const RolesPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const deletingRole = useSelector((state) => state.roles.deletingRole)

  const onCloseDeleteConfirmDialog = (result: boolean) => {
    if (deletingRole && result) {
      // dispatch(deleteRole(deletingRole!)).then((e: any) => {
      //   if (e.error) {
      //     showHttpError(enqueueSnackbar, e.error, 'Deleting role failed')
      //   } else {
      //     enqueueSnackbar('The role was successfully deleted', {
      //       variant: 'success',
      //     })
      //
      //     if (
      //       location.pathname.startsWith(
      //         `/user-management/roles/${deletingRole!}`
      //       )
      //     ) {
      //       navigate('/user-management/roles', { replace: true })
      //     }
      //   }
      //
      //   dispatch(setDeletingRole(undefined))
      // })
    } else {
      dispatch(setDeletingRole(undefined))
    }
  }

  return (
    <>
      <div className="flex h-full w-full flex-col justify-between overflow-y-scroll px-15 py-10">
        <Routes>
          <Route path="/" element={<RoleManager />} />
          <Route path="/:roleName/" element={<RoleView />} />
        </Routes>
      </div>

      <RoleCreatePanel />
      <RoleEditPanel />
      <ConfirmDialog
        open={!!deletingRole}
        title="Delete Role"
        content="This action will result in deleting the role permanently. Are you sure you want to delete this role?"
        onClose={onCloseDeleteConfirmDialog}
      />

      <UserEditPanel />
    </>
  )
}
