import { PermissionGroup } from 'components/Roles/PermissionGroup'
import { PermissionInfo } from 'components/Roles/PermissionLine'

export const PERMISSIONS = {
  ACCESS_ADMIN: 'access-spotter-admin',
  ACCESS_WEB: 'access-spotter-web',
  USER: {
    READ: 'user.read',
    CREATE: 'user.create',
    UPDATE: 'user.update',
    DELETE: 'user.delete',
  },
  ROLE: {
    READ: 'role.read',
    CREATE: 'role.create',
    UPDATE: 'role.update',
    DELETE: 'role.delete',
  },
  DEVICE_ACCESS: {
    READ: 'device-access.read',
    CREATE: 'device-access.create',
    UPDATE: 'device-access.update',
    DELETE: 'device-access.delete',
  },
  RECORDER: {
    READ: 'recorder.read',
    CREATE: 'recorder.create',
    UPDATE: 'recorder.update',
    DELETE: 'recorder.delete',
  },
  VIEW_LIVE_STREAM: 'view-live-stream',
  VIEW_PLAYBACK: 'view-playback',
}

// Should be fixed
export const spotterAdminPermissions: PermissionGroup[] = [
  {
    name: 'role_view',
    label: 'permissionForm.viewRoleManager',
    depend: 'user_management',
    info: 'permissionForm.viewRoleManagerInfo',
    children: [
      {
        name: 'role_add',
        label: 'common.addRole',
        depend: 'role_view',
      },
      {
        name: 'role_delete',
        label: 'permissionForm.deleteRole',
        depend: 'role_view',
      },
      {
        name: 'role_edit',
        label: 'permissionForm.editRole',
        depend: 'role_view',
      },
    ],
  },
  {
    name: 'device_view',
    label: 'permissionForm.deviceView',
    depend: 'spotter_admin_access',
    children: [
      {
        name: 'device_add',
        label: 'permissionForm.deviceAdd',
        depend: 'device_view',
      },
      {
        name: 'device_delete',
        label: 'permissionForm.deviceDelete',
        depend: 'device_view',
      },
      {
        name: 'device_edit',
        label: 'permissionForm.deviceEdit',
        depend: 'device_view',
      },
    ],
  },
  {
    name: 'recorder_view',
    label: 'permissionForm.viewRecorders',
    depend: 'spotter_admin_access',
    children: [
      {
        name: 'recorder_add',
        label: 'permissionForm.addNewRecorder',
        depend: 'recorder_view',
      },
      {
        name: 'recorder_delete',
        label: 'permissionForm.deleteRecorder',
        depend: 'recorder_view',
      },
      {
        name: 'recorder_edit',
        label: 'permissionForm.editRecorder',
        depend: 'recorder_view',
      },
    ],
  },
]

export const spotterWebPermissions: PermissionInfo[] = [
  {
    name: 'view_livestream',
    label: 'permissionForm.viewLiveStream',
    depend: 'spotter_web_access',
  },
  {
    name: 'view_playback',
    label: 'permissionForm.viewPlayback',
    depend: 'spotter_web_access',
  },
  {
    name: 'export_video',
    label: 'permissionForm.exportVideoClips',
    depend: 'spotter_web_access',
  },
  {
    name: 'control_ptz',
    label: 'permissionForm.controlCameraPTZ',
    depend: 'spotter_web_access',
  },
  {
    name: 'create_ptz',
    label: 'permissionForm.createCameraPTZPresets',
    depend: 'spotter_web_access',
  },
  {
    name: 'view_alarms',
    label: 'permissionForm.viewAlarmEvents',
    depend: 'spotter_web_access',
  },
  {
    name: 'view_alarm_details',
    label: 'permissionForm.openAlarmsForViewing',
    depend: 'spotter_web_access',
  },
]
