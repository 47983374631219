import { gql } from '@apollo/client'

export const LIST_PROFILES = gql`
  query ListProfiles {
    listProfiles {
      description
      id
      name
      nodes
      organizationId
      roles {
        id
        name
        organizationId
        color
        description
      }
    }
  }
`

export const GET_PROFILE = gql`
  query GetProfile($getProfileId: String!) {
    getProfile(id: $getProfileId) {
      description
      id
      name
      nodes
      organizationId
      roles {
        color
        description
        id
        name
        organizationId
      }
    }
  }
`
