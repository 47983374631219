import { FC, MutableRefObject, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Panel } from '../Panel'
import {
  FormProvider,
  RHFAutocomplete,
  RHFTextarea,
  RHFTextField,
} from 'common-lib/components/RHFControls'
import { RoleChip } from 'common-lib/components'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import { ProfileTreeForm } from './ProfileTreeForm'
import { isVisibleRole } from 'utils/helpers'
import { Profile, CreateProfileDto } from 'gql/graphql'

export interface ProfileDetailFormRef {
  getFormData(): Promise<CreateProfileDto | undefined>
}

interface ProfileDetailFormProps {
  className?: string
  profile?: Profile
  formRef: MutableRefObject<ProfileDetailFormRef>
  onValidate(isValid: boolean): void
}

const schema = yup
  .object()
  .shape({
    name: yup.string().trim().required('This field is required'),
    description: yup.string(),
    roles: yup.array().notRequired(),
  })
  .required()

export const ProfileDetailForm: FC<ProfileDetailFormProps> = ({
  className,
  profile,
  formRef,
  onValidate,
}) => {
  const { t } = useTranslation()
  // TODO:add node:
  //const [nodes, setNodes] = useState(profile?.spec?.nodes)
  const roles = useTypedSelector((state) =>
    state.roles.roles.filter(isVisibleRole)
  )
  const methods = useForm<CreateProfileDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: profile?.name || '',
      description: profile?.description || '',
      // TODO: roles: profile?.roles || [],
    },
  })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = () => {
        return new Promise((resolve) => {
          methods.handleSubmit(
            (data) => {
              resolve({
                ...data,
                // nodes,
              })
            },
            (err) => {
              console.log(err)
              resolve(undefined)
            }
          )()
        })
      }
    }
  }, [formRef]) // TODO: add node to the dependencies

  useEffect(() => {
    if (profile) {
      methods.trigger()
    }
  }, [profile])

  const formData = methods.watch()

  // TODO: add role to dto
  // const selectedRoles = useMemo(() => {
  //   return Object.values(roles).filter((role) =>
  //     formData.roles?.includes(role.name)
  //   )
  // }, [roles, formData.roles])

  useEffect(() => {
    onValidate(methods.formState.isValid)
  }, [formData, onValidate])

  const onFieldChange = (field: keyof CreateProfileDto, value: any) => {
    methods.setValue(field, value)
    methods.trigger(field)
  }

  // TODO: add role to dto
  // const onRemoveRole = (roleName: string) => {
  //   const roles = (formData.roles || []).filter((item) => item !== roleName)
  //   onFieldChange('roles', roles)
  // }

  return (
    <FormProvider
      className={className}
      methods={methods}
      data-testid="device-access-detail-form"
    >
      <Panel.Section>
        <RHFTextField
          className="mt-4"
          name="displayName"
          label={`${t('common.name')}*`}
          data-testid="name-input"
        />
        <RHFTextarea
          className="mt-4"
          name="description"
          label={t('common.descriptionOptional')}
          minRows={4}
          data-testid="description-input"
        />
        <RHFAutocomplete
          className="mt-4"
          name="roles"
          label={t('profileForm.roles') as string}
          placeholder={t('profileForm.rolePlaceholder') as string}
          multiple
          checkbox
          options={roles.map((role) => role.name)}
          renderTags={() => null}
          data-testid="role-autocomplete"
        />
        {/* TODO: add role here when available:
        <div className="flex flex-wrap">
          {selectedRoles.map((role) => (
            <RoleChip
              key={role.name}
              className="mr-3 mt-3"
              role={role}
              onDelete={() => onRemoveRole(role.name)}
              data-testid="role-chip"
            />
          ))}
        </div> */}
      </Panel.Section>

      <Panel.Section containerClass="mt-6 mb-2">
        <h4 className="mb-6">{t('profileForm.profileTree')}</h4>
        {/* <ProfileTreeForm
          className="grid grid-cols-2 gap-4"
          profileName={formData.name}
          profile={profile}
          onChange={setNodes}
        /> */}
      </Panel.Section>
    </FormProvider>
  )
}
