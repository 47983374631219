import React, { FC, useEffect, useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import HomeIcon from '@mui/icons-material/Home'
import PeopleIcon from '@mui/icons-material/People'
import VideocamIcon from '@mui/icons-material/Videocam'
import DnsIcon from '@mui/icons-material/Dns'
import ReportIcon from '@mui/icons-material/Report'
import SettingsIcon from '@mui/icons-material/Settings'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { UserProfile } from './UserProfile'
import { MirasysLogoCropedIcon } from 'assets/icons'
import { PERMISSIONS } from 'common-lib/constants/permissions'
import { selectAccount } from 'store/selectors'
import { hasPermission } from 'utils/helpers'

interface IMenu {
  name: string
  prefix?: string
  link?: string
  icon?: FC<any>
  children?: IMenu[]
  permissions?: string[]
}

export const sidebarMenus: IMenu[] = [
  { name: 'sidebar.dashboard', link: '/dashboard', icon: HomeIcon },
  {
    name: 'sidebar.userManagement',
    prefix: '/user-management',
    icon: PeopleIcon,
    children: [
      {
        name: 'common.users',
        link: '/user-management/users',
        permissions: [PERMISSIONS.USER.READ],
      },
      {
        name: 'sidebar.roleManager',
        link: '/user-management/roles',
        permissions: [PERMISSIONS.ROLE.READ],
      },
    ],
  },
  {
    name: 'sidebar.profiles',
    link: '/profiles',
    icon: VideocamIcon,
    permissions: [PERMISSIONS.DEVICE_ACCESS.READ],
  },
  {
    name: 'sidebar.recorders',
    link: '/recorders',
    icon: DnsIcon,
    permissions: [PERMISSIONS.RECORDER.READ],
  },
  { name: 'sidebar.reports', link: '/reports', icon: ReportIcon },
  { name: 'sidebar.settings', link: '/settings', icon: SettingsIcon },
]

export const lngs: { [key: string]: { nativeName: string } } = {
  en: { nativeName: 'English' },
  fi: { nativeName: 'Finnish' },
}

export const Sidebar = () => {
  const location = useLocation()
  const account = useSelector(selectAccount)
  const { t, i18n } = useTranslation()

  const [expanded, setExpanded] = useState(true)
  const [openedMenu, setOpenedMenu] = useState<IMenu>()

  const enabledMenus = useMemo(() => {
    const menus: IMenu[] = []
    sidebarMenus.forEach((menu) => {
      const children = menu.children?.filter((child) =>
        hasPermission(account, child.permissions)
      )
      if (!menu.link && !children?.length) {
        return
      }
      if (!hasPermission(account, menu.permissions)) {
        return
      }
      menus.push({
        ...menu,
        children,
      })
    })
    return menus
  }, [account])

  useEffect(() => {
    const activeMenu = enabledMenus.find(
      (menu) => menu.prefix && location.pathname.startsWith(menu.prefix)
    )
    setOpenedMenu(activeMenu)
  }, [enabledMenus, location])

  const onSelectMenu = (menu: IMenu) => {
    setOpenedMenu(menu === openedMenu ? undefined : menu)
  }

  return (
    <aside
      className={classNames(
        'z-10 flex h-screen flex-col overflow-y-auto overflow-x-hidden bg-black px-6 pt-6 pb-8 transition-all',
        expanded ? 'w-78 items-start' : 'w-19 items-center'
      )}
      data-testid="sidebar.container"
      // onMouseEnter={() => setExpanded(true)}
      // onMouseLeave={() => setExpanded(false)}
    >
      <div className="mx-auto">
        <MirasysLogoCropedIcon className="h-8 w-33" />
      </div>

      <div className="mt-6 mb-2 w-full">
        <hr className="border-neutral-300" />
      </div>

      <nav className="w-full">
        {enabledMenus.map((item) => {
          const isActive =
            !!item.link && location.pathname.startsWith(item.link)
          const isExpanded = item === openedMenu
          const menuItemClass = classNames(
            'relative mt-6 flex h-12 items-center rounded-lg pl-6 pr-5 transition-all cursor-pointer',
            'hover:bg-secondary-500/30',
            isActive || isExpanded
              ? 'typo-h4 text-white bg-secondary-500/30'
              : 'typo-body1 text-secondary-500 hover:text-white',
            expanded ? 'pl-6 pr-3' : 'justify-center px-6'
          )
          if (item.link) {
            return (
              <NavLink key={item.name} to={item.link} className={menuItemClass}>
                {item.icon && (
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                )}
                <div
                  className={classNames(
                    'ml-2 truncate capitalize',
                    !expanded && 'hidden'
                  )}
                >
                  {t(item.name)}
                </div>
              </NavLink>
            )
          }

          return (
            <React.Fragment key={item.name}>
              <div className={menuItemClass} onClick={() => onSelectMenu(item)}>
                {item.icon && (
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                )}
                <div
                  className={classNames(
                    'ml-2 truncate capitalize',
                    !expanded && 'hidden'
                  )}
                >
                  {t(item.name)}
                </div>
                <ExpandMoreIcon
                  className={classNames(
                    'ml-auto transition-transform',
                    isExpanded && 'rotate-180'
                  )}
                />
              </div>

              {isExpanded && (
                <div className="mt-3 flex items-stretch">
                  <div className="w-9 pb-5">
                    <div className="h-full w-full border-r border-secondary-500" />
                  </div>
                  <div className="ml-3 flex-1">
                    {(item.children || []).map((submenu, j) => {
                      const isActive = location.pathname.startsWith(
                        submenu.link as string
                      )
                      return (
                        <NavLink
                          key={submenu.name}
                          to={submenu.link as string}
                          className={classNames(
                            'typo-body1 flex h-10 w-full items-center rounded-lg px-4 capitalize text-white transition-all hover:bg-secondary-500/30',
                            j > 0 && 'mt-3',
                            isActive && 'bg-secondary-500/30'
                          )}
                        >
                          {t(submenu.name)}
                        </NavLink>
                      )
                    })}
                  </div>
                </div>
              )}
            </React.Fragment>
          )
        })}
      </nav>

      <div className="mt-auto w-full px-4">
        <UserProfile expanded={expanded} />
        <div className="typo-tag mt-4 text-neutral-400">v. 1.0.0.1</div>
        <div className="mt-4">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
              className="mr-2"
            >
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
      </div>
    </aside>
  )
}
