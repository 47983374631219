import { FC, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LogoutIcon from '@mui/icons-material/Logout'
import { LogoutButton } from 'common-lib/components'
import { selectAccount } from 'store/selectors/index'

export interface UserProfile {
  expanded?: boolean
}

export const UserProfile: FC<UserProfile> = ({ expanded }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)
  const open = Boolean(anchorEl)

  const onClose = () => {
    setAnchorEl(undefined)
  }
  const user = useSelector(selectAccount)

  return (
    <>
      <div
        className={classNames(
          'mt-4 flex h-15.5 rounded-lg bg-secondary-900 py-3 pl-4 pr-3 shadow-md transition-all',
          !expanded && 'justify-center'
        )}
      >
        <div className="flex-center h-5 w-5 rounded bg-secondary-500/50">
          <PersonIcon className="text-lg text-secondary-500" />
        </div>
        {expanded && (
          <div className="ml-2 w-0 flex-1">
            <div className="typo-body2 truncate text-white">
              <p>{user.email}</p>
            </div>
            <div className="typo-helper mt-1 truncate text-secondary-400"></div>
          </div>
        )}
        <MoreVertIcon
          className="cursor-pointer text-white"
          onClick={(e) => setAnchorEl(e.currentTarget as any)}
          data-testid="dropdown-trigger"
        />
      </div>

      <Menu
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: 'w-40',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={onClose}
      >
        <MenuItem
          className="bg-transparent hover:bg-secondary-800"
          onClick={onClose}
        >
          <PersonIcon className="text-xl" />
          <span className="ml-3">{t('sidebar.profile')}</span>
        </MenuItem>
        <LogoutButton>
          <MenuItem className="hover:bg-secondary-800" onClick={onClose}>
            <LogoutIcon className="text-xl" />
            <span className="ml-3">{t('sidebar.logout')}</span>
          </MenuItem>
        </LogoutButton>
      </Menu>
    </>
  )
}
