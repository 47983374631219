import { useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Panel } from '../Panel'
import { AddDevicesForm, AddDevicesFormRef } from './AddDevicesForm'
import { useDispatch, useSelector } from '../../store'
import { createDevice, setCreatingDevice } from 'store/slices/devices'
import { showHttpError } from 'utils/helpers'

export const AddDevicesPanel = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const formRef = useRef<AddDevicesFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { creatingDevice } = useSelector((state) => state.devices)
  const [isValid, setIsValid] = useState(false)

  const onSubmit = async () => {
    const data = await formRef.current.getFormData()
    if (!data?.length) {
      return
    }

    return Promise.all(data.map((spec) => dispatch(createDevice(spec)))).then(
      (results: any[]) => {
        let error,
          createdCount = 0
        for (const result of results) {
          if (result.error) {
            if (!error) {
              error = result.error
            }
          } else {
            createdCount++
          }
        }
        if (createdCount > 0) {
          enqueueSnackbar(`${createdCount} devices are created successfully`, {
            variant: 'success',
          })
          onClose()
        } else if (error) {
          showHttpError(enqueueSnackbar, error, 'Creating device failed')
        }
      }
    )
  }

  const onClose = () => {
    dispatch(setCreatingDevice(undefined))
  }

  return (
    <Panel
      title="Add New Device(s)"
      submitButtonText="Add Devices"
      open={!!creatingDevice}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
      isValid={isValid}
    >
      {creatingDevice && (
        <AddDevicesForm
          formRef={formRef}
          recorder={creatingDevice.recorder}
          onValidate={setIsValid}
        />
      )}
    </Panel>
  )
}
