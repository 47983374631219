import { gql } from '@apollo/client'

export const LIST_RECORDERS = gql`
  query ListRecorders {
    listRecorders {
      metadata {
        id
      }
      spec {
        displayName
      }
      status {
        info {
          manufacturer
          model
        }
        conditions {
          status
        }
      }
    }
  }
`

export const GET_RECORDER = gql`
  query GetRecorder($getRecorderId: String!) {
    getRecorder(id: $getRecorderId) {
      spec {
        displayName
        connectionDetails {
          address
          port
          protocol
        }
        description
      }
      gatekeeperId
      status {
        info {
          manufacturer
          model
          softwareVersion
        }
        conditions {
          status
        }
      }
      metadata {
        id
      }
    }
  }
`
