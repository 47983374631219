import { useFormContext, Controller } from 'react-hook-form'
import { Select, SelectProps } from '../FormControls'

type RHFSelectProps = SelectProps & {
  name: string
}

export const RHFSelect = ({
  name,
  helperText,
  children,
  ...other
}: RHFSelectProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </Select>
      )}
    />
  )
}
