import { ConditionStatus, ProtocolEnum } from 'gql/graphql'
import {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from 'react'
import { useTranslation } from 'react-i18next'
// import { Recorder } from 'common-api/clients/recorder/typescript'
import { OnlineIcon } from '../../assets/icons'
import { GET_GATEKEEPER } from 'lib/queries/gatekeeper'
import { useQuery } from '@apollo/client'

interface RecorderDetailsProps {
  recorder: {
    gatekeeperId: string
    spec: {
      displayName: string
      connectionDetails: {
        address: string
        port: number
        protocol: ProtocolEnum
      }
      description?: string | null
    }
    status: {
      info: {
        manufacturer: string
        model: string
        softwareVersion: string
      }
      conditions: {
        status: ConditionStatus
      }[]
    }
  }
}

export const RecorderDetails: FC<RecorderDetailsProps> = ({ recorder }) => {
  const getGatekeeperId = recorder.gatekeeperId
  const { loading, error, data } = useQuery(GET_GATEKEEPER, {
    variables: { getGatekeeperId },
  })

  const { t } = useTranslation()
  const gatekeeper = data?.getGatekeeper

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! ${error.message}</div>
  return (
    <div data-testid="recorder-detail">
      <div className="flex justify-between pt-10">
        <h2 data-testid="user-name">Gatekeeper Details</h2>
      </div>

      <div className="pt-6" data-testid="user-detail-section">
        <div className="flex flex-wrap gap-x-24 gap-y-6">
          <div>
            <h4>Gatekeeper name</h4>
            <div className="typo-body2 mt-2">
              {gatekeeper?.spec.displayName}
            </div>
          </div>
        </div>

        <div className="flex justify-between pt-10">
          <h2 data-testid="user-name">{recorder.spec.displayName}</h2>
        </div>

        <div className="mt-6">
          <h4>{t('common.description')}</h4>
          <p className="typo-body2 mt-2 max-w-155">
            {recorder.spec.description}
          </p>
        </div>
      </div>

      <div className="flex flex-wrap gap-x-24 gap-y-6 pt-6">
        <div>
          <h4>Manufacturer</h4>
          <div className="typo-body2 mt-2">
            {recorder.status?.info?.manufacturer}
          </div>
        </div>
        <div>
          <h4>Model</h4>
          <div className="typo-body2 mt-2">{recorder.status?.info?.model}</div>
        </div>
        <div>
          <h4>Software Version</h4>
          <div className="typo-body2 mt-2">
            {recorder.status?.info?.softwareVersion}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-x-24 gap-y-6 pt-7">
        <div>
          <h4>Address</h4>
          <div className="typo-body2 mt-2">
            {recorder.spec.connectionDetails?.address}
          </div>
        </div>
        <div>
          <h4>Port</h4>
          <div className="typo-body2 mt-2">
            {recorder.spec.connectionDetails?.port}
          </div>
        </div>
        <div>
          <h4>Protocol</h4>
          <div className="typo-body2 mt-2">
            {recorder.spec.connectionDetails?.protocol}
          </div>
        </div>

        <div>
          <h4>Status</h4>
          <div className="typo-body2 mt-2">
            {recorder.status.conditions[0].status == 'TRUE' && (
              <div className="flex items-center">
                <OnlineIcon className="h-4 w-4" />
                <p className="ml-2">Online</p>
              </div>
            )}
            {recorder.status.conditions[0].status == 'FALSE' && <p>Offline</p>}
            {recorder.status.conditions[0].status == 'UNKNOWN' && <p>Unkown</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
