import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import { Breadcrumb } from 'common-lib/components'
import { RecorderDetails } from './RecorderDetails'
import { RecorderDevices } from './RecorderDevices'
import { RecorderChannels } from './RecorderChannels'
import { RecorderAlarms } from './RecorderAlarms'
import { useSelector } from '../../store'
import { useQuery } from '@apollo/client'
import { GET_RECORDER } from 'lib/queries/recorder'

export const RecorderView = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { recorderName } = useParams()
  const getRecorderId = recorderName || 'undefined'
  const { loading, error, data } = useQuery(GET_RECORDER, {
    variables: { getRecorderId },
  })

  const recorder = data?.getRecorder
  const { t } = useTranslation()
  const [tab, setTab] = useState('')

  const breadcrumbs = [
    {
      text: 'ALL RECORDERS',
      link: '/recorders',
    },
    { text: recorder?.spec.displayName || '-' },
  ]

  useEffect(() => {
    const tab = location.pathname.split('/')[3]
    setTab(tab || '')
  }, [location])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! ${error.message}</div>

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />
      <Tabs
        value={tab}
        onChange={(_, tab) => {
          setTab(tab)
          navigate(`${tab}`)
        }}
      >
        <Tab label="Recorder Details" value="" data-testid="detail-tab" />
        <Tab label="Devices" value="devices" data-testid="devices-tab" />
        <Tab label="Channels" value="channels" data-testid="channels-tab" />
        <Tab label="Alarms" value="alarms" data-testid="alarms-tab" />
      </Tabs>

      {recorder ? (
        <Routes>
          <Route path="/" element={<RecorderDetails recorder={recorder} />} />
          {/* <Route
            path="/devices"
            element={<RecorderDevices recorder={recorder} />}
          />
          {/*  
          <Route
            path="/channels"
            element={<RecorderChannels recorder={recorder} />}
          />
          <Route
            path="/alarms"
            element={<RecorderAlarms recorder={recorder} />}
          /> */}
        </Routes>
      ) : (
        <div className="mt-4 py-10 text-center" data-testid="notFound">
          {t('recorderView.notFound')}
        </div>
      )}
    </div>
  )
}
