import { v4 as uuidv4 } from 'uuid'
import { AxiosRequestConfig } from 'axios'
import { SYSTEM_API_VERSION, systemClientApi } from './types'
import {
  DeviceAccess,
  DeviceAccessSpec,
} from '../../common-api/clients/system/typescript'
import { compareSpecs } from '../../utils/helpers'

const patchOptions = {
  headers: {
    'Content-Type': 'application/json-patch+json', // ref: https://pkg.go.dev/k8s.io/apimachinery/pkg/types#PatchType
  },
} as AxiosRequestConfig
const fieldValidation = 'Strict'

const listDeviceAccesses = async (namespace: string) => {
  // TODO: Handle pagination
  return systemClientApi.listNamespacedDeviceAccess(
    namespace,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '0'
  )
}

// TODO: rewrite this to correct
const createDeviceAccess = async (
  namespace: string,
  spec: DeviceAccessSpec
) => {
  const req: DeviceAccess = {
    apiVersion: SYSTEM_API_VERSION,
    kind: 'DeviceAccess',
    metadata: {
      name: uuidv4(),
      namespace,
    },
    spec,
  }
  return systemClientApi.createNamespacedDeviceAccess(
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    req
  )
}

// TODO: rewrite this to correct
const patchDeviceAccess = async (
  name: string,
  namespace: string,
  before: DeviceAccessSpec,
  after: DeviceAccessSpec
) => {
  return systemClientApi.patchNamespacedDeviceAccess(
    name,
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    undefined,
    compareSpecs(before, after),
    patchOptions
  )
}

const deleteDeviceAccess = async (namespace: string, name: string) => {
  return systemClientApi.deleteNamespacedDeviceAccess(name, namespace)
}

export {
  listDeviceAccesses,
  createDeviceAccess,
  patchDeviceAccess,
  deleteDeviceAccess,
}
