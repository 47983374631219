import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {
  Recorder,
  RecorderList,
  RecorderSpec,
} from 'common-api/clients/recorder/typescript'
import { List, NamedMap } from './common'
import * as recordersService from 'services/openapi/recorder'
import { todoChangeMeNamespace } from 'services/openapi/types'
import { parseAxiosError } from '../../utils/helpers'

export interface RecordersState {
  recorders: List<Recorder>
  creatingRecorder?: boolean
  editingRecorder?: Recorder
  deletingRecorder?: string
}

const initialState: RecordersState = {
  recorders: { byName: {}, loading: false },
}

export const fetchRecorders = createAsyncThunk<
  RecorderList,
  void,
  {
    state: RootState
  }
>(
  'recorders/fetchRecorders',
  async () => {
    // TODO: Filter out objects with deletionTimestamp set
    return recordersService
      .listRecorders(todoChangeMeNamespace)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (notused, { getState }) => {
      const { recorders } = getState()
      // don't fetch again, if we're already fetching
      return !recorders.recorders.loading
    },
  }
)

export const createRecorder = createAsyncThunk<Recorder, RecorderSpec>(
  'recorders/createRecorder',
  async (payload) => {
    return recordersService
      .createRecorder(todoChangeMeNamespace, payload)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

export interface PatchRecorderRequest {
  name: string
  before: RecorderSpec
  after: RecorderSpec
}

export const updateRecorder = createAsyncThunk<Recorder, PatchRecorderRequest>(
  'recorders/updateRecorder',
  async (payload) => {
    return recordersService
      .patchRecorder(
        payload.name,
        todoChangeMeNamespace,
        payload.before,
        payload.after
      )
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

export const deleteRecorder = createAsyncThunk<string, string>(
  'recorders/deleteRecorder',
  async (id) => {
    return recordersService
      .deleteRecorder(todoChangeMeNamespace, id)
      .then(() => id)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

const recorders = createSlice({
  name: 'recorders',
  initialState,
  reducers: {
    showCreateRecorderPanel: (state, action: PayloadAction<boolean>) => {
      state.creatingRecorder = action.payload
    },
    setEditingRecorder: (state, action: PayloadAction<string | undefined>) => {
      // TODO: Break this out into another reducer?
      if (action.payload === undefined) {
        state.editingRecorder = undefined
        return
      }
      state.editingRecorder = state.recorders.byName[action.payload]
    },
    setDeletingRecorder: (state, action: PayloadAction<string | undefined>) => {
      state.deletingRecorder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRecorder.fulfilled, (state, action) => {
        const recorder = action.payload
        if (recorder.metadata.deletionTimestamp !== undefined) {
          return
        }
        state.recorders.byName[recorder.metadata.name] = recorder
        state.recorders.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(fetchRecorders.pending, (state) => {
        state.recorders.loading = true
      })
      .addCase(fetchRecorders.fulfilled, (state, action) => {
        const recorders = {} as NamedMap<Recorder>
        action.payload.items.forEach((recorder) => {
          // skip users being deleted
          if (recorder.metadata.deletionTimestamp !== undefined) {
            return
          }
          recorders[recorder.metadata.name] = recorder
        })
        state.recorders.byName = recorders
        state.recorders.loading = false
        state.recorders.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(updateRecorder.fulfilled, (state, action) => {
        state.recorders.byName[action.payload.metadata.name] = action.payload
        state.recorders.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(deleteRecorder.fulfilled, (state, action) => {
        delete state.recorders.byName[action.payload]
        state.creatingRecorder = false
        state.editingRecorder = undefined
        state.deletingRecorder = undefined
      })
  },
})

export const {
  showCreateRecorderPanel,
  setEditingRecorder,
  setDeletingRecorder,
} = recorders.actions

export default recorders.reducer
