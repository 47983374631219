import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'common-lib/components'
import { RoleDetail } from './RoleDetail'
import { useSelector } from '../../store'

export const RoleView = () => {
  const { roleName } = useParams()
  const { t } = useTranslation()
  const role = useSelector((store) =>
    store.roles.roles.find((role) => role.name === roleName)
  )

  const breadcrumbs = [
    {
      text: t('roleManager.breadcrumb'),
      link: '/user-management/roles',
    },
    { text: role ? role.name : '-' },
  ]

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />

      {role ? (
        <RoleDetail className="mt-4" role={role} />
      ) : (
        <div className="mt-4 py-10 text-center" data-testid="notFound">
          {t('roleView.notFound')}
        </div>
      )}
    </div>
  )
}
