import { ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import classNames from 'classnames'
import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export type SelectProps = TextFieldProps & {
  native?: boolean
  maxHeight?: boolean | number
  children: ReactNode
  placeholder?: string
}

const FSelect = (
  {
    className,
    native,
    maxHeight = 220,
    fullWidth = true,
    children,
    placeholder,
    ...other
  }: SelectProps,
  ref: ForwardedRef<any>
) => {
  const [opened, setOpened] = useState(false)

  return (
    <TextField
      className={classNames(className, 'Mui-select')}
      select
      SelectProps={{
        displayEmpty: true,
        native,
        MenuProps: {
          classes: { paper: 'select-dropdown' },
          PaperProps: {
            sx: {
              ...(!native && {
                maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
              }),
            },
          },
        },
        onOpen: () => setOpened(true),
        onClose: () => setOpened(false),
      }}
      fullWidth={fullWidth}
      {...other}
      focused={opened}
      ref={ref}
    >
      <MenuItem value="" disabled>
        <span className="text-neutral-400">{placeholder}</span>
      </MenuItem>
      {children}
    </TextField>
  )
}

export const Select = forwardRef(FSelect)
