import { v4 as uuidv4 } from 'uuid'
import { AxiosRequestConfig } from 'axios'
import {
  UserRequest,
  UserRequestSpec,
  UserSpec,
} from 'common-api/clients/system/typescript/api'
import {
  SYSTEM_API_VERSION,
  systemClientApi,
  todoChangeMeNamespace,
} from './types'
import { compareSpecs } from '../../utils/helpers'

const patchOptions = {
  headers: {
    'Content-Type': 'application/json-patch+json', // ref: https://pkg.go.dev/k8s.io/apimachinery/pkg/types#PatchType
  },
} as AxiosRequestConfig
const fieldValidation = 'Strict'

const listUsers = async (namespace: string) => {
  // TODO: Handle pagination
  return systemClientApi.listNamespacedUser(
    namespace,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '0'
  )
}

// TODO: this still doesn't have the role inside UserRequestSpec obj
// so can't create user with roles yet.
const createUser = async (namespace: string, spec: UserRequestSpec) => {
  const req = {
    apiVersion: SYSTEM_API_VERSION,
    kind: 'UserRequest',
    metadata: {
      name: uuidv4(),
      namespace,
    },
    spec: spec,
  } as UserRequest

  return systemClientApi.createNamespacedUserRequest(
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    req
  )
}

const patchUser = async (
  name: string,
  namespace: string,
  before: UserSpec,
  after: UserSpec
) => {
  return systemClientApi.patchNamespacedUser(
    name,
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    undefined,
    compareSpecs(before, after),
    patchOptions
  )
}

const deleteUser = async (id: string) => {
  return systemClientApi.deleteNamespacedUser(id, todoChangeMeNamespace)
}

export { listUsers, createUser, patchUser, deleteUser }
