import { ReactNode, useCallback, useMemo, useState } from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import { TableContainer, Table, TableBody } from '@mui/material'
import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'
import { Pagination } from '../Pagination'
import { FilterModel, SortModel, TableColumn } from './types'
import { isEmptyFilter, paginateRows, sortTableRows } from './utils'
import noSearchedDataIllustration from 'assets/img/illustrations/no-searched-data.svg'

export interface DataTableProps<T = any> {
  className?: string
  rows: T[]
  columns: TableColumn<T>[]
  size?: 'small' | 'medium'
  reverseRowColors?: boolean
  defaultSort?: SortModel
  rowsPerPage?: number
  expandable?: boolean
  renderChildRow?: (row: T, index: number) => ReactNode
  showFirstLastPaginateButton?: boolean
  autoPaginate?: boolean
  paginatorClass?: string
  hideTableWhenNoData?: boolean
  showIllustration?: boolean
  noTableData?: ReactNode
  noSearchedData?: ReactNode
  noTableDataClass?: string
  noSearchedDataClass?: string
  'data-testid'?: string
}

export const DataTable = ({
  className,
  rows,
  columns,
  size = 'medium',
  reverseRowColors,
  defaultSort,
  rowsPerPage = 10,
  expandable,
  renderChildRow,
  showFirstLastPaginateButton,
  autoPaginate,
  paginatorClass = 'mt-5',
  hideTableWhenNoData,
  showIllustration = true,
  noTableData = 'No records',
  noSearchedData = 'No Search Results',
  noTableDataClass,
  noSearchedDataClass,
  ...otherProps
}: DataTableProps) => {
  const [filter, setFilter] = useState<FilterModel>({})
  const [sortModel, setSortModel] = useState<SortModel | undefined>(defaultSort)
  const [page, setPage] = useState(1)

  const filteredRows = useMemo(() => {
    let data = rows
    Object.entries(filter).forEach(([field, value]) => {
      if (isEmptyFilter(value)) {
        return
      }

      const column = columns.find((item) => item.field === field)
      if (!column) {
        return
      }

      let search = ''
      if (column.filter === 'text' && !Array.isArray(value)) {
        search = (value || '').toLowerCase()
      }

      data = data.filter((row) => {
        const filterValue = column.getFilterValue
          ? column.getFilterValue(row)
          : get(row, field)
        if (column.dataForm === 'array') {
          if (column.filter === 'select') {
            return filterValue?.includes(search)
          }
          if (column.filter === 'multiselect') {
            return filterValue?.some((item: string) =>
              (value as string[]).includes(item)
            )
          }
          return false
        }
        if (column.filter === 'text') {
          return (filterValue || '').toLowerCase().includes(search)
        }
        return filterValue == value
      })
    })
    return data
  }, [columns, rows, filter])

  const sortedRows = useMemo(() => {
    if (!sortModel?.field) {
      return filteredRows
    }
    const column = columns.find((col) => col.field === sortModel.field)
    return sortTableRows(filteredRows, column, sortModel.dir)
  }, [filteredRows, columns, sortModel])

  const visibleRows = useMemo(() => {
    return paginateRows(sortedRows, page, rowsPerPage)
  }, [sortedRows, page, rowsPerPage])

  const onSort = useCallback(
    (column: TableColumn) => {
      const field = column.field
      if (!field) {
        return
      }
      setSortModel((prev) => {
        if (prev?.field !== field) {
          return {
            field,
            dir: 'asc',
          }
        }

        if (prev.dir === 'asc') {
          return {
            field,
            dir: 'desc',
          }
        }

        return undefined
      })
    },
    [sortModel]
  )

  const onFilterChange = useCallback((newFilter: FilterModel) => {
    setFilter((prev) => ({
      ...prev,
      ...newFilter,
    }))
    setPage(1)
  }, [])

  return (
    <div className={className} {...otherProps}>
      <TableContainer>
        {(rows.length > 0 || !hideTableWhenNoData) && (
          <Table size={size}>
            <TableHeader
              columns={columns}
              rows={rows}
              expandable={expandable}
              reverseRowColors={reverseRowColors}
              filter={filter}
              sort={sortModel}
              onFilterChange={onFilterChange}
              onSort={onSort}
            />

            <TableBody>
              {visibleRows.map((row, rowId) => (
                <TableRow
                  key={rowId}
                  columns={columns}
                  row={row}
                  rowId={rowId}
                  expandable={expandable}
                  reverseRowColors={reverseRowColors}
                  renderChildRow={renderChildRow}
                />
              ))}
            </TableBody>
          </Table>
        )}

        {!rows.length && (
          <div
            className={classNames(
              'typo-body1 flex-center flex-col py-20 px-6',
              noTableDataClass
            )}
          >
            {noTableData}
          </div>
        )}
        {rows.length > 0 && !visibleRows.length && (
          <div
            className={classNames(
              'typo-body1 flex-center flex-col py-20 px-6',
              noSearchedDataClass
            )}
          >
            {showIllustration && (
              <img className="mb-10" src={noSearchedDataIllustration} alt="" />
            )}
            {noSearchedData}
          </div>
        )}
      </TableContainer>

      {visibleRows.length > 0 &&
        (!autoPaginate || filteredRows.length > rowsPerPage) && (
          <Pagination
            className={paginatorClass}
            count={filteredRows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            showFirstLastButton={showFirstLastPaginateButton}
            onPageChange={setPage}
          />
        )}
    </div>
  )
}
