import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux'
import {
  auth,
  users,
  roles,
  recorders,
  devices,
  videoChannels,
  videoStreams,
  deviceAccesses,
} from './slices'

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type GetState = typeof store.getState

const rootReducer = combineReducers({
  auth,
  users,
  roles,
  recorders,
  devices,
  videoChannels,
  videoStreams,
  deviceAccesses,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector
const useDispatch = () => useAppDispatch<AppDispatch>()

export { store, useSelector, useDispatch }
