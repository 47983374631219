import { v4 as uuidv4 } from 'uuid'
import { AxiosRequestConfig } from 'axios'
import { RECORDER_API_VERSION, recorderClientApi } from './types'
import {
  VideoChannel,
  VideoChannelSpec,
} from '../../common-api/clients/recorder/typescript'
import { compareSpecs } from '../../utils/helpers'

const patchOptions = {
  headers: {
    'Content-Type': 'application/json-patch+json', // ref: https://pkg.go.dev/k8s.io/apimachinery/pkg/types#PatchType
  },
} as AxiosRequestConfig
const fieldValidation = 'Strict'

const listVideoChannels = async (namespace: string) => {
  // TODO: Handle pagination
  return recorderClientApi.listNamespacedVideoChannel(
    namespace,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '0'
  )
}

const createVideoChannel = async (
  namespace: string,
  spec: VideoChannelSpec
) => {
  const req: VideoChannel = {
    apiVersion: RECORDER_API_VERSION,
    kind: 'VideoChannel',
    metadata: {
      name: uuidv4(),
      namespace,
    },
    spec,
  }
  return recorderClientApi.createNamespacedVideoChannel(
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    req
  )
}

const patchVideoChannel = async (
  name: string,
  namespace: string,
  before: VideoChannelSpec,
  after: VideoChannelSpec
) => {
  return recorderClientApi.patchNamespacedVideoChannel(
    name,
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    undefined,
    compareSpecs(before, after),
    patchOptions
  )
}

const deleteVideoChannel = async (namespace: string, id: string) => {
  return recorderClientApi.deleteNamespacedVideoChannel(id, namespace)
}

export {
  listVideoChannels,
  createVideoChannel,
  patchVideoChannel,
  deleteVideoChannel,
}
