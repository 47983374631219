import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import noDataIllustration from 'assets/img/illustrations/no-data.svg'
// import { Recorder } from 'common-api/clients/recorder/typescript'
import { Breadcrumb } from 'common-lib/components'
import { DataTable, SortModel, TableColumn } from 'common-lib/components/Table'
import { useDispatch, useSelector } from '../../store'
import {
  setDeletingRecorder,
  setEditingRecorder,
  showCreateRecorderPanel,
} from 'store/slices/recorders'
import { getStatusClass } from '../../utils/helpers'
import { useQuery } from '@apollo/client'
import { LIST_RECORDERS } from 'lib/queries/recorder'
import { Recorder } from '../../gql/graphql'
const DEFAULT_SORT: SortModel = { field: 'name', dir: 'asc' }
const ROWS_PER_PAGE = 10

interface TableData {
  id: string
  deviceName: string
  manufacturer: string
  model: string
  channels: string
  status: string
}

export const RecordersOverview = () => {
  const { loading, error, data } = useQuery(LIST_RECORDERS)
  const dispatch = useDispatch()
  // const { t } = useTranslation()

  const recorders: Recorder[] = data?.listRecorders || []

  const breadcrumbs = [{ text: 'All Recorders' }]

  const columns: TableColumn[] = [
    {
      title: 'Device Name',
      field: 'deviceName',
      filter: 'text',
      sortable: true,
      render: (row: TableData) => {
        return <Link to={`/recorders/${row.id}`}>{row.deviceName}</Link>
      },
    },
    {
      title: 'Manufacturer',
      field: 'manufacturer',
      filter: 'multiselect',
      dataForm: 'array',
      getFilterValue: (row: TableData) => {
        return Array(row.manufacturer)
      },
      sortable: true,
    },
    {
      title: 'Model',
      field: 'model',
      filter: 'multiselect',
      dataForm: 'array',
      getFilterValue: (row: TableData) => {
        return Array(row.model)
      },
      sortable: true,
    },
    {
      title: 'Channels',
      field: 'channels',
      filter: 'multiselect',
      dataForm: 'array',
      getFilterValue: (row: TableData) => {
        return Array(row.channels)
      },
      sortable: true,
    },
    {
      title: 'Status',
      field: 'status',
      filter: 'multiselect',
      dataForm: 'array',
      getFilterValue: (row: TableData) => {
        return Array(row.status)
      },
      sortable: true,
      render: (row: TableData) => {
        return (
          <div className="flex items-center">
            <div className={getStatusClass(row.status)} />
            {row.status}
          </div>
        )
      },
    },
    {
      title: '',
      align: 'right',
      cellClass: 'py-1',
      render: (row: TableData) => {
        const recorder = recorders[Number(row.id)]
        return (
          <div className="whitespace-nowrap">
            <IconButton
              // onClick={() => onEditRecorder(recorder)}
              data-testid="edit-recorder-button"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              // onClick={() => onDeleteRecorder(recorder)}
              data-testid="delete-recorder-button"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )
      },
    },
  ]

  const rows = useMemo(() => {
    return recorders.map((recorder) => {
      return {
        id: recorder.metadata?.id,
        deviceName: recorder.spec?.displayName || 'No name given',
        manufacturer: recorder.status?.info?.manufacturer,
        model: recorder.status?.info?.model,
        channels: '',
        status: 'Online',
      }
    })
  }, [recorders])

  const onAddRecorder = () => {
    dispatch(showCreateRecorderPanel(true))
  }

  // const onEditRecorder = (recorder: Recorder) => {
  //   dispatch(setEditingRecorder(recorder.metadata.name))
  // }

  // const onDeleteRecorder = (recorder: Recorder) => {
  //   dispatch(setDeletingRecorder(recorder.metadata.name))
  // }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error! ${error.message}</div>
  return (
    <div>
      <Breadcrumb items={breadcrumbs} />

      <div className="flex items-start justify-between">
        <div>
          <h2>All Recorders</h2>
          <p className="typo-body2 mt-2 text-secondary-400">
            Here you can find all the recorders/gatekeepers in you platform.
          </p>
        </div>

        <Button
          color="primary"
          className="ml-auto px-5"
          onClick={onAddRecorder}
          data-testid="add-recorder-button"
        >
          Add Recorder
        </Button>
      </div>

      <DataTable
        className="mt-10"
        columns={columns}
        rows={rows}
        defaultSort={DEFAULT_SORT}
        rowsPerPage={ROWS_PER_PAGE}
        paginatorClass="mt-6"
        showFirstLastPaginateButton
        hideTableWhenNoData
        noTableData={
          <>
            <img src={noDataIllustration} alt="" />
            <p className="mt-10">
              It doesn’t seem to be any registered Recorder.
            </p>
            <Button
              className="mt-4 px-5"
              color="primary"
              variant="outlined"
              onClick={onAddRecorder}
              data-testid="add-recorder-button"
            >
              Add Recorder
            </Button>
          </>
        }
        data-testid="recorders-table"
      />
    </div>
  )
}
