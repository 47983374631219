import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {
  VideoStream,
  VideoStreamList,
  VideoStreamSpec,
} from 'common-api/clients/recorder/typescript'
import { List, NamedMap } from './common'
import * as videoStreamsService from 'services/openapi/videoStream'
import { todoChangeMeNamespace } from 'services/openapi/types'
import { WatchEvent, WatchEventType } from 'services/openapi/watch'
import { parseAxiosError } from '../../utils/helpers'

export interface VideoStreamsState {
  videoStreams: List<VideoStream>
  creatingVideoStream?: boolean
  editingVideoStream?: VideoStream
  deletingVideoStream?: string
}

const initialState: VideoStreamsState = {
  videoStreams: { byName: {}, loading: false },
}

export const fetchVideoStreams = createAsyncThunk<
  VideoStreamList,
  void,
  {
    state: RootState
  }
>(
  'videoStreams/fetchVideoStreams',
  async () => {
    // TODO: Filter out objects with deletionTimestamp set
    return videoStreamsService
      .listVideoStreams(todoChangeMeNamespace)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (notused, { getState }) => {
      const { videoStreams } = getState()
      // don't fetch again, if we're already fetching
      return !videoStreams.videoStreams.loading
    },
  }
)

export const createVideoStream = createAsyncThunk<VideoStream, VideoStreamSpec>(
  'videoStreams/createVideoStream',
  async (payload) => {
    return videoStreamsService
      .createVideoStream(todoChangeMeNamespace, payload)
      .then((resp) => resp.data)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

export interface PatchVideoStreamRequest {
  name: string
  before: VideoStreamSpec
  after: VideoStreamSpec
}

export const updateVideoStream = createAsyncThunk<
  VideoStream,
  PatchVideoStreamRequest
>('videoStreams/updateVideoStream', async (payload) => {
  return videoStreamsService
    .patchVideoStream(
      payload.name,
      todoChangeMeNamespace,
      payload.before,
      payload.after
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw parseAxiosError(err)
    })
})

export const deleteVideoStream = createAsyncThunk<string, string>(
  'videoStreams/deleteVideoStream',
  async (id) => {
    return videoStreamsService
      .deleteVideoStream(todoChangeMeNamespace, id)
      .then(() => id)
      .catch((err) => {
        throw parseAxiosError(err)
      })
  }
)

const videoStreams = createSlice({
  name: 'videoStreams',
  initialState,
  reducers: {
    showCreateVideoStreamPanel: (state, action: PayloadAction<boolean>) => {
      state.creatingVideoStream = action.payload
    },
    setEditingVideoStream: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      // TODO: Break this out into another reducer?
      if (action.payload === undefined) {
        state.editingVideoStream = undefined
        return
      }
      state.editingVideoStream = state.videoStreams.byName[action.payload]
    },
    setDeletingVideoStream: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.deletingVideoStream = action.payload
    },
    watchVideoStreamChange: (
      state,
      action: PayloadAction<WatchEvent<VideoStream>>
    ) => {
      const videoStream = action.payload.object
      switch (action.payload.type) {
        case WatchEventType.ADDED:
          state.videoStreams.byName[videoStream.metadata.name] = videoStream
          break
        case WatchEventType.MODIFIED:
          if (videoStream.metadata.deletionTimestamp) {
            delete state.videoStreams.byName[videoStream.metadata.name]
          } else {
            state.videoStreams.byName[videoStream.metadata.name] = videoStream
          }
          break
        case WatchEventType.DELETED:
          delete state.videoStreams.byName[videoStream.metadata.name]
          break
        default:
          console.log('unknown watch event', action.payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVideoStream.fulfilled, (state, action) => {
        const videoStream = action.payload
        if (videoStream.metadata.deletionTimestamp !== undefined) {
          return
        }
        state.videoStreams.byName[videoStream.metadata.name] = videoStream
        state.videoStreams.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(fetchVideoStreams.pending, (state) => {
        state.videoStreams.loading = true
      })
      .addCase(fetchVideoStreams.fulfilled, (state, action) => {
        const videoStreams = {} as NamedMap<VideoStream>
        action.payload.items.forEach((videoStream) => {
          // skip users being deleted
          if (videoStream.metadata.deletionTimestamp !== undefined) {
            return
          }
          videoStreams[videoStream.metadata.name] = videoStream
        })
        state.videoStreams.byName = videoStreams
        state.videoStreams.loading = false
        state.videoStreams.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(updateVideoStream.fulfilled, (state, action) => {
        state.videoStreams.byName[action.payload.metadata.name] = action.payload
        state.videoStreams.resourceVersion =
          action.payload.metadata.resourceVersion
      })
      .addCase(deleteVideoStream.fulfilled, (state, action) => {
        delete state.videoStreams.byName[action.payload]
        state.creatingVideoStream = false
        state.editingVideoStream = undefined
        state.deletingVideoStream = undefined
      })
  },
})

export const {
  showCreateVideoStreamPanel,
  setEditingVideoStream,
  setDeletingVideoStream,
  watchVideoStreamChange,
} = videoStreams.actions

export default videoStreams.reducer
