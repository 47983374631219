import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { User } from 'common-api/clients/system/typescript'
import { Breadcrumb, RoleChip } from 'common-lib/components'
import { DataTable, SortModel, TableColumn } from 'common-lib/components/Table'
import { Role } from 'gql/graphql'
import { useDispatch, useSelector } from '../../store'
import {
  setDeletingUser,
  setEditingUser,
  showCreateUserPanel,
} from 'store/slices/users'

interface TableData {
  id: string
  name: string
  email: string
  roles: Role[]
}

const DEFAULT_SORT: SortModel = { field: 'name', dir: 'asc' }
const ROWS_PER_PAGE = 10

export const UsersOverview = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // TODO: rewrite Sanity check:
  /* if (!initialized || !keycloak.authenticated) {
    return <div>You must first log in...</div>
  }
  if (keycloak.idTokenParsed === undefined) {
    return null
  } */

  const users = useSelector((state) => state.users.users.byName)
  const roles = useSelector((state) => state.roles.roles)

  const breadcrumbs = [{ text: t('users.breadcrumb') }]

  const columns: TableColumn[] = [
    {
      title: t('common.name'),
      field: 'name',
      filter: 'text',
      sortable: true,
      render: (row: TableData) => {
        return <Link to={`/user-management/users/${row.id}`}>{row.name}</Link>
      },
    },
    {
      title: t('common.email'),
      field: 'email',
      filter: 'text',
      sortable: true,
    },
    {
      title: t('users.roles'),
      field: 'roles',
      cellClass: 'py-1',
      filter: 'multiselect',
      dataForm: 'array',
      sortable: true,
      getFilterValue: (row: TableData) => {
        return row.roles.map((role) => role.name)
      },
      render: (row: TableData) => {
        return (
          <div className="-mb-1 flex flex-wrap">
            {row.roles.map((role) => (
              <RoleChip
                key={role.name}
                className="mr-2 mb-1"
                role={role}
                size="small"
              />
            ))}
          </div>
        )
      },
    },
    {
      title: '',
      align: 'right',
      cellClass: 'py-1',
      render: (row: TableData) => {
        const user = users[row.id]
        return (
          <div className="whitespace-nowrap">
            <IconButton
              onClick={() => onEditUser(user)}
              data-testid="edit-user-button"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {Object.values(users).length > 1 && user && (
              <IconButton
                onClick={() => onDeleteUser(user)}
                data-testid="delete-user-button"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )
      },
    },
  ]

  const rows = useMemo(() => {
    const rows: TableData[] = []
    if (Object.keys(users).length) {
      Object.values(users).forEach((user: User) => {
        const userRoles = (user.spec.roles || [])
          .map((id) => {
            return Object.values(roles).find((role: Role) => role.name === id)
          })
          .filter((role) => !!role)

        rows.push({
          id: user.metadata.name,
          name: `${user.spec.firstName} ${user.spec.lastName}`,
          email: user.spec.email,
          roles: userRoles as Role[],
        })
      })
    }
    return rows
  }, [users])

  const onAddUser = () => {
    dispatch(showCreateUserPanel(true))
  }

  const onEditUser = (user: User) => {
    dispatch(setEditingUser(user.metadata.name))
  }

  const onDeleteUser = (user: User) => {
    dispatch(setDeletingUser(user))
  }

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />

      <div className="flex items-start justify-between">
        <div>
          <h2>{t('users.userList')}</h2>
          <p className="typo-body2 mt-2 text-secondary-400">
            {t('users.descriptionPlaceholder')}
          </p>
        </div>

        <Button
          color="primary"
          className="ml-auto px-8"
          onClick={onAddUser}
          data-testid="add-user-button"
        >
          {t('common.addUser')}
        </Button>
      </div>

      <DataTable
        className="mt-10"
        columns={columns}
        rows={rows}
        defaultSort={DEFAULT_SORT}
        rowsPerPage={ROWS_PER_PAGE}
        paginatorClass="mt-6"
        showFirstLastPaginateButton
        noTableData="There are no users created"
        data-testid="users-table"
      />
    </div>
  )
}
