import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ENDPOINTS } from 'common-lib/constants'
import { COOKIE_ITEM, CookieService } from 'common-lib/services/cookies'

// const client = new ApolloClient({
//   link: new HttpLink({
//     uri: ENDPOINTS.API_SERVER,
//     headers: {
//       Authorization:
//         'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhMmEwOGYyYy0zZDk2LTRkOTQtODk2NC0xYTY2NTkwOTdhM2UiLCJ0eXAiOiJCZWFyZXIiLCJpYXQiOjE2OTgzMTkwMjQsImV4cCI6MTY5ODkyMzgyNCwiaXNzIjoiTWlyYXN5cyJ9.hEXJzHCE3qt_w7ADkhCFX7NLlVWMBUtBdRM3fEmhulhvQUqFVhROWWFy24H0Ic3qZA0ms2HkLljrk18kmJjEKUlp6Fq7bplGRs92R0FA-EbmcOjl-T4TlhLnQwiNPI6VGP6Ttj93oW-EgSImyLl18w78ID47XkeQjh-L0-H8IPZHG04Z8FkVrB2Z9Par7-7MB_3XZDhm6DAX2CjAddhQAcVHBnWW7H-8iDQGXGXFamfa4K72qwmvJdynMc-Oc_LL8Bn7SF5n9vvCw-OhKD_n9xtI77PMZTJgkTkvNBj6nipqllDu1-nCPNfvNoHJbQs3onbJyP-SD9ylhNLOsvnn-Q',
//     },
//     fetchOptions: {
//       mode: 'no-cors',
//     },
//   }),
//   cache: new InMemoryCache(),
// })
// console.log(client)

const httpLink = createHttpLink({
  uri: ENDPOINTS.API_SERVER_URL,
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const token = CookieService.get(COOKIE_ITEM.ACCESS_TOKEN)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      origin: '*',
    },
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
