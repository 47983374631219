/* tslint:disable */
/* eslint-disable */
/**
 * Kubernetes CRD Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AlarmConfiguration
 */
export interface AlarmConfiguration {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof AlarmConfiguration
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof AlarmConfiguration
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationMetadata}
     * @memberof AlarmConfiguration
     */
    'metadata': AlarmConfigurationMetadata;
    /**
     * 
     * @type {AlarmConfigurationSpec}
     * @memberof AlarmConfiguration
     */
    'spec': AlarmConfigurationSpec;
    /**
     * 
     * @type {AlarmConfigurationStatus}
     * @memberof AlarmConfiguration
     */
    'status'?: AlarmConfigurationStatus;
}
/**
 * AlarmConfigurationList is a list of AlarmConfiguration
 * @export
 * @interface AlarmConfigurationList
 */
export interface AlarmConfigurationList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof AlarmConfigurationList
     */
    'apiVersion': string;
    /**
     * List of alarmconfigurations. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<AlarmConfiguration>}
     * @memberof AlarmConfigurationList
     */
    'items': Array<AlarmConfiguration>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof AlarmConfigurationList
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationListMetadata}
     * @memberof AlarmConfigurationList
     */
    'metadata': AlarmConfigurationListMetadata;
}
/**
 * Standard list metadata. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
 * @export
 * @interface AlarmConfigurationListMetadata
 */
export interface AlarmConfigurationListMetadata {
    /**
     * continue may be set if the user set a limit on the number of items returned, and indicates that the server has more data available. The value is opaque and may be used to issue another request to the endpoint that served this list to retrieve the next set of available objects. Continuing a consistent list may not be possible if the server configuration has changed or more than a few minutes have passed. The resourceVersion field returned when using this continue value will be identical to the value in the first response, unless you have received this token from an error message.
     * @type {string}
     * @memberof AlarmConfigurationListMetadata
     */
    'continue'?: string;
    /**
     * remainingItemCount is the number of subsequent items in the list which are not included in this list response. If the list request contained label or field selectors, then the number of remaining items is unknown and the field will be left unset and omitted during serialization. If the list is complete (either because it is not chunking or because this is the last chunk), then there are no more remaining items and this field will be left unset and omitted during serialization. Servers older than v1.15 do not set this field. The intended use of the remainingItemCount is *estimating* the size of a collection. Clients should not rely on the remainingItemCount to be set or to be exact.
     * @type {number}
     * @memberof AlarmConfigurationListMetadata
     */
    'remainingItemCount'?: number;
    /**
     * String that identifies the server\'s internal version of this object that can be used by clients to determine when objects have changed. Value must be treated as opaque by clients and passed unmodified back to the server. Populated by the system. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof AlarmConfigurationListMetadata
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof AlarmConfigurationListMetadata
     */
    'selfLink'?: string;
}
/**
 * Standard object\'s metadata. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata
 * @export
 * @interface AlarmConfigurationMetadata
 */
export interface AlarmConfigurationMetadata {
    /**
     * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations
     * @type {{ [key: string]: string; }}
     * @memberof AlarmConfigurationMetadata
     */
    'annotations'?: { [key: string]: string; };
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'creationTimestamp'?: string;
    /**
     * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only.
     * @type {number}
     * @memberof AlarmConfigurationMetadata
     */
    'deletionGracePeriodSeconds'?: number;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'deletionTimestamp'?: string;
    /**
     * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list.
     * @type {Array<string>}
     * @memberof AlarmConfigurationMetadata
     */
    'finalizers'?: Array<string>;
    /**
     * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will return a 409.  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'generateName'?: string;
    /**
     * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only.
     * @type {number}
     * @memberof AlarmConfigurationMetadata
     */
    'generation'?: number;
    /**
     * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/labels
     * @type {{ [key: string]: string; }}
     * @memberof AlarmConfigurationMetadata
     */
    'labels'?: { [key: string]: string; };
    /**
     * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.
     * @type {Array<MetaV1ObjectMetaManagedFields>}
     * @memberof AlarmConfigurationMetadata
     */
    'managedFields'?: Array<MetaV1ObjectMetaManagedFields>;
    /**
     * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'name': string;
    /**
     * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'namespace': string;
    /**
     * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller.
     * @type {Array<MetaV1ObjectMetaOwnerReferences>}
     * @memberof AlarmConfigurationMetadata
     */
    'ownerReferences'?: Array<MetaV1ObjectMetaOwnerReferences>;
    /**
     * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'selfLink'?: string;
    /**
     * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof AlarmConfigurationMetadata
     */
    'uid'?: string;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface AlarmConfigurationSpec
 */
export interface AlarmConfigurationSpec {
    /**
     * Actions list for current alarm. Could be empty for non-configured event
     * @type {Array<AlarmConfigurationSpecActions>}
     * @memberof AlarmConfigurationSpec
     */
    'actions'?: Array<AlarmConfigurationSpecActions>;
    /**
     * Alarm name should be selected from events list supported by current recorder
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'alarmName'?: string;
    /**
     * Unique alarm configuration Id
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'configurationId'?: string;
    /**
     * Alarm configuration parameters list. Could be empty for non-configured alarms
     * @type {Array<AlarmConfigurationSpecActionsConfigurationParameters>}
     * @memberof AlarmConfigurationSpec
     */
    'configurationParameters'?: Array<AlarmConfigurationSpecActionsConfigurationParameters>;
    /**
     * String with rule to prepare content for notification alarm object Could consists from some predefined tages which mixed with constant text. Eqauls to DisplayName by default.
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'contentRule'?: string;
    /**
     * Custom description which is visible for user
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'description'?: string | null;
    /**
     * Display name which is visible for user
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'displayName'?: string;
    /**
     * Is active or disabled
     * @type {boolean}
     * @memberof AlarmConfigurationSpec
     */
    'enabled'?: boolean;
    /**
     * Time when need to referesh settings
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'needProcessSettingsAfterTime'?: string | null;
    /**
     * Alarm priority
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'priority'?: AlarmConfigurationSpecPriorityEnum;
    /**
     * Recorder identifier
     * @type {string}
     * @memberof AlarmConfigurationSpec
     */
    'recorderRef'?: string;
}

export const AlarmConfigurationSpecPriorityEnum = {
    VeryLow: 'VeryLow',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    VeryHigh: 'VeryHigh',
    Critical: 'Critical'
} as const;

export type AlarmConfigurationSpecPriorityEnum = typeof AlarmConfigurationSpecPriorityEnum[keyof typeof AlarmConfigurationSpecPriorityEnum];

/**
 * 
 * @export
 * @interface AlarmConfigurationSpecActions
 */
export interface AlarmConfigurationSpecActions {
    /**
     * Alarm action configuration parameters list. Could be empty for non-configured alarms
     * @type {Array<AlarmConfigurationSpecActionsConfigurationParameters>}
     * @memberof AlarmConfigurationSpecActions
     */
    'configurationParameters'?: Array<AlarmConfigurationSpecActionsConfigurationParameters>;
    /**
     * Alarm action name should be selected from events list supported by current recorder
     * @type {string}
     * @memberof AlarmConfigurationSpecActions
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AlarmConfigurationSpecActionsConfigurationParameters
 */
export interface AlarmConfigurationSpecActionsConfigurationParameters {
    /**
     * Parameter name
     * @type {string}
     * @memberof AlarmConfigurationSpecActionsConfigurationParameters
     */
    'name': string;
    /**
     * Parameter type from supported alarm parameters type
     * @type {string}
     * @memberof AlarmConfigurationSpecActionsConfigurationParameters
     */
    'type'?: string;
    /**
     * Parameter value has always string type
     * @type {string}
     * @memberof AlarmConfigurationSpecActionsConfigurationParameters
     */
    'value'?: string;
}
/**
 * Status object for the entity.
 * @export
 * @interface AlarmConfigurationStatus
 */
export interface AlarmConfigurationStatus {
    /**
     * Recorder conditions
     * @type {Array<AlarmConfigurationStatusConditions>}
     * @memberof AlarmConfigurationStatus
     */
    'conditions'?: Array<AlarmConfigurationStatusConditions>;
    /**
     * Last time when settings were refreshed
     * @type {string}
     * @memberof AlarmConfigurationStatus
     */
    'lastSettingsApplyTime'?: string | null;
}
/**
 * 
 * @export
 * @interface AlarmConfigurationStatusConditions
 */
export interface AlarmConfigurationStatusConditions {
    /**
     * Last time the condition transitioned from one status to another
     * @type {string}
     * @memberof AlarmConfigurationStatusConditions
     */
    'lastTransitionTime'?: string | null;
    /**
     * Human readable message indicating details about the transition
     * @type {string}
     * @memberof AlarmConfigurationStatusConditions
     */
    'message'?: string;
    /**
     * ObservedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.\",
     * @type {number}
     * @memberof AlarmConfigurationStatusConditions
     */
    'observedGeneration'?: number;
    /**
     * Condition reason for the condition\'s last transition
     * @type {string}
     * @memberof AlarmConfigurationStatusConditions
     */
    'reason'?: AlarmConfigurationStatusConditionsReasonEnum;
    /**
     * Status of the condition, one of Unknown, True, False
     * @type {string}
     * @memberof AlarmConfigurationStatusConditions
     */
    'status'?: AlarmConfigurationStatusConditionsStatusEnum;
    /**
     * Type of condition
     * @type {string}
     * @memberof AlarmConfigurationStatusConditions
     */
    'type'?: AlarmConfigurationStatusConditionsTypeEnum;
}

export const AlarmConfigurationStatusConditionsReasonEnum = {
    Unknown: 'Unknown',
    Ok: 'Ok',
    RecorderSettingsApplyError: 'RecorderSettingsApplyError',
    DeviceSettingsApplyError: 'DeviceSettingsApplyError',
    VideoChannelSettingsApplyError: 'VideoChannelSettingsApplyError',
    VideoStreamSettingsApplyError: 'VideoStreamSettingsApplyError',
    VideoStreamSettingsApplyErrorStreamNotFound: 'VideoStreamSettingsApplyErrorStreamNotFound',
    VideoStreamSettingsApplyErrorVideoSettings: 'VideoStreamSettingsApplyErrorVideoSettings',
    VideoStreamSettingsApplyErrorRecordingSettings: 'VideoStreamSettingsApplyErrorRecordingSettings',
    RecorderSettingsPartiallyApplied: 'RecorderSettingsPartiallyApplied',
    DeviceSettingsPartiallyApplied: 'DeviceSettingsPartiallyApplied',
    VideoChannelSettingsPartiallyApplied: 'VideoChannelSettingsPartiallyApplied',
    VideoStreamSettingsPartiallyApplied: 'VideoStreamSettingsPartiallyApplied',
    UnsupportedRecorder: 'UnsupportedRecorder',
    UnsupportedDevice: 'UnsupportedDevice',
    AddDeviceError: 'AddDeviceError',
    AddDeviceErrorNoFreeChannels: 'AddDeviceErrorNoFreeChannels',
    AddDeviceErrorRemovedByRecorder: 'AddDeviceErrorRemovedByRecorder',
    RemoveDeviceError: 'RemoveDeviceError',
    RecorderGetCapabilitiesError: 'RecorderGetCapabilitiesError',
    RecorderGetCapabilitiesErrorCapabilities: 'RecorderGetCapabilitiesErrorCapabilities',
    DeviceGetCapabilitiesError: 'DeviceGetCapabilitiesError',
    DeviceGetCapabilitiesErrorDeviceNotFound: 'DeviceGetCapabilitiesErrorDeviceNotFound',
    VideoChannelGetCapabilitiesError: 'VideoChannelGetCapabilitiesError',
    VideoChannelGetCapabilitiesErrorChannelNotFound: 'VideoChannelGetCapabilitiesErrorChannelNotFound',
    VideoStreamGetCapabilitiesError: 'VideoStreamGetCapabilitiesError',
    VideoStreamGetCapabilitiesErrorStreamNotFound: 'VideoStreamGetCapabilitiesErrorStreamNotFound',
    StreamingServerUnaccessible: 'StreamingServerUnaccessible',
    StreamingStreamNotFound: 'StreamingStreamNotFound',
    StreamingTracksAreEmpty: 'StreamingTracksAreEmpty',
    StreamingStreamCreateError: 'StreamingStreamCreateError',
    StreamingStreamStartError: 'StreamingStreamStartError'
} as const;

export type AlarmConfigurationStatusConditionsReasonEnum = typeof AlarmConfigurationStatusConditionsReasonEnum[keyof typeof AlarmConfigurationStatusConditionsReasonEnum];
export const AlarmConfigurationStatusConditionsStatusEnum = {
    Unknown: 'Unknown',
    True: 'True',
    False: 'False'
} as const;

export type AlarmConfigurationStatusConditionsStatusEnum = typeof AlarmConfigurationStatusConditionsStatusEnum[keyof typeof AlarmConfigurationStatusConditionsStatusEnum];
export const AlarmConfigurationStatusConditionsTypeEnum = {
    Connected: 'Connected',
    Added: 'Added',
    CapabilitiesReceived: 'CapabilitiesReceived',
    Configured: 'Configured',
    StreamCreated: 'StreamCreated',
    StreamStarted: 'StreamStarted'
} as const;

export type AlarmConfigurationStatusConditionsTypeEnum = typeof AlarmConfigurationStatusConditionsTypeEnum[keyof typeof AlarmConfigurationStatusConditionsTypeEnum];

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof Device
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof Device
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationMetadata}
     * @memberof Device
     */
    'metadata': AlarmConfigurationMetadata;
    /**
     * 
     * @type {DeviceSpec}
     * @memberof Device
     */
    'spec': DeviceSpec;
    /**
     * 
     * @type {DeviceStatus}
     * @memberof Device
     */
    'status'?: DeviceStatus;
}
/**
 * DeviceList is a list of Device
 * @export
 * @interface DeviceList
 */
export interface DeviceList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof DeviceList
     */
    'apiVersion': string;
    /**
     * List of devices. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<Device>}
     * @memberof DeviceList
     */
    'items': Array<Device>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof DeviceList
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationListMetadata}
     * @memberof DeviceList
     */
    'metadata': AlarmConfigurationListMetadata;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface DeviceSpec
 */
export interface DeviceSpec {
    /**
     * 
     * @type {DeviceSpecConnectionDetails}
     * @memberof DeviceSpec
     */
    'connectionDetails'?: DeviceSpecConnectionDetails | null;
    /**
     * Custom description which is visible for user
     * @type {string}
     * @memberof DeviceSpec
     */
    'description'?: string | null;
    /**
     * Display name which is visible for user
     * @type {string}
     * @memberof DeviceSpec
     */
    'displayName'?: string;
    /**
     * Is active or disabled
     * @type {boolean}
     * @memberof DeviceSpec
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {DeviceSpecExtraParameters}
     * @memberof DeviceSpec
     */
    'extraParameters'?: DeviceSpecExtraParameters;
    /**
     * Time when need to add device / channel / stream
     * @type {string}
     * @memberof DeviceSpec
     */
    'needProcessAddingAfterTime'?: string | null;
    /**
     * Time when need to referesh capabilities
     * @type {string}
     * @memberof DeviceSpec
     */
    'needProcessCapabilitiesAfterTime'?: string | null;
    /**
     * Time when need to referesh settings
     * @type {string}
     * @memberof DeviceSpec
     */
    'needProcessSettingsAfterTime'?: string | null;
    /**
     * Enable or disable passive mode (not apply any settings)
     * @type {boolean}
     * @memberof DeviceSpec
     */
    'passiveMode'?: boolean;
}
/**
 * Gets or sets cnnection details
 * @export
 * @interface DeviceSpecConnectionDetails
 */
export interface DeviceSpecConnectionDetails {
    /**
     * Network address to access (host IP address or DNS name)
     * @type {string}
     * @memberof DeviceSpecConnectionDetails
     */
    'address'?: string;
    /**
     * Driver name which should be used to communicate with device
     * @type {string}
     * @memberof DeviceSpecConnectionDetails
     */
    'driverName'?: string;
    /**
     * Network port to access
     * @type {number}
     * @memberof DeviceSpecConnectionDetails
     */
    'port'?: number;
    /**
     * Protocol which is used to access
     * @type {string}
     * @memberof DeviceSpecConnectionDetails
     */
    'protocol'?: DeviceSpecConnectionDetailsProtocolEnum;
    /**
     * Reference to the secret (user name and password)) in API server
     * @type {string}
     * @memberof DeviceSpecConnectionDetails
     */
    'secretRef'?: string;
    /**
     * Streaming port, can be RTSP or custom port
     * @type {number}
     * @memberof DeviceSpecConnectionDetails
     */
    'streamingPort'?: number;
}

export const DeviceSpecConnectionDetailsProtocolEnum = {
    Http: 'HTTP',
    Https: 'HTTPS'
} as const;

export type DeviceSpecConnectionDetailsProtocolEnum = typeof DeviceSpecConnectionDetailsProtocolEnum[keyof typeof DeviceSpecConnectionDetailsProtocolEnum];

/**
 * Additional parameters in raw JSON format which are specific for manufacturer or model
 * @export
 * @interface DeviceSpecExtraParameters
 */
export interface DeviceSpecExtraParameters {
    /**
     * API version of raw extension
     * @type {string}
     * @memberof DeviceSpecExtraParameters
     */
    'apiVersion': string;
    /**
     * Kind of raw extension
     * @type {string}
     * @memberof DeviceSpecExtraParameters
     */
    'kind': string;
    /**
     * 
     * @type {DeviceSpecExtraParametersSpec}
     * @memberof DeviceSpecExtraParameters
     */
    'spec': DeviceSpecExtraParametersSpec;
}
/**
 * User-defined object
 * @export
 * @interface DeviceSpecExtraParametersSpec
 */
export interface DeviceSpecExtraParametersSpec {
    /**
     * JSON content of extra parameters
     * @type {string}
     * @memberof DeviceSpecExtraParametersSpec
     */
    'content'?: string;
}
/**
 * Status object for the entity.
 * @export
 * @interface DeviceStatus
 */
export interface DeviceStatus {
    /**
     * 
     * @type {DeviceStatusCapabilities}
     * @memberof DeviceStatus
     */
    'capabilities'?: DeviceStatusCapabilities;
    /**
     * Recorder conditions
     * @type {Array<AlarmConfigurationStatusConditions>}
     * @memberof DeviceStatus
     */
    'conditions'?: Array<AlarmConfigurationStatusConditions>;
    /**
     * Device number in recorder (for compatibility with 3rd party recorders) Will be skipped for V10 recorder
     * @type {number}
     * @memberof DeviceStatus
     */
    'deviceNumber'?: number | null;
    /**
     * 
     * @type {DeviceStatusInfo}
     * @memberof DeviceStatus
     */
    'info'?: DeviceStatusInfo;
    /**
     * Last time when device / chanenl / stream was added
     * @type {string}
     * @memberof DeviceStatus
     */
    'lastAddingTime'?: string | null;
    /**
     * Last time when capabilities were refreshed
     * @type {string}
     * @memberof DeviceStatus
     */
    'lastCapabilitiesRefreshTime'?: string | null;
    /**
     * Last time when settings were refreshed
     * @type {string}
     * @memberof DeviceStatus
     */
    'lastSettingsApplyTime'?: string | null;
    /**
     * Recorder identifier
     * @type {string}
     * @memberof DeviceStatus
     */
    'recorderRef'?: string;
}
/**
 * Device capabilities
 * @export
 * @interface DeviceStatusCapabilities
 */
export interface DeviceStatusCapabilities {
    /**
     * 
     * @type {DeviceStatusCapabilitiesChannelCapacity}
     * @memberof DeviceStatusCapabilities
     */
    'channelCapacity'?: DeviceStatusCapabilitiesChannelCapacity;
    /**
     * 
     * @type {DeviceStatusCapabilitiesDIOCapacity}
     * @memberof DeviceStatusCapabilities
     */
    'dIOCapacity'?: DeviceStatusCapabilitiesDIOCapacity;
    /**
     * Internal parameters in raw JSON format which are specific for manufacturer or model
     * @type {string}
     * @memberof DeviceStatusCapabilities
     */
    'internalStates'?: string;
}
/**
 * Channel capabilities for the recorder
 * @export
 * @interface DeviceStatusCapabilitiesChannelCapacity
 */
export interface DeviceStatusCapabilitiesChannelCapacity {
    /**
     * Max audio channels count
     * @type {number}
     * @memberof DeviceStatusCapabilitiesChannelCapacity
     */
    'maxAudioIn'?: number;
    /**
     * Max audio output channels count
     * @type {number}
     * @memberof DeviceStatusCapabilitiesChannelCapacity
     */
    'maxAudioOut'?: number;
    /**
     * Max video channels count
     * @type {number}
     * @memberof DeviceStatusCapabilitiesChannelCapacity
     */
    'maxVideoChannels'?: number;
}
/**
 * Digital IO capabilities for the device
 * @export
 * @interface DeviceStatusCapabilitiesDIOCapacity
 */
export interface DeviceStatusCapabilitiesDIOCapacity {
    /**
     * Maximum number of supported inputs
     * @type {number}
     * @memberof DeviceStatusCapabilitiesDIOCapacity
     */
    'inputs'?: number;
    /**
     * Maximum number of supported outputs
     * @type {number}
     * @memberof DeviceStatusCapabilitiesDIOCapacity
     */
    'outputs'?: number;
}
/**
 * Information about device
 * @export
 * @interface DeviceStatusInfo
 */
export interface DeviceStatusInfo {
    /**
     * Additional information which are specific for manufacturer or model
     * @type {Array<DeviceStatusInfoExtras>}
     * @memberof DeviceStatusInfo
     */
    'extras'?: Array<DeviceStatusInfoExtras>;
    /**
     * Recorder manufacturer
     * @type {string}
     * @memberof DeviceStatusInfo
     */
    'manufacturer'?: string;
    /**
     * Recorder model
     * @type {string}
     * @memberof DeviceStatusInfo
     */
    'model'?: string;
    /**
     * Firmware version for 3rd party recorders and software version for Mirasys recorders
     * @type {string}
     * @memberof DeviceStatusInfo
     */
    'softwareVersion'?: string;
}
/**
 * 
 * @export
 * @interface DeviceStatusInfoExtras
 */
export interface DeviceStatusInfoExtras {
    /**
     * Name which will be displayd for user (for example, Vendor Part)
     * @type {string}
     * @memberof DeviceStatusInfoExtras
     */
    'displayName'?: string;
    /**
     * Identifier of the extra information parameter (for example, dahua.mirasys.com/vendorPart)
     * @type {string}
     * @memberof DeviceStatusInfoExtras
     */
    'id'?: string;
    /**
     * Value of the extra information parameter (for example, 1.0.2)
     * @type {string}
     * @memberof DeviceStatusInfoExtras
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Recorder
 */
export interface Recorder {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof Recorder
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof Recorder
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationMetadata}
     * @memberof Recorder
     */
    'metadata': AlarmConfigurationMetadata;
    /**
     * 
     * @type {RecorderSpec}
     * @memberof Recorder
     */
    'spec': RecorderSpec;
    /**
     * 
     * @type {RecorderStatus}
     * @memberof Recorder
     */
    'status'?: RecorderStatus;
}
/**
 * RecorderList is a list of Recorder
 * @export
 * @interface RecorderList
 */
export interface RecorderList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof RecorderList
     */
    'apiVersion': string;
    /**
     * List of recorders. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<Recorder>}
     * @memberof RecorderList
     */
    'items': Array<Recorder>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof RecorderList
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationListMetadata}
     * @memberof RecorderList
     */
    'metadata': AlarmConfigurationListMetadata;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface RecorderSpec
 */
export interface RecorderSpec {
    /**
     * 
     * @type {RecorderSpecConnectionDetails}
     * @memberof RecorderSpec
     */
    'connectionDetails'?: RecorderSpecConnectionDetails;
    /**
     * Custom description which is visible for user
     * @type {string}
     * @memberof RecorderSpec
     */
    'description'?: string | null;
    /**
     * Display name which is visible for user
     * @type {string}
     * @memberof RecorderSpec
     */
    'displayName'?: string;
    /**
     * Is active or disabled
     * @type {boolean}
     * @memberof RecorderSpec
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {DeviceSpecExtraParameters}
     * @memberof RecorderSpec
     */
    'extraParameters'?: DeviceSpecExtraParameters;
    /**
     * Gatekeeper fimware version
     * @type {string}
     * @memberof RecorderSpec
     */
    'firmwareVersion'?: string;
    /**
     * Gatekeeper identifier, immutable
     * @type {string}
     * @memberof RecorderSpec
     */
    'gatekeeperRef'?: string;
    /**
     * Time when need to add device / channel / stream
     * @type {string}
     * @memberof RecorderSpec
     */
    'needProcessAddingAfterTime'?: string | null;
    /**
     * Time when need to referesh capabilities
     * @type {string}
     * @memberof RecorderSpec
     */
    'needProcessCapabilitiesAfterTime'?: string | null;
    /**
     * Time when need to referesh settings
     * @type {string}
     * @memberof RecorderSpec
     */
    'needProcessSettingsAfterTime'?: string | null;
    /**
     * NTP server address
     * @type {string}
     * @memberof RecorderSpec
     */
    'ntpServer'?: string;
    /**
     * Enable or disable passive mode (not apply any settings)
     * @type {boolean}
     * @memberof RecorderSpec
     */
    'passiveMode'?: boolean;
}
/**
 * Connection details for the gatekeeper to communicate with 3rd party recorder
 * @export
 * @interface RecorderSpecConnectionDetails
 */
export interface RecorderSpecConnectionDetails {
    /**
     * Network address to access (host IP address or DNS name)
     * @type {string}
     * @memberof RecorderSpecConnectionDetails
     */
    'address'?: string;
    /**
     * Driver name which should be used to communicate with device
     * @type {string}
     * @memberof RecorderSpecConnectionDetails
     */
    'driverName'?: string;
    /**
     * Network port to access
     * @type {number}
     * @memberof RecorderSpecConnectionDetails
     */
    'port'?: number;
    /**
     * Protocol which is used to access
     * @type {string}
     * @memberof RecorderSpecConnectionDetails
     */
    'protocol'?: RecorderSpecConnectionDetailsProtocolEnum;
    /**
     * Reference to the secret (user name and password)) in API server
     * @type {string}
     * @memberof RecorderSpecConnectionDetails
     */
    'secretRef'?: string;
    /**
     * Streaming port, can be RTSP or custom port
     * @type {number}
     * @memberof RecorderSpecConnectionDetails
     */
    'streamingPort'?: number;
}

export const RecorderSpecConnectionDetailsProtocolEnum = {
    Http: 'HTTP',
    Https: 'HTTPS'
} as const;

export type RecorderSpecConnectionDetailsProtocolEnum = typeof RecorderSpecConnectionDetailsProtocolEnum[keyof typeof RecorderSpecConnectionDetailsProtocolEnum];

/**
 * Status object for the entity.
 * @export
 * @interface RecorderStatus
 */
export interface RecorderStatus {
    /**
     * 
     * @type {RecorderStatusCapabilities}
     * @memberof RecorderStatus
     */
    'capabilities'?: RecorderStatusCapabilities;
    /**
     * Recorder conditions
     * @type {Array<AlarmConfigurationStatusConditions>}
     * @memberof RecorderStatus
     */
    'conditions'?: Array<AlarmConfigurationStatusConditions>;
    /**
     * 
     * @type {RecorderStatusInfo}
     * @memberof RecorderStatus
     */
    'info'?: RecorderStatusInfo;
    /**
     * Is recorder supported or not
     * @type {boolean}
     * @memberof RecorderStatus
     */
    'isSupported'?: boolean;
    /**
     * Last time when device / chanenl / stream was added
     * @type {string}
     * @memberof RecorderStatus
     */
    'lastAddingTime'?: string | null;
    /**
     * Last time when capabilities were refreshed
     * @type {string}
     * @memberof RecorderStatus
     */
    'lastCapabilitiesRefreshTime'?: string | null;
    /**
     * Last time when settings were refreshed
     * @type {string}
     * @memberof RecorderStatus
     */
    'lastSettingsApplyTime'?: string | null;
}
/**
 * Recorder capabilities
 * @export
 * @interface RecorderStatusCapabilities
 */
export interface RecorderStatusCapabilities {
    /**
     * List of supported alarms for current recorder
     * @type {Array<RecorderStatusCapabilitiesAlarmCapabilities>}
     * @memberof RecorderStatusCapabilities
     */
    'alarmCapabilities'?: Array<RecorderStatusCapabilitiesAlarmCapabilities>;
    /**
     * Can add and remove channels
     * @type {boolean}
     * @memberof RecorderStatusCapabilities
     */
    'canAddRemoveChannels'?: boolean;
    /**
     * Can add and remove devices
     * @type {boolean}
     * @memberof RecorderStatusCapabilities
     */
    'canAddRemoveDevices'?: boolean;
    /**
     * Can add and remove streams
     * @type {boolean}
     * @memberof RecorderStatusCapabilities
     */
    'canAddRemoveStreams'?: boolean;
    /**
     * 
     * @type {RecorderStatusCapabilitiesChannelCapacity}
     * @memberof RecorderStatusCapabilities
     */
    'channelCapacity'?: RecorderStatusCapabilitiesChannelCapacity;
    /**
     * 
     * @type {RecorderStatusCapabilitiesExport}
     * @memberof RecorderStatusCapabilities
     */
    'export'?: RecorderStatusCapabilitiesExport;
    /**
     * Internal parameters in raw JSON format which are specific for manufacturer or model
     * @type {string}
     * @memberof RecorderStatusCapabilities
     */
    'internalStates'?: string;
    /**
     * 
     * @type {RecorderStatusCapabilitiesNetwork}
     * @memberof RecorderStatusCapabilities
     */
    'network'?: RecorderStatusCapabilitiesNetwork;
    /**
     * 
     * @type {RecorderStatusCapabilitiesPlayback}
     * @memberof RecorderStatusCapabilities
     */
    'playback'?: RecorderStatusCapabilitiesPlayback;
    /**
     * List of strings which define supported protocols (Hanwha, Pelco, Bosch, Sony, Dahua, ONVIF, etc.)
     * @type {Array<string>}
     * @memberof RecorderStatusCapabilities
     */
    'protocols'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RecorderStatusCapabilitiesAlarmCapabilities
 */
export interface RecorderStatusCapabilitiesAlarmCapabilities {
    /**
     * Actions list for current alarm. Could be empty for non-configured event
     * @type {Array<RecorderStatusCapabilitiesAlarmCapabilitiesActions>}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilities
     */
    'actions'?: Array<RecorderStatusCapabilitiesAlarmCapabilitiesActions>;
    /**
     * Alarm name (unique alarm type for recorder)
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilities
     */
    'alarmName'?: string;
    /**
     * Could be configured or not this alarm
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilities
     */
    'enable'?: boolean;
    /**
     * Default alarm priority. Could be changed in UI
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilities
     */
    'priority'?: RecorderStatusCapabilitiesAlarmCapabilitiesPriorityEnum;
    /**
     * Trigger configuration parameters list. Could be empty for non-configured alarms
     * @type {Array<RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration>}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilities
     */
    'triggerParameters'?: Array<RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration>;
}

export const RecorderStatusCapabilitiesAlarmCapabilitiesPriorityEnum = {
    VeryLow: 'VeryLow',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    VeryHigh: 'VeryHigh',
    Critical: 'Critical'
} as const;

export type RecorderStatusCapabilitiesAlarmCapabilitiesPriorityEnum = typeof RecorderStatusCapabilitiesAlarmCapabilitiesPriorityEnum[keyof typeof RecorderStatusCapabilitiesAlarmCapabilitiesPriorityEnum];

/**
 * 
 * @export
 * @interface RecorderStatusCapabilitiesAlarmCapabilitiesActions
 */
export interface RecorderStatusCapabilitiesAlarmCapabilitiesActions {
    /**
     * Configuration parameters list for current alarm action. Could be empty for non-configured action
     * @type {Array<RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration>}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActions
     */
    'configuration'?: Array<RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration>;
    /**
     * Could be configured this alarm action or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActions
     */
    'enable'?: boolean;
    /**
     * Alarm action name
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActions
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration
 */
export interface RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration {
    /**
     * Parameter name
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration
     */
    'name': string;
    /**
     * Parameter value type
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration
     */
    'type'?: RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeEnum;
    /**
     * List of tuples with attribute type and value for current alarm parameter
     * @type {Array<RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributes>}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfiguration
     */
    'typeAttributes'?: Array<RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributes>;
}

export const RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeEnum = {
    String: 'String',
    Enum: 'Enum',
    Bool: 'Bool',
    Int: 'Int',
    Float: 'Float',
    Range: 'Range'
} as const;

export type RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeEnum = typeof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeEnum[keyof typeof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeEnum];

/**
 * 
 * @export
 * @interface RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributes
 */
export interface RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributes {
    /**
     * 
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributes
     */
    'item1'?: RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributesItem1Enum;
    /**
     * 
     * @type {string}
     * @memberof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributes
     */
    'item2'?: string;
}

export const RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributesItem1Enum = {
    Value: 'Value',
    MaxSymbols: 'MaxSymbols',
    Min: 'Min',
    Max: 'Max',
    Dimension: 'Dimension',
    PossibleValuesList: 'PossibleValuesList',
    State: 'State',
    Step: 'Step'
} as const;

export type RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributesItem1Enum = typeof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributesItem1Enum[keyof typeof RecorderStatusCapabilitiesAlarmCapabilitiesActionsConfigurationTypeAttributesItem1Enum];

/**
 * Channel capabilities for the recorder
 * @export
 * @interface RecorderStatusCapabilitiesChannelCapacity
 */
export interface RecorderStatusCapabilitiesChannelCapacity {
    /**
     * 
     * @type {RecorderStatusCapabilitiesChannelCapacityLocal}
     * @memberof RecorderStatusCapabilitiesChannelCapacity
     */
    'local'?: RecorderStatusCapabilitiesChannelCapacityLocal | null;
    /**
     * 
     * @type {RecorderStatusCapabilitiesChannelCapacityRemote}
     * @memberof RecorderStatusCapabilitiesChannelCapacity
     */
    'remote'?: RecorderStatusCapabilitiesChannelCapacityRemote | null;
}
/**
 * Maximum number of supported local channels (fixed, can\'t be modified)
 * @export
 * @interface RecorderStatusCapabilitiesChannelCapacityLocal
 */
export interface RecorderStatusCapabilitiesChannelCapacityLocal {
    /**
     * Max audio channels count
     * @type {number}
     * @memberof RecorderStatusCapabilitiesChannelCapacityLocal
     */
    'maxAudioIn'?: number;
    /**
     * Max audio output channels count
     * @type {number}
     * @memberof RecorderStatusCapabilitiesChannelCapacityLocal
     */
    'maxAudioOut'?: number;
    /**
     * Max video channels count
     * @type {number}
     * @memberof RecorderStatusCapabilitiesChannelCapacityLocal
     */
    'maxVideoChannels'?: number;
}
/**
 * Maximum number of supported remote channels
 * @export
 * @interface RecorderStatusCapabilitiesChannelCapacityRemote
 */
export interface RecorderStatusCapabilitiesChannelCapacityRemote {
    /**
     * Max audio channels count
     * @type {number}
     * @memberof RecorderStatusCapabilitiesChannelCapacityRemote
     */
    'maxAudioIn'?: number;
    /**
     * Max audio output channels count
     * @type {number}
     * @memberof RecorderStatusCapabilitiesChannelCapacityRemote
     */
    'maxAudioOut'?: number;
    /**
     * Max video channels count
     * @type {number}
     * @memberof RecorderStatusCapabilitiesChannelCapacityRemote
     */
    'maxVideoChannels'?: number;
}
/**
 * Export capabilities for the recorder
 * @export
 * @interface RecorderStatusCapabilitiesExport
 */
export interface RecorderStatusCapabilitiesExport {
    /**
     * List of supported formats for downloading file by time interval
     * @type {string}
     * @memberof RecorderStatusCapabilitiesExport
     */
    'downloadFileInterval'?: RecorderStatusCapabilitiesExportDownloadFileIntervalEnum;
    /**
     * Is RTSP without delay supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesExport
     */
    'rtspWithoutDelay'?: boolean;
}

export const RecorderStatusCapabilitiesExportDownloadFileIntervalEnum = {
    Mp4: 'MP4',
    Asf: 'ASF',
    Raw: 'Raw'
} as const;

export type RecorderStatusCapabilitiesExportDownloadFileIntervalEnum = typeof RecorderStatusCapabilitiesExportDownloadFileIntervalEnum[keyof typeof RecorderStatusCapabilitiesExportDownloadFileIntervalEnum];

/**
 * Network capabilities for the recorder
 * @export
 * @interface RecorderStatusCapabilitiesNetwork
 */
export interface RecorderStatusCapabilitiesNetwork {
    /**
     * Is RTP protocol supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesNetwork
     */
    'isRTPSupported'?: boolean;
    /**
     * Is RTSP protocol supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesNetwork
     */
    'isRTSPSupported'?: boolean;
    /**
     * RTSP port of recorder
     * @type {number}
     * @memberof RecorderStatusCapabilitiesNetwork
     */
    'portRTSP'?: number;
}
/**
 * Playback capabilities for the recorder
 * @export
 * @interface RecorderStatusCapabilitiesPlayback
 */
export interface RecorderStatusCapabilitiesPlayback {
    /**
     * Is backward supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesPlayback
     */
    'backward'?: boolean;
    /**
     * Is pause supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesPlayback
     */
    'pause'?: boolean;
    /**
     * Is step frame backward supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesPlayback
     */
    'stepFrameBackward'?: boolean;
    /**
     * Is step frame forward supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesPlayback
     */
    'stepFrameForward'?: boolean;
    /**
     * Is playback supported or not
     * @type {boolean}
     * @memberof RecorderStatusCapabilitiesPlayback
     */
    'supported'?: boolean;
}
/**
 * Information about recorder
 * @export
 * @interface RecorderStatusInfo
 */
export interface RecorderStatusInfo {
    /**
     * Additional information which are specific for manufacturer or model
     * @type {Array<DeviceStatusInfoExtras>}
     * @memberof RecorderStatusInfo
     */
    'extras'?: Array<DeviceStatusInfoExtras>;
    /**
     * Recorder manufacturer
     * @type {string}
     * @memberof RecorderStatusInfo
     */
    'manufacturer'?: string;
    /**
     * Recorder model
     * @type {string}
     * @memberof RecorderStatusInfo
     */
    'model'?: string;
    /**
     * Firmware version for 3rd party recorders and software version for Mirasys recorders
     * @type {string}
     * @memberof RecorderStatusInfo
     */
    'softwareVersion'?: string;
}
/**
 * 
 * @export
 * @interface VideoChannel
 */
export interface VideoChannel {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof VideoChannel
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof VideoChannel
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationMetadata}
     * @memberof VideoChannel
     */
    'metadata': AlarmConfigurationMetadata;
    /**
     * 
     * @type {VideoChannelSpec}
     * @memberof VideoChannel
     */
    'spec': VideoChannelSpec;
    /**
     * 
     * @type {VideoChannelStatus}
     * @memberof VideoChannel
     */
    'status'?: VideoChannelStatus;
}
/**
 * VideoChannelList is a list of VideoChannel
 * @export
 * @interface VideoChannelList
 */
export interface VideoChannelList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof VideoChannelList
     */
    'apiVersion': string;
    /**
     * List of videochannels. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<VideoChannel>}
     * @memberof VideoChannelList
     */
    'items': Array<VideoChannel>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof VideoChannelList
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationListMetadata}
     * @memberof VideoChannelList
     */
    'metadata': AlarmConfigurationListMetadata;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface VideoChannelSpec
 */
export interface VideoChannelSpec {
    /**
     * Custom description which is visible for user
     * @type {string}
     * @memberof VideoChannelSpec
     */
    'description'?: string | null;
    /**
     * Is dewarping enabled for the video channel
     * @type {boolean}
     * @memberof VideoChannelSpec
     */
    'dewarpingEnabled'?: boolean;
    /**
     * Display name which is visible for user
     * @type {string}
     * @memberof VideoChannelSpec
     */
    'displayName'?: string;
    /**
     * Is edge storage enabled for the channel (used for all streams with enabled recording)
     * @type {boolean}
     * @memberof VideoChannelSpec
     */
    'edgeStorageEnabled'?: boolean;
    /**
     * Is active or disabled
     * @type {boolean}
     * @memberof VideoChannelSpec
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {DeviceSpecExtraParameters}
     * @memberof VideoChannelSpec
     */
    'extraParameters'?: DeviceSpecExtraParameters;
    /**
     * Time when need to add device / channel / stream
     * @type {string}
     * @memberof VideoChannelSpec
     */
    'needProcessAddingAfterTime'?: string | null;
    /**
     * Time when need to referesh capabilities
     * @type {string}
     * @memberof VideoChannelSpec
     */
    'needProcessCapabilitiesAfterTime'?: string | null;
    /**
     * Time when need to referesh settings
     * @type {string}
     * @memberof VideoChannelSpec
     */
    'needProcessSettingsAfterTime'?: string | null;
    /**
     * Enable or disable passive mode (not apply any settings)
     * @type {boolean}
     * @memberof VideoChannelSpec
     */
    'passiveMode'?: boolean;
}
/**
 * Status object for the entity.
 * @export
 * @interface VideoChannelStatus
 */
export interface VideoChannelStatus {
    /**
     * 
     * @type {VideoChannelStatusCapabilities}
     * @memberof VideoChannelStatus
     */
    'capabilities'?: VideoChannelStatusCapabilities;
    /**
     * Channel number in recorder (for compatibility with 3rd party recorders) Will be skipped for V10 recorder
     * @type {number}
     * @memberof VideoChannelStatus
     */
    'channelNumber'?: number | null;
    /**
     * Recorder conditions
     * @type {Array<AlarmConfigurationStatusConditions>}
     * @memberof VideoChannelStatus
     */
    'conditions'?: Array<AlarmConfigurationStatusConditions>;
    /**
     * Device identifier
     * @type {string}
     * @memberof VideoChannelStatus
     */
    'deviceRef'?: string;
    /**
     * Last time when device / chanenl / stream was added
     * @type {string}
     * @memberof VideoChannelStatus
     */
    'lastAddingTime'?: string | null;
    /**
     * Last time when capabilities were refreshed
     * @type {string}
     * @memberof VideoChannelStatus
     */
    'lastCapabilitiesRefreshTime'?: string | null;
    /**
     * Last time when settings were refreshed
     * @type {string}
     * @memberof VideoChannelStatus
     */
    'lastSettingsApplyTime'?: string | null;
    /**
     * Recorder identifier
     * @type {string}
     * @memberof VideoChannelStatus
     */
    'recorderRef'?: string;
}
/**
 * Video channel capabilities
 * @export
 * @interface VideoChannelStatusCapabilities
 */
export interface VideoChannelStatusCapabilities {
    /**
     * Internal parameters in raw JSON format which are specific for manufacturer or model
     * @type {string}
     * @memberof VideoChannelStatusCapabilities
     */
    'internalStates'?: string;
    /**
     * Is dewarping supported or not
     * @type {boolean}
     * @memberof VideoChannelStatusCapabilities
     */
    'isDewarpingSupported'?: boolean;
    /**
     * Is edge storage supported or not
     * @type {boolean}
     * @memberof VideoChannelStatusCapabilities
     */
    'isEdgeStorageSupported'?: boolean;
    /**
     * 
     * @type {VideoChannelStatusCapabilitiesStreams}
     * @memberof VideoChannelStatusCapabilities
     */
    'streams'?: VideoChannelStatusCapabilitiesStreams;
}
/**
 * Capabilities for streams
 * @export
 * @interface VideoChannelStatusCapabilitiesStreams
 */
export interface VideoChannelStatusCapabilitiesStreams {
    /**
     * Maximum number of supported video streams (-1 - unlimited)
     * @type {number}
     * @memberof VideoChannelStatusCapabilitiesStreams
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface VideoStream
 */
export interface VideoStream {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof VideoStream
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof VideoStream
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationMetadata}
     * @memberof VideoStream
     */
    'metadata': AlarmConfigurationMetadata;
    /**
     * 
     * @type {VideoStreamSpec}
     * @memberof VideoStream
     */
    'spec': VideoStreamSpec;
    /**
     * 
     * @type {VideoStreamStatus}
     * @memberof VideoStream
     */
    'status'?: VideoStreamStatus;
}
/**
 * VideoStreamList is a list of VideoStream
 * @export
 * @interface VideoStreamList
 */
export interface VideoStreamList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof VideoStreamList
     */
    'apiVersion': string;
    /**
     * List of videostreams. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<VideoStream>}
     * @memberof VideoStreamList
     */
    'items': Array<VideoStream>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof VideoStreamList
     */
    'kind': string;
    /**
     * 
     * @type {AlarmConfigurationListMetadata}
     * @memberof VideoStreamList
     */
    'metadata': AlarmConfigurationListMetadata;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface VideoStreamSpec
 */
export interface VideoStreamSpec {
    /**
     * Bitrate value for video stream
     * @type {number}
     * @memberof VideoStreamSpec
     */
    'bitrate'?: number;
    /**
     * Bitrate mode for video stream
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'bitrateMode'?: VideoStreamSpecBitrateModeEnum;
    /**
     * Compression format of the video stream
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'compression'?: VideoStreamSpecCompressionEnum;
    /**
     * Custom description which is visible for user
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'description'?: string | null;
    /**
     * Display name which is visible for user
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'displayName'?: string;
    /**
     * Is active or disabled
     * @type {boolean}
     * @memberof VideoStreamSpec
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {DeviceSpecExtraParameters}
     * @memberof VideoStreamSpec
     */
    'extraParameters'?: DeviceSpecExtraParameters;
    /**
     * Frame rate of the video stream, 0 to disable
     * @type {number}
     * @memberof VideoStreamSpec
     */
    'framerate'?: number;
    /**
     * Should use slow or normal framerate
     * @type {boolean}
     * @memberof VideoStreamSpec
     */
    'isSlowFramerateUsed'?: boolean;
    /**
     * Time when need to add device / channel / stream
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'needProcessAddingAfterTime'?: string | null;
    /**
     * Time when need to referesh capabilities
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'needProcessCapabilitiesAfterTime'?: string | null;
    /**
     * Time when need to referesh settings
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'needProcessSettingsAfterTime'?: string | null;
    /**
     * Enable or disable passive mode (not apply any settings)
     * @type {boolean}
     * @memberof VideoStreamSpec
     */
    'passiveMode'?: boolean;
    /**
     * Video quality of the video stream
     * @type {number}
     * @memberof VideoStreamSpec
     */
    'quality'?: number;
    /**
     * 
     * @type {VideoStreamSpecResolution}
     * @memberof VideoStreamSpec
     */
    'resolution'?: VideoStreamSpecResolution;
    /**
     * Slow frame rate of the video stream, means number of seconds between frames
     * @type {number}
     * @memberof VideoStreamSpec
     */
    'slowFramerate'?: number;
    /**
     * Transport protocol
     * @type {string}
     * @memberof VideoStreamSpec
     */
    'transport'?: VideoStreamSpecTransportEnum;
    /**
     * Use or not stream for recording
     * @type {boolean}
     * @memberof VideoStreamSpec
     */
    'useForRecording'?: boolean;
}

export const VideoStreamSpecBitrateModeEnum = {
    Cbr: 'CBR',
    Vbr: 'VBR',
    VbrMax: 'VBRMax'
} as const;

export type VideoStreamSpecBitrateModeEnum = typeof VideoStreamSpecBitrateModeEnum[keyof typeof VideoStreamSpecBitrateModeEnum];
export const VideoStreamSpecCompressionEnum = {
    Undefined: 'Undefined',
    Jpeg: 'JPEG',
    Wmv: 'WMV',
    Mpeg4: 'MPEG4',
    H264: 'H264',
    H265: 'H265',
    Mxpeg: 'MXPEG'
} as const;

export type VideoStreamSpecCompressionEnum = typeof VideoStreamSpecCompressionEnum[keyof typeof VideoStreamSpecCompressionEnum];
export const VideoStreamSpecTransportEnum = {
    Automatic: 'Automatic',
    RtPoverUdp: 'RTPoverUDP',
    RtPoverRtsp: 'RTPoverRTSP',
    RtPoverHttp: 'RTPoverHTTP',
    RtPoverHttps: 'RTPoverHTTPS',
    RtPoverMulticast: 'RTPoverMulticast'
} as const;

export type VideoStreamSpecTransportEnum = typeof VideoStreamSpecTransportEnum[keyof typeof VideoStreamSpecTransportEnum];

/**
 * Resolution of the video stream
 * @export
 * @interface VideoStreamSpecResolution
 */
export interface VideoStreamSpecResolution {
    /**
     * Video resolution height
     * @type {number}
     * @memberof VideoStreamSpecResolution
     */
    'height'?: number;
    /**
     * Video resolution width
     * @type {number}
     * @memberof VideoStreamSpecResolution
     */
    'width'?: number;
}
/**
 * Status object for the entity.
 * @export
 * @interface VideoStreamStatus
 */
export interface VideoStreamStatus {
    /**
     * 
     * @type {VideoStreamStatusCapabilities}
     * @memberof VideoStreamStatus
     */
    'capabilities'?: VideoStreamStatusCapabilities;
    /**
     * Channel number in the device (for compatibility with 3rd party recorders) Will be skipped for V10 recorder
     * @type {number}
     * @memberof VideoStreamStatus
     */
    'channelNumber'?: number | null;
    /**
     * Channel identifier
     * @type {string}
     * @memberof VideoStreamStatus
     */
    'channelRef'?: string;
    /**
     * Recorder conditions
     * @type {Array<AlarmConfigurationStatusConditions>}
     * @memberof VideoStreamStatus
     */
    'conditions'?: Array<AlarmConfigurationStatusConditions>;
    /**
     * Device identifier
     * @type {string}
     * @memberof VideoStreamStatus
     */
    'deviceRef'?: string;
    /**
     * Last time when device / chanenl / stream was added
     * @type {string}
     * @memberof VideoStreamStatus
     */
    'lastAddingTime'?: string | null;
    /**
     * Last time when capabilities were refreshed
     * @type {string}
     * @memberof VideoStreamStatus
     */
    'lastCapabilitiesRefreshTime'?: string | null;
    /**
     * Last time when settings were refreshed
     * @type {string}
     * @memberof VideoStreamStatus
     */
    'lastSettingsApplyTime'?: string | null;
    /**
     * Recorder identifier
     * @type {string}
     * @memberof VideoStreamStatus
     */
    'recorderRef'?: string;
    /**
     * Stream number in the channel (for compatibility with 3rd party recorders) Will be skipped for V10 recorder
     * @type {number}
     * @memberof VideoStreamStatus
     */
    'streamNumber'?: number | null;
}
/**
 * Video channel capabilities
 * @export
 * @interface VideoStreamStatusCapabilities
 */
export interface VideoStreamStatusCapabilities {
    /**
     * List of compression formats which are supported for the video stream
     * @type {Array<VideoStreamStatusCapabilitiesCompressions>}
     * @memberof VideoStreamStatusCapabilities
     */
    'compressions'?: Array<VideoStreamStatusCapabilitiesCompressions>;
    /**
     * Internal parameters in raw JSON format which are specific for manufacturer or model
     * @type {string}
     * @memberof VideoStreamStatusCapabilities
     */
    'internalStates'?: string;
    /**
     * Can enable recording for this stream
     * @type {boolean}
     * @memberof VideoStreamStatusCapabilities
     */
    'isRecordingSupported'?: boolean;
    /**
     * Can enable slow framerate for this stream
     * @type {boolean}
     * @memberof VideoStreamStatusCapabilities
     */
    'isSlowFramerateSupported'?: boolean;
    /**
     * Transport protocol
     * @type {Array<string>}
     * @memberof VideoStreamStatusCapabilities
     */
    'transports'?: Array<VideoStreamStatusCapabilitiesTransportsEnum>;
}

export const VideoStreamStatusCapabilitiesTransportsEnum = {
    Automatic: 'Automatic',
    RtPoverUdp: 'RTPoverUDP',
    RtPoverRtsp: 'RTPoverRTSP',
    RtPoverHttp: 'RTPoverHTTP',
    RtPoverHttps: 'RTPoverHTTPS',
    RtPoverMulticast: 'RTPoverMulticast'
} as const;

export type VideoStreamStatusCapabilitiesTransportsEnum = typeof VideoStreamStatusCapabilitiesTransportsEnum[keyof typeof VideoStreamStatusCapabilitiesTransportsEnum];

/**
 * 
 * @export
 * @interface VideoStreamStatusCapabilitiesCompressions
 */
export interface VideoStreamStatusCapabilitiesCompressions {
    /**
     * List of supported bitrate modes for compression format
     * @type {Array<string>}
     * @memberof VideoStreamStatusCapabilitiesCompressions
     */
    'bitrateModes'?: Array<VideoStreamStatusCapabilitiesCompressionsBitrateModesEnum>;
    /**
     * Supported bitrates for compression format
     * @type {Array<VideoStreamStatusCapabilitiesCompressionsBitrates>}
     * @memberof VideoStreamStatusCapabilitiesCompressions
     */
    'bitrates'?: Array<VideoStreamStatusCapabilitiesCompressionsBitrates>;
    /**
     * Video compression format
     * @type {string}
     * @memberof VideoStreamStatusCapabilitiesCompressions
     */
    'compression'?: VideoStreamStatusCapabilitiesCompressionsCompressionEnum;
    /**
     * Supported framerates for compression format, should be serializable
     * @type {Array<VideoStreamStatusCapabilitiesCompressionsBitrates>}
     * @memberof VideoStreamStatusCapabilitiesCompressions
     */
    'framerates'?: Array<VideoStreamStatusCapabilitiesCompressionsBitrates>;
    /**
     * List of supported resolutions for compression format
     * @type {Array<VideoStreamStatusCapabilitiesCompressionsResolutions>}
     * @memberof VideoStreamStatusCapabilitiesCompressions
     */
    'resolutions'?: Array<VideoStreamStatusCapabilitiesCompressionsResolutions>;
    /**
     * Supported framerates for compression format, should be serializable
     * @type {Array<VideoStreamStatusCapabilitiesCompressionsBitrates>}
     * @memberof VideoStreamStatusCapabilitiesCompressions
     */
    'slowFramerates'?: Array<VideoStreamStatusCapabilitiesCompressionsBitrates>;
}

export const VideoStreamStatusCapabilitiesCompressionsBitrateModesEnum = {
    Cbr: 'CBR',
    Vbr: 'VBR',
    VbrMax: 'VBRMax'
} as const;

export type VideoStreamStatusCapabilitiesCompressionsBitrateModesEnum = typeof VideoStreamStatusCapabilitiesCompressionsBitrateModesEnum[keyof typeof VideoStreamStatusCapabilitiesCompressionsBitrateModesEnum];
export const VideoStreamStatusCapabilitiesCompressionsCompressionEnum = {
    Undefined: 'Undefined',
    Jpeg: 'JPEG',
    Wmv: 'WMV',
    Mpeg4: 'MPEG4',
    H264: 'H264',
    H265: 'H265',
    Mxpeg: 'MXPEG'
} as const;

export type VideoStreamStatusCapabilitiesCompressionsCompressionEnum = typeof VideoStreamStatusCapabilitiesCompressionsCompressionEnum[keyof typeof VideoStreamStatusCapabilitiesCompressionsCompressionEnum];

/**
 * 
 * @export
 * @interface VideoStreamStatusCapabilitiesCompressionsBitrates
 */
export interface VideoStreamStatusCapabilitiesCompressionsBitrates {
    /**
     * Maximum framerate value
     * @type {number}
     * @memberof VideoStreamStatusCapabilitiesCompressionsBitrates
     */
    'maximum'?: number;
    /**
     * Minimum framerate value
     * @type {number}
     * @memberof VideoStreamStatusCapabilitiesCompressionsBitrates
     */
    'minimum'?: number;
    /**
     * List of supported values
     * @type {Array<number>}
     * @memberof VideoStreamStatusCapabilitiesCompressionsBitrates
     */
    'values'?: Array<number>;
}
/**
 * 
 * @export
 * @interface VideoStreamStatusCapabilitiesCompressionsResolutions
 */
export interface VideoStreamStatusCapabilitiesCompressionsResolutions {
    /**
     * Video resolution height
     * @type {number}
     * @memberof VideoStreamStatusCapabilitiesCompressionsResolutions
     */
    'height'?: number;
    /**
     * Video resolution width
     * @type {number}
     * @memberof VideoStreamStatusCapabilitiesCompressionsResolutions
     */
    'width'?: number;
}
/**
 * DeleteOptions may be provided when deleting an API object.
 * @export
 * @interface MetaV1DeleteOptions
 */
export interface MetaV1DeleteOptions {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof MetaV1DeleteOptions
     */
    'apiVersion': string;
    /**
     * When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @type {Array<string>}
     * @memberof MetaV1DeleteOptions
     */
    'dryRun'?: Array<string>;
    /**
     * The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @type {number}
     * @memberof MetaV1DeleteOptions
     */
    'gracePeriodSeconds'?: number;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1DeleteOptions
     */
    'kind': string;
    /**
     * Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \"orphan\" finalizer will be added to/removed from the object\'s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @type {boolean}
     * @memberof MetaV1DeleteOptions
     */
    'orphanDependents'?: boolean;
    /**
     * 
     * @type {MetaV1DeleteOptionsPreconditions}
     * @memberof MetaV1DeleteOptions
     */
    'preconditions'?: MetaV1DeleteOptionsPreconditions;
    /**
     * Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \'Orphan\' - orphan the dependents; \'Background\' - allow the garbage collector to delete the dependents in the background; \'Foreground\' - a cascading policy that deletes all dependents in the foreground.
     * @type {string}
     * @memberof MetaV1DeleteOptions
     */
    'propagationPolicy'?: string;
}
/**
 * Must be fulfilled before a deletion is carried out. If not possible, a 409 Conflict status will be returned.
 * @export
 * @interface MetaV1DeleteOptionsPreconditions
 */
export interface MetaV1DeleteOptionsPreconditions {
    /**
     * Specifies the target ResourceVersion
     * @type {string}
     * @memberof MetaV1DeleteOptionsPreconditions
     */
    'resourceVersion'?: string;
    /**
     * Specifies the target UID.
     * @type {string}
     * @memberof MetaV1DeleteOptionsPreconditions
     */
    'uid'?: string;
}
/**
 * ListMeta describes metadata that synthetic resources must have, including lists and various status objects. A resource may have only one of {ObjectMeta, ListMeta}.
 * @export
 * @interface MetaV1ListMeta
 */
export interface MetaV1ListMeta {
    /**
     * continue may be set if the user set a limit on the number of items returned, and indicates that the server has more data available. The value is opaque and may be used to issue another request to the endpoint that served this list to retrieve the next set of available objects. Continuing a consistent list may not be possible if the server configuration has changed or more than a few minutes have passed. The resourceVersion field returned when using this continue value will be identical to the value in the first response, unless you have received this token from an error message.
     * @type {string}
     * @memberof MetaV1ListMeta
     */
    'continue'?: string;
    /**
     * remainingItemCount is the number of subsequent items in the list which are not included in this list response. If the list request contained label or field selectors, then the number of remaining items is unknown and the field will be left unset and omitted during serialization. If the list is complete (either because it is not chunking or because this is the last chunk), then there are no more remaining items and this field will be left unset and omitted during serialization. Servers older than v1.15 do not set this field. The intended use of the remainingItemCount is *estimating* the size of a collection. Clients should not rely on the remainingItemCount to be set or to be exact.
     * @type {number}
     * @memberof MetaV1ListMeta
     */
    'remainingItemCount'?: number;
    /**
     * String that identifies the server\'s internal version of this object that can be used by clients to determine when objects have changed. Value must be treated as opaque by clients and passed unmodified back to the server. Populated by the system. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof MetaV1ListMeta
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof MetaV1ListMeta
     */
    'selfLink'?: string;
}
/**
 * ManagedFieldsEntry is a workflow-id, a FieldSet and the group version of the resource that the fieldset applies to.
 * @export
 * @interface MetaV1ManagedFieldsEntry
 */
export interface MetaV1ManagedFieldsEntry {
    /**
     * APIVersion defines the version of this resource that this field set applies to. The format is \"group/version\" just like the top-level APIVersion field. It is necessary to track the version of a field set because it cannot be automatically converted.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'apiVersion': string;
    /**
     * FieldsType is the discriminator for the different fields format and version. There is currently only one possible value: \"FieldsV1\"
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'fieldsType'?: string;
    /**
     * FieldsV1 stores a set of fields in a data structure like a Trie, in JSON format.  Each key is either a \'.\' representing the field itself, and will always map to an empty set, or a string representing a sub-field or item. The string will follow one of these four formats: \'f:<name>\', where <name> is the name of a field in a struct, or key in a map \'v:<value>\', where <value> is the exact json formatted value of a list item \'i:<index>\', where <index> is position of a item in a list \'k:<keys>\', where <keys> is a map of  a list item\'s key fields to their unique values If a key maps to an empty Fields value, the field that key represents is part of the set.  The exact format is defined in sigs.k8s.io/structured-merge-diff
     * @type {object}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'fieldsV1'?: object;
    /**
     * Manager is an identifier of the workflow managing these fields.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'manager'?: string;
    /**
     * Operation is the type of operation which lead to this ManagedFieldsEntry being created. The only valid values for this field are \'Apply\' and \'Update\'.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'operation'?: string;
    /**
     * Subresource is the name of the subresource used to update that object, or empty string if the object was updated through the main resource. The value of this field is used to distinguish between managers, even if they share the same name. For example, a status update will be distinct from a regular update using the same manager name. Note that the APIVersion field is not related to the Subresource field and it always corresponds to the version of the main resource.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'subresource'?: string;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'time'?: string;
}
/**
 * ObjectMeta is metadata that all persisted resources must have, which includes all objects users must create.
 * @export
 * @interface MetaV1ObjectMeta
 */
export interface MetaV1ObjectMeta {
    /**
     * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations
     * @type {{ [key: string]: string; }}
     * @memberof MetaV1ObjectMeta
     */
    'annotations'?: { [key: string]: string; };
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'creationTimestamp'?: string;
    /**
     * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only.
     * @type {number}
     * @memberof MetaV1ObjectMeta
     */
    'deletionGracePeriodSeconds'?: number;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'deletionTimestamp'?: string;
    /**
     * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list.
     * @type {Array<string>}
     * @memberof MetaV1ObjectMeta
     */
    'finalizers'?: Array<string>;
    /**
     * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will return a 409.  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'generateName'?: string;
    /**
     * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only.
     * @type {number}
     * @memberof MetaV1ObjectMeta
     */
    'generation'?: number;
    /**
     * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/labels
     * @type {{ [key: string]: string; }}
     * @memberof MetaV1ObjectMeta
     */
    'labels'?: { [key: string]: string; };
    /**
     * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.
     * @type {Array<MetaV1ObjectMetaManagedFields>}
     * @memberof MetaV1ObjectMeta
     */
    'managedFields'?: Array<MetaV1ObjectMetaManagedFields>;
    /**
     * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'name': string;
    /**
     * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'namespace': string;
    /**
     * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller.
     * @type {Array<MetaV1ObjectMetaOwnerReferences>}
     * @memberof MetaV1ObjectMeta
     */
    'ownerReferences'?: Array<MetaV1ObjectMetaOwnerReferences>;
    /**
     * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'selfLink'?: string;
    /**
     * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'uid'?: string;
}
/**
 * 
 * @export
 * @interface MetaV1ObjectMetaManagedFields
 */
export interface MetaV1ObjectMetaManagedFields {
    /**
     * APIVersion defines the version of this resource that this field set applies to. The format is \"group/version\" just like the top-level APIVersion field. It is necessary to track the version of a field set because it cannot be automatically converted.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'apiVersion': string;
    /**
     * FieldsType is the discriminator for the different fields format and version. There is currently only one possible value: \"FieldsV1\"
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'fieldsType'?: string;
    /**
     * FieldsV1 stores a set of fields in a data structure like a Trie, in JSON format.  Each key is either a \'.\' representing the field itself, and will always map to an empty set, or a string representing a sub-field or item. The string will follow one of these four formats: \'f:<name>\', where <name> is the name of a field in a struct, or key in a map \'v:<value>\', where <value> is the exact json formatted value of a list item \'i:<index>\', where <index> is position of a item in a list \'k:<keys>\', where <keys> is a map of  a list item\'s key fields to their unique values If a key maps to an empty Fields value, the field that key represents is part of the set.  The exact format is defined in sigs.k8s.io/structured-merge-diff
     * @type {object}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'fieldsV1'?: object;
    /**
     * Manager is an identifier of the workflow managing these fields.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'manager'?: string;
    /**
     * Operation is the type of operation which lead to this ManagedFieldsEntry being created. The only valid values for this field are \'Apply\' and \'Update\'.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'operation'?: string;
    /**
     * Subresource is the name of the subresource used to update that object, or empty string if the object was updated through the main resource. The value of this field is used to distinguish between managers, even if they share the same name. For example, a status update will be distinct from a regular update using the same manager name. Note that the APIVersion field is not related to the Subresource field and it always corresponds to the version of the main resource.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'subresource'?: string;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface MetaV1ObjectMetaOwnerReferences
 */
export interface MetaV1ObjectMetaOwnerReferences {
    /**
     * API version of the referent.
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'apiVersion': string;
    /**
     * If true, AND if the owner has the \"foregroundDeletion\" finalizer, then the owner cannot be deleted from the key-value store until this reference is removed. See https://kubernetes.io/docs/concepts/architecture/garbage-collection/#foreground-deletion for how the garbage collector interacts with this field and enforces the foreground deletion. Defaults to false. To set this field, a user needs \"delete\" permission of the owner, otherwise 422 (Unprocessable Entity) will be returned.
     * @type {boolean}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'blockOwnerDeletion'?: boolean;
    /**
     * If true, this reference points to the managing controller.
     * @type {boolean}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'controller'?: boolean;
    /**
     * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'kind': string;
    /**
     * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'name': string;
    /**
     * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'uid': string;
}
/**
 * OwnerReference contains enough information to let you identify an owning object. An owning object must be in the same namespace as the dependent, or be cluster-scoped, so there is no namespace field.
 * @export
 * @interface MetaV1OwnerReference
 */
export interface MetaV1OwnerReference {
    /**
     * API version of the referent.
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'apiVersion': string;
    /**
     * If true, AND if the owner has the \"foregroundDeletion\" finalizer, then the owner cannot be deleted from the key-value store until this reference is removed. See https://kubernetes.io/docs/concepts/architecture/garbage-collection/#foreground-deletion for how the garbage collector interacts with this field and enforces the foreground deletion. Defaults to false. To set this field, a user needs \"delete\" permission of the owner, otherwise 422 (Unprocessable Entity) will be returned.
     * @type {boolean}
     * @memberof MetaV1OwnerReference
     */
    'blockOwnerDeletion'?: boolean;
    /**
     * If true, this reference points to the managing controller.
     * @type {boolean}
     * @memberof MetaV1OwnerReference
     */
    'controller'?: boolean;
    /**
     * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'kind': string;
    /**
     * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'name': string;
    /**
     * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'uid': string;
}
/**
 * Preconditions must be fulfilled before an operation (update, delete, etc.) is carried out.
 * @export
 * @interface MetaV1Preconditions
 */
export interface MetaV1Preconditions {
    /**
     * Specifies the target ResourceVersion
     * @type {string}
     * @memberof MetaV1Preconditions
     */
    'resourceVersion'?: string;
    /**
     * Specifies the target UID.
     * @type {string}
     * @memberof MetaV1Preconditions
     */
    'uid'?: string;
}
/**
 * Status is a return value for calls that don\'t return other objects.
 * @export
 * @interface MetaV1Status
 */
export interface MetaV1Status {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof MetaV1Status
     */
    'apiVersion': string;
    /**
     * Suggested HTTP return code for this status, 0 if not set.
     * @type {number}
     * @memberof MetaV1Status
     */
    'code'?: number;
    /**
     * 
     * @type {MetaV1StatusDetails}
     * @memberof MetaV1Status
     */
    'details'?: MetaV1StatusDetails;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1Status
     */
    'kind': string;
    /**
     * A human-readable description of the status of this operation.
     * @type {string}
     * @memberof MetaV1Status
     */
    'message'?: string;
    /**
     * 
     * @type {MetaV1StatusMetadata}
     * @memberof MetaV1Status
     */
    'metadata': MetaV1StatusMetadata;
    /**
     * A machine-readable description of why this operation is in the \"Failure\" status. If this value is empty there is no information available. A Reason clarifies an HTTP status code but does not override it.
     * @type {string}
     * @memberof MetaV1Status
     */
    'reason'?: string;
    /**
     * Status of the operation. One of: \"Success\" or \"Failure\". More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#spec-and-status
     * @type {string}
     * @memberof MetaV1Status
     */
    'status'?: string;
}
/**
 * StatusCause provides more information about an api.Status failure, including cases when multiple errors are encountered.
 * @export
 * @interface MetaV1StatusCause
 */
export interface MetaV1StatusCause {
    /**
     * The field of the resource that has caused this error, as named by its JSON serialization. May include dot and postfix notation for nested attributes. Arrays are zero-indexed.  Fields may appear more than once in an array of causes due to fields having multiple errors. Optional.  Examples:   \"name\" - the field \"name\" on the current resource   \"items[0].name\" - the field \"name\" on the first array entry in \"items\"
     * @type {string}
     * @memberof MetaV1StatusCause
     */
    'field'?: string;
    /**
     * A human-readable description of the cause of the error.  This field may be presented as-is to a reader.
     * @type {string}
     * @memberof MetaV1StatusCause
     */
    'message'?: string;
    /**
     * A machine-readable description of the cause of the error. If this value is empty there is no information available.
     * @type {string}
     * @memberof MetaV1StatusCause
     */
    'reason'?: string;
}
/**
 * Extended data associated with the reason.  Each reason may define its own extended details. This field is optional and the data returned is not guaranteed to conform to any schema except that defined by the reason type.
 * @export
 * @interface MetaV1StatusDetails
 */
export interface MetaV1StatusDetails {
    /**
     * The Causes array includes more details associated with the StatusReason failure. Not all StatusReasons may provide detailed causes.
     * @type {Array<MetaV1StatusDetailsCauses>}
     * @memberof MetaV1StatusDetails
     */
    'causes'?: Array<MetaV1StatusDetailsCauses>;
    /**
     * The group attribute of the resource associated with the status StatusReason.
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'group'?: string;
    /**
     * The kind attribute of the resource associated with the status StatusReason. On some operations may differ from the requested resource Kind. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'kind': string;
    /**
     * The name attribute of the resource associated with the status StatusReason (when there is a single name which can be described).
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'name': string;
    /**
     * If specified, the time in seconds before the operation should be retried. Some errors may indicate the client must take an alternate action - for those errors this field may indicate how long to wait before taking the alternate action.
     * @type {number}
     * @memberof MetaV1StatusDetails
     */
    'retryAfterSeconds'?: number;
    /**
     * UID of the resource. (when there is a single resource which can be described). More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'uid'?: string;
}
/**
 * 
 * @export
 * @interface MetaV1StatusDetailsCauses
 */
export interface MetaV1StatusDetailsCauses {
    /**
     * The field of the resource that has caused this error, as named by its JSON serialization. May include dot and postfix notation for nested attributes. Arrays are zero-indexed.  Fields may appear more than once in an array of causes due to fields having multiple errors. Optional.  Examples:   \"name\" - the field \"name\" on the current resource   \"items[0].name\" - the field \"name\" on the first array entry in \"items\"
     * @type {string}
     * @memberof MetaV1StatusDetailsCauses
     */
    'field'?: string;
    /**
     * A human-readable description of the cause of the error.  This field may be presented as-is to a reader.
     * @type {string}
     * @memberof MetaV1StatusDetailsCauses
     */
    'message'?: string;
    /**
     * A machine-readable description of the cause of the error. If this value is empty there is no information available.
     * @type {string}
     * @memberof MetaV1StatusDetailsCauses
     */
    'reason'?: string;
}
/**
 * Standard list metadata. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
 * @export
 * @interface MetaV1StatusMetadata
 */
export interface MetaV1StatusMetadata {
    /**
     * continue may be set if the user set a limit on the number of items returned, and indicates that the server has more data available. The value is opaque and may be used to issue another request to the endpoint that served this list to retrieve the next set of available objects. Continuing a consistent list may not be possible if the server configuration has changed or more than a few minutes have passed. The resourceVersion field returned when using this continue value will be identical to the value in the first response, unless you have received this token from an error message.
     * @type {string}
     * @memberof MetaV1StatusMetadata
     */
    'continue'?: string;
    /**
     * remainingItemCount is the number of subsequent items in the list which are not included in this list response. If the list request contained label or field selectors, then the number of remaining items is unknown and the field will be left unset and omitted during serialization. If the list is complete (either because it is not chunking or because this is the last chunk), then there are no more remaining items and this field will be left unset and omitted during serialization. Servers older than v1.15 do not set this field. The intended use of the remainingItemCount is *estimating* the size of a collection. Clients should not rely on the remainingItemCount to be set or to be exact.
     * @type {number}
     * @memberof MetaV1StatusMetadata
     */
    'remainingItemCount'?: number;
    /**
     * String that identifies the server\'s internal version of this object that can be used by clients to determine when objects have changed. Value must be treated as opaque by clients and passed unmodified back to the server. Populated by the system. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof MetaV1StatusMetadata
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof MetaV1StatusMetadata
     */
    'selfLink'?: string;
}

/**
 * Api - axios parameter creator
 * @export
 */
export const ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create an AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedAlarmConfiguration: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comAlarmConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedDevice: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedRecorder: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comRecorder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedVideoChannel: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comVideoChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedVideoStream: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comVideoStream, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedAlarmConfiguration: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedDevice: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedRecorder: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedVideoChannel: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedVideoStream: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedAlarmConfiguration: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedAlarmConfiguration', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedDevice: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedDevice', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedRecorder: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedRecorder', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedVideoChannel: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedVideoChannel', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedVideoStream: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedVideoStream', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind AlarmConfiguration
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAlarmConfigurationForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/alarmconfigurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Device
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedAlarmConfiguration: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedDevice: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedRecorder: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedVideoChannel: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedVideoStream: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Recorder
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecorderForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/recorders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind VideoChannel
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoChannelForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/videochannels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind VideoStream
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoStreamForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/videostreams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedAlarmConfiguration: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedAlarmConfiguration', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedAlarmConfigurationStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedAlarmConfigurationStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedAlarmConfigurationStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedDevice: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedDevice', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedDeviceStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedDeviceStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedDeviceStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedRecorder: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedRecorder', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedRecorderStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedRecorderStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedRecorderStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoChannel: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedVideoChannel', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoChannelStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedVideoChannelStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedVideoChannelStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoStream: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedVideoStream', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoStreamStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedVideoStreamStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedVideoStreamStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedAlarmConfiguration: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedAlarmConfiguration', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedAlarmConfigurationStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedAlarmConfigurationStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedAlarmConfigurationStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedDevice: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedDevice', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedDeviceStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedDeviceStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedDeviceStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedRecorder: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedRecorder', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedRecorderStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedRecorderStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedRecorderStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoChannel: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedVideoChannel', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoChannelStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedVideoChannelStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedVideoChannelStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoStream: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedVideoStream', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoStreamStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedVideoStreamStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedVideoStreamStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedAlarmConfiguration: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedAlarmConfiguration', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedAlarmConfiguration', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comAlarmConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedAlarmConfigurationStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedAlarmConfigurationStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedAlarmConfigurationStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/alarmconfigurations/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comAlarmConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedDevice: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedDevice', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedDevice', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedDeviceStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedDeviceStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedDeviceStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/devices/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedRecorder: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedRecorder', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedRecorder', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comRecorder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedRecorderStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedRecorderStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedRecorderStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/recorders/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comRecorder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoChannel: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedVideoChannel', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedVideoChannel', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comVideoChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoChannelStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedVideoChannelStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedVideoChannelStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videochannels/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comVideoChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoStream: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedVideoStream', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedVideoStream', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comVideoStream, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoStreamStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedVideoStreamStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedVideoStreamStatus', 'namespace', namespace)
            const localVarPath = `/apis/recorder.mirasys.com/v1alpha1/namespaces/{namespace}/videostreams/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comVideoStream, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Api - functional programming interface
 * @export
 */
export const ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiAxiosParamCreator(configuration)
    return {
        /**
         * create an AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedAlarmConfiguration(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedAlarmConfiguration(namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedDevice(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedDevice(namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedRecorder(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedRecorder(namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedVideoChannel(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedVideoChannel(namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedVideoStream(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedVideoStream(namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedAlarmConfiguration(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedAlarmConfiguration(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedDevice(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedDevice(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedRecorder(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedRecorder(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedVideoChannel(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedVideoChannel(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedVideoStream(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedVideoStream(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedDevice(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedRecorder(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedVideoChannel(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedVideoStream(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind AlarmConfiguration
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAlarmConfigurationForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfigurationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAlarmConfigurationForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Device
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedAlarmConfiguration(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfigurationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedAlarmConfiguration(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedDevice(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedDevice(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedRecorder(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecorderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedRecorder(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedVideoChannel(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannelList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedVideoChannel(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedVideoStream(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStreamList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedVideoStream(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Recorder
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecorderForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecorderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecorderForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind VideoChannel
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVideoChannelForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannelList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVideoChannelForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind VideoStream
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVideoStreamForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStreamList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVideoStreamForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedAlarmConfigurationStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedDevice(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedDeviceStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedRecorder(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedRecorderStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedVideoChannel(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedVideoChannelStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedVideoStream(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedVideoStreamStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedAlarmConfiguration(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedAlarmConfigurationStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedDevice(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedDevice(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedDeviceStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedRecorder(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedRecorder(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedRecorderStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedVideoChannel(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedVideoChannel(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedVideoChannelStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedVideoStream(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedVideoStream(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedVideoStreamStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlarmConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedAlarmConfigurationStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedDevice(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedDeviceStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedRecorder(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recorder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedRecorderStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedVideoChannel(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedVideoChannelStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedVideoStream(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedVideoStreamStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Api - factory interface
 * @export
 */
export const ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiFp(configuration)
    return {
        /**
         * create an AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedAlarmConfiguration(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.createNamespacedAlarmConfiguration(namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * create a Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedDevice(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: any): AxiosPromise<Device> {
            return localVarFp.createNamespacedDevice(namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * create a Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedRecorder(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: any): AxiosPromise<Recorder> {
            return localVarFp.createNamespacedRecorder(namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options).then((request) => request(axios, basePath));
        },
        /**
         * create a VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedVideoChannel(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.createNamespacedVideoChannel(namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * create a VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedVideoStream(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.createNamespacedVideoStream(namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedAlarmConfiguration(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedAlarmConfiguration(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedDevice(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedDevice(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedRecorder(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedRecorder(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedVideoChannel(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedVideoChannel(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedVideoStream(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedVideoStream(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedDevice(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedRecorder(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedVideoChannel(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedVideoStream(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind AlarmConfiguration
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAlarmConfigurationForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<AlarmConfigurationList> {
            return localVarFp.listAlarmConfigurationForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Device
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<DeviceList> {
            return localVarFp.listDeviceForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedAlarmConfiguration(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<AlarmConfigurationList> {
            return localVarFp.listNamespacedAlarmConfiguration(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedDevice(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<DeviceList> {
            return localVarFp.listNamespacedDevice(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedRecorder(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<RecorderList> {
            return localVarFp.listNamespacedRecorder(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedVideoChannel(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<VideoChannelList> {
            return localVarFp.listNamespacedVideoChannel(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedVideoStream(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<VideoStreamList> {
            return localVarFp.listNamespacedVideoStream(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Recorder
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecorderForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<RecorderList> {
            return localVarFp.listRecorderForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind VideoChannel
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoChannelForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<VideoChannelList> {
            return localVarFp.listVideoChannelForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind VideoStream
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoStreamForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<VideoStreamList> {
            return localVarFp.listVideoStreamForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.patchNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.patchNamespacedAlarmConfigurationStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Device> {
            return localVarFp.patchNamespacedDevice(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Device> {
            return localVarFp.patchNamespacedDeviceStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Recorder> {
            return localVarFp.patchNamespacedRecorder(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Recorder> {
            return localVarFp.patchNamespacedRecorderStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.patchNamespacedVideoChannel(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.patchNamespacedVideoChannelStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.patchNamespacedVideoStream(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.patchNamespacedVideoStreamStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.readNamespacedAlarmConfiguration(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.readNamespacedAlarmConfigurationStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedDevice(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Device> {
            return localVarFp.readNamespacedDevice(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Device> {
            return localVarFp.readNamespacedDeviceStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedRecorder(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Recorder> {
            return localVarFp.readNamespacedRecorder(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Recorder> {
            return localVarFp.readNamespacedRecorderStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoChannel(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.readNamespacedVideoChannel(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.readNamespacedVideoChannelStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoStream(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.readNamespacedVideoStream(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.readNamespacedVideoStreamStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.replaceNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified AlarmConfiguration
         * @param {string} name name of the AlarmConfiguration
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {AlarmConfiguration} [comAlarmConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: any): AxiosPromise<AlarmConfiguration> {
            return localVarFp.replaceNamespacedAlarmConfigurationStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: any): AxiosPromise<Device> {
            return localVarFp.replaceNamespacedDevice(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified Device
         * @param {string} name name of the Device
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Device} [comDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: any): AxiosPromise<Device> {
            return localVarFp.replaceNamespacedDeviceStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: any): AxiosPromise<Recorder> {
            return localVarFp.replaceNamespacedRecorder(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified Recorder
         * @param {string} name name of the Recorder
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Recorder} [comRecorder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: any): AxiosPromise<Recorder> {
            return localVarFp.replaceNamespacedRecorderStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.replaceNamespacedVideoChannel(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified VideoChannel
         * @param {string} name name of the VideoChannel
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoChannel} [comVideoChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: any): AxiosPromise<VideoChannel> {
            return localVarFp.replaceNamespacedVideoChannelStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.replaceNamespacedVideoStream(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified VideoStream
         * @param {string} name name of the VideoStream
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {VideoStream} [comVideoStream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: any): AxiosPromise<VideoStream> {
            return localVarFp.replaceNamespacedVideoStreamStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Api - object-oriented interface
 * @export
 * @class Api
 * @extends {BaseAPI}
 */
export class Api extends BaseAPI {
    /**
     * create an AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {AlarmConfiguration} [comAlarmConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedAlarmConfiguration(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedAlarmConfiguration(namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Device} [comDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedDevice(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedDevice(namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Recorder} [comRecorder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedRecorder(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedRecorder(namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {VideoChannel} [comVideoChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedVideoChannel(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedVideoChannel(namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {VideoStream} [comVideoStream] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedVideoStream(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedVideoStream(namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedAlarmConfiguration(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedAlarmConfiguration(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedDevice(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedDevice(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedRecorder(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedRecorder(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedVideoChannel(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedVideoChannel(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedVideoStream(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedVideoStream(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedDevice(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedRecorder(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedVideoChannel(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedVideoStream(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind AlarmConfiguration
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listAlarmConfigurationForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listAlarmConfigurationForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Device
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listDeviceForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listDeviceForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedAlarmConfiguration(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedAlarmConfiguration(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedDevice(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedDevice(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedRecorder(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedRecorder(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedVideoChannel(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedVideoChannel(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedVideoStream(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedVideoStream(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Recorder
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listRecorderForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listRecorderForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind VideoChannel
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listVideoChannelForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listVideoChannelForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind VideoStream
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listVideoStreamForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listVideoStreamForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedAlarmConfigurationStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedDevice(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedDeviceStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedRecorder(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedRecorderStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedVideoChannel(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedVideoChannelStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedVideoStream(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedVideoStreamStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedAlarmConfiguration(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedAlarmConfigurationStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedDevice(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedDevice(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedDeviceStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedRecorder(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedRecorder(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedRecorderStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedVideoChannel(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedVideoChannel(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedVideoChannelStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedVideoStream(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedVideoStream(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedVideoStreamStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {AlarmConfiguration} [comAlarmConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedAlarmConfiguration(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedAlarmConfiguration(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified AlarmConfiguration
     * @param {string} name name of the AlarmConfiguration
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {AlarmConfiguration} [comAlarmConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedAlarmConfigurationStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comAlarmConfiguration?: AlarmConfiguration, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedAlarmConfigurationStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comAlarmConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Device} [comDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedDevice(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedDevice(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified Device
     * @param {string} name name of the Device
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Device} [comDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedDeviceStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDevice?: Device, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedDeviceStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Recorder} [comRecorder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedRecorder(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedRecorder(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified Recorder
     * @param {string} name name of the Recorder
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Recorder} [comRecorder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedRecorderStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRecorder?: Recorder, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedRecorderStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRecorder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {VideoChannel} [comVideoChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedVideoChannel(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedVideoChannel(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified VideoChannel
     * @param {string} name name of the VideoChannel
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {VideoChannel} [comVideoChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedVideoChannelStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoChannel?: VideoChannel, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedVideoChannelStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {VideoStream} [comVideoStream] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedVideoStream(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedVideoStream(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified VideoStream
     * @param {string} name name of the VideoStream
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {VideoStream} [comVideoStream] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedVideoStreamStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comVideoStream?: VideoStream, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedVideoStreamStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comVideoStream, options).then((request) => request(this.axios, this.basePath));
    }
}



