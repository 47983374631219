import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@mui/material'
import {
  RoleDetailForm,
  RoleDetailFormData,
  RoleDetailFormRef,
} from './RoleDetailForm'
import {
  PermissionPanel,
  PermissionFormData,
  PermissionFormRef,
} from './PermissionPanel'
import { Role } from 'gql/graphql'

export interface RoleFormData extends RoleDetailFormData {
  permissions: PermissionFormData | undefined
}

export interface RoleFormRef {
  getFormData(): Promise<RoleFormData | undefined>
}

interface RoleFormProps {
  role?: Role
  formRef: MutableRefObject<RoleFormRef>
  onValidate(isValid: boolean): void
}

export const RoleForm: FC<RoleFormProps> = ({ role, formRef, onValidate }) => {
  const [tab, setTab] = useState('detail')
  const [detailFormValid, setDetailFormValid] = useState(false)
  const { t } = useTranslation()
  const detailFormRef = useRef<RoleDetailFormRef>({
    getFormData: async () => undefined,
  })

  const permissionFormRef = useRef<PermissionFormRef>({
    getFormData: async () => undefined,
  })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = async () => {
        const detailData = await detailFormRef.current.getFormData()
        const permissionData = await permissionFormRef.current.getFormData()
        if (!detailData) {
          setTab('detail')
          return undefined
        }
        return { ...detailData, permissions: permissionData }
      }
    }
  }, [formRef])

  useEffect(() => {
    onValidate(detailFormValid)
  }, [detailFormValid, onValidate])

  return (
    <div className="flex h-full flex-col">
      <div className="px-10 pt-8">
        <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
          <Tab
            label={t('roleAdd.roleDetails')}
            value="detail"
            data-testid="detail-tab"
          />
          <Tab
            label={t('common.permissions')}
            value="permissions"
            data-testid="permissions-tab"
          />
        </Tabs>
      </div>

      <div className="mt-6 h-0 flex-1 overflow-y-auto">
        <RoleDetailForm
          className={tab === 'detail' ? '' : 'hidden'}
          role={role}
          formRef={detailFormRef}
          onValidate={setDetailFormValid}
        />
        <PermissionPanel
          className={tab === 'permissions' ? '' : 'hidden'}
          role={role}
          formRef={permissionFormRef}
          createEditPanel
        />
      </div>
    </div>
  )
}
