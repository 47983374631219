import { useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Panel } from '../Panel'
import { RoleForm, RoleFormRef } from './RoleForm'
import { RoleSpec, UserSpec } from 'common-api/clients/system/typescript'
import { useDispatch, useSelector } from '../../store'
import { showCreateRolePanel } from 'store/slices/roles'
import { showHttpError } from '../../utils/helpers'
import { setEditingUser, updateUser } from 'store/slices/users'
import { useTranslation } from 'react-i18next'

export const RoleCreatePanel = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef<RoleFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { creatingRole } = useSelector((state) => state.roles)
  const allUsers = useSelector((state) => state.users.users.byName)
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation()
  const onSubmit = async () => {
    // const data = await formRef.current.getFormData()
    // if (data) {
    //   const { users, permissions, ...info } = data
    //   const { apiPermissions, uiPermissions, applications, deviceAccessRefs } =
    //     createPermissionsArrays(permissions)
    //   const spec: RoleSpec = {
    //     ...info,
    //     apiPermissions,
    //     uiPermissions,
    //     applications,
    //     deviceAccessRefs,
    //   }
    //   dispatch(createRole(spec)).then((e: any) => {
    //     if (e.error) {
    //       showHttpError(enqueueSnackbar, e.error, 'Creating role failed')
    //     } else {
    //       enqueueSnackbar('Created role successfully', { variant: 'success' })
    //       // Add the selected user to the newly created role here
    //       Object.values(users).map((user) => {
    //         dispatch(setEditingUser(user))
    //         const roles = allUsers[user].spec.roles.concat(info.name) || []
    //         const newUserSpec: UserSpec = { ...allUsers[user].spec, roles }
    //         dispatch(updateUser(newUserSpec))
    //       })
    //       dispatch(setEditingUser(undefined))
    //       onClose()
    //     }
    //   })
    // }
  }

  const onClose = () => {
    dispatch(showCreateRolePanel(false))
  }

  return (
    <Panel
      title={t('common.addNewRole') as string}
      submitButtonText={t('common.addRole') as string}
      open={creatingRole}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
      isValid={isValid}
    >
      {creatingRole && <RoleForm formRef={formRef} onValidate={setIsValid} />}
    </Panel>
  )
}
