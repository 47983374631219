import { useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Panel } from '../Panel'
import { RoleForm, RoleFormRef } from './RoleForm'
import { RoleSpec } from 'common-api/clients/system/typescript'
import { useDispatch, useSelector } from '../../store'
import { setEditingRole } from 'store/slices/roles'
import { showHttpError } from 'utils/helpers'

export const RoleEditPanel = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef<RoleFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { editingRole } = useSelector((state) => state.roles)
  const [isValid, setIsValid] = useState(false)

  const onSubmit = async () => {
    if (!editingRole) {
      return
    }

    const data = await formRef.current.getFormData()
    if (!data) {
      return
    }

    // const { users, permissions, ...info } = data
    // const { apiPermissions, uiPermissions, applications, deviceAccessRefs } =
    //   createPermissionsArrays(permissions)
    // let spec: RoleSpec = {
    //   ...info,
    // }
    // if (permissions) {
    //   spec = {
    //     ...info,
    //     apiPermissions,
    //     uiPermissions,
    //     applications,
    //     deviceAccessRefs,
    //   }
    // }
    // dispatch(
    //   updateRole({
    //     name: editingRole.name,
    //     before: editingRole.spec,
    //     after: spec,
    //   })
    // ).then((e: any) => {
    //   if (e.error) {
    //     showHttpError(enqueueSnackbar, e.error, 'Updating role failed')
    //   } else {
    //     enqueueSnackbar('Your changes are saved successfully', {
    //       variant: 'success',
    //     })
    //     onClose()
    //   }
    // })
  }

  const onClose = () => {
    dispatch(setEditingRole(undefined))
  }

  return (
    <Panel
      title={t('common.editRole') as string}
      submitButtonText={t('roleEdit.save') as string}
      open={!!editingRole}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
      isValid={isValid}
    >
      {editingRole && (
        <RoleForm
          role={editingRole}
          formRef={formRef}
          onValidate={setIsValid}
        />
      )}
    </Panel>
  )
}
