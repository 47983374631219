import React, { StrictMode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SnackbarProvider, ThemeProvider } from './common-lib/components'
import { AuthGuard } from './common-lib/providers/AuthGuard'
import { MainPage } from './pages/MainPage'
import { PERMISSIONS } from './common-lib/constants/permissions'

const App = () => {
  return (
    <StrictMode>
      <ThemeProvider>
        <SnackbarProvider>
          <DndProvider backend={HTML5Backend}>
            <AuthGuard permissions={[PERMISSIONS.ACCESS_ADMIN]}>
              <MainPage />
            </AuthGuard>
          </DndProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StrictMode>
  )
}
export { App }
