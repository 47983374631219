import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Chip, IconButton, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import noDataIllustration from 'assets/img/illustrations/no-data.svg'
import noSearchedDataIllustration from 'assets/img/illustrations/no-searched-data.svg'
import {
  Device,
  VideoStream,
  VideoStreamSpecBitrateModeEnum,
  VideoStreamSpecCompressionEnum,
} from 'common-api/clients/recorder/typescript'
import { Autocomplete } from 'common-lib/components/FormControls'
import { DataTable, SortModel, TableColumn } from 'common-lib/components/Table'
import {
  bitrateModeOptions,
  bitrateOptions,
  compressionOptions,
  framerateOptions,
  resolutionOptions,
} from './VideoStreamDetailForm'
import { VideoStreamsTable } from './VideoStreamsTable'
import { useDispatch, useSelector } from '../../store'
import { setEditingDevice } from 'store/slices/devices'

const DEFAULT_SORT: SortModel = { field: 'name', dir: false }
const ROWS_PER_PAGE = 13

interface TableData {
  id: string
  name: string
  description: string
  videoStreams: VideoStream[]
}

interface TableFilter {
  search: string
  framerate: Array<number>
  resolution: Array<(typeof resolutionOptions)[0]>
  bitrateMode: Array<VideoStreamSpecBitrateModeEnum>
  bitrate: Array<(typeof bitrateOptions)[0]>
  compression: Array<VideoStreamSpecCompressionEnum>
}

interface DeviceChannelsProps {
  className?: string
  device: Device
}

export const DeviceChannels: FC<DeviceChannelsProps> = ({
  className,
  device,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<TableFilter>({
    search: '',
    framerate: [],
    resolution: [],
    bitrateMode: [],
    bitrate: [],
    compression: [],
  })

  const recorder = useSelector(
    (state) =>
      state.recorders.recorders.byName[device.status?.recorderRef as string]
  )
  const videoChannels = useSelector((state) =>
    Object.values(state.videoChannels.videoChannels.byName).filter(
      (item) => item.status?.deviceRef === device.metadata.name
    )
  )
  const videoStreams = useSelector((state) =>
    Object.values(state.videoStreams.videoStreams.byName).filter(
      (item) => item.status?.deviceRef === device.metadata.name
    )
  )

  const canAddRemoveChannels =
    recorder?.status?.capabilities?.canAddRemoveChannels
  const canAddRemoveStreams =
    recorder?.status?.capabilities?.canAddRemoveStreams
  const hasFilter = Object.values(filter).some(
    (item) =>
      (typeof item === 'string' && item !== '') ||
      (Array.isArray(item) && item.length !== 0)
  )

  const columns: TableColumn[] = [
    {
      title: 'Channel Name',
      field: 'name',
      sortable: true,
    },
    {
      title: t('common.description'),
      field: 'description',
      sortable: true,
    },
    {
      title: '',
      align: 'right',
      render: (row: TableData) => {
        return (
          <div className="whitespace-nowrap">
            <IconButton
              onClick={() => onEditChannel(row)}
              data-testid="edit-channel-button"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {canAddRemoveChannels && (
              <IconButton
                // onClick={() => onDeleteChannel(row.id)}
                data-testid="delete-channel-button"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )
      },
    },
  ]

  const rows = useMemo<TableData[]>(() => {
    console.log('filter: ', filter)
    const streams = videoStreams.filter((item) => {
      if (
        filter.framerate.length > 0 &&
        item.spec.framerate &&
        !filter.framerate.includes(item.spec.framerate)
      ) {
        return false
      }
      if (
        filter.resolution.length > 0 &&
        !(
          filter.resolution.filter((i) => {
            if (
              item.spec.resolution?.height &&
              item.spec.resolution?.width &&
              i.height === item.spec.resolution?.height &&
              i.width === item.spec.resolution.width
            ) {
              return true
            }
          }).length > 0
        )
      ) {
        return false
      }
      if (
        filter.bitrateMode.length > 0 &&
        item.spec.bitrateMode &&
        !filter.bitrateMode.includes(item.spec.bitrateMode)
      ) {
        return false
      }
      if (
        filter.bitrate.length > 0 &&
        item.spec.bitrate &&
        !filter.bitrate.map((i) => i.value).includes(item.spec.bitrate)
      ) {
        return false
      }
      if (
        filter.compression.length > 0 &&
        item.spec.compression &&
        !filter.compression.includes(item.spec.compression)
      ) {
        return false
      }
      return true
    })
    let channels = videoChannels.map((item) => ({
      id: item.metadata.name,
      name: item.spec.displayName || '',
      description: item.spec.description || '',
      videoStreams: streams.filter(
        (stream) => stream.status?.channelRef === item.metadata.name
      ),
    }))
    if (hasFilter) {
      if (filter.search) {
        const keyword = filter.search.toLowerCase()
        channels = channels.filter((channel) => {
          if (channel.name.toLowerCase().includes(keyword)) {
            return true
          }
          if (channel.description.toLowerCase().includes(keyword)) {
            return true
          }
          return false
        })
      }
      channels = channels.filter((channel) => channel.videoStreams.length > 0)
    }
    return channels
  }, [filter, hasFilter, videoChannels])

  const renderChildRow = (row: TableData) => {
    return (
      <VideoStreamsTable
        size="small"
        videoStreams={row.videoStreams}
        canRemoveStream={canAddRemoveStreams}
        reverseRowColors
        noTableData="No video streams found"
      />
    )
  }

  const onEditChannel = (row: TableData) => {
    dispatch(
      setEditingDevice({
        deviceName: device.metadata.name,
        channelName: row.id,
      })
    )
  }

  const onFilterChange = (field: keyof TableFilter, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const onRemoveFilter = (field: keyof TableFilter, value: any) => {
    if (field === 'search') {
      setFilter((prev) => ({
        ...prev,
        [field]: '',
      }))
    } else {
      setFilter((prev) => ({
        ...prev,
        [field]: (
          prev[field] as Array<
            | number
            | (typeof resolutionOptions)[0]
            | VideoStreamSpecBitrateModeEnum
            | (typeof bitrateOptions)[0]
            | VideoStreamSpecCompressionEnum
          >
        ).filter((i) => i !== value),
      }))
    }
  }

  const onClearFilter = () => {
    setFilter({
      search: '',
      framerate: [],
      resolution: [],
      bitrateMode: [],
      bitrate: [],
      compression: [],
    })
  }

  return (
    <div className={className} data-testid="device-channels">
      <div className="flex items-center">
        <FilterListIcon />
        <h4 className="ml-2">Filter(s)</h4>
      </div>
      <div className="mt-2 flex flex-wrap gap-x-4 gap-y-2">
        <TextField
          className="w-56"
          placeholder="Search"
          value={filter.search}
          InputProps={{ endAdornment: <SearchIcon className="mr-3" /> }}
          onChange={(e) => onFilterChange('search', e.target.value)}
          data-testid="search-filter"
        />
        <Autocomplete
          className="w-38"
          placeholder="Frame Rate"
          multiple
          checkbox
          value={filter.framerate}
          options={framerateOptions}
          getOptionLabel={(option) => (option ? `${option} FPS` : '')}
          onChange={(e, value) => onFilterChange('framerate', value)}
          data-testid="framerate-filter"
        />
        <Autocomplete
          className="w-36"
          placeholder="Resolution"
          multiple
          checkbox
          value={filter.resolution}
          options={resolutionOptions}
          disableClearable
          getOptionLabel={(option) => option?.text || ''}
          onChange={(e, value) => onFilterChange('resolution', value)}
          data-testid="resolution-filter"
        />
        <Autocomplete
          className="w-28"
          placeholder="Bitrate Mode"
          multiple
          checkbox
          value={filter.bitrateMode}
          options={bitrateModeOptions}
          disableClearable
          onChange={(e, value) => onFilterChange('bitrateMode', value)}
          data-testid="bitrate-filter"
        />
        <Autocomplete
          className="w-36"
          placeholder="Bitrate"
          multiple
          checkbox
          value={filter.bitrate}
          options={bitrateOptions}
          disableClearable
          getOptionLabel={(option) => option?.text || ''}
          onChange={(e, value) => onFilterChange('bitrate', value)}
          data-testid="bitrate-filter"
        />
        <Autocomplete
          className="w-42"
          placeholder="Comp. Quality"
          multiple
          checkbox
          value={filter.compression}
          options={compressionOptions}
          disableClearable
          onChange={(e, value) => onFilterChange('compression', value)}
          data-testid="compression-filter"
        />
      </div>
      {hasFilter && (
        <div className="mt-3 flex flex-wrap items-center gap-x-3 gap-y-2">
          {filter.search !== '' && (
            <Chip
              color="primary"
              label={filter.search}
              size="small"
              onDelete={() => onFilterChange('search', '')}
            />
          )}
          {filter.framerate.map((i) => (
            <Chip
              color="primary"
              label={`${i} FPS`}
              size="small"
              onDelete={() => onRemoveFilter('framerate', i)}
              key={i}
            />
          ))}
          {filter.resolution.map((i) => (
            <Chip
              color="primary"
              label={i.text}
              size="small"
              onDelete={() => onRemoveFilter('resolution', i)}
              key={i.text}
            />
          ))}
          {filter.bitrateMode.map((i) => (
            <Chip
              color="primary"
              label={i}
              size="small"
              onDelete={() => onRemoveFilter('bitrateMode', i)}
              key={i}
            />
          ))}
          {filter.bitrate.map((i) => (
            <Chip
              color="primary"
              label={i.text}
              size="small"
              onDelete={() => onRemoveFilter('bitrate', i)}
              key={i.text}
            />
          ))}
          {filter.compression.map((i) => (
            <Chip
              color="primary"
              label={i}
              size="small"
              onDelete={() => onRemoveFilter('compression', i)}
              key={i}
            />
          ))}
          <Chip
            color="primary"
            variant="outlined"
            label="Clear filter(s)"
            size="small"
            onDelete={onClearFilter}
          />
        </div>
      )}

      <DataTable
        className="mt-8"
        columns={columns}
        rows={rows}
        defaultSort={DEFAULT_SORT}
        rowsPerPage={ROWS_PER_PAGE}
        expandable
        renderChildRow={renderChildRow}
        autoPaginate
        paginatorClass="mt-6"
        showFirstLastPaginateButton
        hideTableWhenNoData
        noTableData={
          !videoChannels.length ? (
            <>
              <img src={noDataIllustration} alt="" />
              <p className="mt-10">
                It seems to be no registered video channels.
              </p>
            </>
          ) : (
            <>
              <img src={noSearchedDataIllustration} alt="" />
              <p className="mt-10">No Search Results</p>
            </>
          )
        }
        data-testid="video-channels-table"
      />
    </div>
  )
}
