import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import { DataTable, SortModel, TableColumn } from 'common-lib/components/Table'
import { RoleChip } from 'common-lib/components'
import { User } from 'common-api/clients/system/typescript'
import { Role } from 'gql/graphql'
import { useDispatch, useSelector } from 'store'
import { setEditingUser } from 'store/slices/users'

interface TableData {
  id: string
  name: string
  roles: Role[]
}

export interface RoleUserTableProps {
  className?: string
  roleName?: string
  userIds: string[]
  size?: 'small' | 'medium'
  editable?: boolean
  deletable?: boolean
  onDeleteUser?: (id: string) => void
}

const DEFAULT_SORT: SortModel = { field: 'name', dir: 'asc' }
const ROWS_PER_PAGE = 10

export const RoleUsersTable = ({
  className,
  roleName,
  userIds,
  size,
  editable,
  deletable,
  onDeleteUser,
}: RoleUserTableProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const users = useSelector((state) => state.users.users.byName)
  const roles = useSelector((state) => state.roles.roles)

  const columns = useMemo(() => {
    const cols: TableColumn[] = [
      {
        title: t('common.name') as string,
        field: 'name',
        sortable: true,
        render: (row: TableData) => {
          return <Link to={`/user-management/users/${row.id}`}>{row.name}</Link>
        },
      },
      {
        title: t('roleAdd.tableRoles'),
        field: 'roles',
        sortable: true,
        cellClass: '!py-1',
        render: (row: TableData) => {
          return (
            <div className="-mb-1 flex flex-wrap">
              {row.roles.map((role) => (
                <RoleChip
                  key={role.name}
                  className="mr-2 mb-1"
                  role={role}
                  size="small"
                />
              ))}
            </div>
          )
        },
      },
    ]
    if (editable || deletable) {
      cols.push({
        title: '',
        align: 'right',
        cellClass: '!py-1',
        render: (row: TableData) => {
          return (
            <>
              {editable && (
                <IconButton onClick={() => dispatch(setEditingUser(row.id))}>
                  <Edit />
                </IconButton>
              )}
              {deletable && onDeleteUser && (
                <IconButton onClick={() => onDeleteUser(row.id)}>
                  <DeleteOutline />
                </IconButton>
              )}
            </>
          )
        },
      })
    }
    return cols
  }, [editable, deletable, dispatch, onDeleteUser])

  const rows = useMemo(() => {
    const rows: TableData[] = []
    if (Object.keys(users).length) {
      Object.values(users).forEach((user: User) => {
        if (!user.metadata.name || !userIds.includes(user.metadata.name)) {
          return
        }

        const otherRoles = (user.spec.roles || [])
          .filter((id) => id !== roleName)
          .map((id) => {
            return Object.values(roles).find((role: Role) => role.name === id)
          })
          .filter((role) => !!role)

        rows.push({
          id: user.metadata.name,
          name: `${user.spec.firstName} ${user.spec.lastName}`,
          roles: otherRoles as Role[],
        })
      })
    }
    return rows
  }, [users, roles, roleName, userIds])

  return (
    <DataTable
      className={className}
      columns={columns}
      rows={rows}
      size={size}
      defaultSort={DEFAULT_SORT}
      rowsPerPage={ROWS_PER_PAGE}
      autoPaginate
      noTableData={t('roleAdd.tablePlaceholder')}
    />
  )
}
