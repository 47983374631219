import { v4 as uuidv4 } from 'uuid'
import { AxiosRequestConfig } from 'axios'
import { RECORDER_API_VERSION, recorderClientApi } from './types'
import {
  Device,
  DeviceSpec,
} from '../../common-api/clients/recorder/typescript'
import { compareSpecs } from '../../utils/helpers'

const patchOptions = {
  headers: {
    'Content-Type': 'application/json-patch+json', // ref: https://pkg.go.dev/k8s.io/apimachinery/pkg/types#PatchType
  },
} as AxiosRequestConfig
const fieldValidation = 'Strict'

const listDevices = async (namespace: string) => {
  // TODO: Handle pagination
  return recorderClientApi.listNamespacedDevice(
    namespace,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '0'
  )
}

const createDevice = async (
  namespace: string,
  recorderName: string,
  spec: DeviceSpec
) => {
  const req: Device = {
    apiVersion: RECORDER_API_VERSION,
    kind: 'Device',
    metadata: {
      name: uuidv4(),
      namespace,
      labels: {
        rec: recorderName,
      },
    },
    spec,
  }
  return recorderClientApi.createNamespacedDevice(
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    req
  )
}

const patchDevice = async (
  name: string,
  namespace: string,
  before: DeviceSpec,
  after: DeviceSpec
) => {
  return recorderClientApi.patchNamespacedDevice(
    name,
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    undefined,
    compareSpecs(before, after),
    patchOptions
  )
}

const deleteDevice = async (namespace: string, id: string) => {
  return recorderClientApi.deleteNamespacedDevice(id, namespace)
}

export { listDevices, createDevice, patchDevice, deleteDevice }
