import { validate as uuidValidate } from 'uuid'
// import {
//   Role,
//   RoleSpecApiPermissions,
//   RoleSpecApiPermissionsKindEnum,
//   RoleSpecApiPermissionsVerbsEnum,
//   RoleSpecApplicationsEnum,
//   RoleSpecUiPermissionsEnum,
// } from 'common-api/clients/system/typescript'
import { PermissionFormData } from 'components/Roles/PermissionPanel'
import { Role, UserProfile } from 'gql/graphql'

export const hasPermission = (
  account: UserProfile,
  permissionGroups?: string | string[]
) => {
  if (!permissionGroups) {
    return true
  }
  if (!Array.isArray(permissionGroups)) {
    permissionGroups = [permissionGroups]
  }

  const userPermissions = account.permissions || []
  return permissionGroups.some((permissionGroup) => {
    const permissions = permissionGroup.split(',')
    return permissions.every((permission) =>
      userPermissions.includes(permission)
    )
  })
}

// export const roleHasApplication = (
//   role?: Role,
//   application?: RoleSpecApplicationsEnum
// ) => {
//   if (!application) {
//     return true
//   }
//   if (!role?.spec.applications) {
//     return false
//   }
//   return role.spec.applications.includes(application)
// }
//
// export const roleHasApiPermission = (
//   role?: Role,
//   kind?: RoleSpecApiPermissionsKindEnum,
//   verb?: RoleSpecApiPermissionsVerbsEnum
// ) => {
//   if (!kind) {
//     return true
//   }
//   if (!role?.spec.apiPermissions) {
//     return false
//   }
//   const permission = role.spec.apiPermissions.find((item) => item.kind === kind)
//   if (!permission) {
//     return false
//   }
//   return !verb || permission.verbs.includes(verb)
// }
//
// export const roleHasUiPermission = (
//   role?: Role,
//   permission?: RoleSpecUiPermissionsEnum
// ) => {
//   if (!permission) {
//     return true
//   }
//   if (!role?.spec.uiPermissions) {
//     return false
//   }
//   return role.spec.uiPermissions.includes(permission)
// }
//
// export const createPermissionsArrays = (
//   permission: PermissionFormData | undefined
// ) => {
//   const apiPermissions = [
//     ...(permission?.user_management !== 'No Access'
//       ? [
//           {
//             kind: 'User',
//             verbs: [
//               ...(permission?.user_management === 'View Only' ? ['read'] : []),
//               ...(permission?.user_management === 'Edit'
//                 ? ['read', 'create', 'update']
//                 : []),
//               ...(permission?.user_management === 'Admin'
//                 ? ['read', 'create', 'update', 'delete']
//                 : []), // TODO: This should enable everything below
//             ],
//           },
//         ]
//       : []),
//     ...(permission?.role_view
//       ? [
//           {
//             kind: 'Role',
//             verbs: [
//               'read',
//               ...(permission?.role_add ? ['create'] : []),
//               ...(permission?.role_edit ? ['update'] : []),
//               ...(permission?.role_delete ? ['delete'] : []),
//             ],
//           },
//         ]
//       : []),
//     ...(permission?.device_view
//       ? [
//           {
//             kind: 'DeviceAccess',
//             verbs: [
//               'read',
//               ...(permission?.device_add ? ['create'] : []),
//               ...(permission?.device_edit ? ['update'] : []),
//               ...(permission?.device_delete ? ['delete'] : []),
//             ],
//           },
//         ]
//       : []),
//     ...(permission?.recorder_view
//       ? [
//           {
//             kind: 'Recorder',
//             verbs: [
//               'read',
//               ...(permission?.recorder_add ? ['create'] : []),
//               ...(permission?.recorder_edit ? ['update'] : []),
//               ...(permission?.recorder_delete ? ['delete'] : []),
//             ],
//           },
//         ]
//       : []),
//   ] as RoleSpecApiPermissions[]
//   const uiPermissions = [
//     ...(permission?.view_livestream ? ['view-livestream'] : []),
//     ...(permission?.view_playback ? ['view-playback'] : []),
//     ...(permission?.export_video ? ['export-video'] : []),
//     ...(permission?.control_ptz ? ['control-ptz'] : []),
//     ...(permission?.create_ptz ? ['create-ptz'] : []),
//     ...(permission?.view_alarms ? ['view-alarms'] : []),
//     ...(permission?.view_alarm_details ? ['view-alarm-details'] : []),
//   ] as RoleSpecUiPermissionsEnum[]
//   const applications = [
//     ...(permission?.spotter_admin_access ? ['spotter-admin'] : []),
//     ...(permission?.spotter_web_access ? ['spotter-web'] : []),
//   ] as RoleSpecApplicationsEnum[]
//   const deviceAccessRefs: Array<string> = []
//   return { apiPermissions, uiPermissions, applications, deviceAccessRefs }
// }

export const isVisibleRole = (role: Role) => {
  if (!role) {
    return false
  }
  return !role.name.startsWith('user-') && !uuidValidate(role.name.substring(4))
}
