import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { ConfirmDialog } from 'common-lib/components'
import { VideoChannelsLoader } from 'components/Recorders/VideoChannelsLoader'
import { RecordersOverview } from 'components/Recorders/RecordersOverview'
import { RecorderView } from 'components/Recorders/RecorderView'
import { AddDevicesPanel } from 'components/Recorders/AddDevicesPanel'
import { EditDevicePanel } from 'components/Recorders/EditDevicePanel'
import { DeviceView } from 'components/Recorders/DeviceView'
import { useDispatch, useSelector } from '../store'
import { deleteDevice, setDeletingDevice } from 'store/slices/devices'
import { deleteRecorder, setDeletingRecorder } from 'store/slices/recorders'
import { showHttpError } from 'utils/helpers'

export const RecordersPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const { deletingRecorder } = useSelector((state) => state.recorders)
  const { deletingDevice } = useSelector((state) => state.devices)

  const onCloseDeleteRecorderConfirmDialog = (result: boolean) => {
    if (deletingRecorder && result) {
      dispatch(deleteRecorder(deletingRecorder)).then((e: any) => {
        if (e.error) {
          showHttpError(enqueueSnackbar, e.error, 'Deleting recorder failed')
        } else {
          enqueueSnackbar('Deleted recorder successfully', {
            variant: 'success',
          })

          if (location.pathname.startsWith(`/recorders/${deletingRecorder}`)) {
            navigate('/recorders', { replace: true })
          }
        }

        dispatch(setDeletingRecorder(undefined))
      })
    } else {
      dispatch(setDeletingRecorder(undefined))
    }
  }

  const onCloseDeleteDeviceConfirmDialog = (result: boolean) => {
    if (deletingDevice && result) {
      dispatch(deleteDevice(deletingDevice)).then((e: any) => {
        if (e.error) {
          showHttpError(enqueueSnackbar, e.error, 'Deleting device failed')
        } else {
          enqueueSnackbar('Deleted device successfully', {
            variant: 'success',
          })

          const match = /^\/recorders\/([\w-]+)\/devices\/([\w-]+)/.exec(
            location.pathname
          )
          if (match && match[2] === deletingDevice) {
            navigate(`/recorders/${match[1]}`, { replace: true })
          }
        }

        dispatch(setDeletingDevice(undefined))
      })
    } else {
      dispatch(setDeletingDevice(undefined))
    }
  }

  return (
    <>
      <div className="h-full w-full overflow-y-scroll py-10 px-15">
        <Routes>
          <Route path="/" element={<RecordersOverview />} />
          <Route path="/:recorderName/*" element={<RecorderView />} />
          <Route
            path="/:recorderName/devices/:deviceName"
            element={
              <VideoChannelsLoader>
                <DeviceView />
              </VideoChannelsLoader>
            }
          />
        </Routes>
      </div>

      <AddDevicesPanel />
      <EditDevicePanel />

      <ConfirmDialog
        open={!!deletingRecorder}
        title="Delete Recorder"
        content="This action will result in deleting the recorder permanently. Are you sure you want to delete this recorder?"
        onClose={onCloseDeleteRecorderConfirmDialog}
      />

      <ConfirmDialog
        open={!!deletingDevice}
        title="Delete Device"
        content="This action will result in deleting the device permanently. Are you sure you want to delete this device?"
        onClose={onCloseDeleteDeviceConfirmDialog}
      />
    </>
  )
}
