import { compare, Operation } from 'fast-json-patch'

export const wait = (msecs = 50) =>
  new Promise((resolve) => setTimeout(resolve, msecs))

export const compareSpecs = <T extends object>(
  before: T,
  after: T
): Operation[] => {
  return compare(before, after).map((diff) => {
    return {
      ...diff,
      path: '/spec' + diff.path,
    }
  })
}
