import i18next, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslation from './translations/en.json'
import fiTranslation from './translations/fi.json'
import brptTranslation from './translations/portuguese_brasilian.json'
export const resources = {
  en: {
    translation: enTranslation,
  },
  fi: {
    translation: fiTranslation,
  },
  brpt: {
    translation: brptTranslation,
  },
}

i18next
  // Enable automatic language detection
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Standard language used
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    resources,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ['localStorage'],
      cache: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  } as InitOptions)

export default i18next
