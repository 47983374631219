import { FC, ReactNode, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { ENDPOINTS } from '../constants'
import { UserProfile } from 'gql/graphql'
import { GET_CURRENT_USER } from 'lib/queries/auth'
import { COOKIE_ITEM, CookieService } from '../services/cookies'
import { useDispatch } from 'store'
import { setUser } from 'store/slices/auth'
import { hasPermission } from 'utils/helpers'

export interface AuthGuardProps {
  permissions: string[]
  children: ReactNode
}

export const AuthGuard: FC<AuthGuardProps> = ({ permissions, children }) => {
  const dispatch = useDispatch()
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = CookieService.get(COOKIE_ITEM.ACCESS_TOKEN)
    if (!token) {
      window.location.href = `${ENDPOINTS.SSO_AUTH_URL}/login?redirectTo=${window.location.href}`
      return
    }

    setLoading(true)
    apolloClient
      .query<{ getCurrentUser: UserProfile }>({
        query: GET_CURRENT_USER,
      })
      .then((res) => {
        const user = res.data.getCurrentUser
        if (!hasPermission(user, permissions)) {
          throw new Error('Unable to access to admin')
        }

        dispatch(
          setUser({
            ...user,
            email: (user.emails || '').split(',')[0],
          })
        )
        setLoading(false)
      })
      .catch(() => {
        window.location.href = `${ENDPOINTS.SSO_AUTH_URL}/login?redirectTo=${window.location.href}`
      })
  }, [])

  if (loading) {
    return (
      <div className="flex-center h-screen w-screen">
        <CircularProgress />
      </div>
    )
  }

  return <>{children}</>
}
