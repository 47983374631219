import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Card, CardContent, IconButton, TextField } from '@mui/material'
import { Search, DeleteOutline, EditOutlined } from '@mui/icons-material'
import noRolesIllustration from 'assets/img/illustrations/no-data.svg'
import noSearchedRolesIllustration from 'assets/img/illustrations/no-searched-data.svg'
import { Breadcrumb, RoleChip, Pagination } from 'common-lib/components'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import { Role } from 'gql/graphql'
import {
  showCreateRolePanel,
  setDeletingRole,
  setEditingRole,
} from 'store/slices/roles'
import { isVisibleRole } from 'utils/helpers'

const cardsPerPage = 9

export const RoleManager = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const breadcrumbs = [{ text: t('roleManager.breadcrumb') }]

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const roles = useTypedSelector((state) =>
    state.roles.roles.filter(isVisibleRole)
  )

  const filteredRoles = useMemo(() => {
    if (!search) {
      return roles
    }
    const keyword = search.trim().toLowerCase()
    return roles.filter(
      (role) =>
        role.name.toLowerCase().startsWith(keyword) ||
        role.description?.toLowerCase().includes(keyword)
    )
  }, [search, roles])

  const visibleCards = useMemo(() => {
    return filteredRoles.slice((page - 1) * cardsPerPage, page * cardsPerPage)
  }, [filteredRoles, page, cardsPerPage])

  useEffect(() => {
    if (!visibleCards.length && page > 1) {
      const newPage = Math.ceil(filteredRoles.length / cardsPerPage)
      setPage(Math.max(1, newPage))
    }
  }, [filteredRoles, visibleCards, page, cardsPerPage])

  const onCreateRole = () => {
    dispatch(showCreateRolePanel(true))
  }

  const onEditRole = (role: Role) => {
    dispatch(setEditingRole(role.name))
  }

  const onDeleteRole = (role: Role) => {
    dispatch(setDeletingRole(role.name))
  }

  const onSearchChange = (value: string) => {
    setSearch(value)
    setPage(1)
  }

  return (
    <>
      <Breadcrumb items={breadcrumbs} />

      <div className="mt-4 mb-6 flex items-start justify-between">
        <div>
          <h3>{t('roleManager.allRoles')}</h3>
          <p className="typo-body2 mt-2 text-secondary-400">
            {t('roleManager.description')}
          </p>
          <TextField
            className="mt-6 h-10 w-[343px]"
            name="search"
            placeholder="Search"
            InputProps={{ endAdornment: <Search className="mr-3" /> }}
            onChange={(e) => onSearchChange(e.target.value)}
            data-testid="search-filter"
          />
        </div>

        <Button
          className="ml-auto w-37"
          onClick={onCreateRole}
          data-testid="add-role-button"
        >
          {t('common.addNewRole')}
        </Button>
      </div>

      {!roles.length ? (
        <div
          className="flex flex-1 flex-col items-center py-30"
          data-testid="no-roles"
        >
          <img src={noRolesIllustration} alt="" />
          <p className="mt-7">{t('roleManager.noRoles')}</p>
          <Button
            className="mt-4 px-5"
            color="primary"
            variant="outlined"
            onClick={onCreateRole}
            data-testid="add-recorder-button"
          >
            {t('common.addNewRole')}
          </Button>
        </div>
      ) : !filteredRoles.length ? (
        <div
          className="flex flex-1 flex-col items-center py-30"
          data-testid="no-search-results"
        >
          <img src={noSearchedRolesIllustration} alt="" />
          <p className="mt-7">{t('roleManager.noSearch')}</p>
        </div>
      ) : (
        <>
          <div className="-mx-4 flex-1 overflow-y-auto px-4">
            <div className="grid grid-cols-onecard gap-4 pb-8 1lg:grid-cols-twocards 1xl:grid-cols-threecards">
              {visibleCards.map((role) => (
                <Link
                  key={role.name}
                  className="block"
                  to={`/user-management/roles/${role.name}`}
                >
                  <Card>
                    <CardContent className="flex h-52 flex-col p-6">
                      <div className="flex items-center gap-4">
                        <RoleChip role={role} className="cursor-pointer" />
                        <div className="ml-auto">
                          <IconButton
                            color="secondary"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault()
                              onEditRole(role)
                            }}
                            data-testid="edit-role"
                          >
                            <EditOutlined />
                          </IconButton>
                          {role.color && (
                            <IconButton
                              color="secondary"
                              size="small"
                              onClick={(e) => {
                                e.preventDefault()
                                onDeleteRole(role)
                              }}
                              data-testid="delete-role"
                            >
                              <DeleteOutline />
                            </IconButton>
                          )}
                        </div>
                      </div>

                      <div className="typo-body2 line-clamp-5 mt-6 mb-auto">
                        {role.description}
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              ))}
            </div>
          </div>

          {filteredRoles.length > cardsPerPage && (
            <Pagination
              className="mt-6"
              count={filteredRoles.length}
              page={page}
              rowsPerPage={cardsPerPage}
              showFirstLastButton
              onPageChange={setPage}
            />
          )}
        </>
      )}
    </>
  )
}
