import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { UsersOverview } from '../components/User/UsersOverview'
import { UserView } from '../components/User/UserView'
import { UserCreatePanel } from '../components/User/UserCreatePanel'
import { UserEditPanel } from '../components/User/UserEditPanel'
import { ConfirmDialog } from '../common-lib/components'
import { useDispatch, useSelector } from '../store'
import { deleteUser, setDeletingUser } from '../store/slices/users'
import { showHttpError } from '../utils/helpers'

export const UsersPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const deletingUser = useSelector((state) => state.users.deletingUser)

  const onCloseDeleteConfirmDialog = (result: boolean) => {
    if (deletingUser && result) {
      dispatch(deleteUser(deletingUser.metadata.name)).then((e: any) => {
        if (e.error) {
          showHttpError(enqueueSnackbar, e.error, 'Deleting user failed')
        } else {
          enqueueSnackbar('Deleted user successfully', { variant: 'success' })

          if (
            location.pathname.startsWith(
              `/user-management/users/${deletingUser.metadata.name}`
            )
          ) {
            navigate('/user-management/users', { replace: true })
          }
        }

        dispatch(setDeletingUser(undefined))
      })
    } else {
      dispatch(setDeletingUser(undefined))
    }
  }

  return (
    <>
      <div className="h-full w-full overflow-y-scroll py-10 px-15">
        <Routes>
          <Route path="/" element={<UsersOverview />} />
          <Route path="/:id" element={<UserView />} />
        </Routes>
      </div>

      <UserCreatePanel />
      <UserEditPanel />
      <ConfirmDialog
        open={!!deletingUser}
        title="Delete User"
        content="This action will result in deleting the user permanently. Are you sure you want to delete this user?"
        onClose={onCloseDeleteConfirmDialog}
      />
    </>
  )
}
