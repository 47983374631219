import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'common-lib/components'
import { ProfileDetail } from './ProfileDetail'
import { useSelector } from '../../store'
import { useQuery } from '@apollo/client'
import { GET_PROFILE } from 'lib/queries/profile'

export const ProfileView = () => {
  const { t } = useTranslation()
  const { profileName } = useParams()
  const { loading, error, data } = useQuery(GET_PROFILE, {
    variables: { getProfileId: profileName || '' },
  })

  const profile = useMemo(() => {
    if (!profileName) {
      return undefined
    }
    return data?.getProfile
  }, [profileName, data])

  const breadcrumbs = [
    {
      text: t('profiles.breadcrumb'),
      link: '/profiles',
    },
    { text: profile?.name || '-' },
  ]

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />

      {profile ? (
        <ProfileDetail className="mt-4" profile={profile} />
      ) : (
        <div className="mt-4 py-10 text-center" data-testid="profile-not-found">
          Profile Not Found
        </div>
      )}
    </div>
  )
}
