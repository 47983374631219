import { v4 as uuidv4 } from 'uuid'
import { AxiosRequestConfig } from 'axios'
import { RECORDER_API_VERSION, recorderClientApi } from './types'
import {
  VideoStream,
  VideoStreamSpec,
} from '../../common-api/clients/recorder/typescript'
import { compareSpecs } from '../../utils/helpers'

const patchOptions = {
  headers: {
    'Content-Type': 'application/json-patch+json', // ref: https://pkg.go.dev/k8s.io/apimachinery/pkg/types#PatchType
  },
} as AxiosRequestConfig
const fieldValidation = 'Strict'

const listVideoStreams = async (namespace: string) => {
  // TODO: Handle pagination
  return recorderClientApi.listNamespacedVideoStream(
    namespace,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '0'
  )
}

const createVideoStream = async (namespace: string, spec: VideoStreamSpec) => {
  const req: VideoStream = {
    apiVersion: RECORDER_API_VERSION,
    kind: 'VideoStream',
    metadata: {
      name: uuidv4(),
      namespace,
    },
    spec,
  }
  return recorderClientApi.createNamespacedVideoStream(
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    req
  )
}

const patchVideoStream = async (
  name: string,
  namespace: string,
  before: VideoStreamSpec,
  after: VideoStreamSpec
) => {
  return recorderClientApi.patchNamespacedVideoStream(
    name,
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    undefined,
    compareSpecs(before, after),
    patchOptions
  )
}

const deleteVideoStream = async (namespace: string, id: string) => {
  return recorderClientApi.deleteNamespacedVideoStream(id, namespace)
}

export {
  listVideoStreams,
  createVideoStream,
  patchVideoStream,
  deleteVideoStream,
}
