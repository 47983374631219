import classNames from 'classnames'
import { Recorder } from '../../common-api/clients/recorder/typescript'

export const isDahuaRecorder = (recorder?: Recorder) => {
  return recorder?.status?.info?.manufacturer === 'Dahua'
}

export const getStatusClass = (status: string, extraClass?: string) => {
  const statusClass: Record<string, string> = {
    Online: 'bg-primary-400',
    Pending: 'bg-warning-200',
    Error: 'bg-error-200',
    Offline: 'bg-neutral-500',
  }
  return classNames(
    'mr-3 h-4 w-4 shrink-0 rounded-full',
    statusClass[status] || 'bg-neutral-500',
    extraClass
  )
}
