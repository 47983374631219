import { FC, useEffect } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { MenuItem } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  VideoStream,
  VideoStreamSpec,
  VideoStreamSpecBitrateModeEnum,
  VideoStreamSpecCompressionEnum,
} from 'common-api/clients/recorder/typescript'
import { FormProvider, RHFSelect } from 'common-lib/components/RHFControls'

export interface VideoStreamDetailFormData {
  framerate: number
  resolution?: string
  bitrateMode?: VideoStreamSpecBitrateModeEnum
  bitrate?: number
  compression?: VideoStreamSpecCompressionEnum
}

export interface VideoStreamDetailFormRef {
  getFormData(): Promise<VideoStreamSpec | undefined>
}

interface VideoStreamDetailFormProps {
  className?: string
  videoStream?: VideoStream
  disabled?: boolean
  extraFormData?: Partial<VideoStreamSpec>
  formRef: VideoStreamDetailFormRef
  onValidate(isValid: boolean): void
  onFormUpdate?: (data: VideoStreamDetailFormData) => void
}

const schema = yup
  .object()
  .shape({
    framerate: yup.number().required('This field is required'),
    resolution: yup.string().required('This field is required'),
    bitrateMode: yup.string().required('This field is required'),
    bitrate: yup.string().required('This field is required'),
    compression: yup.string().required('This field is required'),
  })
  .required()

export const framerateOptions = [30, 60]
export const resolutionOptions = [
  { text: '1920 * 1080', width: 1920, height: 1080 },
  { text: '1280 * 960', width: 1280, height: 960 },
  { text: '1024 * 768', width: 1024, height: 768 },
]
export const bitrateModeOptions = Object.values(VideoStreamSpecBitrateModeEnum)
export const bitrateOptions = [
  { text: '0Kbps', value: 0 },
  { text: '5Kbps', value: 5000 },
  { text: '8Kbps', value: 8000 },
]
export const compressionOptions = Object.values(VideoStreamSpecCompressionEnum)

export const VideoStreamDetailForm: FC<VideoStreamDetailFormProps> = ({
  className,
  videoStream,
  disabled,
  extraFormData,
  formRef,
  onValidate,
  onFormUpdate,
}) => {
  const { t } = useTranslation()

  const methods = useForm<VideoStreamDetailFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      framerate: videoStream?.spec?.framerate || '',
      resolution: videoStream?.spec?.resolution
        ? `${videoStream?.spec?.resolution.width} * ${videoStream?.spec?.resolution.height}`
        : '',
      bitrateMode: videoStream?.spec?.bitrateMode || '',
      bitrate: videoStream?.spec?.bitrate || 0,
      compression: videoStream?.spec?.compression || '',
    } as any,
  })

  useEffect(() => {
    formRef.getFormData = async () => {
      const formData = await new Promise<VideoStreamDetailFormData | undefined>(
        (resolve) => {
          if (disabled) {
            resolve(methods.getValues())
            return
          }
          methods.handleSubmit(
            (data) => {
              resolve(data)
            },
            (err) => {
              console.log(err)
              resolve(undefined)
            }
          )()
        }
      )
      if (!formData) {
        return undefined
      }

      const resolution = resolutionOptions.find(
        (item) => item.text === formData!.resolution
      )
      return {
        ...extraFormData,
        framerate: formData.framerate,
        resolution: resolution
          ? { width: resolution.width, height: resolution.height }
          : undefined,
        bitrateMode: formData.bitrateMode,
        bitrate: formData.bitrate,
        compression: formData.compression,
      }
    }
  }, [formRef, extraFormData])

  useEffect(() => {
    if (videoStream) {
      methods.trigger()
    }
  }, [videoStream])

  const formData = methods.watch()

  useEffect(() => {
    onValidate(disabled || methods.formState.isValid)
  }, [formData, disabled, onValidate])

  useEffect(() => {
    if (onFormUpdate) {
      onFormUpdate(formData)
    }
  }, [formData, onFormUpdate])

  return (
    <FormProvider
      className={className}
      methods={methods}
      data-testid="videoStream-detail-form"
    >
      <RHFSelect
        name="framerate"
        label="Frame Rate"
        placeholder="Select"
        fullWidth
        disabled={disabled}
        data-testid="framerate-select"
      >
        {framerateOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option} FPS
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        className="mt-4"
        name="resolution"
        label="Resolution"
        placeholder="Select"
        fullWidth
        disabled={disabled}
        data-testid="resolution-select"
      >
        {resolutionOptions.map((option) => (
          <MenuItem key={option.text} value={option.text}>
            {option.text}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        className="mt-4"
        name="bitrateMode"
        label="Bitrate Mode"
        placeholder="Select"
        fullWidth
        disabled={disabled}
        data-testid="bitrate-mode-select"
      >
        {bitrateModeOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        className="mt-4"
        name="bitrate"
        label="Bitrate"
        placeholder="Select"
        fullWidth
        disabled={disabled}
        data-testid="bitrate-select"
      >
        {bitrateOptions.map((option) => (
          <MenuItem key={option.value} value={option.value.toString()}>
            {option.text}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        className="mt-4"
        name="compression"
        label="Compression"
        placeholder="Select"
        fullWidth
        disabled={disabled}
        data-testid="compression-select"
      >
        {compressionOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </RHFSelect>
    </FormProvider>
  )
}
