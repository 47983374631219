import { FC, ReactNode, useState } from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import { TableRow as MuiTableRow, TableCell, IconButton } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { TableColumn } from './types'

export interface TableRowProps<T = any> {
  className?: string
  columns: TableColumn<T>[]
  row: T
  rowId: number
  expandable?: boolean
  reverseRowColors?: boolean
  renderChildRow?: (row: T, index: number) => ReactNode
}

export const TableRow: FC<TableRowProps> = ({
  className,
  columns,
  row,
  rowId,
  expandable,
  reverseRowColors,
  renderChildRow,
}) => {
  const [opened, setOpened] = useState(false)

  const rowClass = classNames(
    `MuiTableRow-${(rowId + (reverseRowColors ? 1 : 0)) % 2 ? 'even' : 'odd'}`,
    className
  )

  return (
    <>
      <MuiTableRow className={rowClass} data-testid="table-row">
        {expandable && (
          <TableCell padding="checkbox" sx={{ pr: 0 }}>
            <IconButton size="small" onClick={() => setOpened(!opened)}>
              {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column, colId) => {
          let content
          if (column.render) {
            content = column.render(row, rowId)
          } else if (column.getText) {
            content = column.getText(row, rowId)
          } else if (column.field) {
            content = get(row, column.field)
          }
          return (
            <TableCell
              key={colId}
              className={column.cellClass}
              align={column.align}
            >
              {content}
            </TableCell>
          )
        })}
      </MuiTableRow>

      {expandable && opened && renderChildRow && (
        <MuiTableRow className={rowClass} data-testid="table-child-row">
          <TableCell padding="none" />
          <TableCell colSpan={columns.length - 1} sx={{ pl: 2, pb: 3 }}>
            {renderChildRow(row, rowId)}
          </TableCell>
          <TableCell padding="none" />
        </MuiTableRow>
      )}
    </>
  )
}
