import { ReactNode } from 'react'
import classNames from 'classnames'
import {
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Switch as MuiSwitch,
} from '@mui/material'

interface Props extends Omit<FormControlLabelProps, 'control' | 'label'> {
  label?: ReactNode
}

const AntSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    color: '#262626',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#262626',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#7FD456' : '#7FD456',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#7FD456',
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#262626',
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    color: '#262626',
    backgroundColor: theme.palette.mode === 'dark' ? '#D1D1D1' : '#D1D1D1',
    boxSizing: 'border-box',
  },
}))

export const Switch = ({ label = null, ...other }: Props) => {
  return (
    <FormControlLabel
      className={classNames(!label && 'm-0')}
      control={<AntSwitch />}
      label={label}
      {...other}
    />
  )
}
