import classNames from 'classnames'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
} from '@mui/material'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { SelectFilter } from './SelectFilter'
import { FilterModel, SortModel, TableColumn } from './types'

export interface TableHeaderProps {
  columns: TableColumn[]
  rows: any[]
  expandable?: boolean
  reverseRowColors?: boolean
  filter?: FilterModel
  sort?: SortModel
  onFilterChange?: (filter: FilterModel) => void
  onSort?: (column: TableColumn) => void
}

export const TableHeader = ({
  columns,
  rows,
  expandable,
  reverseRowColors,
  filter,
  sort,
  onFilterChange,
  onSort,
}: TableHeaderProps) => {
  const rowClass = classNames(
    reverseRowColors ? 'MuiTableRow-odd' : 'MuiTableRow-even'
  )
  const hasFilter = columns.some((column) => column.filter)

  const onSortColumn = (column: TableColumn) => {
    if (!column.sortable) {
      return
    }

    if (onSort) {
      onSort(column)
    }
  }

  const onFilterColumn = (column: TableColumn, value: string | string[]) => {
    if (onFilterChange) {
      onFilterChange({
        [column.field!]: value,
      })
    }
  }

  return (
    <TableHead>
      <TableRow className={rowClass}>
        {expandable && <TableCell padding="none" />}

        {columns.map((column, i) => {
          const {
            title,
            field,
            cellClass,
            filter,
            dataForm,
            sortable,
            render,
            getText,
            getFilterValue,
            ...cellProps
          } = column

          return (
            <TableCell key={i} {...cellProps}>
              <TableSortLabel
                className="pl-1"
                onClick={() => onSortColumn(column)}
              >
                {field &&
                  field === sort?.field &&
                  (sort?.dir === 'asc' ? (
                    <KeyboardArrowUp fontSize="small" />
                  ) : (
                    <KeyboardArrowDown fontSize="small" />
                  ))}
                <span className="ml-1">{title}</span>
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>

      {hasFilter && (
        <TableRow className={rowClass}>
          {expandable && <TableCell padding="none" />}
          {columns.map((column, i) => {
            if (!column.filter || !column.field) {
              return <TableCell key={i} />
            }
            const filterValue = (filter || {})[column.field]
            return (
              <TableCell key={i} className="pt-0">
                {column.filter === 'text' && (
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Search"
                    value={filterValue || ''}
                    onChange={(e) => onFilterColumn(column, e.target.value)}
                    data-testid={`table-${column.field}-filter`}
                  />
                )}
                {(column.filter === 'select' ||
                  column.filter === 'multiselect') && (
                  <SelectFilter
                    column={column}
                    rows={rows}
                    value={filterValue}
                    fullWidth
                    multiple={column.filter === 'multiselect'}
                    onChange={(value) => onFilterColumn(column, value)}
                    data-testid={`table-${column.field}-filter`}
                  />
                )}
              </TableCell>
            )
          })}
        </TableRow>
      )}
    </TableHead>
  )
}
