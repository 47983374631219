import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { VideoStream } from 'common-api/clients/recorder/typescript'
import {
  DataTable,
  DataTableProps,
  SortModel,
  TableColumn,
} from 'common-lib/components/Table'
import { useDispatch } from '../../store'
import { setEditingDevice } from 'store/slices/devices'

const DEFAULT_SORT: SortModel = { field: 'number', dir: 'asc' }
const ROWS_PER_PAGE = 10

interface VideoStreamsTableProps extends Partial<DataTableProps> {
  videoStreams: VideoStream[]
  canRemoveStream?: boolean
}

export const VideoStreamsTable: FC<VideoStreamsTableProps> = ({
  videoStreams,
  canRemoveStream,
  ...tableProps
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const columns: TableColumn[] = [
    {
      title: 'Stream Number',
      field: 'number',
      getText: (_, i) => `Stream ${i + 1}`,
      sortable: true,
    },
    {
      title: t('deviceView.frameRate'),
      field: 'framerate',
      getText: (row: VideoStream) =>
        row.spec.framerate ? `${row.spec.framerate} FPS` : '',
      sortable: true,
    },
    {
      title: t('deviceView.resolution'),
      field: 'resolution',
      getText: (row: VideoStream) =>
        row.spec.resolution?.width
          ? `${row.spec.resolution.width} * ${row.spec.resolution.height}`
          : '',
      sortable: true,
    },
    {
      title: t('deviceView.bitrateMode'),
      field: 'bitrateMode',
      getText: (row: VideoStream) => `${row.spec.bitrateMode}`,
      sortable: true,
    },
    {
      title: t('deviceView.bitrate'),
      field: 'bitrate',
      getText: (row: VideoStream) =>
        typeof row.spec.bitrate === 'number' ? `${row.spec.bitrate}Kbps` : '',
      sortable: true,
    },
    {
      title: 'Comp. Quality',
      field: 'compression',
      sortable: true,
    },
    {
      title: '',
      align: 'right',
      render: (row: VideoStream) => {
        return (
          <div className="whitespace-nowrap">
            <IconButton
              onClick={() => onEditStream(row)}
              data-testid="edit-stream-button"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {canRemoveStream && (
              <IconButton
                // onClick={() => onDeleteStream(row.id)}
                data-testid="delete-stream-button"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )
      },
    },
  ]

  const onEditStream = (row: VideoStream) => {
    if (row.status?.deviceRef) {
      dispatch(
        setEditingDevice({
          deviceName: row.status.deviceRef,
          channelName: row.status.channelRef,
          streamName: row.metadata.name,
        })
      )
    }
  }

  return (
    <DataTable
      columns={columns}
      rows={videoStreams}
      defaultSort={DEFAULT_SORT}
      rowsPerPage={ROWS_PER_PAGE}
      paginatorClass="mt-6"
      autoPaginate
      showFirstLastPaginateButton
      hideTableWhenNoData
      data-testid="video-streams-table"
      {...tableProps}
    />
  )
}
