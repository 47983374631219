import { API_SERVER_URL } from 'common-lib/constants/endpoints'
import * as SystemClient from 'common-api/clients/system/typescript'
import * as RecorderClient from 'common-api/clients/recorder/typescript'

export const SYSTEM_GROUP_NAME = 'system.mirasys.com'
export const RECORDER_GROUP_NAME = 'recorder.mirasys.com'
export const GROUP_VERSION = 'v1alpha1'
export const SYSTEM_API_VERSION = SYSTEM_GROUP_NAME + '/' + GROUP_VERSION
export const RECORDER_API_VERSION = RECORDER_GROUP_NAME + '/' + GROUP_VERSION
export const SYSTEM_API_PATH = '/apis/' + SYSTEM_API_VERSION
export const RECORDER_API_PATH = '/apis/' + RECORDER_API_VERSION
/*
  TODO: The namespace should be dynamic, and the same as the realm.
  For example, if one is accessing spotter admin in organization test, they
  go to URL test.spotter-admin.dev.mirasys.com, and this code should extract the
  "test" string and use it as the realm. We need to be very careful when extracting
  this string, and validate before using it to be a conformant DNS_LABEL.

  Check out https://mirasys.visualstudio.com/ADV/_git/SpotterWeb-ts/pullrequest/491
  and put that code into the common library where you can get the realm dynamically
*/
export const todoChangeMeNamespace = 'test-organization'

export const systemClientConfig = new SystemClient.Configuration({
  basePath: API_SERVER_URL,
  baseOptions: {
    headers: { Authorization: '' },
  },
})
export const systemClientApi = new SystemClient.Api(systemClientConfig)

export const recorderClientConfig = new RecorderClient.Configuration({
  basePath: API_SERVER_URL,
  baseOptions: {
    headers: { Authorization: '' },
  },
})
export const recorderClientApi = new RecorderClient.Api(recorderClientConfig)
