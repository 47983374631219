import { FC, MouseEventHandler, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Device } from 'common-api/clients/recorder/typescript'
import {
  DeviceDetailForm,
  DeviceDetailFormData,
  DeviceDetailFormRef,
} from './DeviceDetailForm'

interface DeviceDetailFormAccordionProps {
  className?: string
  device?: Device
  index: number
  expanded?: boolean
  removable?: boolean
  formRef: DeviceDetailFormRef
  onToggleExpand(): void
  onDelete?: () => void
  onValidate(id: number, isValid: boolean): void
}

export const DeviceDetailFormAccordion: FC<DeviceDetailFormAccordionProps> = ({
  className,
  index,
  expanded,
  removable,
  onToggleExpand,
  onDelete,
  onValidate,
  ...formProps
}) => {
  const [deviceName, setDeviceName] = useState(`Device ${index}`)

  const onFormUpdate = useCallback(
    (data: DeviceDetailFormData) => {
      setDeviceName(data.deviceName || `Device ${index}`)
    },
    [index]
  )

  const onFormValidate = useCallback(
    (isValid: boolean) => {
      onValidate(index, isValid)
    },
    [index, onValidate]
  )

  const onDeleteForm: MouseEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onDelete) {
      onDelete()
    }
  }

  return (
    <Accordion
      className={className}
      expanded={expanded}
      onChange={onToggleExpand}
    >
      <AccordionSummary classes={{ content: 'items-center' }}>
        <h4>{deviceName}</h4>
        {removable && (
          <IconButton
            className="group ml-auto"
            onClick={onDeleteForm}
            data-testid="delete-device"
          >
            <DeleteOutlineIcon className="group-hover:hidden" />
            <DeleteIcon className="hidden group-hover:block" />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <DeviceDetailForm
          {...formProps}
          onFormUpdate={onFormUpdate}
          onValidate={onFormValidate}
        />
      </AccordionDetails>
    </Accordion>
  )
}
