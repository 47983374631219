import { FC, MouseEventHandler, useRef } from 'react'
import { RoleChip } from 'common-lib/components'
import { Role } from 'gql/graphql'

interface ScrollableRolesProps {
  roles: Role[]
}

export const ScrollableRoles: FC<ScrollableRolesProps> = ({ roles }) => {
  const divRef = useRef<HTMLDivElement>(null)

  const handleMouseDown: MouseEventHandler = (event) => {
    event.preventDefault()
    if (divRef.current) {
      divRef.current.style.cursor = 'grabbing'
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (divRef.current) {
      const scrollSpeed = 5 // Adjust scroll speed as needed
      divRef.current.scrollLeft -= event.movementX * scrollSpeed
    }
  }

  const handleMouseUp = () => {
    if (divRef.current) {
      divRef.current.style.cursor = 'grab'
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }

  const handleWheel = (event: WheelEvent) => {
    const targetElement = event.currentTarget as HTMLDivElement
    targetElement.scrollLeft += event.deltaY
  }

  const handleMouseEnter = () => {
    const targetElement = divRef.current
    if (targetElement) {
      targetElement.addEventListener('wheel', handleWheel)
    }
  }

  const handleMouseLeave = () => {
    const targetElement = divRef.current
    if (targetElement) {
      targetElement.removeEventListener('wheel', handleWheel)
    }
  }

  return (
    <div
      className="slider scrollable mt-3 flex w-full cursor-pointer flex-row gap-2 overflow-x-auto whitespace-nowrap pr-6"
      ref={divRef}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        e.preventDefault()
      }}
      data-testid="scroll-container"
    >
      {roles.length === 0 ? (
        <div className="mt-1 mb-1">
          <p className="typo-body2 leading-6 text-neutral-300">
            No roles associated with this profile
          </p>
        </div>
      ) : (
        roles.map((role) => <RoleChip key={role.name} role={role} />)
      )}
    </div>
  )
}
