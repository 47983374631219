import { get } from 'lodash'
import { SortDirection } from '@mui/material/TableCell/TableCell'
import { TableColumn } from './types'

export const getFieldValue = <T = any>(column: TableColumn<T>, row: T) => {
  if (column.getText) {
    return column.getText(row, 0)
  }
  if (column.field) {
    return get(row, column.field)
  }
  return undefined
}

export const compareValue = (a: any, b: any) => {
  const isNull1 = a === undefined || a === null
  const isNull2 = b === undefined || b === null
  if (isNull1 && isNull2) {
    return 0
  }
  if (isNull1) {
    return -1
  }
  if (isNull2) {
    return 1
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b
  }
  return a.toString().localeCompare(b.toString())
}

export const sortTableRows = <T = any>(
  rows: T[],
  column?: TableColumn,
  dir?: SortDirection
) => {
  if (!column || !dir) {
    return rows
  }

  return [...rows].sort((a, b) => {
    const value1 = getFieldValue(column, a)
    const value2 = getFieldValue(column, b)
    return compareValue(value1, value2) * (dir === 'asc' ? 1 : -1)
  })
}

export const paginateRows = <T = any>(
  rows: T[],
  page: number,
  rowsPerPage: number
) => {
  return rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)
}

export const isEmptyFilter = (value: any) => {
  if (Array.isArray(value)) {
    return !value.length
  }
  return value === undefined || value === null || value === ''
}
