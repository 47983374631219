import React, { FC, useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import WarningIcon from '@mui/icons-material/Warning'
import {
  VideoChannel,
  VideoChannelSpec,
  VideoStream,
  VideoStreamSpec,
} from 'common-api/clients/recorder/typescript'
import { Tooltip } from 'common-lib/components'
import {
  FormProvider,
  RHFTextarea,
  RHFTextField,
} from 'common-lib/components/RHFControls'
import { VideoStreamDetailFormRef } from './VideoStreamDetailForm'
import { VideoStreamDetailFormAccordion } from './VideoStreamDetailFormAccordion'
import { useSelector } from '../../store'
import { EditingDeviceInfo } from 'store/slices/devices'

export interface VideoChannelSpecFormData {
  displayName: string
  description?: string
}

export interface VideoChannelDetailFormData {
  spec: VideoChannelSpec
  streams: VideoStreamSpec[]
}

export interface StreamForm {
  id: number
  stream?: VideoStream
  expanded?: boolean
  isValid?: boolean
  formRef: VideoStreamDetailFormRef
}

export interface VideoChannelDetailFormRef {
  getFormData(): Promise<VideoChannelDetailFormData | undefined>
}

interface VideoChannelDetailFormProps {
  className?: string
  videoChannel?: VideoChannel
  disabled?: boolean
  canAddRemoveStreams?: boolean
  extraFormData?: Partial<VideoChannelSpec>
  editingDeviceInfo?: EditingDeviceInfo
  formRef: VideoChannelDetailFormRef
  onValidate(isValid: boolean): void
  onFormUpdate?: (data: VideoChannelSpecFormData) => void
}

const schema = yup
  .object()
  .shape({
    displayName: yup.string().required('This field is required'),
    description: yup.string(),
  })
  .required()

export const VideoChannelDetailForm: FC<VideoChannelDetailFormProps> = ({
  className,
  videoChannel,
  disabled,
  canAddRemoveStreams,
  extraFormData,
  editingDeviceInfo,
  formRef,
  onValidate,
  onFormUpdate,
}) => {
  const { t } = useTranslation()
  const streams = useSelector((state) => {
    const { videoStreams } = state.videoStreams
    return Object.values(videoStreams.byName).filter(
      (item) => item.status?.channelRef === videoChannel?.metadata?.name
    )
  })
  const [maxId, setMaxId] = useState(streams.length)
  const [streamForms, setStreamForms] = useState<StreamForm[]>(
    streams.map((item, i) => ({
      id: i + 1,
      stream: item,
      expanded: editingDeviceInfo?.streamName
        ? item.metadata.name === editingDeviceInfo?.streamName
        : item.spec.enabled,
      formRef: {
        getFormData: async () => undefined,
      },
    }))
  )

  const maxStreams = videoChannel?.status?.capabilities?.streams?.max
  const canAddMoreStream =
    !disabled && (maxStreams === undefined || maxStreams > streamForms.length)

  const methods = useForm<VideoChannelSpecFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: videoChannel?.spec?.displayName || '',
      description: videoChannel?.spec?.description || '',
    },
  })

  useEffect(() => {
    formRef.getFormData = async () => {
      const specFormData = await new Promise<
        VideoChannelSpecFormData | undefined
      >((resolve) => {
        if (disabled) {
          resolve(methods.getValues())
          return
        }
        methods.handleSubmit(
          (data) => {
            resolve(data)
          },
          (err) => {
            console.log(err)
            resolve(undefined)
          }
        )()
      })
      if (!specFormData) {
        return undefined
      }

      const invalidFormIds: number[] = []
      const streams = await Promise.all(
        streamForms.map((form) =>
          form.formRef?.getFormData().then((data) => {
            if (!data) {
              invalidFormIds.push(form.id)
            }
            return data
          })
        )
      )
      if (invalidFormIds.length) {
        setStreamForms((prev) =>
          prev.map((item) => ({
            ...item,
            expanded: invalidFormIds.includes(item.id),
          }))
        )
        return undefined
      }

      return {
        spec: {
          ...extraFormData,
          displayName: specFormData.displayName,
          description: specFormData.description,
        },
        streams: streams as VideoStreamSpec[],
      }
    }
  }, [formRef, extraFormData])

  useEffect(() => {
    if (videoChannel) {
      methods.trigger()
    }
  }, [videoChannel])

  const formData = methods.watch()

  useEffect(() => {
    if (disabled) {
      onValidate(true)
      return
    }
    onValidate(
      methods.formState.isValid && streamForms.every((item) => item.isValid)
    )
  }, [formData, streamForms, disabled, onValidate])

  useEffect(() => {
    if (onFormUpdate) {
      onFormUpdate(formData)
    }
  }, [formData, onFormUpdate])

  const onToggleExpandForm = (id: number) => {
    setStreamForms((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, expanded: !item.expanded } : item
      )
    )
  }

  const onFormValidate = useCallback((id: number, isValid: boolean) => {
    setStreamForms((prev) =>
      prev.map((item) => (item.id === id ? { ...item, isValid } : item))
    )
  }, [])

  const onAddForm = useCallback(() => {
    const newStreamForm = {
      id: maxId + 1,
      formRef: {
        getFormData: async () => undefined,
      },
      expanded: true,
    }
    setMaxId(maxId + 1)
    setStreamForms((forms) => [...forms, newStreamForm])
  }, [maxId])

  const onDeleteForm = useCallback(
    (id: number) => {
      setStreamForms((prev) => prev.filter((item) => item.id !== id))
    },
    [maxId]
  )

  return (
    <FormProvider
      className={className}
      methods={methods}
      data-testid="videoChannel-detail-form"
    >
      <RHFTextField
        name="displayName"
        label="Channel Name"
        placeholder="Camera Exit 1"
        disabled={disabled}
        data-testid="display-name-input"
      />
      <RHFTextarea
        className="mt-4"
        name="description"
        label="Description"
        minRows={2}
        disabled={disabled}
        data-testid="description-input"
      />

      {streamForms.map((form) => (
        <VideoStreamDetailFormAccordion
          key={form.id}
          className="mt-4"
          index={form.id}
          videoStream={form.stream}
          formRef={form.formRef}
          expanded={form.expanded}
          disabled={disabled}
          removable={canAddRemoveStreams}
          onDelete={() => onDeleteForm(form.id)}
          onToggleExpand={() => onToggleExpandForm(form.id)}
          onValidate={onFormValidate}
        />
      ))}

      {canAddRemoveStreams && (
        <Tooltip
          title="Maximum number of streams reached on this channel."
          arrow
          severity="info"
          icon={<WarningIcon fontSize="small" />}
          placement="top-start"
          disabled={canAddMoreStream}
        >
          <span>
            <Button
              className="mt-4"
              color="primary"
              variant="text"
              disabled={!canAddMoreStream}
              onClick={onAddForm}
            >
              <AddIcon /> Add Stream
            </Button>
          </span>
        </Tooltip>
      )}
    </FormProvider>
  )
}
