import { useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Panel } from '../Panel'
import { EditDeviceForm, EditDeviceFormRef } from './EditDeviceForm'
import { useDispatch, useSelector } from '../../store'
import { setEditingDevice, updateDevice } from 'store/slices/devices'
import { showHttpError } from 'utils/helpers'

export const EditDevicePanel = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const formRef = useRef<EditDeviceFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { editingDevice } = useSelector((state) => state.devices)
  const device = useSelector((state) =>
    editingDevice
      ? state.devices.devices.byName[editingDevice.deviceName]
      : undefined
  )
  const [isValid, setIsValid] = useState(false)

  const onSubmit = async () => {
    if (!device) {
      return
    }

    const data = await formRef.current.getFormData()
    if (!data) {
      return
    }

    dispatch(
      updateDevice({
        name: device.metadata.name,
        before: device.spec,
        after: data.spec,
      })
    ).then((e: any) => {
      if (e.error) {
        showHttpError(enqueueSnackbar, e.error, 'Updating device failed')
      } else {
        enqueueSnackbar('Your changes are saved successfully', {
          variant: 'success',
        })
        onClose()
      }
    })
  }

  const onClose = () => {
    dispatch(setEditingDevice(undefined))
  }

  return (
    <Panel
      title="Edit Device"
      submitButtonText="Save Changes"
      open={!!device}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
      isValid={isValid}
    >
      {device && (
        <EditDeviceForm
          device={device}
          editingDeviceInfo={editingDevice}
          formRef={formRef}
          onValidate={setIsValid}
          data-testid="edit-device-form"
        />
      )}
    </Panel>
  )
}
