import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import WarningIcon from '@mui/icons-material/Warning'
import { VideoChannel, Device } from 'common-api/clients/recorder/typescript'
import { Tooltip } from 'common-lib/components'
import {
  VideoChannelDetailFormData,
  VideoChannelDetailFormRef,
} from './VideoChannelDetailForm'
import { VideoChannelDetailFormAccordion } from './VideoChannelDetailFormAccordion'
import { useSelector } from '../../store'
import { EditingDeviceInfo } from '../../store/slices/devices'

export interface VideoChannelsFormRef {
  getFormData(): Promise<VideoChannelDetailFormData[] | undefined>
}

interface VideoChannelsFormProps {
  className?: string
  device: Device
  editingDeviceInfo?: EditingDeviceInfo
  disabled?: boolean
  formRef: VideoChannelsFormRef
  onValidate(isValid: boolean): void
}

export interface ChannelForm {
  id: number
  channel?: VideoChannel
  isValid?: boolean
  formRef: VideoChannelDetailFormRef
}

export const VideoChannelsForm: FC<VideoChannelsFormProps> = ({
  className,
  device,
  editingDeviceInfo,
  disabled,
  formRef,
  onValidate,
}) => {
  const { t } = useTranslation()
  const recorder = useSelector((state) => {
    const { recorders } = state.recorders
    return Object.values(recorders.byName).find(
      (item) => item.metadata.name === device?.status?.recorderRef
    )
  })
  const channels = useSelector((state) => {
    const { videoChannels } = state.videoChannels
    return Object.values(videoChannels.byName).filter(
      (item) => item.status?.deviceRef === device?.metadata?.name
    )
  })

  const [maxId, setMaxId] = useState(channels.length)
  const [videoChannelForms, setVideoChannelForms] = useState<ChannelForm[]>(
    () => {
      return channels.map((item, i) => ({
        id: i + 1,
        channel: item,
        formRef: {
          getFormData: async () => undefined,
        },
      }))
    }
  )
  const [openedId, setOpenedId] = useState<number | undefined>(() => {
    const index = channels.findIndex(
      (item) => item.metadata.name === editingDeviceInfo?.channelName
    )
    return index === -1 ? 1 : index + 1
  })

  const maxVideoChannels =
    device?.status?.capabilities?.channelCapacity?.maxVideoChannels || 0
  const canAddMoreDevice =
    !disabled && maxVideoChannels > videoChannelForms.length
  const canAddRemoveChannels =
    recorder?.status?.capabilities?.canAddRemoveChannels

  const onAddVideoChannel = useCallback(() => {
    const newVideoChannelForm = {
      id: maxId + 1,
      formRef: {
        getFormData: async () => undefined,
      },
    }
    setMaxId(maxId + 1)
    setVideoChannelForms((forms) => [...forms, newVideoChannelForm])
    setOpenedId(newVideoChannelForm.id)
  }, [maxId])

  useEffect(() => {
    if (formRef) {
      formRef.getFormData = () => {
        return Promise.all(
          videoChannelForms.map((form) =>
            form.formRef?.getFormData().then((data) => ({ id: form.id, data }))
          )
        ).then((forms) => {
          for (const form of forms) {
            if (!form?.data) {
              setOpenedId(form?.id)
              return undefined
            }
          }
          return forms.map((form) => form!.data as VideoChannelDetailFormData)
        })
      }
    }
  }, [formRef, videoChannelForms])

  useEffect(() => {
    onValidate(disabled || videoChannelForms.every((item) => item.isValid))
  }, [disabled, videoChannelForms, onValidate])

  const onFormValidate = useCallback((id: number, isValid: boolean) => {
    setVideoChannelForms((prev) =>
      prev.map((item) => (item.id === id ? { ...item, isValid } : item))
    )
  }, [])

  const onToggleExpandForm = (id: number) => {
    setOpenedId((prev) => (prev === id ? undefined : id))
  }

  const onDeleteForm = (id: number) => {
    if (!canAddRemoveChannels) {
      return
    }
    setVideoChannelForms((prev) => prev.filter((item) => item.id !== id))
  }

  return (
    <div className={className}>
      {videoChannelForms.map((form) => (
        <VideoChannelDetailFormAccordion
          key={form.id}
          className="mb-6"
          index={form.id}
          videoChannel={form.channel}
          formRef={form.formRef}
          editingDeviceInfo={editingDeviceInfo}
          expanded={openedId === form.id}
          disabled={disabled}
          removable={canAddRemoveChannels}
          canAddRemoveStreams={
            recorder?.status?.capabilities?.canAddRemoveStreams
          }
          onToggleExpand={() => onToggleExpandForm(form.id)}
          onDelete={() => onDeleteForm(form.id)}
          onValidate={onFormValidate}
        />
      ))}
      {canAddRemoveChannels && (
        <Tooltip
          title="Maximum number of channels reached on this recorder."
          arrow
          severity="info"
          icon={<WarningIcon fontSize="small" />}
          placement="top-start"
          disabled={canAddMoreDevice}
        >
          <span>
            <Button
              color="primary"
              variant="text"
              disabled={!canAddMoreDevice}
              onClick={onAddVideoChannel}
            >
              <AddIcon /> Add Channel
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  )
}
