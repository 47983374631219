import { FC, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

export interface PanelSectionProps extends PropsWithChildren {
  title?: ReactNode
  className?: string
  containerClass?: string
}

export const PanelSection: FC<PanelSectionProps> = ({
  title,
  children,
  className,
  containerClass,
}) => {
  return (
    <div className={classNames('rounded px-10 text-white', containerClass)}>
      {title && <h3 className="mb-4">{title}</h3>}
      <div className={className}>{children}</div>
    </div>
  )
}
