import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { DeviceSpec, Recorder } from 'common-api/clients/recorder/typescript'
import { DeviceDetailFormRef } from './DeviceDetailForm'
import { DeviceDetailFormAccordion } from './DeviceDetailFormAccordion'
import { isDahuaRecorder } from '../../utils/helpers'

export interface AddDevicesFormRef {
  getFormData(): Promise<DeviceSpec[] | undefined>
}

interface AddDevicesFormProps {
  className?: string
  recorder: Recorder
  formRef: MutableRefObject<AddDevicesFormRef>
  onValidate(isValid: boolean): void
}

interface DeviceForm {
  id: number
  formRef: DeviceDetailFormRef
  isValid?: boolean
}

export const AddDevicesForm: FC<AddDevicesFormProps> = ({
  className,
  recorder,
  formRef,
  onValidate,
}) => {
  const { t } = useTranslation()
  const [maxId, setMaxId] = useState(1)
  const [openedId, setOpenedId] = useState<number | undefined>(1)
  const [deviceForms, setDeviceForms] = useState<DeviceForm[]>([
    {
      id: 1,
      formRef: {
        getFormData: async () => undefined,
      },
    },
  ])
  const [showAlert, setShowAlert] = useState(isDahuaRecorder(recorder))

  const onAddDevice = useCallback(() => {
    const newDeviceForm = {
      id: maxId + 1,
      formRef: {
        getFormData: async () => undefined,
      },
    }
    setMaxId(maxId + 1)
    setDeviceForms((forms) => [...forms, newDeviceForm])
    setOpenedId(newDeviceForm.id)
  }, [maxId])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = () => {
        return Promise.all(
          deviceForms.map((form) =>
            form.formRef?.getFormData().then((data) => ({ id: form.id, data }))
          )
        ).then((forms) => {
          for (const form of forms) {
            if (!form?.data) {
              setOpenedId(form?.id)
              return undefined
            }
          }
          return forms.map((form) => form!.data as DeviceSpec)
        })
      }
    }
  }, [formRef, deviceForms])

  const onFormValidate = useCallback(
    (id: number, isValid: boolean) => {
      const form = deviceForms.find((item) => item.id === id)
      if (form) {
        form.isValid = isValid
        onValidate(deviceForms.every((item) => item.isValid))
      }
    },
    [deviceForms]
  )

  const onToggleExpandForm = useCallback((id: number) => {
    setOpenedId((prev) => (prev === id ? undefined : id))
  }, [])

  const onDeleteForm = useCallback((id: number) => {
    setDeviceForms((prev) => prev.filter((item) => item.id !== id))
    setOpenedId((prev) => (prev === id ? undefined : prev))
  }, [])

  return (
    <div className={classNames('px-10 py-8', className)}>
      {showAlert && (
        <Alert
          className="mb-6"
          severity="info"
          variant="filled"
          onClose={() => setShowAlert(false)}
        >
          When adding a device to a Dahua recorder, one channel is added
          automatically. You can change this later when you’re editing the
          device.
        </Alert>
      )}

      {deviceForms.map((form) => (
        <DeviceDetailFormAccordion
          key={form.id}
          className="mb-6"
          index={form.id}
          formRef={form.formRef}
          expanded={openedId === form.id}
          removable={deviceForms.length > 1}
          onToggleExpand={() => onToggleExpandForm(form.id)}
          onDelete={() => onDeleteForm(form.id)}
          onValidate={onFormValidate}
        />
      ))}

      <Button color="primary" variant="text" onClick={onAddDevice}>
        <AddIcon /> Add Another Device
      </Button>
    </div>
  )
}
