import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { UserDetail } from './UserDetail'
import { Breadcrumb } from '../../common-lib/components'
import { useSelector } from '../../store'
import { useTranslation } from 'react-i18next'

export const UserView = () => {
  const { id } = useParams()
  const users = useSelector((store) => store.users.users)
  const { t } = useTranslation()
  const user = useMemo(() => {
    if (!id) {
      return undefined
    }
    return users.byName[id]
  }, [id, users])

  const breadcrumbs = [
    { text: t('users.breadcrumb'), link: '/user-management/users' },
    { text: user ? `${user.spec.firstName} ${user.spec.lastName}` : '-' },
  ]

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />

      {user ? (
        <UserDetail className="mt-4" user={user} />
      ) : (
        <div className="mt-4 py-10 text-center">User Not Found</div>
      )}
    </div>
  )
}
