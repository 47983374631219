import { v4 as uuidv4 } from 'uuid'
import { AxiosRequestConfig } from 'axios'
import { RECORDER_API_VERSION, recorderClientApi } from './types'
import {
  Recorder,
  RecorderSpec,
} from '../../common-api/clients/recorder/typescript'
import { compareSpecs } from '../../utils/helpers'

const patchOptions = {
  headers: {
    'Content-Type': 'application/json-patch+json', // ref: https://pkg.go.dev/k8s.io/apimachinery/pkg/types#PatchType
  },
} as AxiosRequestConfig
const fieldValidation = 'Strict'

const listRecorders = async (namespace: string) => {
  // TODO: Handle pagination
  return recorderClientApi.listNamespacedRecorder(
    namespace,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '0'
  )
}

const createRecorder = async (namespace: string, spec: RecorderSpec) => {
  const req: Recorder = {
    apiVersion: RECORDER_API_VERSION,
    kind: 'Recorder',
    metadata: {
      name: uuidv4(),
      namespace,
    },
    spec,
  }
  return recorderClientApi.createNamespacedRecorder(
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    req
  )
}

const patchRecorder = async (
  name: string,
  namespace: string,
  before: RecorderSpec,
  after: RecorderSpec
) => {
  return recorderClientApi.patchNamespacedRecorder(
    name,
    namespace,
    undefined,
    undefined,
    undefined,
    fieldValidation,
    undefined,
    compareSpecs(before, after),
    patchOptions
  )
}

const deleteRecorder = async (namespace: string, id: string) => {
  return recorderClientApi.deleteNamespacedRecorder(id, namespace)
}

export { listRecorders, createRecorder, patchRecorder, deleteRecorder }
