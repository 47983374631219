import { gql } from '@apollo/client'

export const LIST_ROLES = gql`
  query ListRoles {
    listRoles {
      id
      name
      description
      color
      users {
        id
      }
      permissions {
        id
        name
      }
    }
  }
`
