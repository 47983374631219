import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from 'common-lib/components'
import { DeviceDetail } from './DeviceDetail'
import { useSelector } from '../../store'

export const DeviceView = () => {
  const { t } = useTranslation()
  const { recorderName, deviceName } = useParams()

  const device = useSelector(
    (state) => state.devices.devices.byName[deviceName!]
  )
  const recorder = useSelector(
    (state) => state.recorders.recorders.byName[recorderName!]
  )

  const breadcrumbs = [
    {
      text: `${recorder?.spec?.displayName || recorderName}`,
      link: `/recorders/${recorderName}`,
    },
    { text: device ? `${device.spec.displayName}` : '-' },
  ]

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />

      {device ? (
        <DeviceDetail device={device} />
      ) : (
        <div
          className="mt-4 py-10 text-center"
          data-testid="device-undefined-div"
        >
          {t('deviceView.deviceNotFound')}
        </div>
      )}
    </div>
  )
}
