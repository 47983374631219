import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Panel } from '../Panel'
import { UserForm, UserFormRef } from './UserForm'
import { useDispatch, useSelector } from '../../store'
import { setEditingUser, updateUser } from 'store/slices/users'
import { RoleSpec } from 'common-api/clients/system/typescript'
// import { updateRole } from 'store/slices/roles'
import { showHttpError } from 'utils/helpers'

export const UserEditPanel = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef<UserFormRef>({
    getFormData: () => Promise.resolve(undefined),
  })
  const { editingUser } = useSelector((state) => state.users)
  const [isValid, setIsValid] = useState(false)

  const onSubmit = async () => {
    if (!editingUser) {
      return
    }

    const data = await formRef.current.getFormData()
    if (data) {
      const { permissions, oldRolePermission, ...newUser } = data

      dispatch(updateUser(newUser)).then((e: any) => {
        if (e.error) {
          showHttpError(enqueueSnackbar, e.error, 'Updating user failed')
        } else {
          enqueueSnackbar('User updated successfully', {
            variant: 'success',
          })
          onClose()
        }
      })

      if (
        JSON.stringify(permissions) !== JSON.stringify(oldRolePermission) &&
        permissions
      ) {
        // const {
        //   apiPermissions,
        //   uiPermissions,
        //   applications,
        //   deviceAccessRefs,
        // } = createPermissionsArrays(permissions)
        // const info = {
        //   name: oldRolePermission?.name || `user-${editingUser.metadata.name}`,
        //   color: oldRolePermission?.color || 'gray',
        //   description: oldRolePermission?.description,
        // }
        // const spec: RoleSpec = {
        //   ...info,
        //   apiPermissions,
        //   uiPermissions,
        //   applications,
        //   deviceAccessRefs,
        // }
        // dispatch(
        //   updateRole({
        //     name: info.name,
        //     before: oldRolePermission?.spec,
        //     after: spec,
        //   })
        // ).then((e: any) => {
        //   if (e.error) {
        //     showHttpError(enqueueSnackbar, e.error, 'Updating role failed')
        //   } else {
        //     enqueueSnackbar('Your changes are saved successfully', {
        //       variant: 'success',
        //     })
        //     onClose()
        //   }
        // })
      }
    }
  }

  const onClose = () => {
    dispatch(setEditingUser(undefined))
  }

  return (
    <Panel
      open={!!editingUser}
      title={t('userEditView.edit') as string}
      submitButtonText={t('roleEdit.save') as string}
      isValid={isValid}
      disableBackdrop
      onSubmit={onSubmit}
      onClose={onClose}
    >
      {editingUser && (
        <UserForm
          key={editingUser.metadata.name}
          user={editingUser}
          formRef={formRef}
          onValidate={setIsValid}
        />
      )}
    </Panel>
  )
}
