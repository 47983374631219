import { useFormContext, Controller } from 'react-hook-form'
import { TextareaAutosize, TextField, TextFieldProps } from '@mui/material'

type Props = TextFieldProps & {
  name: string
}

export const RHFTextarea = ({ name, helperText, ...other }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              minRows: other.minRows,
              maxRows: other.maxRows,
            },
          }}
          sx={{
            '.MuiInputBase-root': {
              height: 'auto',
            },
          }}
          {...other}
        />
      )}
    />
  )
}
