import { FC, useEffect } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { MenuItem } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Device,
  DeviceSpec,
  DeviceSpecConnectionDetailsProtocolEnum,
} from 'common-api/clients/recorder/typescript'
import {
  FormProvider,
  RHFAutocomplete,
  RHFSelect,
  RHFTextarea,
  RHFTextField,
} from 'common-lib/components/RHFControls'

export interface DeviceDetailFormData {
  deviceName: string
  description?: string
  address?: string
  port?: number
  username?: string
  password?: string
  protocol?: DeviceSpecConnectionDetailsProtocolEnum
  driverName: string
}

export interface DeviceDetailFormRef {
  getFormData(): Promise<DeviceSpec | undefined>
}

interface DeviceDetailFormProps {
  className?: string
  device?: Device
  formRef: DeviceDetailFormRef
  onValidate(isValid: boolean): void
  onFormUpdate?: (data: DeviceDetailFormData) => void
}

const schema = yup
  .object()
  .shape({
    deviceName: yup.string().trim().required('This field is required'),
    description: yup.string(),
    address: yup.string().test('ipv4', 'Invalid IP address', (value) => {
      if (!value) {
        return true
      }
      if (!/^((^|\.)\d{1,3}){4}$/.test(value)) {
        return false
      }
      return value.split('.').every((val) => Number(val) <= 255)
    }),
    port: yup.string().test('port', 'Invalid port number', (value) => {
      if (!value) {
        return true
      }
      const val = Number(value)
      return val >= 0 && val <= 65535
    }),
    username: yup.string(),
    password: yup.string(),
    protocol: yup.string().nullable(),
    driverName: yup.string().nullable().required('This field is required'),
  })
  .required()

const protocolOptions = Object.values(DeviceSpecConnectionDetailsProtocolEnum)

const driverOptions = ['Driver1', 'Driver2', 'Driver3', 'Driver4', 'Driver5']

export const DeviceDetailForm: FC<DeviceDetailFormProps> = ({
  className,
  device,
  formRef,
  onValidate,
  onFormUpdate,
}) => {
  const { t } = useTranslation()
  const isEditMode = Boolean(device)

  const methods = useForm<DeviceDetailFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      deviceName: device?.spec?.displayName || '',
      description: device?.spec?.description || '',
      address: device?.spec?.connectionDetails?.address || '',
      port: device?.spec?.connectionDetails?.port || '',
      username: '',
      password: '',
      protocol: device?.spec?.connectionDetails?.protocol || '',
      driverName: device?.spec?.connectionDetails?.driverName || null,
    } as any,
  })

  useEffect(() => {
    formRef.getFormData = () => {
      return new Promise((resolve) => {
        methods.handleSubmit(
          (data) => {
            resolve({
              displayName: data.deviceName,
              description: data.description,
              connectionDetails: {
                address: data.address,
                driverName: data.driverName || undefined,
                port: Number(data.port) || undefined,
                protocol: data.protocol,
              },
            })
          },
          (err) => {
            console.log(err)
            resolve(undefined)
          }
        )()
      })
    }
  }, [formRef])

  useEffect(() => {
    if (device) {
      methods.trigger()
    }
  }, [device])

  const formData = methods.watch()

  useEffect(() => {
    onValidate(methods.formState.isValid)
  }, [formData, onValidate])

  useEffect(() => {
    if (onFormUpdate) {
      onFormUpdate(formData)
    }
  }, [formData, onFormUpdate])

  return (
    <FormProvider
      className={className}
      methods={methods}
      data-testid="device-detail-form"
    >
      <RHFTextField
        name="deviceName"
        label="Device Name"
        placeholder="Camera Exit 1"
        data-testid="device-name-input"
      />
      <RHFTextarea
        className="mt-4"
        name="description"
        label="Description"
        minRows={2}
        data-testid="description-input"
      />
      <RHFTextField
        className="mt-4"
        name="address"
        label="Address"
        placeholder="123.123.1231"
        disabled={isEditMode}
        data-testid="address-input"
      />
      <RHFTextField
        className="mt-4"
        name="port"
        type="number"
        label="Port"
        placeholder="0001"
        data-testid="port-input"
      />
      <RHFTextField
        className="mt-4"
        name="username"
        label={t('common.username')}
        data-testid="username"
      />
      <RHFTextField
        className="mt-4"
        name="password"
        type="password"
        label="Device Password"
        data-testid="password-input"
      />
      <RHFSelect
        className="mt-4"
        name="protocol"
        label="Protocol"
        placeholder="Select"
        data-testid="protocol-select"
      >
        {protocolOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFAutocomplete
        className="mt-4"
        name="driverName"
        label="Select drivers*"
        placeholder="Select"
        options={driverOptions}
        data-testid="driver-autocomplete"
      />
    </FormProvider>
  )
}
