import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import classNames from 'classnames'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { VideoChannel } from 'common-api/clients/recorder/typescript'
import { Switch } from 'common-lib/components/FormControls'
import {
  VideoChannelDetailForm,
  VideoChannelDetailFormRef,
  VideoChannelSpecFormData,
} from './VideoChannelDetailForm'
import { EditingDeviceInfo } from 'store/slices/devices'

interface VideoChannelDetailFormAccordionProps {
  className?: string
  videoChannel?: VideoChannel
  index: number
  expanded?: boolean
  disabled?: boolean
  removable?: boolean
  canAddRemoveStreams?: boolean
  editingDeviceInfo?: EditingDeviceInfo
  formRef: VideoChannelDetailFormRef
  onDelete?: () => void
  onToggleExpand(): void
  onValidate(id: number, isValid: boolean): void
}

export const VideoChannelDetailFormAccordion: FC<
  VideoChannelDetailFormAccordionProps
> = ({
  className,
  videoChannel,
  index,
  expanded,
  disabled,
  removable,
  canAddRemoveStreams,
  editingDeviceInfo,
  onToggleExpand,
  onDelete,
  onValidate,
  ...formProps
}) => {
  const [videoChannelName, setVideoChannelName] = useState(
    `Video Channel ${index}`
  )
  const [formEnabled, setFormEnabled] = useState(
    !videoChannel || videoChannel?.spec?.enabled
  )

  useEffect(() => {
    setFormEnabled(!videoChannel || videoChannel?.spec?.enabled)
  }, [videoChannel])

  const onFormUpdate = useCallback(
    (data: VideoChannelSpecFormData) => {
      setVideoChannelName(data.displayName || `Video Channel ${index}`)
    },
    [index]
  )

  const onFormValidate = useCallback(
    (isValid: boolean) => {
      onValidate(index, isValid)
    },
    [index, onValidate]
  )

  const onDeleteForm: MouseEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onDelete) {
      onDelete()
    }
  }

  return (
    <Accordion
      className={classNames(
        `video-channel-${index}`,
        videoChannel && `video-channel-${videoChannel.metadata.name}`,
        className
      )}
      expanded={expanded}
      onChange={onToggleExpand}
      data-testid="video-channel-detail-form"
    >
      <AccordionSummary classes={{ content: 'items-center' }}>
        <h4>{videoChannelName}</h4>
        {removable && (
          <IconButton
            className="group ml-3"
            onClick={onDeleteForm}
            data-testid="delete-channel-button"
          >
            <DeleteOutlineIcon
              className="group-hover:hidden"
              fontSize="small"
            />
            <DeleteIcon className="hidden group-hover:block" fontSize="small" />
          </IconButton>
        )}
        <Switch
          className="ml-auto"
          checked={formEnabled}
          disabled={disabled}
          onChange={(_, checked) => setFormEnabled(checked)}
          onClick={(e) => e.stopPropagation()}
          data-testid="switch-element"
        />
      </AccordionSummary>
      <AccordionDetails>
        <VideoChannelDetailForm
          {...formProps}
          videoChannel={videoChannel}
          disabled={disabled || !formEnabled}
          canAddRemoveStreams={canAddRemoveStreams}
          editingDeviceInfo={editingDeviceInfo}
          extraFormData={{ enabled: formEnabled }}
          onFormUpdate={onFormUpdate}
          onValidate={onFormValidate}
        />
      </AccordionDetails>
    </Accordion>
  )
}
