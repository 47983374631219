import { gql } from '@apollo/client'

export const ADD_PROFILES = gql`
  mutation AddProfile($dto: CreateProfileDto!) {
    addProfile(dto: $dto) {
      id
      name
      organizationId
      nodes
      description
      roles {
        color
        description
        id
        name
        organizationId
      }
    }
  }
`
// {
//   "dto": {
//     "name": "test 1000",
//     "organizationId": "c063e0b0-029e-41ec-861f-0c4b1ecd2f22"
//   }
// }

export const DELETE_PROFILE = gql`
  mutation DeleteProfile($deleteProfileId: String!) {
    deleteProfile(id: $deleteProfileId)
  }
`
// {
//   "deleteProfileId": "b90e74ee-5bd2-4108-b746-376858adf397"
// }

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($updateProfileId: String!, $dto: PatchProfileDto!) {
    updateProfile(id: $updateProfileId, dto: $dto) {
      description
      id
      name
      nodes
      organizationId
    }
  }
`
// {
//   "updateProfileId": "e61b322a-c6f5-44c5-9be0-d96db4b77687",
//   "dto": {
//     "description": "updated",
//     "name": "New name",
//     "nodes": "updated"
//   }
// }
