import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { RoleUsersTable } from './RoleUsersTable'
import PermissionPanel, { PermissionFormRef } from './PermissionPanel'
import { Role } from 'gql/graphql'
import { useDispatch, useSelector } from 'store'
import { setDeletingRole, setEditingRole } from 'store/slices/roles'

interface RoleDetailProps {
  className?: string
  role: Role
}

export const RoleDetail: FC<RoleDetailProps> = ({ className, role }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const users = useSelector((state) => state.users.users.byName)
  const roleUserIds = useMemo(() => {
    if (!role) {
      return []
    }
    return Object.values(users)
      .filter((user) => user.spec.roles.includes(role.name))
      .map((user) => user.metadata.name)
  }, [users, role])

  const formRef = useRef<PermissionFormRef>({
    getFormData: async () => undefined,
  })

  const onEditRole = (role: Role) => {
    dispatch(setEditingRole(role.name))
  }

  const onDeleteRole = () => {
    dispatch(setDeletingRole(role.name))
  }

  return (
    <div className={className} data-testid="role-detail">
      <div className="flex items-start justify-between gap-4">
        <div>
          <h2>{role.name}</h2>
          <h4 className="mt-4">{t('common.description')}</h4>
          <p className="typo-body2 mt-2 max-w-155">{role.description}</p>
        </div>
        <Button
          className="ml-auto w-32"
          color="primary"
          onClick={() => onEditRole(role)}
        >
          {t('common.editRole')}
        </Button>
      </div>

      <div className="mt-8">
        <h3 className="mb-4">{t('common.users')}</h3>
        <RoleUsersTable roleName={role.name} userIds={roleUserIds} editable />
      </div>

      <div className="mt-10">
        <h3 className="mb-6">{t('roleView.permissions')}</h3>
        <PermissionPanel
          formRef={formRef}
          role={role}
          createEditPanel={false}
        />
      </div>

      {role.color && (
        <div className="mt-10">
          <h3>{t('roleView.deleteRole')}</h3>
          <p className="typo-body2 mt-1 text-neutral-300">
            {t('roleView.placeholder')}
          </p>
          <Button className="mt-4 w-36" color="error" onClick={onDeleteRole}>
            {t('roleView.deleteRole')}
          </Button>
        </div>
      )}
    </div>
  )
}
