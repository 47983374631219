import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@mui/material'
import { Device, DeviceSpec } from 'common-api/clients/recorder/typescript'
import { DeviceDetailForm, DeviceDetailFormRef } from './DeviceDetailForm'
import { VideoChannelsForm, VideoChannelsFormRef } from './VideoChannelsForm'
import { VideoChannelDetailFormData } from './VideoChannelDetailForm'
import { EditingDeviceInfo } from 'store/slices/devices'

export interface EditDeviceFormData {
  spec: DeviceSpec
  channels: VideoChannelDetailFormData[]
}

export interface EditDeviceFormRef {
  getFormData(): Promise<EditDeviceFormData | undefined>
}

interface EditDeviceFormProps {
  device: Device
  editingDeviceInfo?: EditingDeviceInfo
  formRef: MutableRefObject<EditDeviceFormRef>
  onValidate(isValid: boolean): void
}

export const EditDeviceForm: FC<EditDeviceFormProps> = ({
  device,
  editingDeviceInfo,
  formRef,
  onValidate,
}) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(
    editingDeviceInfo?.channelName ? 'channels' : 'detail'
  )
  const [detailFormValid, setDetailFormValid] = useState(false)
  const [channelsFormValid, setChannelsFormValid] = useState(false)
  const detailFormRef = useRef<DeviceDetailFormRef>({
    getFormData: async () => undefined,
  })
  const channelsFormRef = useRef<VideoChannelsFormRef>({
    getFormData: async () => undefined,
  })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = async () => {
        const detailData = await detailFormRef.current.getFormData()
        const channelsData = await channelsFormRef.current.getFormData()
        if (!detailData) {
          setTab('detail')
          return undefined
        }
        if (!channelsData) {
          setTab('channels')
          return undefined
        }
        return { spec: detailData, channels: channelsData }
      }
    }
  }, [formRef])

  useEffect(() => {
    onValidate(detailFormValid && channelsFormValid)
  }, [detailFormValid, channelsFormValid, onValidate])

  useEffect(() => {
    if (editingDeviceInfo?.streamName) {
      setTimeout(() => {
        const el = document.querySelector(
          `.video-stream-${editingDeviceInfo.streamName}`
        )
        if (el) {
          el.scrollIntoView()
        }
      }, 500)
    } else if (editingDeviceInfo?.channelName) {
      setTimeout(() => {
        const el = document.querySelector(
          `.video-channel-${editingDeviceInfo.channelName}`
        )
        if (el) {
          el.scrollIntoView()
        }
      }, 500)
    }
  }, [])

  return (
    <div className="flex h-full flex-col px-10 pt-8">
      <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
        <Tab label="Device Details" value="detail" data-testid="detail-tab" />
        <Tab label="Channels" value="channels" data-testid="channels-tab" />
      </Tabs>

      <div className="mt-6 h-0 flex-1 overflow-y-auto">
        <DeviceDetailForm
          className={tab === 'detail' ? '' : 'hidden'}
          device={device}
          formRef={detailFormRef.current}
          onValidate={setDetailFormValid}
          data-testid="detail-form"
        />
        <VideoChannelsForm
          className={tab === 'channels' ? '' : 'hidden'}
          device={device}
          editingDeviceInfo={editingDeviceInfo}
          formRef={channelsFormRef.current}
          onValidate={setChannelsFormValid}
          data-testid="channels-form"
        />
      </div>
    </div>
  )
}
