import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@mui/material'
import { User, UserSpec } from 'common-api/clients/system/typescript'
import { Role } from 'gql/graphql'
import { UserDetailForm, UserDetailFormRef } from './UserDetailForm'
import {
  PermissionPanel,
  PermissionFormData,
  PermissionFormRef,
} from '../Roles/PermissionPanel'
import { useSelector } from 'store'
export interface UserFormData extends UserSpec {
  permissions: PermissionFormData | undefined
  oldRolePermission?: Role
}

export interface UserFormRef {
  getFormData(): Promise<UserFormData | undefined>
}

interface UserFormProps {
  user?: User
  formRef: MutableRefObject<UserFormRef>
  onValidate(isValid: boolean): void
}

export const UserForm: FC<UserFormProps> = ({ user, formRef, onValidate }) => {
  const [tab, setTab] = useState('detail')
  const [detailFormValid, setDetailFormValid] = useState(false)
  const roles = useSelector((state) => state.roles.roles)
  const permissionRole = roles.find(
    (role) => role.name === `user-${user?.metadata.name}`
  )
  const { t } = useTranslation()
  const detailFormRef = useRef<UserDetailFormRef>({
    getFormData: async () => undefined,
  })

  const permissionFormRef = useRef<PermissionFormRef>({
    getFormData: async () => undefined,
  })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = async () => {
        const detailData = await detailFormRef.current.getFormData()
        const permissionData = await permissionFormRef.current.getFormData()
        if (!detailData) {
          setTab('detail')
          return undefined
        }
        return {
          ...detailData,
          permissions: permissionData,
          oldRolePermission: permissionRole,
        }
      }
    }
  }, [formRef])

  useEffect(() => {
    onValidate(detailFormValid)
  }, [detailFormValid, onValidate])

  return (
    <div className="flex h-full flex-col">
      <div className="px-10 pt-8">
        <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
          <Tab
            label={t('userCreate.userDetails')}
            value="detail"
            data-testid="detail-tab"
          />
          {user === undefined ? null : (
            <Tab
              label={t('common.permissions')}
              value="permissions"
              data-testid="permissions-tab"
            />
          )}
        </Tabs>
      </div>

      <div className="mt-6 h-0 flex-1 overflow-y-auto">
        <UserDetailForm
          className={tab == 'detail' ? '' : 'hidden'}
          user={user}
          formRef={detailFormRef}
          onValidate={setDetailFormValid}
        />
        <PermissionPanel
          className={tab == 'permissions' ? '' : 'hidden'}
          role={permissionRole}
          formRef={permissionFormRef}
          createEditPanel
        />
      </div>
    </div>
  )
}
