import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { DeviceChannels } from './DeviceChannels'
import { Device } from 'common-api/clients/recorder/typescript'
import { useDispatch, useSelector } from '../../store'
import { setDeletingDevice, setEditingDevice } from 'store/slices/devices'

interface DeviceDetailProps {
  className?: string
  device: Device
}

export const DeviceDetail: FC<DeviceDetailProps> = ({ className, device }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const recorder = useSelector(
    (state) =>
      state.recorders.recorders.byName[device.status?.recorderRef as string]
  )
  const canAddRemoveDevices = recorder.status?.capabilities?.canAddRemoveDevices

  const onEditDevice = () => {
    dispatch(
      setEditingDevice({
        deviceName: device.metadata.name,
      })
    )
  }

  const onDelete = () => {
    dispatch(setDeletingDevice(device.metadata.name))
  }

  return (
    <div className={className} data-testid="device-detail">
      <div className="flex items-start justify-between">
        <h2 className="mt-4">{t('deviceView.settings')}</h2>
        <Button
          color="primary"
          className="ml-auto w-36"
          onClick={onEditDevice}
          data-testid="edit-device-button"
        >
          {t('deviceView.editDevice')}
        </Button>
      </div>

      <div className="mt-6 flex flex-wrap gap-x-15 gap-y-6">
        <div>
          <h4>{t('common.deviceName')}</h4>
          <div className="typo-body2 mt-2">{device.spec.displayName}</div>
        </div>
        <div>
          <h4>{t('common.deviceModel')}</h4>
          <div className="typo-body2 mt-2">{device.status?.info?.model}</div>
        </div>
        <div>
          <h4>{t('common.address')}</h4>
          <div className="typo-body2 mt-2">
            {device.spec.connectionDetails?.address}
          </div>
        </div>
        <div>
          <h4>{t('common.port')}</h4>
          <div className="typo-body2 mt-2">
            {device.spec.connectionDetails?.port}
          </div>
        </div>
        <div>
          <h4>{t('common.protocol')}</h4>
          <div className="typo-body2 mt-2">
            {device.spec.connectionDetails?.protocol}
          </div>
        </div>
      </div>
      <div className="mt-6">
        <h4>{t('common.description')}</h4>
        <p className="typo-body2 mt-2 max-w-155">{device.spec.description}</p>
      </div>

      <div className="mt-7">
        <h3>{t('deviceView.channels')}</h3>
        <DeviceChannels className="mt-4" device={device} />
      </div>

      {canAddRemoveDevices && (
        <div className="mt-10">
          <h3>{t('deviceView.deleteDevice')}</h3>
          <p className="typo-body2 mt-1 text-neutral-300">
            {t('deviceView.deletePlaceholder')}
          </p>
          <Button
            className="mt-4 w-36"
            color="error"
            disabled={!canAddRemoveDevices}
            onClick={onDelete}
            data-testid="delete-device-button"
          >
            {t('deviceView.deleteDevice')}
          </Button>
        </div>
      )}
    </div>
  )
}
