import { API_SERVER_URL } from 'common-lib/constants/endpoints'
import { CookieService, COOKIE_ITEM } from 'common-lib/services/cookies'

const findLine = (buffer: string, fn: (s: string) => void): string => {
  const newLineIndex = buffer.indexOf('\n')
  // if the buffer doesn't contain a new line, do nothing
  if (newLineIndex === -1) {
    return buffer
  }
  const chunk = buffer.slice(0, buffer.indexOf('\n'))
  const newBuffer = buffer.slice(buffer.indexOf('\n') + 1)

  // found a new line! execute the callback
  fn(chunk)

  // there could be more lines, checking again
  return findLine(newBuffer, fn)
}

export enum WatchEventType {
  ADDED = 'ADDED',
  MODIFIED = 'MODIFIED',
  DELETED = 'DELETED',
  BOOKMARK = 'BOOKMARK',
}

export interface WatchEvent<T> {
  object: T
  type: WatchEventType
}

export const watch = <T>(
  apiPath: string,
  resource: string,
  resourceVersion: string,
  callback: (event: WatchEvent<T>) => void,
  namespace?: string
) => {
  // TODO: make watcher class which has server URL pre-configured
  // TODO: make resource version optional
  // TODO: add authentication
  // TODO: handle disconnection
  // TODO: error handling
  // TODO: dynamically set API path base on object

  let watchURL = API_SERVER_URL + apiPath
  if (namespace) {
    watchURL += '/namespaces/' + namespace
  }
  watchURL += '/' + resource + `?watch=1&resourceVersion=` + resourceVersion

  const controller = new AbortController()

  return {
    abort: () => controller.abort(),
    f: fetch(watchURL, {
      headers: {
        Authorization: 'Bearer ' + CookieService.get(COOKIE_ITEM.ACCESS_TOKEN), // TODO: Use correct persistence mechanism
      },
      signal: controller.signal,
    }).then((response) => {
      if (response.body !== null) {
        // ref: https://learnk8s.io/real-time-dashboard
        const stream = response.body.getReader()
        const utf8Decoder = new TextDecoder('utf-8')
        let buffer = ''

        // wait for an update and prepare to read it
        return stream.read().then(({ done, value }): Promise<void> | void => {
          if (done) {
            console.log('Watch request terminated')
            return
          }
          buffer += utf8Decoder.decode(value)
          const remainingBuffer = findLine(buffer, (line) => {
            try {
              const event = JSON.parse(line)
              console.log('Watch event: ', event.type, event.object)
              // TODO: check that validated type and objects are of right structure
              // TODO: to do handle duplicate event
              callback(event)
            } catch (error) {
              console.log('Error while parsing', line, '\n', error)
            }
          })

          buffer = remainingBuffer

          // continue waiting & reading the stream of updates from the server
          return stream.read().then()
        })
      }
    }),
  }
}
