import { gql } from '@apollo/client'

export const GET_GATEKEEPER = gql`
  query GetGatekeeper($getGatekeeperId: String!) {
    getGatekeeper(id: $getGatekeeperId) {
      spec {
        displayName
      }
    }
  }
`
