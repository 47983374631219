import { FC, MutableRefObject, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MenuItem } from '@mui/material'
import { Panel } from '../Panel'
import { Language } from 'store/slices/users'
import { User, UserSpec } from 'common-api/clients/system/typescript'
import { RoleChip } from 'common-lib/components'
import {
  FormProvider,
  RHFAutocomplete,
  RHFSelect,
  RHFTextarea,
  RHFTextField,
} from 'common-lib/components/RHFControls'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import { isVisibleRole } from 'utils/helpers'

export interface UserDetailFormRef {
  getFormData(): Promise<UserSpec | undefined>
}

interface UserDetailFormProps {
  className?: string
  user?: User
  formRef: MutableRefObject<UserDetailFormRef>
  onValidate(isValid: boolean): void
}

const schema = yup
  .object()
  .shape({
    firstName: yup.string().trim().required('This field is required'),
    lastName: yup.string().trim().required('This field is required'),
    email: yup
      .string()
      .email('Please input valid email')
      .required('This field is required'),
    languageCode: yup.string(),
    description: yup.string(),
    roles: yup.array().notRequired(),
  })
  .required()

const languageOptions = [
  { label: 'English', value: Language.English },
  { label: 'Finnish', value: Language.Finnish },
  { label: 'Svenska', value: Language.Swedish },
  { label: 'Brasil', value: Language.Brasilian },
]

export const UserDetailForm: FC<UserDetailFormProps> = ({
  className,
  user,
  formRef,
  onValidate,
}) => {
  const { t } = useTranslation()
  const roles = useTypedSelector((state) =>
    state.roles.roles.filter(isVisibleRole)
  )

  const userRoles: string[] | undefined = user?.spec.roles

  const methods = useForm<UserSpec>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.spec.firstName || '',
      lastName: user?.spec.lastName || '',
      email: user?.spec.email || '',
      description: user?.spec.description || '',
      languageCode: user?.spec.languageCode || '',
      roles: userRoles || [],
    },
    mode: 'onBlur',
  })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormData = () => {
        return new Promise((resolve) => {
          methods.handleSubmit(
            (data) => {
              resolve(data)
            },
            (err) => {
              console.log(err)
              resolve(undefined)
            }
          )()
        })
      }
    }
  }, [formRef])

  useEffect(() => {
    if (user) {
      methods.trigger()
    }
  }, [user])

  const formData = methods.watch()

  const selectedRoles = useMemo(() => {
    return Object.values(roles).filter((role) =>
      formData.roles.includes(role.name)
    )
  }, [roles, formData.roles])

  useEffect(() => {
    onValidate(methods.formState.isValid)
  }, [formData, onValidate])

  const onFieldChange = (field: keyof UserSpec, value: any) => {
    methods.setValue(field, value)
    methods.trigger(field)
  }

  const onRemoveRole = (roleName: string) => {
    const roles = formData.roles.filter((item) => item !== roleName)
    onFieldChange('roles', roles)
  }

  return (
    <FormProvider
      className={className}
      methods={methods}
      data-testid="user-detail-form"
    >
      <Panel.Section>
        <RHFTextField
          name="email"
          label={`${t('common.email')}*`}
          data-testid="email-input"
        />
        <RHFTextField
          className="mt-4"
          name="firstName"
          label={`${t('userCreate.firstName')}*`}
          data-testid="first-name-input"
        />
        <RHFTextField
          className="mt-4"
          name="lastName"
          label={`${t('userCreate.lastName')}*`}
          data-testid="last-name-input"
        />
        <RHFTextarea
          className="mt-4"
          name="description"
          label={t('common.descriptionOptional')}
          minRows={4}
          data-testid="description-input"
        />
        <RHFSelect
          className="mt-4"
          name="languageCode"
          label={t('userCreate.language')}
          placeholder={t('userCreate.languagePlaceholder') as string}
          data-testid="language-select"
        >
          {languageOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </RHFSelect>
      </Panel.Section>

      <Panel.Section title={t('userCreate.roles')} containerClass="mt-6">
        <RHFAutocomplete
          name="roles"
          label={t('common.addRole') as string}
          placeholder={t('userCreate.addRolesPlaceholder') as string}
          multiple
          checkbox
          options={roles.map((role) => role.name)}
          renderTags={() => null}
          data-testid="role-autocomplete"
        />
        <div className="flex flex-wrap">
          {selectedRoles.map((role) => (
            <RoleChip
              key={role.name}
              className="mr-3 mt-3"
              role={role}
              onDelete={() => onRemoveRole(role.name)}
              data-testid="role-chip"
            />
          ))}
        </div>
      </Panel.Section>
    </FormProvider>
  )
}
