import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import classNames from 'classnames'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { VideoStream } from 'common-api/clients/recorder/typescript'
import { Switch } from 'common-lib/components/FormControls'
import {
  VideoStreamDetailForm,
  VideoStreamDetailFormRef,
} from './VideoStreamDetailForm'

interface VideoStreamDetailFormAccordionProps {
  className?: string
  videoStream?: VideoStream
  index: number
  expanded?: boolean
  disabled?: boolean
  removable?: boolean
  formRef: VideoStreamDetailFormRef
  onDelete?: () => void
  onToggleExpand(): void
  onValidate(id: number, isValid: boolean): void
}

export const VideoStreamDetailFormAccordion: FC<
  VideoStreamDetailFormAccordionProps
> = ({
  className,
  videoStream,
  index,
  expanded,
  disabled,
  removable,
  onDelete,
  onToggleExpand,
  onValidate,
  ...formProps
}) => {
  const videoStreamName = `Video Stream ${index}`
  const [formEnabled, setFormEnabled] = useState(
    !videoStream || videoStream?.spec?.enabled
  )

  useEffect(() => {
    setFormEnabled(!videoStream || videoStream?.spec?.enabled)
  }, [videoStream])

  const onFormValidate = useCallback(
    (isValid: boolean) => {
      onValidate(index, isValid)
    },
    [index, onValidate]
  )

  const onDeleteForm: MouseEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onDelete) {
      onDelete()
    }
  }

  return (
    <Accordion
      className={classNames(
        `video-stream-${index}`,
        videoStream && `video-stream-${videoStream.metadata.name}`,
        className
      )}
      expanded={expanded}
      onChange={onToggleExpand}
    >
      <AccordionSummary classes={{ content: 'items-center' }}>
        <h4>{videoStreamName}</h4>
        {removable && (
          <IconButton
            className="group ml-3"
            onClick={onDeleteForm}
            data-testid="delete-channel-button"
          >
            <DeleteOutlineIcon
              className="group-hover:hidden"
              fontSize="small"
            />
            <DeleteIcon className="hidden group-hover:block" fontSize="small" />
          </IconButton>
        )}
        <Switch
          className="ml-auto"
          checked={formEnabled}
          disabled={disabled}
          onChange={(_, checked) => setFormEnabled(checked)}
          onClick={(e) => e.stopPropagation()}
          data-testid="switch-element"
        />
      </AccordionSummary>
      <AccordionDetails>
        <VideoStreamDetailForm
          {...formProps}
          videoStream={videoStream}
          disabled={disabled || !formEnabled}
          extraFormData={{ enabled: formEnabled }}
          onValidate={onFormValidate}
        />
      </AccordionDetails>
    </Accordion>
  )
}
