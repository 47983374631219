import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserProfile } from '../../gql/graphql'

export type Account = UserProfile & {
  email: string
}

export interface AuthState {
  account: Account
}

const initialState: AuthState = {
  account: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    isMFAEnabled: false,
    organizations: [],
    roles: [],
    permissions: [],
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Account>) => {
      state.account = action.payload
    },
  },
})

export const { setUser } = authSlice.actions
export default authSlice.reducer
