import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { Role } from 'gql/graphql'
import { apolloClient } from 'lib/apollo-client'
import { LIST_ROLES } from 'lib/queries/roles'
import { parseAxiosError } from 'utils/helpers'

export interface RolesState {
  isLoading: boolean
  roles: Role[]
  creatingRole?: boolean
  editingRole?: Role
  deletingRole?: string
}

const initialState: RolesState = {
  isLoading: false,
  roles: [],
}

export const fetchRoles = createAsyncThunk<
  Role[],
  void,
  {
    state: RootState
  }
>(
  'roles/fetchRoles',
  async () => {
    return apolloClient
      .query<{ listRoles: Role[] }>({
        query: LIST_ROLES,
      })
      .then((res) => res.data.listRoles || [])
      .catch((err) => {
        throw parseAxiosError(err)
      })
  },
  {
    condition: (_, { getState }) => {
      const { roles } = getState()
      // don't fetch again, if we're already fetching
      return !roles.isLoading
    },
  }
)

// export const createRole = createAsyncThunk<Role, RoleSpecRequest>(
//   'roles/createRole',
//   async (payload) => {
//     return rolesService
//       .createRole(todoChangeMeNamespace, payload)
//       .then((resp) => resp.data)
//       .catch((err) => {
//         throw parseAxiosError(err)
//       })
//   }
// )
//
// export interface PatchRoleRequest {
//   name: string
//   before: RoleSpec
//   after: RoleSpec
// }
//
// export const updateRole = createAsyncThunk<Role, PatchRoleRequest>(
//   'roles/updateRole',
//   async (payload) => {
//     return rolesService
//       .patchRole(
//         payload.name,
//         todoChangeMeNamespace,
//         payload.before,
//         payload.after
//       )
//       .then((resp) => resp.data)
//       .catch((err) => {
//         throw parseAxiosError(err)
//       })
//   }
// )
//
// export const deleteRole = createAsyncThunk<string, string>(
//   'roles/deleteRole',
//   async (name) => {
//     return rolesService
//       .deleteRole(todoChangeMeNamespace, name)
//       .then(() => name)
//       .catch((err) => {
//         throw parseAxiosError(err)
//       })
//   }
// )

const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    showCreateRolePanel: (state, action: PayloadAction<boolean>) => {
      state.creatingRole = action.payload
    },
    setEditingRole: (state, action: PayloadAction<string | undefined>) => {
      // TODO: Break this out into another reducer?
      if (action.payload === undefined) {
        state.editingRole = undefined
        return
      }
      state.editingRole = state.roles.find((role) => role.id === action.payload)
    },
    setDeletingRole: (state, action: PayloadAction<string | undefined>) => {
      state.deletingRole = action.payload
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(createRole.fulfilled, (state, action) => {
    //     const role = action.payload
    //     if (role.metadata.deletionTimestamp !== undefined) {
    //       return
    //     }
    //     state.roles.byName[role.metadata.name] = role
    //     state.roles.resourceVersion = action.payload.metadata.resourceVersion
    //   })
    //   .addCase(fetchRoles.pending, (state) => {
    //     state.roles.loading = true
    //   })
    //   .addCase(fetchRoles.fulfilled, (state, action) => {
    //     const roles = {} as NamedMap<Role>
    //     action.payload.items.forEach((role) => {
    //       // skip users being deleted
    //       if (role.metadata.deletionTimestamp !== undefined) {
    //         return
    //       }
    //       roles[role.metadata.name] = role
    //     })
    //     state.roles.byName = roles
    //     state.roles.loading = false
    //     state.roles.resourceVersion = action.payload.metadata.resourceVersion
    //   })
    //   .addCase(updateRole.fulfilled, (state, action) => {
    //     state.roles.byName[action.payload.metadata.name] = action.payload
    //     state.roles.resourceVersion = action.payload.metadata.resourceVersion
    //   })
    //   .addCase(deleteRole.fulfilled, (state, action) => {
    //     delete state.roles.byName[action.payload]
    //     state.creatingRole = false
    //     state.editingRole = undefined
    //     state.deletingRole = undefined
    //   })
  },
})

export const { showCreateRolePanel, setEditingRole, setDeletingRole } =
  roles.actions

export default roles.reducer
