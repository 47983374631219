/* tslint:disable */
/* eslint-disable */
/**
 * Kubernetes CRD Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Camera is the Schema for the cameras API
 * @export
 * @interface Camera
 */
export interface Camera {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof Camera
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof Camera
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof Camera
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {CameraSpec}
     * @memberof Camera
     */
    'spec': CameraSpec;
    /**
     * CameraStatus defines the observed state of Camera
     * @type {object}
     * @memberof Camera
     */
    'status'?: object;
}
/**
 * CameraList is a list of Camera
 * @export
 * @interface CameraList
 */
export interface CameraList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof CameraList
     */
    'apiVersion': string;
    /**
     * List of cameras. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<Camera>}
     * @memberof CameraList
     */
    'items': Array<Camera>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof CameraList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof CameraList
     */
    'metadata': CameraListMetadata;
}
/**
 * Standard list metadata. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
 * @export
 * @interface CameraListMetadata
 */
export interface CameraListMetadata {
    /**
     * continue may be set if the user set a limit on the number of items returned, and indicates that the server has more data available. The value is opaque and may be used to issue another request to the endpoint that served this list to retrieve the next set of available objects. Continuing a consistent list may not be possible if the server configuration has changed or more than a few minutes have passed. The resourceVersion field returned when using this continue value will be identical to the value in the first response, unless you have received this token from an error message.
     * @type {string}
     * @memberof CameraListMetadata
     */
    'continue'?: string;
    /**
     * remainingItemCount is the number of subsequent items in the list which are not included in this list response. If the list request contained label or field selectors, then the number of remaining items is unknown and the field will be left unset and omitted during serialization. If the list is complete (either because it is not chunking or because this is the last chunk), then there are no more remaining items and this field will be left unset and omitted during serialization. Servers older than v1.15 do not set this field. The intended use of the remainingItemCount is *estimating* the size of a collection. Clients should not rely on the remainingItemCount to be set or to be exact.
     * @type {number}
     * @memberof CameraListMetadata
     */
    'remainingItemCount'?: number;
    /**
     * String that identifies the server\'s internal version of this object that can be used by clients to determine when objects have changed. Value must be treated as opaque by clients and passed unmodified back to the server. Populated by the system. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof CameraListMetadata
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof CameraListMetadata
     */
    'selfLink'?: string;
}
/**
 * Standard object\'s metadata. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata
 * @export
 * @interface CameraMetadata
 */
export interface CameraMetadata {
    /**
     * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations
     * @type {{ [key: string]: string; }}
     * @memberof CameraMetadata
     */
    'annotations'?: { [key: string]: string; };
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof CameraMetadata
     */
    'creationTimestamp'?: string;
    /**
     * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only.
     * @type {number}
     * @memberof CameraMetadata
     */
    'deletionGracePeriodSeconds'?: number;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof CameraMetadata
     */
    'deletionTimestamp'?: string;
    /**
     * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list.
     * @type {Array<string>}
     * @memberof CameraMetadata
     */
    'finalizers'?: Array<string>;
    /**
     * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will return a 409.  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency
     * @type {string}
     * @memberof CameraMetadata
     */
    'generateName'?: string;
    /**
     * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only.
     * @type {number}
     * @memberof CameraMetadata
     */
    'generation'?: number;
    /**
     * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/labels
     * @type {{ [key: string]: string; }}
     * @memberof CameraMetadata
     */
    'labels'?: { [key: string]: string; };
    /**
     * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.
     * @type {Array<MetaV1ObjectMetaManagedFields>}
     * @memberof CameraMetadata
     */
    'managedFields'?: Array<MetaV1ObjectMetaManagedFields>;
    /**
     * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof CameraMetadata
     */
    'name': string;
    /**
     * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces
     * @type {string}
     * @memberof CameraMetadata
     */
    'namespace': string;
    /**
     * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller.
     * @type {Array<MetaV1ObjectMetaOwnerReferences>}
     * @memberof CameraMetadata
     */
    'ownerReferences'?: Array<MetaV1ObjectMetaOwnerReferences>;
    /**
     * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof CameraMetadata
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof CameraMetadata
     */
    'selfLink'?: string;
    /**
     * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof CameraMetadata
     */
    'uid'?: string;
}
/**
 * CameraSpec defines the desired state of Camera
 * @export
 * @interface CameraSpec
 */
export interface CameraSpec {
    /**
     * Foo is an example field of Camera. Edit camera_types.go to remove/update
     * @type {string}
     * @memberof CameraSpec
     */
    'foo'?: string;
}
/**
 * 
 * @export
 * @interface DeviceAccess
 */
export interface DeviceAccess {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof DeviceAccess
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof DeviceAccess
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof DeviceAccess
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {DeviceAccessSpec}
     * @memberof DeviceAccess
     */
    'spec': DeviceAccessSpec;
}
/**
 * DeviceAccessList is a list of DeviceAccess
 * @export
 * @interface DeviceAccessList
 */
export interface DeviceAccessList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof DeviceAccessList
     */
    'apiVersion': string;
    /**
     * List of deviceaccesses. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<DeviceAccess>}
     * @memberof DeviceAccessList
     */
    'items': Array<DeviceAccess>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof DeviceAccessList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof DeviceAccessList
     */
    'metadata': CameraListMetadata;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface DeviceAccessSpec
 */
export interface DeviceAccessSpec {
    /**
     * Device access description
     * @type {string}
     * @memberof DeviceAccessSpec
     */
    'description'?: string;
    /**
     * Device access unique identifier
     * @type {string}
     * @memberof DeviceAccessSpec
     */
    'deviceAccessId'?: string;
    /**
     * Device access display name for UI
     * @type {string}
     * @memberof DeviceAccessSpec
     */
    'displayName'?: string;
    /**
     * Device access state
     * @type {boolean}
     * @memberof DeviceAccessSpec
     */
    'enabled'?: boolean;
    /**
     * Device tree. At the moment contains folders and channels only
     * @type {Array<DeviceAccessSpecNodes>}
     * @memberof DeviceAccessSpec
     */
    'nodes'?: Array<DeviceAccessSpecNodes>;
    /**
     * Device access roles from some predefined list Shouldn\'t be empty?
     * @type {Array<string>}
     * @memberof DeviceAccessSpec
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeviceAccessSpecNodes
 */
export interface DeviceAccessSpecNodes {
    /**
     * Version of node format
     * @type {string}
     * @memberof DeviceAccessSpecNodes
     */
    'apiVersion': string;
    /**
     * Display name by default (can be overriden by internal node display name)
     * @type {string}
     * @memberof DeviceAccessSpecNodes
     */
    'defaultDisplayName'?: string;
    /**
     * Kind of node
     * @type {string}
     * @memberof DeviceAccessSpecNodes
     */
    'kind': DeviceAccessSpecNodesKindEnum;
    /**
     * Unique ID (UUID format) of node
     * @type {string}
     * @memberof DeviceAccessSpecNodes
     */
    'nodeUuid'?: string;
    /**
     * Child nodes. Can be null.
     * @type {Array<object>}
     * @memberof DeviceAccessSpecNodes
     */
    'nodes'?: Array<object> | null;
    /**
     * 
     * @type {DeviceAccessSpecNodesSpec}
     * @memberof DeviceAccessSpecNodes
     */
    'spec': DeviceAccessSpecNodesSpec;
}

export const DeviceAccessSpecNodesKindEnum = {
    Folder: 'Folder',
    VideoChannel: 'VideoChannel',
    VideoChannelPtz: 'VideoChannelPTZ'
} as const;

export type DeviceAccessSpecNodesKindEnum = typeof DeviceAccessSpecNodesKindEnum[keyof typeof DeviceAccessSpecNodesKindEnum];

/**
 * JSON serialized node model with all params. Specification corresponds to kind (type) and can be validated.
 * @export
 * @interface DeviceAccessSpecNodesSpec
 */
export interface DeviceAccessSpecNodesSpec {
    /**
     * JSON content of extra parameters
     * @type {string}
     * @memberof DeviceAccessSpecNodesSpec
     */
    'content'?: string;
}
/**
 * Organization is the Schema for the organizations API It is a namespaced singleton of the name \"config\"; any deletions of it or other creations should be disallowed using webhooks or OPA.
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof Organization
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof Organization
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof Organization
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {OrganizationSpec}
     * @memberof Organization
     */
    'spec': OrganizationSpec;
    /**
     * OrganizationStatus defines the observed state of Organization
     * @type {object}
     * @memberof Organization
     */
    'status'?: object;
}
/**
 * OrganizationList is a list of Organization
 * @export
 * @interface OrganizationList
 */
export interface OrganizationList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof OrganizationList
     */
    'apiVersion': string;
    /**
     * List of organizations. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<Organization>}
     * @memberof OrganizationList
     */
    'items': Array<Organization>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof OrganizationList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof OrganizationList
     */
    'metadata': CameraListMetadata;
}
/**
 * OrganizationSpec defines the desired state of Organization
 * @export
 * @interface OrganizationSpec
 */
export interface OrganizationSpec {
    /**
     * TwoFactorAuthenticationMandatory specifies whether two-factor authentication using TOTP is mandatory in the organization.
     * @type {boolean}
     * @memberof OrganizationSpec
     */
    'twoFactorAuthenticationMandatory': boolean;
}
/**
 * PTZPreset is the Schema for the ptzpresets API
 * @export
 * @interface PTZPreset
 */
export interface PTZPreset {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof PTZPreset
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof PTZPreset
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof PTZPreset
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {PTZPresetSpec}
     * @memberof PTZPreset
     */
    'spec': PTZPresetSpec;
    /**
     * PTZPresetStatus defines the observed state of PTZPreset
     * @type {object}
     * @memberof PTZPreset
     */
    'status'?: object;
}
/**
 * PTZPresetList is a list of PTZPreset
 * @export
 * @interface PTZPresetList
 */
export interface PTZPresetList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof PTZPresetList
     */
    'apiVersion': string;
    /**
     * List of ptzpresets. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<PTZPreset>}
     * @memberof PTZPresetList
     */
    'items': Array<PTZPreset>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof PTZPresetList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof PTZPresetList
     */
    'metadata': CameraListMetadata;
}
/**
 * PTZPresetSpec defines the desired state of PTZPreset
 * @export
 * @interface PTZPresetSpec
 */
export interface PTZPresetSpec {
    /**
     * Foo is an example field of PTZPreset. Edit ptzpreset_types.go to remove/update
     * @type {string}
     * @memberof PTZPresetSpec
     */
    'foo'?: string;
}
/**
 * 
 * @export
 * @interface PeerConnection
 */
export interface PeerConnection {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof PeerConnection
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof PeerConnection
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof PeerConnection
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {PeerConnectionSpec}
     * @memberof PeerConnection
     */
    'spec': PeerConnectionSpec;
    /**
     * 
     * @type {PeerConnectionStatus}
     * @memberof PeerConnection
     */
    'status'?: PeerConnectionStatus;
}
/**
 * PeerConnectionList is a list of PeerConnection
 * @export
 * @interface PeerConnectionList
 */
export interface PeerConnectionList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof PeerConnectionList
     */
    'apiVersion': string;
    /**
     * List of peerconnections. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<PeerConnection>}
     * @memberof PeerConnectionList
     */
    'items': Array<PeerConnection>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof PeerConnectionList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof PeerConnectionList
     */
    'metadata': CameraListMetadata;
}
/**
 * Specification of the kubernetes object.
 * @export
 * @interface PeerConnectionSpec
 */
export interface PeerConnectionSpec {
    /**
     * OfferSDP contains the WebRTC Offer SDP generated by the user\'s browser The offer MUST contain at least one host and one server-reflexive (STUN) ICE candidate. The offer MIGHT contain a TURN relay candidate. Trickle ICE is NOT supported.
     * @type {string}
     * @memberof PeerConnectionSpec
     */
    'offerSDP'?: string;
    /**
     * RecorderRef points to the Recorder\'s .metadata.name for which this peer connection should be opened. All channel references must be bound to this recorder. The requesting User must have access to this recorder at admission time. This field is immutable.
     * @type {string}
     * @memberof PeerConnectionSpec
     */
    'recorderRef'?: string;
    /**
     * Tracks define the different tracks that are attached to this peer connection For now, only one track per PeerConnection is supported, but in the future there might be more. For now, this field is immutable, but in the future it might become mutable. The channels referenced MUST belong to the same gatekeeper. The requesting User must have access to all referenced channels at admission time.
     * @type {Array<PeerConnectionSpecTracks>}
     * @memberof PeerConnectionSpec
     */
    'tracks'?: Array<PeerConnectionSpecTracks>;
    /**
     * 
     * @type {PeerConnectionSpecUser}
     * @memberof PeerConnectionSpec
     */
    'user'?: PeerConnectionSpecUser;
}
/**
 * 
 * @export
 * @interface PeerConnectionSpecTracks
 */
export interface PeerConnectionSpecTracks {
    /**
     * Stream id, is used to create unique playback name
     * @type {string}
     * @memberof PeerConnectionSpecTracks
     */
    'id'?: string;
    /**
     * StartTime specifies from where to start a playback. If both StartTime and StopTime are null, livestreaming is assumed.
     * @type {string}
     * @memberof PeerConnectionSpecTracks
     */
    'startTime'?: string | null;
    /**
     * StopTime specifies from where to stop a playback. If both StartTime and StopTime are nil, livestreaming is assumed. If StopTime is defined, StartTime MUST also be defined.
     * @type {string}
     * @memberof PeerConnectionSpecTracks
     */
    'stopTime'?: string | null;
    /**
     * StreamKind describes what Kubernetes stream Kind (e.g. VideoStream) this is referring to
     * @type {string}
     * @memberof PeerConnectionSpecTracks
     */
    'streamKind'?: PeerConnectionSpecTracksStreamKindEnum;
    /**
     * StreamRef refers to the .metadata.name of the referenced stream kind in the same namespace
     * @type {string}
     * @memberof PeerConnectionSpecTracks
     */
    'streamRef'?: string;
}

export const PeerConnectionSpecTracksStreamKindEnum = {
    VideoStream: 'VideoStream'
} as const;

export type PeerConnectionSpecTracksStreamKindEnum = typeof PeerConnectionSpecTracksStreamKindEnum[keyof typeof PeerConnectionSpecTracksStreamKindEnum];

/**
 * User identifies the requesting user.
 * @export
 * @interface PeerConnectionSpecUser
 */
export interface PeerConnectionSpecUser {
    /**
     * JTI point\'s to the current user\'s JWT Token ID from Keycloak. This value is re-generated for each token refresh, although the session ID stays the same. It should be enforced by the admission controller to match the requesting User\'s JWT token sid field. This field is immutable.
     * @type {string}
     * @memberof PeerConnectionSpecUser
     */
    'jTI'?: string | null;
    /**
     * SID point\'s to the current user\'s Session ID in Keycloak. This can be the shared between multiple tabs of the same user browser. It also stays the same across token refreshes. It should be enforced by the admission controller to match the requesting User\'s JWT token sid field. This field is immutable.
     * @type {string}
     * @memberof PeerConnectionSpecUser
     */
    'sID'?: string | null;
    /**
     * UUID specifies the User\'s .metadata.name or UUID in Keycloak, the sub field of the JWT token. This field should be enforced by the admission controller to match the requesting User\'s JWT token sub field. This field is immutable.
     * @type {string}
     * @memberof PeerConnectionSpecUser
     */
    'uUID'?: string | null;
}
/**
 * Status object for the entity.
 * @export
 * @interface PeerConnectionStatus
 */
export interface PeerConnectionStatus {
    /**
     * SDP answer
     * @type {string}
     * @memberof PeerConnectionStatus
     */
    'answerSDP'?: string | null;
    /**
     * Peer connection conditions
     * @type {Array<PeerConnectionStatusConditions>}
     * @memberof PeerConnectionStatus
     */
    'conditions'?: Array<PeerConnectionStatusConditions>;
    /**
     * Session internal streaming server id
     * @type {string}
     * @memberof PeerConnectionStatus
     */
    'sessionUUID'?: string | null;
}
/**
 * 
 * @export
 * @interface PeerConnectionStatusConditions
 */
export interface PeerConnectionStatusConditions {
    /**
     * Last time the condition transitioned from one status to another
     * @type {string}
     * @memberof PeerConnectionStatusConditions
     */
    'lastTransitionTime'?: string | null;
    /**
     * Human readable message indicating details about the transition
     * @type {string}
     * @memberof PeerConnectionStatusConditions
     */
    'message'?: string;
    /**
     * ObservedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.\",
     * @type {number}
     * @memberof PeerConnectionStatusConditions
     */
    'observedGeneration'?: number;
    /**
     * Condition reason for the condition\'s last transition
     * @type {string}
     * @memberof PeerConnectionStatusConditions
     */
    'reason'?: PeerConnectionStatusConditionsReasonEnum;
    /**
     * Status of the condition, one of Unknown, True, False
     * @type {string}
     * @memberof PeerConnectionStatusConditions
     */
    'status'?: PeerConnectionStatusConditionsStatusEnum;
    /**
     * Type of condition
     * @type {string}
     * @memberof PeerConnectionStatusConditions
     */
    'type'?: PeerConnectionStatusConditionsTypeEnum;
}

export const PeerConnectionStatusConditionsReasonEnum = {
    Unknown: 'Unknown',
    Ok: 'Ok',
    RecorderSettingsApplyError: 'RecorderSettingsApplyError',
    DeviceSettingsApplyError: 'DeviceSettingsApplyError',
    VideoChannelSettingsApplyError: 'VideoChannelSettingsApplyError',
    VideoStreamSettingsApplyError: 'VideoStreamSettingsApplyError',
    VideoStreamSettingsApplyErrorStreamNotFound: 'VideoStreamSettingsApplyErrorStreamNotFound',
    VideoStreamSettingsApplyErrorVideoSettings: 'VideoStreamSettingsApplyErrorVideoSettings',
    VideoStreamSettingsApplyErrorRecordingSettings: 'VideoStreamSettingsApplyErrorRecordingSettings',
    RecorderSettingsPartiallyApplied: 'RecorderSettingsPartiallyApplied',
    DeviceSettingsPartiallyApplied: 'DeviceSettingsPartiallyApplied',
    VideoChannelSettingsPartiallyApplied: 'VideoChannelSettingsPartiallyApplied',
    VideoStreamSettingsPartiallyApplied: 'VideoStreamSettingsPartiallyApplied',
    UnsupportedRecorder: 'UnsupportedRecorder',
    UnsupportedDevice: 'UnsupportedDevice',
    AddDeviceError: 'AddDeviceError',
    AddDeviceErrorNoFreeChannels: 'AddDeviceErrorNoFreeChannels',
    AddDeviceErrorRemovedByRecorder: 'AddDeviceErrorRemovedByRecorder',
    RemoveDeviceError: 'RemoveDeviceError',
    RecorderGetCapabilitiesError: 'RecorderGetCapabilitiesError',
    RecorderGetCapabilitiesErrorCapabilities: 'RecorderGetCapabilitiesErrorCapabilities',
    DeviceGetCapabilitiesError: 'DeviceGetCapabilitiesError',
    DeviceGetCapabilitiesErrorDeviceNotFound: 'DeviceGetCapabilitiesErrorDeviceNotFound',
    VideoChannelGetCapabilitiesError: 'VideoChannelGetCapabilitiesError',
    VideoChannelGetCapabilitiesErrorChannelNotFound: 'VideoChannelGetCapabilitiesErrorChannelNotFound',
    VideoStreamGetCapabilitiesError: 'VideoStreamGetCapabilitiesError',
    VideoStreamGetCapabilitiesErrorStreamNotFound: 'VideoStreamGetCapabilitiesErrorStreamNotFound',
    StreamingServerUnaccessible: 'StreamingServerUnaccessible',
    StreamingStreamNotFound: 'StreamingStreamNotFound',
    StreamingTracksAreEmpty: 'StreamingTracksAreEmpty',
    StreamingStreamCreateError: 'StreamingStreamCreateError',
    StreamingStreamStartError: 'StreamingStreamStartError'
} as const;

export type PeerConnectionStatusConditionsReasonEnum = typeof PeerConnectionStatusConditionsReasonEnum[keyof typeof PeerConnectionStatusConditionsReasonEnum];
export const PeerConnectionStatusConditionsStatusEnum = {
    Unknown: 'Unknown',
    True: 'True',
    False: 'False'
} as const;

export type PeerConnectionStatusConditionsStatusEnum = typeof PeerConnectionStatusConditionsStatusEnum[keyof typeof PeerConnectionStatusConditionsStatusEnum];
export const PeerConnectionStatusConditionsTypeEnum = {
    Connected: 'Connected',
    Added: 'Added',
    CapabilitiesReceived: 'CapabilitiesReceived',
    Configured: 'Configured',
    StreamCreated: 'StreamCreated',
    StreamStarted: 'StreamStarted'
} as const;

export type PeerConnectionStatusConditionsTypeEnum = typeof PeerConnectionStatusConditionsTypeEnum[keyof typeof PeerConnectionStatusConditionsTypeEnum];

/**
 * Role is the Schema for the roles API
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof Role
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof Role
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof Role
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {RoleSpec}
     * @memberof Role
     */
    'spec': RoleSpec;
    /**
     * 
     * @type {RoleStatus}
     * @memberof Role
     */
    'status'?: RoleStatus;
}
/**
 * RoleList is a list of Role
 * @export
 * @interface RoleList
 */
export interface RoleList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof RoleList
     */
    'apiVersion': string;
    /**
     * List of roles. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<Role>}
     * @memberof RoleList
     */
    'items': Array<Role>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof RoleList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof RoleList
     */
    'metadata': CameraListMetadata;
}
/**
 * RoleSpec defines the desired state of Role
 * @export
 * @interface RoleSpec
 */
export interface RoleSpec {
    /**
     * 
     * @type {Array<RoleSpecApiPermissions>}
     * @memberof RoleSpec
     */
    'apiPermissions'?: Array<RoleSpecApiPermissions>;
    /**
     * Should have unique items
     * @type {Array<string>}
     * @memberof RoleSpec
     */
    'applications'?: Array<RoleSpecApplicationsEnum>;
    /**
     * 
     * @type {string}
     * @memberof RoleSpec
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleSpec
     */
    'description'?: string;
    /**
     * Should have unique items
     * @type {Array<string>}
     * @memberof RoleSpec
     */
    'deviceAccessRefs'?: Array<string>;
    /**
     * Name is the human-friendly name of the role, which allows for e.g. capital letters and whitespace
     * @type {string}
     * @memberof RoleSpec
     */
    'name': string;
    /**
     * Should have unique items
     * @type {Array<string>}
     * @memberof RoleSpec
     */
    'uiPermissions'?: Array<RoleSpecUiPermissionsEnum>;
}

export const RoleSpecApplicationsEnum = {
    Admin: 'spotter-admin',
    Web: 'spotter-web'
} as const;

export type RoleSpecApplicationsEnum = typeof RoleSpecApplicationsEnum[keyof typeof RoleSpecApplicationsEnum];
export const RoleSpecUiPermissionsEnum = {
    ViewLivestream: 'view-livestream',
    ViewPlayback: 'view-playback',
    ExportVideo: 'export-video',
    ControlPtz: 'control-ptz',
    CreatePtz: 'create-ptz',
    ViewAlarms: 'view-alarms',
    ViewAlarmDetails: 'view-alarm-details'
} as const;

export type RoleSpecUiPermissionsEnum = typeof RoleSpecUiPermissionsEnum[keyof typeof RoleSpecUiPermissionsEnum];

/**
 * 
 * @export
 * @interface RoleSpecApiPermissions
 */
export interface RoleSpecApiPermissions {
    /**
     * Should have unique items Kind is the \"key\" of the array
     * @type {string}
     * @memberof RoleSpecApiPermissions
     */
    'kind': RoleSpecApiPermissionsKindEnum;
    /**
     * Should have unique items
     * @type {Array<string>}
     * @memberof RoleSpecApiPermissions
     */
    'verbs': Array<RoleSpecApiPermissionsVerbsEnum>;
}

export const RoleSpecApiPermissionsKindEnum = {
    Camera: 'Camera',
    VideoChannel: 'VideoChannel',
    DeviceAccess: 'DeviceAccess',
    View: 'View',
    Role: 'Role',
    User: 'User',
    Recorder: 'Recorder',
    Gatekeeper: 'Gatekeeper'
} as const;

export type RoleSpecApiPermissionsKindEnum = typeof RoleSpecApiPermissionsKindEnum[keyof typeof RoleSpecApiPermissionsKindEnum];
export const RoleSpecApiPermissionsVerbsEnum = {
    Read: 'read',
    Create: 'create',
    Update: 'update',
    Delete: 'delete',
    Bind: 'bind'
} as const;

export type RoleSpecApiPermissionsVerbsEnum = typeof RoleSpecApiPermissionsVerbsEnum[keyof typeof RoleSpecApiPermissionsVerbsEnum];

/**
 * RoleStatus defines the observed state of Role
 * @export
 * @interface RoleStatus
 */
export interface RoleStatus {
    /**
     * 
     * @type {Array<RoleStatusConditions>}
     * @memberof RoleStatus
     */
    'conditions'?: Array<RoleStatusConditions>;
}
/**
 * Condition contains details for one aspect of the current state of this API Resource. --- This struct is intended for direct use as an array at the field path .status.conditions.  For example,   type FooStatus struct{ // Represents the observations of a foo\'s current state. // Known .status.conditions.type are: \"Available\", \"Progressing\", and \"Degraded\" // +patchMergeKey=type // +patchStrategy=merge // +listType=map // +listMapKey=type Conditions []metav1.Condition `json:\"conditions,omitempty\" patchStrategy:\"merge\" patchMergeKey:\"type\" protobuf:\"bytes,1,rep,name=conditions\"`   // other fields }
 * @export
 * @interface RoleStatusConditions
 */
export interface RoleStatusConditions {
    /**
     * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
     * @type {string}
     * @memberof RoleStatusConditions
     */
    'lastTransitionTime': string;
    /**
     * message is a human readable message indicating details about the transition. This may be an empty string.
     * @type {string}
     * @memberof RoleStatusConditions
     */
    'message': string;
    /**
     * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
     * @type {number}
     * @memberof RoleStatusConditions
     */
    'observedGeneration'?: number;
    /**
     * reason contains a programmatic identifier indicating the reason for the condition\'s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
     * @type {string}
     * @memberof RoleStatusConditions
     */
    'reason': string;
    /**
     * status of the condition, one of True, False, Unknown.
     * @type {string}
     * @memberof RoleStatusConditions
     */
    'status': RoleStatusConditionsStatusEnum;
    /**
     * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
     * @type {string}
     * @memberof RoleStatusConditions
     */
    'type': string;
}

export const RoleStatusConditionsStatusEnum = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;

export type RoleStatusConditionsStatusEnum = typeof RoleStatusConditionsStatusEnum[keyof typeof RoleStatusConditionsStatusEnum];

/**
 * User is the Schema for the users API
 * @export
 * @interface User
 */
export interface User {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof User
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof User
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof User
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {UserSpec}
     * @memberof User
     */
    'spec': UserSpec;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    'status'?: UserStatus;
}
/**
 * UserList is a list of User
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof UserList
     */
    'apiVersion': string;
    /**
     * List of users. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<User>}
     * @memberof UserList
     */
    'items': Array<User>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof UserList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof UserList
     */
    'metadata': CameraListMetadata;
}
/**
 * UserRequest is the Schema for the userrequests API
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof UserRequest
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof UserRequest
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof UserRequest
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {UserRequestSpec}
     * @memberof UserRequest
     */
    'spec': UserRequestSpec;
    /**
     * 
     * @type {UserRequestStatus}
     * @memberof UserRequest
     */
    'status'?: UserRequestStatus;
}
/**
 * UserRequestList is a list of UserRequest
 * @export
 * @interface UserRequestList
 */
export interface UserRequestList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof UserRequestList
     */
    'apiVersion': string;
    /**
     * List of userrequests. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<UserRequest>}
     * @memberof UserRequestList
     */
    'items': Array<UserRequest>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof UserRequestList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof UserRequestList
     */
    'metadata': CameraListMetadata;
}
/**
 * UserRequestSpec defines the desired state of UserRequest
 * @export
 * @interface UserRequestSpec
 */
export interface UserRequestSpec {
    /**
     * Description allows specifying a comment about the user
     * @type {string}
     * @memberof UserRequestSpec
     */
    'description': string;
    /**
     * Email describes the email of the user, used for password setting and recovery Email functions as a primary key for the user, as it needs to be unique; it is also the user name.
     * @type {string}
     * @memberof UserRequestSpec
     */
    'email': string;
    /**
     * FirstName describes the first name of the user
     * @type {string}
     * @memberof UserRequestSpec
     */
    'firstName': string;
    /**
     * LanguageCode specifies the language of the user, e.g. \"en\", \"fi\" or \"se\"
     * @type {string}
     * @memberof UserRequestSpec
     */
    'languageCode': string;
    /**
     * FirstName describes the last name of the user
     * @type {string}
     * @memberof UserRequestSpec
     */
    'lastName': string;
    /**
     * Roles point to the name of the Roles in the same namespace This list should NOT include the user\'s \"own\" system-generated role for overrides, because the binding to that is implicit (always active) and cannot be removed.
     * @type {Array<string>}
     * @memberof UserRequestSpec
     */
    'roles': Array<string>;
}
/**
 * UserRequestStatus defines the observed state of UserRequest
 * @export
 * @interface UserRequestStatus
 */
export interface UserRequestStatus {
    /**
     * Conditions specify available conditions of the user, the following are well-defined: Created=true, false, unknown Can post back here in case of errors
     * @type {Array<RoleStatusConditions>}
     * @memberof UserRequestStatus
     */
    'conditions'?: Array<RoleStatusConditions>;
}
/**
 * UserSpec defines the desired state of User
 * @export
 * @interface UserSpec
 */
export interface UserSpec {
    /**
     * Description allows specifying a comment about the user
     * @type {string}
     * @memberof UserSpec
     */
    'description': string;
    /**
     * Email describes the email of the user, used for password setting and recovery Email functions as a primary key for the user, as it needs to be unique; it is also the user name.
     * @type {string}
     * @memberof UserSpec
     */
    'email': string;
    /**
     * FirstName describes the first name of the user
     * @type {string}
     * @memberof UserSpec
     */
    'firstName': string;
    /**
     * LanguageCode specifies the language of the user, e.g. \"en\", \"fi\" or \"se\"
     * @type {string}
     * @memberof UserSpec
     */
    'languageCode': string;
    /**
     * FirstName describes the last name of the user
     * @type {string}
     * @memberof UserSpec
     */
    'lastName': string;
    /**
     * 
     * @type {UserSpecRequiredActions}
     * @memberof UserSpec
     */
    'requiredActions'?: UserSpecRequiredActions;
    /**
     * Roles point to the name of the Roles in the same namespace This list should NOT include the user\'s \"own\" system-generated role for overrides, because the binding to that is implicit (always active) and cannot be removed.
     * @type {Array<string>}
     * @memberof UserSpec
     */
    'roles': Array<string>;
}
/**
 * RequiredActions specify a timestamp when some request needs to be executed
 * @export
 * @interface UserSpecRequiredActions
 */
export interface UserSpecRequiredActions {
    /**
     * This can use execute-actions-email
     * @type {string}
     * @memberof UserSpecRequiredActions
     */
    'updatePassword'?: string;
    /**
     * Good to know: execute-actions-email doesn\'t work with CONFIGURE_OTP One can add multiple OTPs by chaining CONFIGURE_OTP requests
     * @type {string}
     * @memberof UserSpecRequiredActions
     */
    'updateTwoFactorAuth'?: string;
}
/**
 * UserStatus defines the observed state of User
 * @export
 * @interface UserStatus
 */
export interface UserStatus {
    /**
     * Conditions specify available conditions of the user, the following are well-defined: Synced=true, false, unknown
     * @type {Array<RoleStatusConditions>}
     * @memberof UserStatus
     */
    'conditions'?: Array<RoleStatusConditions>;
    /**
     * CreationTimestamp specifies when the user was created in Keycloak
     * @type {string}
     * @memberof UserStatus
     */
    'creationTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserStatus
     */
    'passwordSetTimestamp'?: string;
    /**
     * 
     * @type {UserStatusTwoFactorDevice}
     * @memberof UserStatus
     */
    'twoFactorDevice'?: UserStatusTwoFactorDevice;
    /**
     * VerifiedEmail specifies what the latest known email has been
     * @type {string}
     * @memberof UserStatus
     */
    'verifiedEmail'?: string;
}
/**
 * 
 * @export
 * @interface UserStatusTwoFactorDevice
 */
export interface UserStatusTwoFactorDevice {
    /**
     * 
     * @type {string}
     * @memberof UserStatusTwoFactorDevice
     */
    'creationTimestamp'?: string;
    /**
     * Data specifies provider-specific (Keycloak) data about the credential In Keycloak 20 it looks something like \'{\"subType\":\"totp\",\"digits\":6,\"counter\":0,\"period\":30,\"algorithm\":\"HmacSHA1\"}\'
     * @type {string}
     * @memberof UserStatusTwoFactorDevice
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserStatusTwoFactorDevice
     */
    'description'?: string;
}
/**
 * View is the Schema for the views API
 * @export
 * @interface View
 */
export interface View {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof View
     */
    'apiVersion': string;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof View
     */
    'kind': string;
    /**
     * 
     * @type {CameraMetadata}
     * @memberof View
     */
    'metadata': CameraMetadata;
    /**
     * 
     * @type {ViewSpec}
     * @memberof View
     */
    'spec': ViewSpec;
    /**
     * ViewStatus defines the observed state of View
     * @type {object}
     * @memberof View
     */
    'status'?: object;
}
/**
 * ViewList is a list of View
 * @export
 * @interface ViewList
 */
export interface ViewList {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof ViewList
     */
    'apiVersion': string;
    /**
     * List of views. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md
     * @type {Array<View>}
     * @memberof ViewList
     */
    'items': Array<View>;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof ViewList
     */
    'kind': string;
    /**
     * 
     * @type {CameraListMetadata}
     * @memberof ViewList
     */
    'metadata': CameraListMetadata;
}
/**
 * ViewSpec defines the desired state of View
 * @export
 * @interface ViewSpec
 */
export interface ViewSpec {
    /**
     * Shared controls whether this view is shared with all other users that have access to this deviceaccess
     * @type {boolean}
     * @memberof ViewSpec
     */
    'shared'?: boolean;
}
/**
 * DeleteOptions may be provided when deleting an API object.
 * @export
 * @interface MetaV1DeleteOptions
 */
export interface MetaV1DeleteOptions {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof MetaV1DeleteOptions
     */
    'apiVersion': string;
    /**
     * When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @type {Array<string>}
     * @memberof MetaV1DeleteOptions
     */
    'dryRun'?: Array<string>;
    /**
     * The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @type {number}
     * @memberof MetaV1DeleteOptions
     */
    'gracePeriodSeconds'?: number;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1DeleteOptions
     */
    'kind': string;
    /**
     * Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \"orphan\" finalizer will be added to/removed from the object\'s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @type {boolean}
     * @memberof MetaV1DeleteOptions
     */
    'orphanDependents'?: boolean;
    /**
     * 
     * @type {MetaV1DeleteOptionsPreconditions}
     * @memberof MetaV1DeleteOptions
     */
    'preconditions'?: MetaV1DeleteOptionsPreconditions;
    /**
     * Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \'Orphan\' - orphan the dependents; \'Background\' - allow the garbage collector to delete the dependents in the background; \'Foreground\' - a cascading policy that deletes all dependents in the foreground.
     * @type {string}
     * @memberof MetaV1DeleteOptions
     */
    'propagationPolicy'?: string;
}
/**
 * Must be fulfilled before a deletion is carried out. If not possible, a 409 Conflict status will be returned.
 * @export
 * @interface MetaV1DeleteOptionsPreconditions
 */
export interface MetaV1DeleteOptionsPreconditions {
    /**
     * Specifies the target ResourceVersion
     * @type {string}
     * @memberof MetaV1DeleteOptionsPreconditions
     */
    'resourceVersion'?: string;
    /**
     * Specifies the target UID.
     * @type {string}
     * @memberof MetaV1DeleteOptionsPreconditions
     */
    'uid'?: string;
}
/**
 * ListMeta describes metadata that synthetic resources must have, including lists and various status objects. A resource may have only one of {ObjectMeta, ListMeta}.
 * @export
 * @interface MetaV1ListMeta
 */
export interface MetaV1ListMeta {
    /**
     * continue may be set if the user set a limit on the number of items returned, and indicates that the server has more data available. The value is opaque and may be used to issue another request to the endpoint that served this list to retrieve the next set of available objects. Continuing a consistent list may not be possible if the server configuration has changed or more than a few minutes have passed. The resourceVersion field returned when using this continue value will be identical to the value in the first response, unless you have received this token from an error message.
     * @type {string}
     * @memberof MetaV1ListMeta
     */
    'continue'?: string;
    /**
     * remainingItemCount is the number of subsequent items in the list which are not included in this list response. If the list request contained label or field selectors, then the number of remaining items is unknown and the field will be left unset and omitted during serialization. If the list is complete (either because it is not chunking or because this is the last chunk), then there are no more remaining items and this field will be left unset and omitted during serialization. Servers older than v1.15 do not set this field. The intended use of the remainingItemCount is *estimating* the size of a collection. Clients should not rely on the remainingItemCount to be set or to be exact.
     * @type {number}
     * @memberof MetaV1ListMeta
     */
    'remainingItemCount'?: number;
    /**
     * String that identifies the server\'s internal version of this object that can be used by clients to determine when objects have changed. Value must be treated as opaque by clients and passed unmodified back to the server. Populated by the system. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof MetaV1ListMeta
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof MetaV1ListMeta
     */
    'selfLink'?: string;
}
/**
 * ManagedFieldsEntry is a workflow-id, a FieldSet and the group version of the resource that the fieldset applies to.
 * @export
 * @interface MetaV1ManagedFieldsEntry
 */
export interface MetaV1ManagedFieldsEntry {
    /**
     * APIVersion defines the version of this resource that this field set applies to. The format is \"group/version\" just like the top-level APIVersion field. It is necessary to track the version of a field set because it cannot be automatically converted.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'apiVersion': string;
    /**
     * FieldsType is the discriminator for the different fields format and version. There is currently only one possible value: \"FieldsV1\"
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'fieldsType'?: string;
    /**
     * FieldsV1 stores a set of fields in a data structure like a Trie, in JSON format.  Each key is either a \'.\' representing the field itself, and will always map to an empty set, or a string representing a sub-field or item. The string will follow one of these four formats: \'f:<name>\', where <name> is the name of a field in a struct, or key in a map \'v:<value>\', where <value> is the exact json formatted value of a list item \'i:<index>\', where <index> is position of a item in a list \'k:<keys>\', where <keys> is a map of  a list item\'s key fields to their unique values If a key maps to an empty Fields value, the field that key represents is part of the set.  The exact format is defined in sigs.k8s.io/structured-merge-diff
     * @type {object}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'fieldsV1'?: object;
    /**
     * Manager is an identifier of the workflow managing these fields.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'manager'?: string;
    /**
     * Operation is the type of operation which lead to this ManagedFieldsEntry being created. The only valid values for this field are \'Apply\' and \'Update\'.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'operation'?: string;
    /**
     * Subresource is the name of the subresource used to update that object, or empty string if the object was updated through the main resource. The value of this field is used to distinguish between managers, even if they share the same name. For example, a status update will be distinct from a regular update using the same manager name. Note that the APIVersion field is not related to the Subresource field and it always corresponds to the version of the main resource.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'subresource'?: string;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ManagedFieldsEntry
     */
    'time'?: string;
}
/**
 * ObjectMeta is metadata that all persisted resources must have, which includes all objects users must create.
 * @export
 * @interface MetaV1ObjectMeta
 */
export interface MetaV1ObjectMeta {
    /**
     * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations
     * @type {{ [key: string]: string; }}
     * @memberof MetaV1ObjectMeta
     */
    'annotations'?: { [key: string]: string; };
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'creationTimestamp'?: string;
    /**
     * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only.
     * @type {number}
     * @memberof MetaV1ObjectMeta
     */
    'deletionGracePeriodSeconds'?: number;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'deletionTimestamp'?: string;
    /**
     * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list.
     * @type {Array<string>}
     * @memberof MetaV1ObjectMeta
     */
    'finalizers'?: Array<string>;
    /**
     * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will return a 409.  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'generateName'?: string;
    /**
     * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only.
     * @type {number}
     * @memberof MetaV1ObjectMeta
     */
    'generation'?: number;
    /**
     * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/labels
     * @type {{ [key: string]: string; }}
     * @memberof MetaV1ObjectMeta
     */
    'labels'?: { [key: string]: string; };
    /**
     * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.
     * @type {Array<MetaV1ObjectMetaManagedFields>}
     * @memberof MetaV1ObjectMeta
     */
    'managedFields'?: Array<MetaV1ObjectMetaManagedFields>;
    /**
     * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'name': string;
    /**
     * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'namespace': string;
    /**
     * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller.
     * @type {Array<MetaV1ObjectMetaOwnerReferences>}
     * @memberof MetaV1ObjectMeta
     */
    'ownerReferences'?: Array<MetaV1ObjectMetaOwnerReferences>;
    /**
     * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'selfLink'?: string;
    /**
     * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1ObjectMeta
     */
    'uid'?: string;
}
/**
 * 
 * @export
 * @interface MetaV1ObjectMetaManagedFields
 */
export interface MetaV1ObjectMetaManagedFields {
    /**
     * APIVersion defines the version of this resource that this field set applies to. The format is \"group/version\" just like the top-level APIVersion field. It is necessary to track the version of a field set because it cannot be automatically converted.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'apiVersion': string;
    /**
     * FieldsType is the discriminator for the different fields format and version. There is currently only one possible value: \"FieldsV1\"
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'fieldsType'?: string;
    /**
     * FieldsV1 stores a set of fields in a data structure like a Trie, in JSON format.  Each key is either a \'.\' representing the field itself, and will always map to an empty set, or a string representing a sub-field or item. The string will follow one of these four formats: \'f:<name>\', where <name> is the name of a field in a struct, or key in a map \'v:<value>\', where <value> is the exact json formatted value of a list item \'i:<index>\', where <index> is position of a item in a list \'k:<keys>\', where <keys> is a map of  a list item\'s key fields to their unique values If a key maps to an empty Fields value, the field that key represents is part of the set.  The exact format is defined in sigs.k8s.io/structured-merge-diff
     * @type {object}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'fieldsV1'?: object;
    /**
     * Manager is an identifier of the workflow managing these fields.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'manager'?: string;
    /**
     * Operation is the type of operation which lead to this ManagedFieldsEntry being created. The only valid values for this field are \'Apply\' and \'Update\'.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'operation'?: string;
    /**
     * Subresource is the name of the subresource used to update that object, or empty string if the object was updated through the main resource. The value of this field is used to distinguish between managers, even if they share the same name. For example, a status update will be distinct from a regular update using the same manager name. Note that the APIVersion field is not related to the Subresource field and it always corresponds to the version of the main resource.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'subresource'?: string;
    /**
     * Time is a wrapper around time.Time which supports correct marshaling to YAML and JSON.  Wrappers are provided for many of the factory methods that the time package offers.
     * @type {string}
     * @memberof MetaV1ObjectMetaManagedFields
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface MetaV1ObjectMetaOwnerReferences
 */
export interface MetaV1ObjectMetaOwnerReferences {
    /**
     * API version of the referent.
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'apiVersion': string;
    /**
     * If true, AND if the owner has the \"foregroundDeletion\" finalizer, then the owner cannot be deleted from the key-value store until this reference is removed. See https://kubernetes.io/docs/concepts/architecture/garbage-collection/#foreground-deletion for how the garbage collector interacts with this field and enforces the foreground deletion. Defaults to false. To set this field, a user needs \"delete\" permission of the owner, otherwise 422 (Unprocessable Entity) will be returned.
     * @type {boolean}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'blockOwnerDeletion'?: boolean;
    /**
     * If true, this reference points to the managing controller.
     * @type {boolean}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'controller'?: boolean;
    /**
     * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'kind': string;
    /**
     * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'name': string;
    /**
     * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1ObjectMetaOwnerReferences
     */
    'uid': string;
}
/**
 * OwnerReference contains enough information to let you identify an owning object. An owning object must be in the same namespace as the dependent, or be cluster-scoped, so there is no namespace field.
 * @export
 * @interface MetaV1OwnerReference
 */
export interface MetaV1OwnerReference {
    /**
     * API version of the referent.
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'apiVersion': string;
    /**
     * If true, AND if the owner has the \"foregroundDeletion\" finalizer, then the owner cannot be deleted from the key-value store until this reference is removed. See https://kubernetes.io/docs/concepts/architecture/garbage-collection/#foreground-deletion for how the garbage collector interacts with this field and enforces the foreground deletion. Defaults to false. To set this field, a user needs \"delete\" permission of the owner, otherwise 422 (Unprocessable Entity) will be returned.
     * @type {boolean}
     * @memberof MetaV1OwnerReference
     */
    'blockOwnerDeletion'?: boolean;
    /**
     * If true, this reference points to the managing controller.
     * @type {boolean}
     * @memberof MetaV1OwnerReference
     */
    'controller'?: boolean;
    /**
     * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'kind': string;
    /**
     * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#names
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'name': string;
    /**
     * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1OwnerReference
     */
    'uid': string;
}
/**
 * Preconditions must be fulfilled before an operation (update, delete, etc.) is carried out.
 * @export
 * @interface MetaV1Preconditions
 */
export interface MetaV1Preconditions {
    /**
     * Specifies the target ResourceVersion
     * @type {string}
     * @memberof MetaV1Preconditions
     */
    'resourceVersion'?: string;
    /**
     * Specifies the target UID.
     * @type {string}
     * @memberof MetaV1Preconditions
     */
    'uid'?: string;
}
/**
 * Status is a return value for calls that don\'t return other objects.
 * @export
 * @interface MetaV1Status
 */
export interface MetaV1Status {
    /**
     * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
     * @type {string}
     * @memberof MetaV1Status
     */
    'apiVersion': string;
    /**
     * Suggested HTTP return code for this status, 0 if not set.
     * @type {number}
     * @memberof MetaV1Status
     */
    'code'?: number;
    /**
     * 
     * @type {MetaV1StatusDetails}
     * @memberof MetaV1Status
     */
    'details'?: MetaV1StatusDetails;
    /**
     * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1Status
     */
    'kind': string;
    /**
     * A human-readable description of the status of this operation.
     * @type {string}
     * @memberof MetaV1Status
     */
    'message'?: string;
    /**
     * 
     * @type {MetaV1StatusMetadata}
     * @memberof MetaV1Status
     */
    'metadata': MetaV1StatusMetadata;
    /**
     * A machine-readable description of why this operation is in the \"Failure\" status. If this value is empty there is no information available. A Reason clarifies an HTTP status code but does not override it.
     * @type {string}
     * @memberof MetaV1Status
     */
    'reason'?: string;
    /**
     * Status of the operation. One of: \"Success\" or \"Failure\". More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#spec-and-status
     * @type {string}
     * @memberof MetaV1Status
     */
    'status'?: string;
}
/**
 * StatusCause provides more information about an api.Status failure, including cases when multiple errors are encountered.
 * @export
 * @interface MetaV1StatusCause
 */
export interface MetaV1StatusCause {
    /**
     * The field of the resource that has caused this error, as named by its JSON serialization. May include dot and postfix notation for nested attributes. Arrays are zero-indexed.  Fields may appear more than once in an array of causes due to fields having multiple errors. Optional.  Examples:   \"name\" - the field \"name\" on the current resource   \"items[0].name\" - the field \"name\" on the first array entry in \"items\"
     * @type {string}
     * @memberof MetaV1StatusCause
     */
    'field'?: string;
    /**
     * A human-readable description of the cause of the error.  This field may be presented as-is to a reader.
     * @type {string}
     * @memberof MetaV1StatusCause
     */
    'message'?: string;
    /**
     * A machine-readable description of the cause of the error. If this value is empty there is no information available.
     * @type {string}
     * @memberof MetaV1StatusCause
     */
    'reason'?: string;
}
/**
 * Extended data associated with the reason.  Each reason may define its own extended details. This field is optional and the data returned is not guaranteed to conform to any schema except that defined by the reason type.
 * @export
 * @interface MetaV1StatusDetails
 */
export interface MetaV1StatusDetails {
    /**
     * The Causes array includes more details associated with the StatusReason failure. Not all StatusReasons may provide detailed causes.
     * @type {Array<MetaV1StatusDetailsCauses>}
     * @memberof MetaV1StatusDetails
     */
    'causes'?: Array<MetaV1StatusDetailsCauses>;
    /**
     * The group attribute of the resource associated with the status StatusReason.
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'group'?: string;
    /**
     * The kind attribute of the resource associated with the status StatusReason. On some operations may differ from the requested resource Kind. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'kind': string;
    /**
     * The name attribute of the resource associated with the status StatusReason (when there is a single name which can be described).
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'name': string;
    /**
     * If specified, the time in seconds before the operation should be retried. Some errors may indicate the client must take an alternate action - for those errors this field may indicate how long to wait before taking the alternate action.
     * @type {number}
     * @memberof MetaV1StatusDetails
     */
    'retryAfterSeconds'?: number;
    /**
     * UID of the resource. (when there is a single resource which can be described). More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names#uids
     * @type {string}
     * @memberof MetaV1StatusDetails
     */
    'uid'?: string;
}
/**
 * 
 * @export
 * @interface MetaV1StatusDetailsCauses
 */
export interface MetaV1StatusDetailsCauses {
    /**
     * The field of the resource that has caused this error, as named by its JSON serialization. May include dot and postfix notation for nested attributes. Arrays are zero-indexed.  Fields may appear more than once in an array of causes due to fields having multiple errors. Optional.  Examples:   \"name\" - the field \"name\" on the current resource   \"items[0].name\" - the field \"name\" on the first array entry in \"items\"
     * @type {string}
     * @memberof MetaV1StatusDetailsCauses
     */
    'field'?: string;
    /**
     * A human-readable description of the cause of the error.  This field may be presented as-is to a reader.
     * @type {string}
     * @memberof MetaV1StatusDetailsCauses
     */
    'message'?: string;
    /**
     * A machine-readable description of the cause of the error. If this value is empty there is no information available.
     * @type {string}
     * @memberof MetaV1StatusDetailsCauses
     */
    'reason'?: string;
}
/**
 * Standard list metadata. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
 * @export
 * @interface MetaV1StatusMetadata
 */
export interface MetaV1StatusMetadata {
    /**
     * continue may be set if the user set a limit on the number of items returned, and indicates that the server has more data available. The value is opaque and may be used to issue another request to the endpoint that served this list to retrieve the next set of available objects. Continuing a consistent list may not be possible if the server configuration has changed or more than a few minutes have passed. The resourceVersion field returned when using this continue value will be identical to the value in the first response, unless you have received this token from an error message.
     * @type {string}
     * @memberof MetaV1StatusMetadata
     */
    'continue'?: string;
    /**
     * remainingItemCount is the number of subsequent items in the list which are not included in this list response. If the list request contained label or field selectors, then the number of remaining items is unknown and the field will be left unset and omitted during serialization. If the list is complete (either because it is not chunking or because this is the last chunk), then there are no more remaining items and this field will be left unset and omitted during serialization. Servers older than v1.15 do not set this field. The intended use of the remainingItemCount is *estimating* the size of a collection. Clients should not rely on the remainingItemCount to be set or to be exact.
     * @type {number}
     * @memberof MetaV1StatusMetadata
     */
    'remainingItemCount'?: number;
    /**
     * String that identifies the server\'s internal version of this object that can be used by clients to determine when objects have changed. Value must be treated as opaque by clients and passed unmodified back to the server. Populated by the system. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
     * @type {string}
     * @memberof MetaV1StatusMetadata
     */
    'resourceVersion'?: string;
    /**
     * Deprecated: selfLink is a legacy read-only field that is no longer populated by the system.
     * @type {string}
     * @memberof MetaV1StatusMetadata
     */
    'selfLink'?: string;
}

/**
 * Api - axios parameter creator
 * @export
 */
export const ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create a Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedCamera: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {DeviceAccess} [comDeviceAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedDeviceAccess: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comDeviceAccess, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create an Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Organization} [comOrganization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedOrganization: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedPTZPreset: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comPTZPreset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedPeerConnection: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comPeerConnection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Role} [comRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedRole: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create an User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {User} [comUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedUser: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create an UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {UserRequest} [comUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedUserRequest: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedView: async (namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comView, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedCamera: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedDeviceAccess: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedOrganization: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedPTZPreset: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedPeerConnection: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedRole: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedUser: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedUserRequest: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete collection of View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedView: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteCollectionNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedCamera: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedCamera', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedDeviceAccess: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedDeviceAccess', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedOrganization: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedOrganization', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedPTZPreset: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedPTZPreset', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedPeerConnection: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedPeerConnection', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedRole: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedRole', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedUser: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedUser', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedUserRequest: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedUserRequest', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedView: async (name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNamespacedView', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (gracePeriodSeconds !== undefined) {
                localVarQueryParameter['gracePeriodSeconds'] = gracePeriodSeconds;
            }

            if (orphanDependents !== undefined) {
                localVarQueryParameter['orphanDependents'] = orphanDependents;
            }

            if (propagationPolicy !== undefined) {
                localVarQueryParameter['propagationPolicy'] = propagationPolicy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ioK8sApimachineryPkgApisMetaV1DeleteOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Camera
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCameraForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind DeviceAccess
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceAccessForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/deviceaccesses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedCamera: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedDeviceAccess: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedOrganization: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedPTZPreset: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedPeerConnection: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedRole: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedUser: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedUserRequest: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedView: async (namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('listNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Organization
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind PTZPreset
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPTZPresetForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/ptzpresets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind PeerConnection
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPeerConnectionForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/peerconnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind Role
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoleForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind User
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind UserRequest
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserRequestForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/userrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list objects of kind View
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listViewForAllNamespaces: async (allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowWatchBookmarks !== undefined) {
                localVarQueryParameter['allowWatchBookmarks'] = allowWatchBookmarks;
            }

            if (_continue !== undefined) {
                localVarQueryParameter['continue'] = _continue;
            }

            if (fieldSelector !== undefined) {
                localVarQueryParameter['fieldSelector'] = fieldSelector;
            }

            if (labelSelector !== undefined) {
                localVarQueryParameter['labelSelector'] = labelSelector;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }

            if (resourceVersionMatch !== undefined) {
                localVarQueryParameter['resourceVersionMatch'] = resourceVersionMatch;
            }

            if (sendInitialEvents !== undefined) {
                localVarQueryParameter['sendInitialEvents'] = sendInitialEvents;
            }

            if (timeoutSeconds !== undefined) {
                localVarQueryParameter['timeoutSeconds'] = timeoutSeconds;
            }

            if (watch !== undefined) {
                localVarQueryParameter['watch'] = watch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedCamera: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedCamera', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedCameraStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedCameraStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedCameraStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedDeviceAccess: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedDeviceAccess', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedOrganization: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedOrganization', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPTZPreset: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedPTZPreset', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPTZPresetStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedPTZPresetStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedPTZPresetStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPeerConnection: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedPeerConnection', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPeerConnectionStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedPeerConnectionStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedPeerConnectionStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedRole: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedRole', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedUser: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedUser', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedUserRequest: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedUserRequest', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedView: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedView', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * partially update status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedViewStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNamespacedViewStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('patchNamespacedViewStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/apply-patch+yaml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedCamera: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedCamera', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedCameraStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedCameraStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedCameraStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedDeviceAccess: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedDeviceAccess', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedOrganization: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedOrganization', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPTZPreset: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedPTZPreset', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPTZPresetStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedPTZPresetStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedPTZPresetStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPeerConnection: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedPeerConnection', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPeerConnectionStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedPeerConnectionStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedPeerConnectionStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedRole: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedRole', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedUser: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedUser', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedUserRequest: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedUserRequest', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedView: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedView', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * read status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedViewStatus: async (name: string, namespace: string, pretty?: string, resourceVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('readNamespacedViewStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('readNamespacedViewStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (resourceVersion !== undefined) {
                localVarQueryParameter['resourceVersion'] = resourceVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedCamera: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedCamera', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedCamera', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedCameraStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedCameraStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedCameraStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/cameras/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comCamera, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {DeviceAccess} [comDeviceAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedDeviceAccess: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedDeviceAccess', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedDeviceAccess', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/deviceaccesses/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comDeviceAccess, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Organization} [comOrganization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedOrganization: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedOrganization', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedOrganization', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/organizations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPTZPreset: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedPTZPreset', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedPTZPreset', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comPTZPreset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPTZPresetStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedPTZPresetStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedPTZPresetStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/ptzpresets/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comPTZPreset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPeerConnection: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedPeerConnection', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedPeerConnection', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comPeerConnection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPeerConnectionStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedPeerConnectionStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedPeerConnectionStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/peerconnections/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comPeerConnection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Role} [comRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedRole: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedRole', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedRole', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/roles/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {User} [comUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedUser: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedUser', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedUser', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/users/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {UserRequest} [comUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedUserRequest: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedUserRequest', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedUserRequest', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/userrequests/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedView: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedView', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedView', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comView, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * replace status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedViewStatus: async (name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceNamespacedViewStatus', 'name', name)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('replaceNamespacedViewStatus', 'namespace', namespace)
            const localVarPath = `/apis/system.mirasys.com/v1alpha1/namespaces/{namespace}/views/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fieldManager !== undefined) {
                localVarQueryParameter['fieldManager'] = fieldManager;
            }

            if (fieldValidation !== undefined) {
                localVarQueryParameter['fieldValidation'] = fieldValidation;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comView, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Api - functional programming interface
 * @export
 */
export const ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiAxiosParamCreator(configuration)
    return {
        /**
         * create a Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedCamera(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedCamera(namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {DeviceAccess} [comDeviceAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedDeviceAccess(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedDeviceAccess(namespace, pretty, dryRun, fieldManager, fieldValidation, comDeviceAccess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create an Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Organization} [comOrganization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedOrganization(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedOrganization(namespace, pretty, dryRun, fieldManager, fieldValidation, comOrganization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedPTZPreset(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedPTZPreset(namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedPeerConnection(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedPeerConnection(namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Role} [comRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedRole(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedRole(namespace, pretty, dryRun, fieldManager, fieldValidation, comRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create an User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {User} [comUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedUser(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedUser(namespace, pretty, dryRun, fieldManager, fieldValidation, comUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create an UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {UserRequest} [comUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedUserRequest(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedUserRequest(namespace, pretty, dryRun, fieldManager, fieldValidation, comUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamespacedView(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNamespacedView(namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedCamera(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedCamera(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedDeviceAccess(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedDeviceAccess(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedOrganization(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedOrganization(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedPTZPreset(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedPTZPreset(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedPeerConnection(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedPeerConnection(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedRole(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedRole(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedUser(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedUser(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedUserRequest(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedUserRequest(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete collection of View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollectionNamespacedView(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollectionNamespacedView(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedCamera(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedDeviceAccess(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedOrganization(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedPTZPreset(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedPeerConnection(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedRole(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedUser(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedUserRequest(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaV1Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamespacedView(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Camera
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCameraForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCameraForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind DeviceAccess
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceAccessForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAccessList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceAccessForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedCamera(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedCamera(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedDeviceAccess(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAccessList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedDeviceAccess(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedOrganization(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedOrganization(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedPTZPreset(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPresetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedPTZPreset(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedPeerConnection(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedPeerConnection(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedRole(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedRole(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedUser(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedUser(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedUserRequest(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedUserRequest(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNamespacedView(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNamespacedView(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Organization
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizationForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizationForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind PTZPreset
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPTZPresetForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPresetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPTZPresetForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind PeerConnection
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPeerConnectionForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPeerConnectionForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind Role
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoleForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoleForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind User
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind UserRequest
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserRequestForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserRequestForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list objects of kind View
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listViewForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listViewForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedCamera(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedCameraStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedCameraStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedDeviceAccess(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedOrganization(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedPTZPreset(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedPTZPresetStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedPeerConnection(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedPeerConnectionStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedRole(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedUser(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedUserRequest(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedView(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * partially update status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNamespacedViewStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNamespacedViewStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedCamera(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedCamera(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedCameraStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedCameraStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedDeviceAccess(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedOrganization(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedOrganization(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedPTZPreset(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedPTZPreset(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedPTZPresetStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedPeerConnection(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedPeerConnection(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedPeerConnectionStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedRole(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedRole(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedUser(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedUser(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedUserRequest(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedUserRequest(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedView(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedView(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * read status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNamespacedViewStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNamespacedViewStatus(name, namespace, pretty, resourceVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedCamera(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedCameraStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Camera>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedCameraStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {DeviceAccess} [comDeviceAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedDeviceAccess(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDeviceAccess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Organization} [comOrganization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedOrganization(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comOrganization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedPTZPreset(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PTZPreset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedPTZPresetStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedPeerConnection(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeerConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedPeerConnectionStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Role} [comRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedRole(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {User} [comUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedUser(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {UserRequest} [comUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedUserRequest(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedView(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * replace status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceNamespacedViewStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceNamespacedViewStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Api - factory interface
 * @export
 */
export const ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiFp(configuration)
    return {
        /**
         * create a Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedCamera(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: any): AxiosPromise<Camera> {
            return localVarFp.createNamespacedCamera(namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options).then((request) => request(axios, basePath));
        },
        /**
         * create a DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {DeviceAccess} [comDeviceAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedDeviceAccess(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options?: any): AxiosPromise<DeviceAccess> {
            return localVarFp.createNamespacedDeviceAccess(namespace, pretty, dryRun, fieldManager, fieldValidation, comDeviceAccess, options).then((request) => request(axios, basePath));
        },
        /**
         * create an Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Organization} [comOrganization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedOrganization(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options?: any): AxiosPromise<Organization> {
            return localVarFp.createNamespacedOrganization(namespace, pretty, dryRun, fieldManager, fieldValidation, comOrganization, options).then((request) => request(axios, basePath));
        },
        /**
         * create a PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedPTZPreset(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.createNamespacedPTZPreset(namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options).then((request) => request(axios, basePath));
        },
        /**
         * create a PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedPeerConnection(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.createNamespacedPeerConnection(namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options).then((request) => request(axios, basePath));
        },
        /**
         * create a Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Role} [comRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedRole(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options?: any): AxiosPromise<Role> {
            return localVarFp.createNamespacedRole(namespace, pretty, dryRun, fieldManager, fieldValidation, comRole, options).then((request) => request(axios, basePath));
        },
        /**
         * create an User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {User} [comUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedUser(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options?: any): AxiosPromise<User> {
            return localVarFp.createNamespacedUser(namespace, pretty, dryRun, fieldManager, fieldValidation, comUser, options).then((request) => request(axios, basePath));
        },
        /**
         * create an UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {UserRequest} [comUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedUserRequest(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options?: any): AxiosPromise<UserRequest> {
            return localVarFp.createNamespacedUserRequest(namespace, pretty, dryRun, fieldManager, fieldValidation, comUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create a View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamespacedView(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: any): AxiosPromise<View> {
            return localVarFp.createNamespacedView(namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedCamera(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedCamera(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedDeviceAccess(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedDeviceAccess(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedOrganization(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedOrganization(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedPTZPreset(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedPTZPreset(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedPeerConnection(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedPeerConnection(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedRole(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedRole(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedUser(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedUser(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedUserRequest(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedUserRequest(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete collection of View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollectionNamespacedView(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteCollectionNamespacedView(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedCamera(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedDeviceAccess(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedOrganization(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedPTZPreset(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedPeerConnection(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedRole(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedUser(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedUserRequest(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
         * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
         * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
         * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: any): AxiosPromise<MetaV1Status> {
            return localVarFp.deleteNamespacedView(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Camera
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCameraForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<CameraList> {
            return localVarFp.listCameraForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind DeviceAccess
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceAccessForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<DeviceAccessList> {
            return localVarFp.listDeviceAccessForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedCamera(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<CameraList> {
            return localVarFp.listNamespacedCamera(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedDeviceAccess(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<DeviceAccessList> {
            return localVarFp.listNamespacedDeviceAccess(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedOrganization(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<OrganizationList> {
            return localVarFp.listNamespacedOrganization(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedPTZPreset(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<PTZPresetList> {
            return localVarFp.listNamespacedPTZPreset(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedPeerConnection(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<PeerConnectionList> {
            return localVarFp.listNamespacedPeerConnection(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedRole(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<RoleList> {
            return localVarFp.listNamespacedRole(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedUser(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<UserList> {
            return localVarFp.listNamespacedUser(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedUserRequest(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<UserRequestList> {
            return localVarFp.listNamespacedUserRequest(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNamespacedView(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<ViewList> {
            return localVarFp.listNamespacedView(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Organization
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<OrganizationList> {
            return localVarFp.listOrganizationForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind PTZPreset
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPTZPresetForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<PTZPresetList> {
            return localVarFp.listPTZPresetForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind PeerConnection
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPeerConnectionForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<PeerConnectionList> {
            return localVarFp.listPeerConnectionForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind Role
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoleForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<RoleList> {
            return localVarFp.listRoleForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind User
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<UserList> {
            return localVarFp.listUserForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind UserRequest
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserRequestForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<UserRequestList> {
            return localVarFp.listUserRequestForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * list objects of kind View
         * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
         * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
         * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
         * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
         * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
         * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
         * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listViewForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: any): AxiosPromise<ViewList> {
            return localVarFp.listViewForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Camera> {
            return localVarFp.patchNamespacedCamera(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedCameraStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Camera> {
            return localVarFp.patchNamespacedCameraStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<DeviceAccess> {
            return localVarFp.patchNamespacedDeviceAccess(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Organization> {
            return localVarFp.patchNamespacedOrganization(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.patchNamespacedPTZPreset(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.patchNamespacedPTZPresetStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.patchNamespacedPeerConnection(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.patchNamespacedPeerConnectionStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<Role> {
            return localVarFp.patchNamespacedRole(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<User> {
            return localVarFp.patchNamespacedUser(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<UserRequest> {
            return localVarFp.patchNamespacedUserRequest(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<View> {
            return localVarFp.patchNamespacedView(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * partially update status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNamespacedViewStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: any): AxiosPromise<View> {
            return localVarFp.patchNamespacedViewStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedCamera(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Camera> {
            return localVarFp.readNamespacedCamera(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedCameraStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Camera> {
            return localVarFp.readNamespacedCameraStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<DeviceAccess> {
            return localVarFp.readNamespacedDeviceAccess(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedOrganization(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.readNamespacedOrganization(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPTZPreset(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.readNamespacedPTZPreset(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.readNamespacedPTZPresetStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPeerConnection(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.readNamespacedPeerConnection(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.readNamespacedPeerConnectionStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedRole(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<Role> {
            return localVarFp.readNamespacedRole(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedUser(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<User> {
            return localVarFp.readNamespacedUser(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedUserRequest(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<UserRequest> {
            return localVarFp.readNamespacedUserRequest(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedView(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<View> {
            return localVarFp.readNamespacedView(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * read status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNamespacedViewStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: any): AxiosPromise<View> {
            return localVarFp.readNamespacedViewStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: any): AxiosPromise<Camera> {
            return localVarFp.replaceNamespacedCamera(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified Camera
         * @param {string} name name of the Camera
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Camera} [comCamera] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedCameraStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: any): AxiosPromise<Camera> {
            return localVarFp.replaceNamespacedCameraStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified DeviceAccess
         * @param {string} name name of the DeviceAccess
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {DeviceAccess} [comDeviceAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options?: any): AxiosPromise<DeviceAccess> {
            return localVarFp.replaceNamespacedDeviceAccess(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDeviceAccess, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified Organization
         * @param {string} name name of the Organization
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Organization} [comOrganization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options?: any): AxiosPromise<Organization> {
            return localVarFp.replaceNamespacedOrganization(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comOrganization, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.replaceNamespacedPTZPreset(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified PTZPreset
         * @param {string} name name of the PTZPreset
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PTZPreset} [comPTZPreset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: any): AxiosPromise<PTZPreset> {
            return localVarFp.replaceNamespacedPTZPresetStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.replaceNamespacedPeerConnection(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified PeerConnection
         * @param {string} name name of the PeerConnection
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {PeerConnection} [comPeerConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: any): AxiosPromise<PeerConnection> {
            return localVarFp.replaceNamespacedPeerConnectionStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified Role
         * @param {string} name name of the Role
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {Role} [comRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options?: any): AxiosPromise<Role> {
            return localVarFp.replaceNamespacedRole(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRole, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified User
         * @param {string} name name of the User
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {User} [comUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options?: any): AxiosPromise<User> {
            return localVarFp.replaceNamespacedUser(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comUser, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified UserRequest
         * @param {string} name name of the UserRequest
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {UserRequest} [comUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options?: any): AxiosPromise<UserRequest> {
            return localVarFp.replaceNamespacedUserRequest(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * replace the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: any): AxiosPromise<View> {
            return localVarFp.replaceNamespacedView(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options).then((request) => request(axios, basePath));
        },
        /**
         * replace status of the specified View
         * @param {string} name name of the View
         * @param {string} namespace object name and auth scope, such as for teams and projects
         * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
         * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
         * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
         * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
         * @param {View} [comView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceNamespacedViewStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: any): AxiosPromise<View> {
            return localVarFp.replaceNamespacedViewStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Api - object-oriented interface
 * @export
 * @class Api
 * @extends {BaseAPI}
 */
export class Api extends BaseAPI {
    /**
     * create a Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Camera} [comCamera] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedCamera(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedCamera(namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {DeviceAccess} [comDeviceAccess] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedDeviceAccess(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedDeviceAccess(namespace, pretty, dryRun, fieldManager, fieldValidation, comDeviceAccess, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create an Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Organization} [comOrganization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedOrganization(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedOrganization(namespace, pretty, dryRun, fieldManager, fieldValidation, comOrganization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {PTZPreset} [comPTZPreset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedPTZPreset(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedPTZPreset(namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {PeerConnection} [comPeerConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedPeerConnection(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedPeerConnection(namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Role} [comRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedRole(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedRole(namespace, pretty, dryRun, fieldManager, fieldValidation, comRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create an User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {User} [comUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedUser(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedUser(namespace, pretty, dryRun, fieldManager, fieldValidation, comUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create an UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {UserRequest} [comUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedUserRequest(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedUserRequest(namespace, pretty, dryRun, fieldManager, fieldValidation, comUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {View} [comView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public createNamespacedView(namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).createNamespacedView(namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedCamera(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedCamera(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedDeviceAccess(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedDeviceAccess(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedOrganization(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedOrganization(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedPTZPreset(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedPTZPreset(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedPeerConnection(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedPeerConnection(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedRole(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedRole(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedUser(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedUser(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedUserRequest(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedUserRequest(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete collection of View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteCollectionNamespacedView(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteCollectionNamespacedView(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedCamera(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a DeviceAccess
     * @param {string} name name of the DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedDeviceAccess(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an Organization
     * @param {string} name name of the Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedOrganization(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedPTZPreset(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedPeerConnection(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a Role
     * @param {string} name name of the Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedRole(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an User
     * @param {string} name name of the User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedUser(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an UserRequest
     * @param {string} name name of the UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedUserRequest(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {number} [gracePeriodSeconds] The duration in seconds before the object should be deleted. Value must be non-negative integer. The value zero indicates delete immediately. If this value is nil, the default grace period for the specified type will be used. Defaults to a per object value if not specified. zero means delete immediately.
     * @param {boolean} [orphanDependents] Deprecated: please use the PropagationPolicy, this field will be deprecated in 1.7. Should the dependent objects be orphaned. If true/false, the \&quot;orphan\&quot; finalizer will be added to/removed from the object\&#39;s finalizers list. Either this field or PropagationPolicy may be set, but not both.
     * @param {string} [propagationPolicy] Whether and how garbage collection will be performed. Either this field or OrphanDependents may be set, but not both. The default policy is decided by the existing finalizer set in the metadata.finalizers and the resource-specific default policy. Acceptable values are: \&#39;Orphan\&#39; - orphan the dependents; \&#39;Background\&#39; - allow the garbage collector to delete the dependents in the background; \&#39;Foreground\&#39; - a cascading policy that deletes all dependents in the foreground.
     * @param {MetaV1DeleteOptions} [ioK8sApimachineryPkgApisMetaV1DeleteOptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public deleteNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, gracePeriodSeconds?: number, orphanDependents?: boolean, propagationPolicy?: string, ioK8sApimachineryPkgApisMetaV1DeleteOptions?: MetaV1DeleteOptions, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).deleteNamespacedView(name, namespace, pretty, dryRun, gracePeriodSeconds, orphanDependents, propagationPolicy, ioK8sApimachineryPkgApisMetaV1DeleteOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Camera
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listCameraForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listCameraForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind DeviceAccess
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listDeviceAccessForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listDeviceAccessForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedCamera(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedCamera(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedDeviceAccess(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedDeviceAccess(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedOrganization(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedOrganization(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedPTZPreset(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedPTZPreset(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedPeerConnection(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedPeerConnection(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedRole(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedRole(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedUser(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedUser(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedUserRequest(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedUserRequest(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listNamespacedView(namespace: string, pretty?: string, allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listNamespacedView(namespace, pretty, allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Organization
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listOrganizationForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listOrganizationForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind PTZPreset
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listPTZPresetForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listPTZPresetForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind PeerConnection
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listPeerConnectionForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listPeerConnectionForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind Role
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listRoleForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listRoleForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind User
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listUserForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listUserForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind UserRequest
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listUserRequestForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listUserRequestForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list objects of kind View
     * @param {boolean} [allowWatchBookmarks] allowWatchBookmarks requests watch events with type \&quot;BOOKMARK\&quot;. Servers that do not implement bookmarks may ignore this flag and bookmarks are sent at the server\&#39;s discretion. Clients should not assume bookmarks are returned at any specific interval, nor may they assume the server will send any BOOKMARK event during a session. If this is not a watch, this field is ignored.
     * @param {string} [_continue] The continue option should be set when retrieving more results from the server. Since this value is server defined, clients may only use the continue value from a previous query result with identical query parameters (except for the value of continue) and the server may reject a continue value it does not recognize. If the specified continue value is no longer valid whether due to expiration (generally five to fifteen minutes) or a configuration change on the server, the server will respond with a 410 ResourceExpired error together with a continue token. If the client needs a consistent list, it must restart their list without the continue field. Otherwise, the client may send another list request with the token received with the 410 error, the server will respond with a list starting from the next key, but from the latest snapshot, which is inconsistent from the previous list results - objects that are created, modified, or deleted after the first list request will be included in the response, as long as their keys are after the \&quot;next key\&quot;.  This field is not supported when watch is true. Clients may start a watch from the last resourceVersion value returned by the server and not miss any modifications.
     * @param {string} [fieldSelector] A selector to restrict the list of returned objects by their fields. Defaults to everything.
     * @param {string} [labelSelector] A selector to restrict the list of returned objects by their labels. Defaults to everything.
     * @param {number} [limit] limit is a maximum number of responses to return for a list call. If more items exist, the server will set the &#x60;continue&#x60; field on the list metadata to a value that can be used with the same initial query to retrieve the next set of results. Setting a limit may return fewer than the requested amount of items (up to zero items) in the event all requested objects are filtered out and clients should only use the presence of the continue field to determine whether more results are available. Servers may choose not to support the limit argument and will return all of the available results. If limit is specified and the continue field is empty, clients may assume that no more results are available. This field is not supported if watch is true.  The server guarantees that the objects returned when using continue will be identical to issuing a single list call without a limit - that is, no objects created, modified, or deleted after the first request is issued will be included in any subsequent continued requests. This is sometimes referred to as a consistent snapshot, and ensures that a client that is using limit to receive smaller chunks of a very large result can ensure they see all possible objects. If objects are updated during a chunked list the version of the object that was present at the time the first list result was calculated is returned.
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {string} [resourceVersionMatch] resourceVersionMatch determines how resourceVersion is applied to list calls. It is highly recommended that resourceVersionMatch be set for list calls where resourceVersion is set See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {boolean} [sendInitialEvents] &#x60;sendInitialEvents&#x3D;true&#x60; may be set together with &#x60;watch&#x3D;true&#x60;. In that case, the watch stream will begin with synthetic events to produce the current state of objects in the collection. Once all such events have been sent, a synthetic \&quot;Bookmark\&quot; event  will be sent. The bookmark will report the ResourceVersion (RV) corresponding to the set of objects, and be marked with &#x60;\&quot;k8s.io/initial-events-end\&quot;: \&quot;true\&quot;&#x60; annotation. Afterwards, the watch stream will proceed as usual, sending watch events corresponding to changes (subsequent to the RV) to objects watched.  When &#x60;sendInitialEvents&#x60; option is set, we require &#x60;resourceVersionMatch&#x60; option to also be set. The semantic of the watch request is as following: - &#x60;resourceVersionMatch&#x60; &#x3D; NotOlderThan   is interpreted as \&quot;data at least as new as the provided &#x60;resourceVersion&#x60;\&quot;   and the bookmark event is send when the state is synced   to a &#x60;resourceVersion&#x60; at least as fresh as the one provided by the ListOptions.   If &#x60;resourceVersion&#x60; is unset, this is interpreted as \&quot;consistent read\&quot; and the   bookmark event is send when the state is synced at least to the moment   when request started being processed. - &#x60;resourceVersionMatch&#x60; set to any other value or unset   Invalid error is returned.  Defaults to true if &#x60;resourceVersion&#x3D;\&quot;\&quot;&#x60; or &#x60;resourceVersion&#x3D;\&quot;0\&quot;&#x60; (for backward compatibility reasons) and to false otherwise.
     * @param {number} [timeoutSeconds] Timeout for the list/watch call. This limits the duration of the call, regardless of any activity or inactivity.
     * @param {boolean} [watch] Watch for changes to the described resources and return them as a stream of add, update, and remove notifications. Specify resourceVersion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public listViewForAllNamespaces(allowWatchBookmarks?: boolean, _continue?: string, fieldSelector?: string, labelSelector?: string, limit?: number, pretty?: string, resourceVersion?: string, resourceVersionMatch?: string, sendInitialEvents?: boolean, timeoutSeconds?: number, watch?: boolean, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).listViewForAllNamespaces(allowWatchBookmarks, _continue, fieldSelector, labelSelector, limit, pretty, resourceVersion, resourceVersionMatch, sendInitialEvents, timeoutSeconds, watch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedCamera(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedCameraStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedCameraStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified DeviceAccess
     * @param {string} name name of the DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedDeviceAccess(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified Organization
     * @param {string} name name of the Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedOrganization(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedPTZPreset(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedPTZPresetStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedPeerConnection(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedPeerConnectionStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified Role
     * @param {string} name name of the Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedRole(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified User
     * @param {string} name name of the User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedUser(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified UserRequest
     * @param {string} name name of the UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedUserRequest(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update the specified View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedView(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * partially update status of the specified View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint. This field is required for apply requests (application/apply-patch) but optional for non-apply patch types (JsonPatch, MergePatch, StrategicMergePatch).
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {boolean} [force] Force is going to \&quot;force\&quot; Apply requests. It means user will re-acquire conflicting fields owned by other people. Force flag must be unset for non-apply patch requests.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public patchNamespacedViewStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, force?: boolean, body?: object, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).patchNamespacedViewStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, force, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedCamera(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedCamera(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedCameraStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedCameraStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified DeviceAccess
     * @param {string} name name of the DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedDeviceAccess(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified Organization
     * @param {string} name name of the Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedOrganization(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedOrganization(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedPTZPreset(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedPTZPreset(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedPTZPresetStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedPeerConnection(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedPeerConnection(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedPeerConnectionStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified Role
     * @param {string} name name of the Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedRole(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedRole(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified User
     * @param {string} name name of the User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedUser(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedUser(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified UserRequest
     * @param {string} name name of the UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedUserRequest(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedUserRequest(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read the specified View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedView(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedView(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * read status of the specified View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [resourceVersion] resourceVersion sets a constraint on what resource versions a request may be served from. See https://kubernetes.io/docs/reference/using-api/api-concepts/#resource-versions for details.  Defaults to unset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public readNamespacedViewStatus(name: string, namespace: string, pretty?: string, resourceVersion?: string, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).readNamespacedViewStatus(name, namespace, pretty, resourceVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Camera} [comCamera] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedCamera(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedCamera(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified Camera
     * @param {string} name name of the Camera
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Camera} [comCamera] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedCameraStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comCamera?: Camera, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedCameraStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comCamera, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified DeviceAccess
     * @param {string} name name of the DeviceAccess
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {DeviceAccess} [comDeviceAccess] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedDeviceAccess(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comDeviceAccess?: DeviceAccess, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedDeviceAccess(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comDeviceAccess, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified Organization
     * @param {string} name name of the Organization
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Organization} [comOrganization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedOrganization(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comOrganization?: Organization, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedOrganization(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comOrganization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {PTZPreset} [comPTZPreset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedPTZPreset(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedPTZPreset(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified PTZPreset
     * @param {string} name name of the PTZPreset
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {PTZPreset} [comPTZPreset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedPTZPresetStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPTZPreset?: PTZPreset, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedPTZPresetStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPTZPreset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {PeerConnection} [comPeerConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedPeerConnection(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedPeerConnection(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified PeerConnection
     * @param {string} name name of the PeerConnection
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {PeerConnection} [comPeerConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedPeerConnectionStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comPeerConnection?: PeerConnection, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedPeerConnectionStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comPeerConnection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified Role
     * @param {string} name name of the Role
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {Role} [comRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedRole(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comRole?: Role, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedRole(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified User
     * @param {string} name name of the User
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {User} [comUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedUser(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUser?: User, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedUser(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified UserRequest
     * @param {string} name name of the UserRequest
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {UserRequest} [comUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedUserRequest(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comUserRequest?: UserRequest, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedUserRequest(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace the specified View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {View} [comView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedView(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedView(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * replace status of the specified View
     * @param {string} name name of the View
     * @param {string} namespace object name and auth scope, such as for teams and projects
     * @param {string} [pretty] If \&#39;true\&#39;, then the output is pretty printed.
     * @param {string} [dryRun] When present, indicates that modifications should not be persisted. An invalid or unrecognized dryRun directive will result in an error response and no further processing of the request. Valid values are: - All: all dry run stages will be processed
     * @param {string} [fieldManager] fieldManager is a name associated with the actor or entity that is making these changes. The value must be less than or 128 characters long, and only contain printable characters, as defined by https://golang.org/pkg/unicode/#IsPrint.
     * @param {string} [fieldValidation] fieldValidation instructs the server on how to handle objects in the request (POST/PUT/PATCH) containing unknown or duplicate fields. Valid values are: - Ignore: This will ignore any unknown fields that are silently dropped from the object, and will ignore all but the last duplicate field that the decoder encounters. This is the default behavior prior to v1.23. - Warn: This will send a warning via the standard warning response header for each unknown field that is dropped from the object, and for each duplicate field that is encountered. The request will still succeed if there are no other errors, and will only persist the last of any duplicate fields. This is the default in v1.23+ - Strict: This will fail the request with a BadRequest error if any unknown fields would be dropped from the object, or if any duplicate fields are present. The error returned from the server will contain all unknown and duplicate fields encountered.
     * @param {View} [comView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Api
     */
    public replaceNamespacedViewStatus(name: string, namespace: string, pretty?: string, dryRun?: string, fieldManager?: string, fieldValidation?: string, comView?: View, options?: AxiosRequestConfig) {
        return ApiFp(this.configuration).replaceNamespacedViewStatus(name, namespace, pretty, dryRun, fieldManager, fieldValidation, comView, options).then((request) => request(this.axios, this.basePath));
    }
}



