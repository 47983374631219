import React, { FC } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'

export interface BreadcrumbItem {
  text: string
  link?: string
}

export interface BreadcrumbProps {
  className?: string
  items: BreadcrumbItem[]
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ className, items }) => {
  return (
    <Breadcrumbs
      className={classNames('pb-4', className)}
      separator={<NavigateNext fontSize="small" />}
      data-testid="breadcrumb"
    >
      {items.map((item, i) =>
        item.link ? (
          <Link
            key={i}
            className="text-secondary-400 decoration-0"
            to={item.link}
          >
            {item.text}
          </Link>
        ) : (
          <span key={i}>{item.text}</span>
        )
      )}
    </Breadcrumbs>
  )
}
