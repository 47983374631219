import { ReactNode } from 'react'
import classNames from 'classnames'
import { Button } from '@mui/material'
import CloseButtonIcon from '@mui/icons-material/Close'
import { PanelSection } from './PanelSection'

export interface PanelProps {
  className?: string
  open?: boolean
  title?: string
  header?: ReactNode
  submitButtonText?: string
  isValid?: boolean
  disableBackdrop?: boolean
  children?: ReactNode
  onClose?: () => void
  onSubmit?: () => void
}

export const Panel = ({
  className,
  open,
  title,
  header,
  submitButtonText,
  isValid,
  disableBackdrop,
  children,
  onSubmit,
  onClose,
}: PanelProps) => {
  const panelClass = classNames(
    'absolute top-0 right-0 z-100 w-[537px] h-screen min-w-80 flex flex-col',
    'bg-neutral-800 text-xs font-normal text-grey rounded-l-xl shadow-right-panel transition-all',
    !open && 'translate-x-[120%] pointer-events-none',
    className
  )

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 z-100 h-screen w-screen overflow-hidden transition-all',
        !open && 'pointer-events-none opacity-0'
      )}
    >
      <div
        className={classNames(
          'h-full w-full bg-black bg-opacity-60 transition-all',
          !open && 'pointer-events-none'
        )}
        onClick={disableBackdrop ? undefined : onClose}
      />

      <section className={panelClass}>
        {header || (
          <div className="flex flex-row items-center justify-between rounded-none px-10 pt-8 text-white">
            <h4 className="text-lg font-bold">{title}</h4>
            <div
              className="cursor-pointer"
              onClick={onClose}
              data-testid="panel-close-button"
            >
              <CloseButtonIcon />
            </div>
          </div>
        )}
        <main className="flex h-0 flex-1 flex-col overflow-auto">
          {children}
        </main>
        <footer className="mt-8 mb-6 px-10">
          <Button
            disabled={isValid === false}
            onClick={onSubmit}
            data-testid="panel-submit-button"
          >
            {submitButtonText}
          </Button>
        </footer>
      </section>
    </div>
  )
}

Panel.Section = PanelSection
